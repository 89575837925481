<template>
  <Loader :loading="isLoading" :full-page="true">
    <div class="kt-portlet">
      <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              :value="profile.deposit_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.deposit_balance_btc || null"
              :value-in-eur="profile.deposit_balance_eur || null"
              :value-in-eth="profile.deposit_balance_eth || null"
              dont-format
              icon="invest"
              :title="$t('dashboard.invested_balance')"
              :dots-tooltip="$t('dashboard.invested_balance_tooltip')"
              :has-warning="!!profile.deposit_balance_below_min"
              :info-text="$t('dashboard.invested_balance_low')"
              :info-tooltip="$t('dashboard.invested_balance_low_tooltip',{ minimum: profile.invested_balance_minimum_display})"
              :info="profile.nft_claimed?$t('deposit.nft_claimed'):(profile.deposit_has_idle?$t('deposit.idle_detected'):null)"
              type="masternoded"
              :nft_claimed="!!profile.nft_claimed"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
                :value="profile.withdraw_balance_display_nc"
                :currency="profile.currency_sign"
                :decimals="profile.account_selected === 'BTC' ? 8 : 2"
                :value-in-btc="profile.withdraw_balance_btc || null"
                :value-in-eur="profile.withdraw_balance_eur || null"
                :value-in-eth="profile.withdraw_balance_eth || null"
                dont-format
                color="warning"
                icon="balance"
                :title="$t('dashboard.withdrawable_balance')"
                :dots-tooltip="$t('dashboard.withdrawable_balance_tooltip')"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              v-if="report.hasOwnProperty('roi_display')"
              :value="report.roi_display"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="report.roi_btc || null"
              :value-in-eur="report.roi_eur || null"
              :value-in-eth="report.roi_eth || null"
              dont-format
              icon="balance"
              :title="$t('dashboard.roi_total')"
              :dots-tooltip="$t('dashboard.roi_total_tooltip')"
            />
          </div>
        </div>
      </div>
    </div>

    <!--<NewUserAlert
      v-if="profile.deposit_first_time"
    />-->

    <EmergencyWithdrawalWarning
      v-if="profile.has_emergency_withdrawal"
      :amount="profile.emergency_withdrawal_amount"
      :wallet="profile.emergency_withdrawal_wallet"
    />

    <!--<PendingBonusDepositReminder
      v-if="profile.has_pending_deposit_bonus"
    />-->

    <YNLWarning
      v-if="profile.ynl"
    ></YNLWarning>

    <BalanceWarning
      v-if="!profile.deposit_first_time && profile.deposit_balance_below_min"
      :invested_minimum="profile.invested_balance_minimum_display"
    />

    <!--<IdleDeposits
      v-if="profile.deposit_has_idle"
      :idle="profile.deposit_idle"
    />-->

    <!--<EstimatedProfits />-->

    <div class="row">
      <div class="col-xl-7 col-lg-7">
        <!--<Poll :id="1" />-->

        <div class="kt-portlet kt-portlet--tab">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                {{ $t('dashboard.3months_profits_title') }}
              </h3>
            </div>
          </div>

          <div class="kt-portlet__body">
            <bar-chart
              id="profitChart"
              element="kt_morris_3"
              xkey="y"
              :ykeys="['a']"
              :labels="[$t('general.profit')]"
              :barColors="['#2abe81']"
              hideHover="true"
              :data="chartData"
            />
            <div class="kt-margin-t-20 text-center">
              <router-link :to="{name: 'reports'}" class="btn btn-brand" :class="profile.limited_mode ? 'disabled' : ''">{{ $t('dashboard.report_link') }}</router-link>
            </div>
          </div>

        </div>
      </div>
      <div class="col-xl-5 col-lg-5">

        <div class="row">
          <div class="col-xl-6 xol-lg-12 kt-margin-b-20">
            <router-link :to="{name: 'deposit'}" class="btn btn-lg btn-brand btn-block kt-marginless" :class="profile.limited_mode ? 'disabled' : ''">{{ $t('title.deposit') }}</router-link>
          </div>
          <div class="col-xl-6 xol-lg-12 kt-margin-b-20">
            <router-link :to="{name: 'withdrawal'}" class="btn btn-lg btn-brand btn-block kt-marginless" :class="profile.limited_mode ? 'disabled' : ''">{{ $t('title.withdrawal') }}</router-link>
          </div>
        </div>

        <div class="kt-portlet kt-callout kt-callout--warning kt-callout--diagonal-bg">
          <div class="kt-portlet__body">
            <div class="kt-callout__body">
              <div class="kt-callout__content">
                <h3 class="kt-callout__title">{{ $t('dashboard.support_title') }}</h3>
                <p class="kt-callout__desc">
                  {{ $t('dashboard.support_details') }}
                </p>
              </div>
              <div class="kt-callout__action">
                <router-link :to="{name: 'faq'}" class="btn btn-brand btn-bold btn-font-sm btn-info">{{ $t('dashboard.support_submit') }}</router-link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </Loader>
</template>

<script>
import axios from 'axios';
import StatisticsBox from '../components/functional/StatisticsBox'
import TransparentBox from '../components/functional/TransparentBox'
import AnimatedNumber from '../components/functional/AnimatedNumber'
import BalanceWarning from '../components/functional/BalanceWarning'
import IdleDeposits from '../components/functional/IdleDeposits'
import { mapState } from 'vuex'
import Loader from '../components/global/Loader'
import { BarChart, LineChart } from 'vue-morris'
import NewUserAlert from '@/components/functional/NewUserAlert';
import EmergencyWithdrawalWarning from '@/components/functional/EmergencyWithdrawalWarning';
import PendingBonusDepositReminder from "@/components/functional/PendingBonusDepositReminder";
import Poll from '../components/Dashboard/Poll';
import EstimatedProfits from '../components/Dashboard/EstimatedProfits';
import YNLWarning from "@/components/functional/YNLWarning.vue";

export default {
  name: 'DashboardPage',
  components: {
    YNLWarning,
    EmergencyWithdrawalWarning,
    NewUserAlert,
    Loader,
    StatisticsBox,
    TransparentBox,
    AnimatedNumber,
    BarChart,
    LineChart,
    BalanceWarning,
    IdleDeposits,
    PendingBonusDepositReminder,
    Poll,
    EstimatedProfits
  },
  data () {
    return {
      report: {},
      isLoading: false,
      chartData2: [{
        y: '6/01/2020',
        a: 0.0123312,
      },
      {
        y: '13/01/2020',
        a: 0.050023,
      },
      {
        y: '20/01/2020',
        a: 0.034252,
      },
      ]
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    chartData() {
      if (!this.report.profitsMonthly) {
        return []
      }
      return this.report.profitsMonthly.map(item => {
        return {
          y: item.month,
          a: item.amount
        }
      })
    }
  },
  created: function () {
    this.getReportData()
  },
  methods: {
    getReportData () {
      this.isLoading = true
      axios.get(this.$api.reports + '/stats').then(response => {
        this.report = response.data.data
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
      })
    },

  }
}
</script>
