<template>
  <div>
    <div class="kt-margin-b-10">
      <div class="kt-margin-b-10 kt-margin-l-10" v-if="profile.currency === 'BTC'">
        <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.deposit_enter_btc') }}
      </div>
      <div class="kt-margin-b-10 kt-margin-l-10" v-if="profile.currency === 'EUR'">
        <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.deposit_enter_eur') }}
      </div>
      <div class="row">
        <div
          v-if="profile.currency === 'EUR'"
          class="col-sm-6"
        >
          <div class="input-group kt-margin-b-5">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fas fa-euro-sign"></i>
              </span>
            </div>
            <MaskedInput
              type="text"
              class="form-control"
              :value="eurAmount"
              placeholder="0.00"
              :mask="eurMask"
              :guide="false"
              :disabled="profile.limited_mode || profile.is_demo"
              @input="setEurAmount"
            />
          </div>
        </div>
        <div
          :class="{
            'col-sm-6': profile.currency === 'EUR',
            'col': profile.currency === 'BTC'
          }"
        >
          <div class="input-group kt-margin-b-5">
            <div class="input-group-prepend">
              <span class="input-group-text">
                <i class="fab fa-bitcoin"></i>
              </span>
            </div>
            <MaskedInput
              type="text"
              class="form-control"
              :value="btcAmount"
              placeholder="0.00"
              :mask="btcMask"
              :guide="false"
              :disabled="profile.limited_mode || profile.is_demo"
              @input="setBtcAmount"
            />
          </div>
        </div>
      </div>

      <div
        v-if="btcAmount && btcAmountHasError"
        class="alert alert-danger"
      >
        <p style="margin-bottom: 0">
          <i class="fas fa-exclamation-circle"></i>
          &nbsp;<span v-html="$t('deposit.btc_lower',{ account: profile.account_selected, minimum: profile.account_selected === 'BTC' ? showMinDepositBtc : showMinDepositEur })"></span>
        </p>
      </div>
    </div>

    <hr>

    <div class="kt-margin-b-10 kt-margin-l-10">
      <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.select_cryptocurrency') }}
    </div>

    <b-tabs nav-class="nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x nav-tabs-line-primary">
      <b-tab active>
        <template #title>
          <i class="cc BTC"></i><b>Bitcoin</b>
        </template>

        <template v-if="!profile.deposit_wallet">
          <div class="kt-margin-b-10 kt-margin-l-10">
            <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.btc_details') }}
          </div>

          <div class="text-center kt-margin-b-20">
            <Loader :loading="isLoading" loader-style="overflow: hidden">
              <button
                class="btn btn-brand"
                type="button"
                @click.prevent="getWallet"
                :disabled="profile.limited_mode"
              >
                {{ $t('deposit.btc_generate_wallet') }}
              </button>
            </Loader>
          </div>
        </template>

        <div v-if="profile.deposit_wallet">
          <Loader :loading="isLoading" loader-style="overflow: hidden">
            <div class="kt-margin-b-10 kt-margin-l-10">
              <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.btc_address') }}
            </div>
            <div class="input-group kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text kt-font-bold">BTC</span>
              </div>
              <input type="text" class="form-control" id="address" :value="profile.deposit_wallet" readonly="">
              <div class="input-group-append">
                <button
                  id="wallet_copy"
                  type="button"
                  class="btn btn-secondary btn-icon"
                  @click="copyWalletAddress"
                ><i class="far fa-copy"></i></button>
                <button
                  id="copyQR"
                  v-if="profile.deposit_wallet_qr"
                  type="button"
                  class="btn btn-primary btn-icon"
                  @click="showQRcodemodal"><i class="fas fa-qrcode"></i> QR</button>
              </div>
            </div>

            <div v-if="profile.deposit_wallet && profile.deposit_wallet_expires" class="alert alert-solid-warning alert-bold" role="alert">
              <div class="alert-text text-left"><i class="fas fa-exclamation-triangle"></i> {{ $t('deposit.btc_expires', { date: formatDate(profile.deposit_wallet_expires) }) }} <span v-if="profile.deposit_wallet_extend"><a href="#" @click.prevent="extendWallet">{{ $t('deposit.btc_extend') }}</a></span></div>
            </div>
          </Loader>
        </div>

        <div v-if="profile.deposit_wallet && !btcAmountHasError" class="alert alert-secondary" role="alert">
          <div class="alert-icon"><i class="fab fa-bitcoin"></i></div>
          <div class="alert-text">{{ $t('deposit.btc_amount') }} <b>{{ btcAmount }} BTC</b></div>
        </div>

        <hr>

        <div class="kt-margin-b-30">
          <h5 class="kt-margin-t-30 kt-margin-b-5">{{ $t('deposit.alternative_title') }}</h5>
          <template v-if="some3rdPartyHidden">
            <div class="kt-margin-b-20" v-if="!showHidden3rdParty">{{ $t('deposit.alternative_subtitle', {country: profile.country_name || 'Unknown'}) }} <a href="#" @click.prevent="showHidden3rdParty = true">{{ $t('deposit.alternative_subtitle_button') }}</a></div>
            <div class="kt-margin-b-20" v-else>{{ $t('deposit.alternative_subtitle2', {country: profile.country_name || 'Unknown'}) }} <a href="#" @click.prevent="showHidden3rdParty = false">{{ $t('deposit.alternative_subtitle_button2') }}</a></div>
          </template>
          <div class="accordion accordion-solid accordion-toggle-plus accordion-deposit" role="tablist">

            <!--<b-card no-body v-if="checkGeoPermissions('transak')">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.transak class="card-title d-flex justify-content-between"><span>{{ $t('deposit.transak_title') }}</span> <span class="kt-margin-r-10"><i class="fas fa-university kt-margin-r-5"></i> <i class="fas fa-credit-card kt-margin-r-5"></i></span></div>
              </b-card-header>
              <b-collapse id="transak" accordion="deposit" role="tabpanel">
                <b-card-body>
                  <template v-if="profile.deposit_wallet">
                    <div class="text-center kt-margin-b-20">
                      <button class="btn btn-primary" @click="showTransakWidget()" v-html="$t('deposit.transak_button')"></button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="alert alert-danger">
                      {{ $t('deposit.transak_amount_wallet') }}
                    </div>
                  </template>
                  <div v-if="transakHasError" class="alert alert-info">
                    <div class="alert-text">
                      <i class="fas fa-exclamation-triangle"></i>
                      &nbsp;<span v-html="$t('deposit.transak_minimum',{ minimum: showMinTransakDeposit })"></span>
                    </div>
                  </div>
                  <div class="alert alert-secondary">
                    <div class="alert-text" v-html="$t('deposit.transak_note')"></div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body v-if="checkGeoPermissions('onramper')">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.onramper class="card-title d-flex justify-content-between"><span>{{ $t('deposit.onramper_title') }}</span> <span class="kt-margin-r-10"><i class="fas fa-university kt-margin-r-5"></i> <i class="fas fa-credit-card kt-margin-r-5"></i> <i class="fab fa-google-pay"></i> <i class="fab fa-apple-pay"></i></span></div>
              </b-card-header>
              <b-collapse id="onramper" accordion="deposit" role="tabpanel">
                <b-card-body>
                  <template v-if="profile.deposit_wallet">
                    <div class="text-center kt-margin-b-20">
                      <iframe
                        :src="onramper_url"
                        height="595"
                        width="100%"
                        title="Onramper widget"
                        allow="accelerometer; autoplay; camera; gyroscope; payment"
                        style="border: 0 none;"
                      >
                        <a href="https://widget.onramper.com" target="_blank">Use Onramper to Buy BTC</a>
                      </iframe>
                    </div>
                  </template>
                  <template v-else>
                    <div class="alert alert-danger">
                      {{ $t('deposit.onramper_amount_wallet') }}
                    </div>
                  </template>
                  <div v-if="transakHasError" class="alert alert-info">
                    <div class="alert-text">
                      <i class="fas fa-exclamation-triangle"></i>
                      &nbsp;<span v-html="$t('deposit.onramper_minimum',{ minimum: showMinTransakDeposit })"></span>
                    </div>
                  </div>
                  <div class="alert alert-secondary">
                    <div class="alert-text" v-html="$t('deposit.onramper_note')"></div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body v-if="checkGeoPermissions('coinify')">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.coinify class="card-title d-flex justify-content-between"><span>{{ $t('deposit.coinify_title') }}</span> <span class="kt-margin-r-10"><i class="fas fa-university kt-margin-r-5"></i> <i class="fas fa-credit-card kt-margin-r-5"></i></span></div>
              </b-card-header>
              <b-collapse id="coinify" accordion="deposit" role="tabpanel">
                <b-card-body>
                  <template v-if="profile.deposit_wallet">
                    <template v-if="!showCoinify">
                      <div class="text-center kt-margin-b-20">
                        <button class="btn btn-primary" @click="showCoinifyIframe" v-html="$t('deposit.coinify_button')"></button>
                      </div>
                    </template>
                    <template v-else>
                      <iframe :src="coinify_url" width="100%" height="576px" allow="camera"></iframe>
                    </template>
                  </template>
                  <template v-else>
                    <div class="alert alert-danger">
                      {{ $t('deposit.coinify_amount_wallet') }}
                    </div>
                  </template>
                  <div v-if="coinifyHasError" class="alert alert-info">
                    <div class="alert-text">
                      <i class="fas fa-exclamation-triangle"></i>
                      &nbsp;<span v-html="$t('deposit.coinify_minimum',{ minimum: showMinCoinifyDeposit })"></span>
                    </div>
                  </div>
                  <div class="alert alert-secondary">
                    <div class="alert-text" v-html="$t('deposit.coinify_note')"></div>
                  </div>

                </b-card-body>
              </b-collapse>
            </b-card>

            <b-card no-body v-if="checkGeoPermissions('relai')">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.relai class="card-title d-flex justify-content-between"><span><i class="fas fa-mobile-alt kt-margin-r-5"></i> {{ $t('deposit.relai_title') }}</span> <span class="kt-margin-r-10"> <i class="fas fa-euro-sign kt-margin-r-5"></i> <i class="fas fa-university kt-margin-r-5"></i></span></div>
              </b-card-header>
              <b-collapse id="relai" accordion="deposit" role="tabpanel">
                <b-card-body>
                  <template v-if="profile.deposit_wallet">
                    <div class="text-center kt-margin-b-10">
                      <span class="kt-margin-r-10"><a href="https://apps.apple.com/app/id1513185997" target="_blank"><img height="50" src="https://relai.ch/wp-content/uploads/2020/10/appstore.png.webp"></a></span>
                      <span><a href="https://play.google.com/store/apps/details?id=com.relai" target="_blank"><img height="50" src="https://relai.ch/wp-content/uploads/2020/10/playstore.png.webp"></a></span>
                    </div>
                  </template>
                  <template v-else>
                    <div class="alert alert-danger">
                      {{ $t('deposit.relai_amount_wallet') }}
                    </div>
                  </template>
                  <div class="alert alert-secondary">
                    <div class="alert-text" v-html="$t('deposit.relai_note')"></div>
                  </div>

                </b-card-body>
              </b-collapse>
            </b-card>-->

            <!--<b-card no-body v-if="checkGeoPermissions('walleteze')">
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.walleteze class="card-title d-flex justify-content-between"><span>{{ $t('deposit.walleteze_title') }}</span> <span class="kt-margin-r-10"><i class="fas fa-university kt-margin-r-5"></i> <i class="fas fa-credit-card kt-margin-r-5"></i></span></div>
              </b-card-header>
              <b-collapse id="walleteze" accordion="deposit" role="tabpanel">
                <b-card-body>
                  <template v-if="profile.deposit_wallet">
                    <div class="text-center kt-margin-b-20">
                      <form method="GET" action="https://walleteze.com/register">
                        <button type="submit" class="btn btn-brand">{{ $t('deposit.walleteze_button') }}</button>
                      </form>
                    </div>
                  </template>
                  <template v-else>
                    <div class="alert alert-danger">
                      {{ $t('deposit.walleteze_amount_wallet') }}
                    </div>
                  </template>
                  <div v-if="walletezeHasError" class="alert alert-info">
                    <div class="alert-text">
                      <i class="fas fa-exclamation-triangle"></i>
                      &nbsp;<span v-html="$t('deposit.walleteze_minimum',{ minimum: showMinWalletezeDeposit })"></span>
                    </div>
                  </div>
                  <div class="alert alert-secondary">
                    <div class="alert-text" v-html="$t('deposit.walleteze_note')"></div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>-->

            <!--<b-card v-if="1 === 0 && checkGeoPermissions('moonpay')" no-body>
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.moonpay class="card-title d-flex justify-content-between"><span>{{ $t('deposit.moonpay_title') }}</span> <span class="kt-margin-r-10"><i class="fas fa-university kt-margin-r-5"></i> <i class="fas fa-credit-card kt-margin-r-5"></i></span></div>
              </b-card-header>
              <b-collapse id="moonpay" accordion="deposit" role="tabpanel">
                <b-card-body>
                  <template v-if="profile.deposit_wallet">
                    <div class="text-center kt-margin-b-20">
                      <form action="https://buy-staging.moonpay.com" method="get" target="_blank">
                        <input type="hidden" name="apiKey" value="pk_test_35HJCOHrsdbBcR64cwPtdvXmO5yScen">
                        <input type="hidden" name="enabledPaymentMethods" value="credit_debit_card,apple_pay,google_pay,samsung_pay,sepa_bank_transfer,gbp_bank_transfer,gbp_open_banking_payment">
                        <input type="hidden" name="currencyCode" value="BTC">
                        <input type="hidden" name="walletAddress" :value="profile.deposit_wallet">
                        <input type="hidden" name="colorCode" value="#ffb822">
                        <input type="hidden" name="language" :value="$i18n.locale">
                        <input type="hidden" name="email" :value="profile.email">
                        <button class="btn btn-primary" type="submit" v-html="$t('deposit.moonpay_button')"></button>
                      </form>
                    </div>
                  </template>
                  <template v-else>
                    <div class="alert alert-danger">
                      {{ $t('deposit.moonpay_amount_wallet') }}
                    </div>
                  </template>
                  <div v-if="moonpayHasError" class="alert alert-info">
                    <div class="alert-text">
                      <i class="fas fa-exclamation-triangle"></i>
                      &nbsp;<span v-html="$t('deposit.moonpay_minimum',{ minimum: showMinMoonpayDeposit })"></span>
                    </div>
                  </div>
                  <div class="alert alert-secondary">
                    <div class="alert-text" v-html="$t('deposit.moonpay_note')"></div>
                  </div>
                </b-card-body>
              </b-collapse>
            </b-card>-->

            <!--<b-card v-if="1 === 0" no-body>
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.wire class="card-title">{{ $t('deposit.wire') }}</div>
              </b-card-header>
              <b-collapse id="wire" accordion="deposit" role="tabpanel">
                <b-card-body>
                  <div
                    v-if="!hasAccountAmount || wireHasError"
                    class="alert alert-danger"
                  >
                    <div class="alert-text">
                      <i class="fas fa-exclamation-triangle"></i>
                      &nbsp;<span v-html="$t('deposit.wire_minimum',{ minimum: showMinWireDeposit })"></span>
                    </div>
                  </div>
                  <div v-if="eurAmount && !eurAmountHasError" class="alert alert-secondary">
                    <div class="alert-icon"><i class="fas fa-euro-sign"></i></div>
                    <div class="alert-text" v-html="$t('deposit.wire_amount',{ amount: amountEurFormatted, amount_usd: amountUsdFormatted, amount_gbp: amountGbpFormatted })"></div>
                  </div>
                  <div class="text-center kt-margin-b-20">
                    <button
                      v-if="hasAccountAmount && !wireHasError"
                      class="btn btn-brand"
                      type="button"
                      @click="mfaCheck"
                    >
                      {{ $t('deposit.wire_generate_invoice') }}
                    </button>
                  </div>
                  <div class="alert alert-secondary">
                    <div class="alert-text" v-html="$t('deposit.wire_note')"></div>
                  </div>

                </b-card-body>
              </b-collapse>
            </b-card>-->

            <!--<b-card v-if="1 === 0" no-body>
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle.wire2 class="card-title">{{ $t('deposit.wire2') }}</div>
              </b-card-header>
              <b-collapse id="wire2" accordion="deposit" role="tabpanel">
                <b-card-body>

                  <template v-if="!profile.profile_complete">
                    <div class="alert alert-warning">
                      <div class="alert-text">
                        <i class="fas fa-exclamation-circle"></i>
                        {{ $t('deposit.wire2_required') }}
                        <a
                          href="#"
                          @click.prevent="scrollTo('profile', '#mainContentArea')"
                        >
                          {{ $t('deposit.wire2_fill') }}
                        </a>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div
                      v-if="!hasAccountAmount || wireHasError"
                      class="alert alert-danger"
                    >
                      <div class="alert-text">
                        <i class="fas fa-exclamation-triangle"></i>
                        &nbsp;<span v-html="$t('deposit.wire2_minimum',{ minimum: showMinWireDeposit })"></span>
                      </div>
                    </div>
                    <div v-if="eurAmount" class="alert alert-secondary">
                      <div class="alert-icon"><i class="fas fa-euro-sign"></i></div>
                      <div class="alert-text" v-html="$t('deposit.wire2_amount',{ amount: amountEurFormatted })"></div>
                    </div>
                    <div class="text-center">
                      <button
                        v-if="hasAccountAmount && !wireHasError"
                        class="btn btn-brand"
                        type="button"
                        @click="showWireAPIForm"
                      >
                        {{ $t('deposit.wire2_generate') }}
                      </button>
                    </div>

                  </template>

                </b-card-body>
              </b-collapse>
            </b-card>-->

          </div>
        </div>
      </b-tab>
      <b-tab>
        <template #title>
          <i class="far fa-gem"></i><b>Sapphire (SAPP)<i class="fas fa-flask" style="font-size:0.9em;position:relative;top:-5px;"></i></b>
        </template>

        <template v-if="!profile.deposit_wallet_decenomy || !profile.deposit_wallet_decenomy.sapp">
          <div class="kt-margin-b-10 kt-margin-l-10">
            <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.sapp_details') }}
          </div>

          <div class="text-center kt-margin-b-20">
            <Loader :loading="isLoading2" loader-style="overflow: hidden">
              <button
                class="btn btn-brand"
                type="button"
                @click.prevent="getWalletSapp"
                :disabled="profile.limited_mode"
              >
                {{ $t('deposit.sapp_generate_wallet') }}
              </button>
            </Loader>
          </div>
        </template>

        <div v-if="profile.deposit_wallet_decenomy && profile.deposit_wallet_decenomy.sapp">
          <Loader :loading="isLoading2" loader-style="overflow: hidden">
            <div class="kt-margin-b-10 kt-margin-l-10">
              <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.sapp_address') }}
            </div>
            <div class="input-group kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text kt-font-bold">SAPP</span>
              </div>
              <input type="text" class="form-control" id="address_sapp" :value="profile.deposit_wallet_decenomy.sapp" readonly="">
              <div class="input-group-append">
                <button
                  id="wallet_sapp_copy"
                  type="button"
                  class="btn btn-secondary btn-icon"
                  @click="copySappWalletAddress"
                ><i class="far fa-copy"></i></button>
              </div>
            </div>
          </Loader>
        </div>

        <div v-if="profile.deposit_wallet_decenomy && profile.deposit_wallet_decenomy.sapp && !sappAmountHasError" class="alert alert-secondary" role="alert">
          <div class="alert-icon"><i class="far fa-gem"></i></div>
          <div class="alert-text">{{ $t('deposit.sapp_amount') }} <b>{{ sappAmount }} SAPP</b></div>
        </div>

        <div class="text-center kt-margin-b-10">
          <button class="btn btn-info btn-sm" :disabled="profile.limited_mode" @click.prevent="sappInfoModal = true"><i class="fas fa-info-circle"></i> Where to buy SAPP?</button>
        </div>

        <!--<div class="text-center kt-margin-b-10">
          <a href="https://www.youtube.com/watch?v=kiEAO7nIZtU" target="_blank" class="vt"><i class="fab fa-youtube"></i> {{ $t('deposit.tutorial') }}</a>
        </div>-->

        <!--<div class="alert alert-solid-success alert-bold" role="alert">
          <div class="alert-text text-left"><i class="fas fa-exclamation-triangle"></i> {{ $t('deposit.sapp_warning',{ bonus: profile.sapp_deposit_bonus }) }}</div>
        </div>-->

      </b-tab>
      <b-tab>
        <template #title>
          <i class="cc USDT"></i><b>USDT (TRC20)</b>
        </template>

        <template v-if="!profile.deposit_wallet_trx">
          <div class="kt-margin-b-10 kt-margin-l-10">
            <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_details') }}
          </div>

          <div class="text-center kt-margin-b-20">
            <Loader :loading="isLoading2" loader-style="overflow: hidden">
              <button
                class="btn btn-brand"
                type="button"
                @click.prevent="getWalletTrx"
                :disabled="profile.limited_mode"
              >
                {{ $t('deposit.trx_generate_wallet') }}
              </button>
            </Loader>
          </div>
        </template>

        <div v-if="profile.deposit_wallet_trx">
          <Loader :loading="isLoading2" loader-style="overflow: hidden">
            <div class="kt-margin-b-10 kt-margin-l-10">
              <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_address') }}
            </div>
            <div class="input-group kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text kt-font-bold">TRC-20</span>
              </div>
              <input type="text" class="form-control" id="address_trx" :value="profile.deposit_wallet_trx" readonly="">
              <div class="input-group-append">
                <button
                  id="wallet_trx_copy"
                  type="button"
                  class="btn btn-secondary btn-icon"
                  @click="copyTrxWalletAddress"
                ><i class="far fa-copy"></i></button>
              </div>
            </div>
          </Loader>
        </div>

        <div v-if="profile.deposit_wallet_trx && !usdtAmountHasError" class="alert alert-secondary" role="alert">
          <div class="alert-icon"><i class="cc USDT"></i></div>
          <div class="alert-text">{{ $t('deposit.trx_amount') }} <b>{{ usdtAmount }} USDT</b></div>
        </div>

        <div v-if="profile.deposit_wallet_trx" class="alert alert-solid-warning alert-bold" role="alert">
          <div class="alert-text text-left"><i class="fas fa-exclamation-triangle"></i> {{ $t('deposit.trx_warning') }}</div>
        </div>

      </b-tab>
      <b-tab>
        <template #title>
          <!--<i class="cc USDC"></i>--><b>USDC (TRC20)</b><i class="fas fa-flask" style="font-size:0.9em;position:relative;top:-5px;"></i>
        </template>

        <template v-if="!profile.deposit_wallet_trx">
          <div class="kt-margin-b-10 kt-margin-l-10">
            <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_usdc_details') }}
          </div>

          <div class="text-center kt-margin-b-20">
            <Loader :loading="isLoading2" loader-style="overflow: hidden">
              <button
                :disabled="profile.limited_mode"
                class="btn btn-brand"
                type="button"
                @click.prevent="getWalletTrx"
              >
                {{ $t('deposit.trx_generate_wallet') }}
              </button>
            </Loader>
          </div>
        </template>

        <div v-if="profile.deposit_wallet_trx">
          <Loader :loading="isLoading2" loader-style="overflow: hidden">
            <div class="kt-margin-b-10 kt-margin-l-10">
              <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_usdc_address') }}
            </div>
            <div class="input-group kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text kt-font-bold">TRC-20</span>
              </div>
              <input type="text" class="form-control" id="address_trx" :value="profile.deposit_wallet_trx" readonly="">
              <div class="input-group-append">
                <button
                  id="wallet_trx_copy"
                  type="button"
                  class="btn btn-secondary btn-icon"
                  @click="copyTrxWalletAddress"
                ><i class="far fa-copy"></i></button>
              </div>
            </div>
          </Loader>
        </div>

        <div v-if="profile.deposit_wallet_trx && !usdcAmountHasError" class="alert alert-secondary" role="alert">
          <div class="alert-icon"><i class="fab fa-bitcoin"></i></div>
          <div class="alert-text">{{ $t('deposit.trx_amount') }} <b>{{ usdcAmount }} USDC</b></div>
        </div>

        <div v-if="profile.deposit_wallet_trx" class="alert alert-solid-warning alert-bold" role="alert">
          <div class="alert-text text-left"><i class="fas fa-exclamation-triangle"></i> {{ $t('deposit.trx_usdc_warning') }}</div>
        </div>

      </b-tab>
    </b-tabs>

    <div class="alert alert-secondary">
      <div class="alert-text" v-html="$t('deposit.idle_note',{ days: 7 })"></div>
    </div>

    <WireModal
      v-if="wireModal"
      :show="wireModal"
      :amount="eurAmount"
      :amount-display="amountEurFormatted"
      :amount-gbp-display="amountGbpFormatted"
      :amount-usd-display="amountUsdFormatted"
      :token="wireToken"
      @hidden="wireModal = false"
    />
    <WireAPIModal
      v-if="wireAPIModal"
      :show="wireAPIModal"
      :amount="eurAmount"
      :amount-display="amountEurFormatted"
      @hidden="wireAPIModal = false"
    />
    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="showWireForm"
      @hidden="hideMfa"
    />
    <QRModal
      v-if="showQRmodal && profile.deposit_wallet && profile.deposit_wallet_qr"
      :show="showQRmodal"
      @hidden="showQRmodal = false"
    />
    <SappInfoModal
      v-if="sappInfoModal"
      :show="sappInfoModal"
      @hidden="sappInfoModal = false"
      @done="hideSappInfoModal"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'
import WireModal from './WireModal'
import WireAPIModal from './WireAPIModal'
import requiresMfa from '../../mixins/requiresMfa'
import moment from 'moment'
import Loader from '../global/Loader'
import copyTextToClipboard from '../../helpers/clipboard'
import vscroll from "vue-scrollto";
import QRModal from "@/components/Deposit/QRModal";
import SappInfoModal from "@/components/Deposit/SappInfoModal";
import transakSDK from '@transak/transak-sdk';
import 'cryptocoins-icons/webfont/cryptocoins.css';
import 'cryptocoins-icons/webfont/cryptocoins-colors.css';

const eurMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  requireDecimal: false
})
const btcMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 8,
  requireDecimal: false
})
const usdtMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 6,
  requireDecimal: false
})
const usdcMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 6,
  requireDecimal: false
})
const sappMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 8,
  requireDecimal: false
})

export default {
  name: 'DepositForm',
  components: {
    QRModal,
    Loader,
    WireModal,
    WireAPIModal,
    SappInfoModal
  },
  mixins: [
    requiresMfa
  ],
  data () {
    return {
      action: 'deposit_wire',
      wireModal: false,
      wireAPIModal: false,
      wireToken: null,
      btcModal: false,
      eurAmount: null,
      btcAmount: null,
      usdtAmount: null,
      usdcAmount: null,
      sappAmount: null,
      isLoading: false,
      isLoading2: false,
      eurMask,
      btcMask,
      usdtMask,
      usdcMask,
      sappMask,
      showCoinify: false,
      coinifyEurAmount: null,
      showQRmodal: false,
      showHidden3rdParty: false,
      some3rdPartyHidden: false,
      sappInfoModal: false
    }
  },
  computed: {
    hasAccountAmount () {
      return this.profile.account_selected === 'EUR' ? this.eurAmount > 0 : this.btcAmount > 0
    },
    amountEurFormatted () {
      return this.eurAmount
        ? Number(this.eurAmount).toLocaleString('en-US', { style: 'currency', currency: 'EUR' })
        : 0
    },
    amountEurWalletezeSEPAFormatted () {
      return this.eurAmount
        ? ((Number(this.eurAmount) - 3)*0.95).toLocaleString('en-US', { style: 'currency', currency: 'EUR' })
        : 0
    },
    amountUsdFormatted () {
      let usdAmount = Number(this.eurAmount) * (this.profile.rate_eur_usd || 1.00);
      return usdAmount
        ? Number(usdAmount).toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        : 0
    },
    amountGbpFormatted () {
      let gbpAmount = Number(this.eurAmount) * (this.profile.rate_eur_gbp || 0.9);
      return gbpAmount
        ? Number(gbpAmount).toLocaleString('en-US', { style: 'currency', currency: 'GBP' })
        : 0
    },
    minDepositEur () {
      if (this.profile.deposit_first_time) {
        return this.profile.deposit_minimum_eur
      } else {
        return this.profile.reinvest_minimum_eur
      }
    },
    minDepositBtc () {
      if (this.profile.deposit_first_time) {
        return this.profile.deposit_minimum_btc
      } else {
        return this.profile.reinvest_minimum_btc
      }
    },
    minDepositCoinify () {
      return this.profile.deposit_minimum_3p.coinify;
    },
    minDepositWalleteze () {
      return this.profile.deposit_minimum_3p.walleteze;
    },
    minDepositTransak () {
      return this.profile.deposit_minimum_3p.transak;
    },
    minDepositMoonpay () {
      return this.profile.deposit_minimum_3p.moonpay;
    },
    showMinDepositEur () {
      return this.profile.deposit_first_time
        ? this.profile.deposit_minimum_eur_display
        : this.profile.reinvest_minimum_eur_display
    },
    showMinDepositBtc () {
      return this.profile.deposit_first_time
        ? this.profile.deposit_minimum_btc
        : this.profile.reinvest_minimum_btc
    },
    showMinWireDeposit () {
      if(this.profile.deposit_minimum_wire > this.minDepositEur){
        return this.profile.deposit_minimum_wire_display;
      }
      return this.profile.deposit_minimum_eur_display;
    },
    showMinCoinifyDeposit () {
      if(this.minDepositCoinify > this.minDepositEur){
        return this.profile.deposit_minimum_3p_display.coinify;
      }
      return this.profile.deposit_minimum_eur_display;
    },
    showMinWalletezeDeposit () {
      if(this.minDepositWalleteze > this.minDepositEur){
        return this.profile.deposit_minimum_3p_display.walleteze;
      }
      return this.profile.deposit_minimum_eur_display;
    },
    showMinTransakDeposit () {
      if(this.minDepositTransak > this.minDepositEur){
        return this.profile.deposit_minimum_3p_display.transak;
      }
      return this.profile.deposit_minimum_eur_display;
    },
    showMinMoonpayDeposit () {
      if(this.minDepositMoonpay > this.minDepositEur){
        return this.profile.deposit_minimum_3p_display.moonpay;
      }
      return this.profile.deposit_minimum_eur_display;
    },
    eurAmountHasError () {
      if (this.eurAmount == null) {
        return true
      }
      return this.eurAmount < this.minDepositEur
    },
    btcAmountHasError () {
      if (this.btcAmount == null) {
        return true
      }
      return this.btcAmount < this.minDepositBtc
    },
    usdtAmountHasError () {
      if (this.usdtAmount == null) {
        return true
      }
      return false
    },
    usdcAmountHasError () {
      if (this.usdcAmount == null) {
        return true
      }
      return false
    },
    sappAmountHasError () {
      if (this.sappAmount == null) {
        return true
      }
      return false
    },
    wireHasError () {
      if ((this.eurAmount === null && this.profile.account_selected === 'EUR')
      || this.btcAmount === null && this.profile.account_selected === 'BTC') {
        return true
      }
      let minAmountEUR = this.profile.deposit_minimum_wire > 0
        ? this.profile.deposit_minimum_wire
        : this.profile.deposit_minimum_eur;
      let minAmountBTC = this.profile.deposit_minimum_wire > 0
        ? this.profile.deposit_minimum_wire
        : this.profile.deposit_minimum_btc;
      return this.profile.account_selected === 'EUR' ? this.eurAmount < minAmountEUR : this.btcAmount < minAmountBTC
    },
    coinifyHasError () {
      if (this.eurAmount === null) {
        return true
      }
      let min = this.minDepositCoinify > this.minDepositEur ? this.minDepositCoinify : this.minDepositEur;
      return min > this.eurAmount;
    },
    walletezeHasError () {
      if (this.eurAmount === null) {
        return true
      }
      let min = this.minDepositWalleteze > this.minDepositEur ? this.minDepositWalleteze : this.minDepositEur;
      return min > this.eurAmount;
    },
    transakHasError () {
      if (this.eurAmount === null) {
        return true
      }
      let min = this.minDepositTransak > this.minDepositEur ? this.minDepositTransak : this.minDepositEur;
      return min > this.eurAmount;
    },
    moonpayHasError () {
      if (this.eurAmount === null) {
        return true
      }
      let min = this.minDepositMoonpay > this.minDepositEur ? this.minDepositMoonpay : this.minDepositEur;
      return min > this.eurAmount;
    },
    onramperHasError () {
      if (this.eurAmount === null) {
        return true
      }
      let min = this.minDepositMoonpay > this.minDepositEur ? this.minDepositMoonpay : this.minDepositEur;
      return min > this.eurAmount;
    },
    hasError () {
      return this.eurAmountHasError || this.btcAmountHasError || this.usdtAmountHasError || this.usdcAmountHasError || this.sappAmountHasError;
    },
    ...mapState({
      profile: state => state.authUser.profile
    }),
    coinify_url: function() {
      let sandbox_url = "https://trade-ui.sandbox.coinify.com/widget?partnerId=119";
      let url = "https://trade-ui.coinify.com/widget?partnerId=123";
      let amount = this.coinifyEurAmount;
      if (this.coinifyEurAmount === null) {
        amount = 300;
      }
      return (
        url +
        "&partnerName=YieldNodes&transferInMedia=card,bank&defaultFiatCurrency=EUR&cryptoCurrencies=BTC&targetPage=buy&address=" +
        this.profile.deposit_wallet +
        "&buyAmount=" +
        amount
      );
    },
    onramper_url () {
      let api_key = 'pk_prod_TtwEvBlggcM9Ax31cOXdG04uWeLTx0Kwe3FUFmBtSRo0';
      let color_hex = 'eab300';
      let default_amount = '500';
      if (!this.onramperHasError) {
        default_amount = this.eurAmount;
      }
      return 'https://widget.onramper.com?apiKey='+ api_key +'&color='+ color_hex +'&defaultCrypto=BTC&defaultFiat=EUR&onlyCryptos=BTC&defaultAmount='+ default_amount + '&wallets=BTC:'+ this.profile.deposit_wallet + '&isAddressEditable=false';
    }
  },
  methods: {
    mfaCheck () {
      if (this.profile.mfa_method) {
        this.showMfa = true
      } else {
        this.showWireForm(null)
      }
    },
    showWireForm (token = null) {
      this.showMfa = false
      this.wireToken = token
      this.wireModal = true
    },
    showWireAPIForm () {
      this.wireAPIModal = true
    },
    setBtcAmount (value) {
      if (!value) {
        value = 0
      }
      this.eurAmount = (value * this.profile.ex_rate).toFixed(2).toString()
      this.btcAmount = value.toString()
      if (this.profile.ex_rate_usdt > 0) {
        this.usdtAmount = (this.eurAmount / this.profile.ex_rate_usdt).toFixed(2).toString()
      }else{
        this.usdtAmount = 0
      }
      if (this.profile.ex_rate_usdc > 0) {
        this.usdtAmount = (this.eurAmount / this.profile.ex_rate_usdc).toFixed(2).toString()
      }else{
        this.usdtAmount = 0
      }
      if (this.profile.ex_rate_sapp > 0) {
        this.sappAmount = (this.eurAmount / this.profile.ex_rate_sapp).toFixed(2).toString()
      }else{
        this.sappAmount = 0
      }
    },
    setEurAmount (value) {
      if (!value) {
        value = 0
      }
      if (this.profile.ex_rate > 0) {
        this.btcAmount = (value / this.profile.ex_rate).toFixed(8).toString()
      }else{
        this.btcAmount = 0
      }
      if (value.toString() !== '') {
        this.eurAmount = value.toString()
      } else {
        this.eurAmount = 0
      }
      if (this.profile.ex_rate_usdt > 0) {
        this.usdtAmount = (value / this.profile.ex_rate_usdt).toFixed(2).toString()
      }else{
        this.usdtAmount = 0
      }
      if (this.profile.ex_rate_usdc > 0) {
        this.usdcAmount = (value / this.profile.ex_rate_usdc).toFixed(2).toString()
      }else{
        this.usdcAmount = 0
      }
      if (this.profile.ex_rate_sapp > 0) {
        this.sappAmount = (value / this.profile.ex_rate_sapp).toFixed(2).toString()
      }else{
        this.sappAmount = 0
      }
    },
    copyWalletAddress () {
      copyTextToClipboard(this.profile.deposit_wallet, 'wallet_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    copyTrxWalletAddress () {
      copyTextToClipboard(this.profile.deposit_wallet_trx, 'wallet_trx_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    copySappWalletAddress () {
      copyTextToClipboard(this.profile.deposit_wallet_decenomy.sapp, 'wallet_sapp_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    formatDate (date) {
      return moment.utc(date).format('LLL')
    },
    getWallet () {
      this.isLoading = true
      axios.get(this.$api.depositWallet).then(response => {
        this.$store.dispatch('setAuthUser')
        this.isLoading = false
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('deposit.address_error'));
        this.isLoading = false
      })
    },
    getWalletTrx () {
      this.isLoading2 = true
      axios.get(this.$api.depositWallet + '/trx').then(response => {
        this.$store.dispatch('setAuthUser')
        this.isLoading2 = false
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('deposit.address_error'));
        this.isLoading2 = false
      })
    },
    getWalletSapp () {
      this.isLoading2 = true
      axios.get(this.$api.depositWallet + '/decenomy?currency=SAPP').then(response => {
        this.$store.dispatch('setAuthUser')
        this.isLoading2 = false
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('deposit.address_error'));
        this.isLoading2 = false
      })
    },
    scrollTo (route, selector) {
      if (this.$route.name === route) {
        vscroll.scrollTo(selector, 500)
      } else {
        this.$router.push({ name: route }, () => {
          this.$nextTick(() => {
            setTimeout(() => {
              vscroll.scrollTo(selector, 500)
            }, 600)
          })
        })
      }
    },
    extendWallet() {
      this.isLoading = true
      axios.post(this.$api.depositWalletExtend, { address: this.profile.deposit_wallet }).then(response => {
        this.$store.dispatch('setAuthUser')
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.wallet_expire_extended'))
        this.isLoading = false
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        this.isLoading = false
      })
    },
    showCoinifyIframe() {
      this.showCoinify = true;
      this.coinifyEurAmount = this.eurAmount;
    },
    showQRcodemodal() {
      this.showQRmodal = true;
    },
    showTransakWidget() {
      let transak = new transakSDK({
        apiKey: '16ba192a-06aa-4bd4-984b-be9a8dd60147',  // Your API Key
        environment: 'PRODUCTION', // STAGING/PRODUCTION
        cryptoCurrencyCode: 'BTC',
        walletAddress: this.profile.deposit_wallet, // Your customer's wallet address
        themeColor: 'ffb822', // App theme color
        //fiatCurrency: 'EUR', // INR/GBP
        countryCode: this.profile.country || null,
        email: this.profile.email, // Your customer's email address
        redirectURL: 'https://members.yieldnodes.com',
        defaultFiatAmount: this.eurAmount,
        hostURL: window.location.origin,
        widgetHeight: '550px',
        widgetWidth: '450px'
      });
      transak.init();
    },
    checkGeoPermissions(provider) {
      if(this.showHidden3rdParty) return true;
      if(this.profile.deposit_geo_3p[provider]){
        return true;
      }
      this.some3rdPartyHidden = true;
      return false;
    },
    hideSappInfoModal() {
      this.sappInfoModal = false;
    }
  }
}
</script>
