<template>
  <Loader :loading="isLoading" :full-page="true">

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">

        <FundPerformance/>

      </div>
    </div>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '../components/global/Loader'
import FundPerformance from '../components/global/FundPerformance'

export default {
  name: 'PerformancePage',
  components: {
    Loader,
    FundPerformance
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {

  },
  methods: {

  }
}
</script>
