import isMobile from "./isMobile";

let sideNav = null
let sidenavLabels = null

const getElements = () => {
  if (!sideNav) {
    sideNav = document.querySelector("#mySidenav");
  }
  if (!sidenavLabels) {
    sidenavLabels = document.querySelectorAll(".sidenav a span");
  }
}

export const showSidebar = () => {
  getElements()
  if (sideNav && sidenavLabels) {
    sideNav.style.left = 0
    sideNav.style.width = '260px'
    if (!isMobile()) {
      Array.prototype.forEach.call(sidenavLabels, function (el) {
        el.style.display = 'inline-table'
      })
    }
    window.localStorage.setItem('showSidebar', '1')
  }
}

export const hideSidebar = () => {
  getElements()
  if (sideNav && sidenavLabels) {
    sideNav.style.left = 0
    if (!isMobile()) {
      sideNav.style.width = '100px'
      Array.prototype.forEach.call(sidenavLabels, function (el) {
        el.style.display = 'none'
      })
    } else {
      sideNav.style.left = '-310px'
    }
    window.localStorage.setItem('showSidebar', '0')
  }
}

export const toggleSidebar = () => {
  let state = parseInt(window.localStorage.getItem('showSidebar'))
  state ? hideSidebar() : showSidebar()
  return !state
}

export const clearCache = () => {
  sideNav = null
  sidenavLabels = null
}
