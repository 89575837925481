<template>
  <div :class="wrapperClass">
    <div
      v-click-outside="hideDropdown"
      class="flex_home_page"
      style="cursor: pointer;"
      @click="toggleDropdown"
    >
      <div class="right_name_user">
        <h6>{{ profile.first_name }} {{ profile.last_name }}</h6>
        <p>{{ profile.account_type | capitalize }}</p>
      </div>

      <div class="dropdown">
        <span>
          <img
            src="~images/user/angle-down.svg"
            alt="angle-down"
          >
        </span>
        <transition
          name="fade"
          mode="out-in"
        >
          <div
            v-if="show"
            class="dropdown-content"
          >
            <!--<a
              v-if="profile.has_aa && altAccountName"
              href="#"
              @click.prevent="switchAccount"
            >
              {{ $t('general.switch_account',{ account: altAccountName }) }}
            </a>-->
            <router-link
              v-if="$route.name !== 'agreement'"
              class="my_account"
              :to="{name: 'profile'}"
              @click.native="closeOnMobile"
            >
              {{ $t('title.profile') }}
            </router-link>
            <router-link
              v-if="$route.name !== 'agreement'"
              class="email_prefer"
              :to="{name: 'preferences'}"
              @click.native="closeOnMobile"
            >
              {{ $t('title.preferences') }}
            </router-link>
            <router-link
              v-if="$route.name !== 'agreement'"
              class="mfa_settings"
              :to="{name: '2fa'}"
              @click.native="closeOnMobile"
            >
              {{ $t('title.2fa') }}
            </router-link>
            <router-link
              v-if="$route.name !== 'agreement' && !profile.deposit_first_time"
              class="ducument_my"
              :to="{name: 'kyc'}"
              @click.native="closeOnMobile"
            >
              {{ $t('title.kyc') }}
            </router-link>
            <router-link
              class="log_outt"
              :to="{name: 'logout'}"
              @click.native="closeOnMobile"
            >
              {{ $t('title.sign_out') }}
            </router-link>
          </div>
        </transition>
      </div>
    </div>
    <Loader
      :loading="isLoading"
      :full-page="true"
      :title="$t('general.switching')"
    />
  </div>
</template>

<script>
import ClickOutside from '../../helpers/clickOutside'
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import { hideSidebar } from 'js/helpers/sidebar'
import isMobile from 'js/helpers/isMobile'

export default {
  name: 'HeaderDropdown',
  components: {
    Loader
  },
  directives: {
    ClickOutside
  },
  props: {
    wrapperClass: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      show: false,
      isLoading: false
    }
  },
  computed: {
    altAccountName () {
      if (this.profile.currency === 'EUR') {
        return 'BTC'
      } else if (this.profile.currency === 'BTC') {
        return 'EUR'
      } else {
        return null
      }
    },
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  methods: {
    toggleDropdown () {
      this.show = !this.show
    },
    hideDropdown () {
      this.show = false
    },
    switchAccount () {
      this.isLoading = true
      axios.patch(this.$api.switchAccount).then(response => {
        this.isLoading = false
        window.location.reload()
      }).catch(error => {
        this.isLoading = false
        this.$store.dispatch('showErrorNotification', this.$t('general.account_not_available'))
      })
    },
    closeOnMobile () {
      if (isMobile()) {
        hideSidebar()
      }
    }
  }
}
</script>
