<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      title="QR code"
      @hidden="hideModal"
      size="md"
      header-close-content=""
    >
      <div class="text-center">
        <img v-if="profile.deposit_wallet && profile.deposit_wallet_qr" :src="profile.deposit_wallet_qr" class="img-fluid">
      </div>
      <div class="input-group kt-margin-b-20">
        <div class="input-group-prepend">
          <span class="input-group-text kt-font-bold">BTC</span>
        </div>
        <input type="text" class="form-control" id="address" :value="profile.deposit_wallet" readonly="">
      </div>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import copyTextToClipboard from "@/helpers/clipboard";

export default {
  name: 'QRModal',
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      modalOpen: this.show
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    }
  }
}
</script>
