<template>
  <Loader
    :loading="isLoading"
  >
    <b-form @submit.prevent="checkMfa">
      <div class="kt-portlet__body">
        <AccountInput
          id="old_password"
          v-model="formData.old_password"
          type="password"
          name="old_password"
          :label="$t('profile.password_old')"
          :errors="errors"
        />
        <AccountInput
          id="password"
          v-model="formData.password"
          type="password"
          name="password"
          :label="$t('profile.password')"
          :errors="errors"
          :description="$t('profile.password_details')"
        />
        <AccountInput
          id="password_repeat"
          v-model="formData.password_repeat"
          type="password"
          name="password_repeat"
          :label="$t('profile.password2')"
          :errors="errors"
        />
      </div>
      <div class="kt-portlet__foot">
        <div class="row">
          <div class="col-xl-12">
            <button type="submit" class="btn btn-primary">{{ $t('profile.password_submit') }}</button>
          </div>
        </div>
      </div>
    </b-form>
    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="changePassword"
      @hidden="hideMfa"
    />
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import AccountInput from '../forms/AccountInput'
import Loader from '../global/Loader'
import formHasErrors from '../../mixins/formHasErrors'
import requiresMfa from '../../mixins/requiresMfa'

export default {
  name: 'PasswordChangeForm',
  components: {
    Loader,
    AccountInput
  },
  mixins: [
    formHasErrors,
    requiresMfa
  ],
  data () {
    return {
      isLoading: false,
      showForm: false,
      formData: {
        old_password: null,
        password: null,
        password_repeat: null
      },
      formField: 'formData'
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  methods: {
    toggleForm () {
      this.showForm = !this.showForm
      if (!this.showForm) {
        this.formData = {
          old_password: null,
          password: null,
          password_repeat: null
        }
        this.clearErrors()
      }
    },
    checkMfa () {
      this.clearErrors()
      if (!this.formData.old_password) {
        this.addError('old_password', this.$t('profile.password_empty'))
        return false
      }
      if (!this.formData.password) {
        this.addError('password', this.$t('profile.password_empty'))
        return false
      }
      if (this.formData.password === this.formData.old_password) {
        this.addError('password', this.$t('profile.password_equal'))
        return false
      }
      if (this.formData.password.length < 8) {
        this.addError('password', this.$t('profile.password_length'))
        return false
      }
      if (this.formData.password !== this.formData.password_repeat) {
        this.addError('password_repeat', this.$t('profile.password_repeat'))
        return false
      }

      if (this.profile.mfa_method) {
        this.verifyMfa('password_change')
      } else {
        this.changePassword()
      }
    },
    changePassword (token = null) {
      this.showMfa = false
      this.isLoading = true
      this.clearErrors()

      axios.patch(this.$api.updateUserPassword, { ...this.formData, token })
        .then((response) => {
          this.isLoading = false
          if (response.status >= 200 && response.status < 300) {
            this.toggleForm()
            this.$emit('changed')
            this.$store.dispatch('showSuccessNotification', this.$t('profile.password_success'))
          }
        }).catch((error) => {
          this.isLoading = false
          if (error.response.data.errors) {
            this.setErrors(error.response.data.errors)
          }
        })
    }
  }
}
</script>
