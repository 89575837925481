const fallbackCopyTextToClipboard = (text, elId = null) => {
  let el = null
  if (elId) {
    el = document.getElementById(elId)
  } else {
    el = document.body
  }

  let textArea = document.createElement('textarea')
  textArea.value = text
  el.appendChild(textArea)
  textArea.focus()
  textArea.select()
  let result = new Promise((resolve, reject) => {
    try {
      let successful = document.execCommand('copy')
      if (successful) {
        resolve()
      } else {
        reject(new Error('unsuccessful'))
      }
    } catch (err) {
      reject(err)
    }
  })
  el.removeChild(textArea)
  return result
}
const copyTextToClipboard = (text, elId = null) => {
  if (!navigator.clipboard) {
    return fallbackCopyTextToClipboard(text, elId)
  }
  return new Promise((resolve, reject) => {
    navigator.clipboard.writeText(text).then(() => {
      resolve()
    }).catch(error => {
      resolve(fallbackCopyTextToClipboard(text, elId))
    })
  })
}

export default copyTextToClipboard
export const copyTest = fallbackCopyTextToClipboard
