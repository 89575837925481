<template>
  <div v-if="false">
    <Loader :loading="isLoading" loader-style="overflow: hidden">
      <template v-if="activeBackendSubscription">
        <div class="kt-margin-b-20">Updates Subscription: <b>{{ subscriptionStatus }}</b></div>
        <template v-if="notificationPermission === false">
          <div class="kt-margin-b-20 text-danger"><i class="fas fa-exclamation-triangle"></i> You didn't give notifications permission in your browser. You need to do it in the browser page settings!</div>
        </template>

        <template v-if="activeSubscription === false">
          <button @click.prevent="subscribe()" type="submit" class="btn btn-primary">Subscribe to Updates</button>
        </template>
        <template v-else>
          <button @click.prevent="unsubscribe()" type="submit" class="btn btn-warning">Unsubscribe from Updates</button>
        </template>
      </template>
      <template v-else>
        <button @click.prevent="subscribe()" type="submit" class="btn btn-primary">Subscribe to Updates</button>
      </template>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AccountInput from '../forms/AccountInput'
import formHasErrors from '../../mixins/formHasErrors'
import Loader from '../global/Loader'
import { btcMask, eurMask } from 'js/helpers/inputMasks'
//import OneSignal from "onesignal-vue";

export default {
  name: 'SubscribeDepositBonus',
  components: {
    Loader,
    AccountInput
  },
  mixins: [
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      activeSubscription: null,
      notificationPermission: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    activeBackendSubscription(){
      if(this.profile.deposit_bonus_subscribed){
        return true;
      }
      return false;
    },
    subscriptionStatus(){
      if(this.activeBackendSubscription){
        if(this.notificationPermission === false){
          return 'No Permission';
        }else{
          if(this.activeSubscription === false){
            return 'Inactive';
          }else{
            return 'Active';
          }
        }
      }else{
        return 'Inactive';
      }
    }
  },
  mounted() {
    //this.initOneSignal();
  },
  methods: {
    /*subscribe () {
      this.isLoading = true
      axios.post(this.$api.depositBonus + '/subscribe').then(response => {
        this.$store.dispatch('showSuccessNotification', 'Subscribed!')
        this.$store.dispatch('setAuthUser').then(() => {
          this.isLoading = false;
          this.subscribeToOneSignal();
        })
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        this.isLoading = false
      })
    },
    unsubscribe () {
      this.isLoading = true
      axios.post(this.$api.depositBonus + '/unsubscribe').then(response => {
        this.$store.dispatch('showSuccessNotification', 'Unsubscribed!')
        this.$store.dispatch('setAuthUser').then(() => {
          this.isLoading = false;
          this.unsubscribeFromOneSignal();
        })
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        this.isLoading = false
      })
    },
    initOneSignal() {
      let user_id = this.profile.id;
      let self = this;
      OneSignal.push(function() {
        OneSignal.on('subscriptionChange', function (isSubscribed) {
          console.log("The user's subscription state is now:", isSubscribed);
          self.getOneSignalState();
        });
        OneSignal.setExternalUserId(user_id);
        self.getOneSignalState();
      });
    },
    subscribeToOneSignal() {
      let self = this;
      OneSignal.push(function() {
        OneSignal.push(function() {
          OneSignal.showNativePrompt();
          self.activeSubscription = true;
          OneSignal.setSubscription(true);
        });
      });
    },
    unsubscribeFromOneSignal() {
      let self = this;
      OneSignal.push(function() {
        OneSignal.setSubscription(false);
        self.activeSubscription = false;
      });
    },
    getOneSignalState() {
      let self = this;
      OneSignal.push(function(){
        OneSignal.getSubscription(function(isActive){
          if(self.activeBackendSubscription){
            self.activeSubscription = isActive;
          }else{
            self.activeSubscription = null;
          }
        });
      });
      OneSignal.push(function(){
        OneSignal.getNotificationPermission(function(status) {
          if(status === 'granted'){
            self.notificationPermission = true;
          }
          if(status === 'denied'){
            self.notificationPermission = false;
          }
        });
      });
    }*/
  }
}
</script>
