<template>
  <Loader :loading="isLoading">
    <form
        novalidate="novalidate"
        class="kt-form kt-form--label-right"
        @submit.prevent="updateNFTConfirm"
    >
      <div class="kt-portlet__body">

        <p>Please, enter your Polygon wallet address to receive the YieldNodes NFT.</p>
        <p><router-link :to="{ name: 'nftterms' }" target="_blank">Terms and Conditions</router-link> must be accepted to enter the address.</p>

        <div class="kt-section kt-section--first kt-margin-t-20 kt-margin-b-0">
          <div class="kt-section__body">
            <b-form-group
              label=""
              label-for="nft_rules_agree"
              :label-cols-xl="3"
              :label-cols-lg="3"
            >

              <b-form-checkbox
                id="nft_rules_agree"
                v-model="nft_rules_agree"
                name="nft_rules_agree"
                :value="true"
                :disabled="nftFormDisabledCheckbox"
              >I accept the YieldNodes NFT <router-link :to="{ name: 'nftterms' }" target="_blank">Terms and conditions</router-link></b-form-checkbox>

            </b-form-group>

            <AccountInput
                id="address"
                v-model="formData.address"
                name="address"
                :errors="errors"
                label="NFT Address"
                placeholder="0xdAC17F958D2ee523a2206206994597C13D831ec7"
                :cols="3"
                :disabled="nftFormDisabled"
            />
          </div>
        </div>
        <div class="kt-form__actions">
          <div class="row">
            <div class="col-lg-3 col-xl-3">
            </div>
            <div class="col-lg-9 col-xl-9">
              <button type="submit" class="btn btn-success" :disabled="nftFormDisabled">Save</button>
            </div>
          </div>
        </div>

      </div>
      <div class="kt-portlet__foot">
        <p><b>New to YieldNodes NFT?</b></p>
        <p>Further information about YieldNodes NFT can be found <a href="https://yieldnodes.com/nft" target="_blank">here</a>.</p>

      </div>

      <MfaModal
        v-if="showMfa"
        :show="showMfa"
        :action="action"
        :method="profile.mfa_method"
        @verified="updateNFT"
        @hidden="hideMfa"
      />

    </form>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import AccountInput from '../forms/AccountInput'
import Loader from '../global/Loader'
import formHasErrors from '../../mixins/formHasErrors'
import requiresMfa from '../../mixins/requiresMfa'

export default {
  name: 'NFTForm',
  components: {
    AccountInput,
    Loader
  },
  mixins: [
    formHasErrors,
    requiresMfa
  ],
  data () {
    return {
      isLoading: false,
      formField: 'formData',
      formData: {
        address: null
      },
      showMfa: false,
      action: 'nft_address',
      token: null,
      nft_rules_agree: false
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    nftFormDisabled (){
      if(this.profile.nft_address){
        return true;
      }
      if(this.profile.nft_claimed !== 0){
        return true;
      }
      if(this.nft_rules_agree){
        return false;
      }
      return true;
    },
    nftFormDisabledCheckbox (){
      if(this.profile.nft_address){
        return true;
      }
      return this.profile.nft_claimed !== 0;
    }
  },
  mounted () {
    this.formData.address = this.profile.nft_address;
    if(this.profile.nft_address || this.profile.nft_claimed){
      this.nft_rules_agree = true;
    }
  },
  methods: {
    updateNFTConfirm () {
      if(this.profile.deposit_balance <= 1){
        this.$store.dispatch('showErrorNotification', 'Your account traded balance is too low to claim NFT!')
        return;
      }
      if(confirm('Warning: You can only enter address once so please double check that the polygon ERC20 adress you have entered is correct!')) {
        if (this.profile.mfa_method === null) {
          this.updateNFT();
        } else {
          this.verifyMfa(this.action);
        }
      }
    },
    updateNFT (token = null) {
      this.showMfa = false;
      this.clearErrors()
      this.isLoading = true

      axios.post(this.$api.updateUserProfile + '/nft', { ...this.formData, token })
        .then((response) => {
          this.isLoading = false
          this.$store.dispatch('setAuthUser')
          this.$store.dispatch('showSuccessNotification', 'Saved')
        }).catch((error) => {
          this.isLoading = false
          if (error.response.data) {
            if (error.response.status === 422) {
              this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
              this.setErrors(error.response.data.errors)
            }else if(error.response.status === 400 && error.response.data && error.response.data.data.message){
              this.$store.dispatch('showErrorNotification', error.response.data.data.message)
            }
          }
        })
    },
  }
}
</script>
