<template>
  <div
    class="alert alert-info"
    role="alert"
  >
    <div class="alert-text">
      <h4 class="alert-heading">{{ $t('withdraw.emergency_warning_title') }}</h4>
      <p v-html="$t('withdraw.emergency_warning_note',{ amount: amount, wallet: wallet })"></p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmergencyWithdrawalWarning',
  components: {
  },
  props: {
    amount: {
      type: String,
      default: null
    },
    wallet: {
      type: String,
      default: null
    }
  },
  computed: {

  }
}
</script>
