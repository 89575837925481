<template>
  <span :class="wrapperClass">
    {{ formatValue(Number(animatedValue)) }}
  </span>
</template>
<script>
import anime from 'animejs'
import { formatMoney } from 'js/helpers/numbers'

const countDecimals = (value) => {
  if (!value) {
    return 0
  }
  if ((value % 1) !== 0) {
    let parts = value.toString().split('.')
    let len = parts.length > 1 ? parts[1].length : 1
    return len < 6 ? len : 6
  }
  return 0
}

export default {
  props: {
    wrapperClass: {
      type: String,
      default: null
    },
    value: {
      type: [Number, String],
      default: '0',
      required: true
    },
    decimals: {
      type: Number,
      default: 2
    },
    formatValue: {
      type: Function,
      default: value => formatMoney(value, 6)
    },
    easing: {
      type: String,
      default: 'linear'
    },
    duration: {
      type: Number,
      default: 500
    },
    update: Function,
    begin: Function,
    complete: Function,
    run: Function,
    delay: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      animatedValue: 0
    }
  },
  watch: {
    value (value) {
      this.animateValue(value)
    }
  },
  mounted () {
    this.animateValue(this.value)
  },
  methods: {
    animateValue (value) {
      let dec = countDecimals(value)
      const {
        begin, easing, duration, complete, update, run, delay
      } = this
      anime({
        targets: this,
        animatedValue: value,
        round: Math.pow(10, dec),
        duration,
        easing,
        update,
        begin,
        complete,
        run,
        delay
      })
    }
  }
}
</script>
