<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      cancel-disabled
      ok-disabled
      size="lg"
      :title="modalTitle || $t('withdraw.wire_manage_wallets')"
      @hidden="hideModal"
      header-close-content=""
    >
      <b-form class="kt-form" @submit.prevent="addWalletValidate">
        <h3>{{ $t('withdraw.wire_title') }}</h3>

        <AccountInput
          v-model="formData.title"
          name="title"
          :errors="errors"
          type="text"
          placeholder=""
          :label="$t('withdraw.wire_details_title')"
        />

        <AccountInput
          v-model="formData.beneficiary_name"
          name="beneficiary_name"
          :errors="errors"
          type="text"
          placeholder=""
          :label="$t('withdraw.wire_details_name')"
        />

        <AccountInput
          v-model="formData.beneficiary_address"
          name="beneficiary_address"
          :errors="errors"
          type="text"
          placeholder=""
          :label="$t('withdraw.wire_details_address')"
        />

        <AccountInput
          v-model="formData.account_number"
          name="account_number"
          :errors="errors"
          type="text"
          placeholder=""
          :label="$t('withdraw.wire_details_acc')"
        />

        <AccountInput
          v-model="formData.swift"
          name="swift"
          :errors="errors"
          type="text"
          placeholder=""
          :label="$t('withdraw.wire_details_swift')"
        />

        <div class="kt-form__actions">
          <button type="submit" class="btn btn-primary">{{ $t('withdraw.wire_add') }}</button>
        </div>

      </b-form>

      <hr>

      <div class="kt-section">
        <div class="kt-section__content">
          <div class="table-responsive">
            <table class="table table-striped table-hover">
              <thead class="thead-dark">
              <tr>
                <th>#</th>
                <th>{{ $t('withdraw.wire_accounts_title') }}</th>
                <th>{{ $t('withdraw.wire_accounts_currency') }}</th>
                <th>{{ $t('withdraw.wire_accounts_details') }}</th>
              </tr>
              </thead>
              <tbody v-if="wallets.length">
              <tr v-for="(wallet, index) in wallets">
                <td>#{{index}}</td>
                <td>{{wallet.title}}</td>
                <td>{{wallet.currency}}</td>
                <td>
                  <p>{{ $t('withdraw.wire_accounts_name') }}: {{wallet.beneficiary_name}}</p>
                  <p>{{ $t('withdraw.wire_accounts_address') }}: {{wallet.beneficiary_address}}</p>
                  <p>{{ $t('withdraw.wire_accounts_acc') }}: {{wallet.account_number}}</p>
                  <p>{{ $t('withdraw.wire_accounts_swift') }}: {{wallet.swift}}</p>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div slot="modal-footer" />
    </b-modal>
    <MfaModal
      v-if="showMfa"
      :use-portal="false"
      :show="showMfa"
      action="withdraw_add_wallet"
      :method="profile.mfa_method"
      @verified="addWallet"
      @hidden="hideMfa"
    />
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import DropDown from '../functional/Dropdown'
import Loader from '../global/Loader'
import AccountInput from '../forms/AccountInput'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'

export default {
  components: {
    DropDown,
    Loader,
    AccountInput
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String,
    },
    currencies: {
      type: Array,
      default: () => ['EUR']
    },
    wallets: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      modalOpen: this.show,
      isLoading: false,
      formData: {
        currency: this.currencies[0],
        title: null,
        beneficiary_name: null,
        beneficiary_address: null,
        account_number: null,
        swift: null
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  watch: {
    show (val) {
      this.modalOpen = val
    }
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    addWalletValidate () {
      this.clearErrors()
      this.addWallet()
    },
    addWallet () {
      this.isLoading = true
      axios.post(this.$api.withdrawBankDetails, this.formData).then(response => {
        this.$store.dispatch('showSuccessNotification', this.$t('withdraw.wire_success'))
        this.$emit('done')
        this.isLoading = false
      }).catch(error => {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
        }
        this.$store.dispatch('showErrorNotification', this.$t('withdraw.wire_error'))
        this.isLoading = false
      })
    }
  }
}
</script>
