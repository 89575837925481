<template>
  <div>
    <ReferralBasePage>
      <template v-slot:header>
        <h1 class="kt-margin-b-0">{{ $t('affiliate.payitforward_title') }}</h1>
      </template>

      <Loader :loading="isLoading" loader-style="overflow: hidden">
        <div class="row">
          <div class="col-xl-6 col-lg-6">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    {{ $t('affiliate.payitforward_invite_title') }}
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <template v-if="pending_invites < 10">
                  <div role="alert" class="alert alert-secondary"><div class="alert-text">{{ $t('affiliate.payitforward_use_up_to') }} <b>{{ profile.withdraw_balance_display }}</b></div></div>
                </template>
                <template v-else>
                  <div role="alert" class="alert alert-warning"><div class="alert-text">You've used your pending invites limit!</div></div>
                </template>
                <form @submit.prevent="send_invite_check" class="kt-form">

                  <AccountInput
                    id="amount"
                    type="number"
                    v-model="formData.amount"
                    name="amount"
                    :errors="errors"
                    :label="$t('affiliate.payitforward_amount')"
                    :cols="3"
                    placeholder="5000"
                    :required="true"
                    :min="min"
                    :max="max"
                    prepend="€"
                    :disabled="pending_invites >= 10 || profile.limited_mode"
                  />

                  <AccountInput
                    id="name"
                    type="text"
                    v-model="formData.name"
                    name="name"
                    :errors="errors"
                    :label="$t('affiliate.payitforward_name')"
                    :cols="3"
                    :required="true"
                    :disabled="pending_invites >= 10 || profile.limited_mode"
                  />

                  <AccountInput
                    id="email"
                    type="email"
                    v-model="formData.email"
                    name="email"
                    :errors="errors"
                    :label="$t('affiliate.payitforward_email')"
                    :cols="3"
                    :required="true"
                    :disabled="pending_invites >= 10 || profile.limited_mode"
                  />

                  <div class="kt-form__actions">
                    <div class="row">
                      <div class="col-lg-3"></div>
                      <div class="col-lg-6">
                        <button type="submit" class="btn btn-success" :disabled="pending_invites >= 10 || profile.limited_mode">{{ $t('affiliate.payitforward_send_invite') }}</button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-6">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__body">
                <h4 class="kt-margin-b-20">{{ $t('affiliate.payitforward_about_header') }}</h4>
                <div v-html="$t('affiliate.payitforward_about')"></div>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="kt-portlet kt-portlet--mobile">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    {{ $t('affiliate.payitforward_pending_header') }}
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="table-responsive">
                  <table class="table">
                    <thead class="thead-light">
                    <tr>
                      <th>{{ $t('affiliate.payitforward_table_name') }}</th>
                      <th>{{ $t('affiliate.payitforward_table_email') }}</th>
                      <th>{{ $t('affiliate.payitforward_table_amount') }}</th>
                      <th>{{ $t('affiliate.payitforward_table_expires_at') }}</th>
                      <th>{{ $t('affiliate.payitforward_table_invited_at') }}</th>
                    </tr>
                    </thead>
                    <tbody>

                      <tr v-for="invite in invites">
                        <td>{{ invite.name }}</td>
                        <td>{{ invite.email }}</td>
                        <td>{{ invite.amount_display }}</td>
                        <td>{{ invite.expired_display }}</td>
                        <td>{{ invite.created_display }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Loader>
    </ReferralBasePage>

    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="send_invite"
      @hidden="hideMfa"
    />

  </div>
</template>

<script>
import { mapState } from "vuex";
import ReferralBasePage from "./ReferralBasePage";
import requiresMfa from '../mixins/requiresMfa'
import Loader from '../components/global/Loader'
import formHasErrors from "@/mixins/formHasErrors";
import AccountInput from "@/components/forms/AccountInput";

export default {
  name: "ReferralLinkPage",
  components: {
    ReferralBasePage,
    Loader,
    AccountInput,
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data() {
    return {
      pending_invites: 0,
      invites: [],
      formField: 'formData',
      formData: {
        name: null,
        email: null,
        amount: null
      },
      isLoading: false,
      showMfa: false,
      action: 'payitforward_invite',
      min: 100,
      max: 10000
    };
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created() {
    this.load_invites();
  },
  methods: {
    load_invites(){
      this.isLoading = true
      axios.get(this.$api.affiliate + '/pif').then(response => {
        this.isLoading = false
        this.invites = response.data.data;
        this.pending_invites = this.invites.length;
      }).catch(error => {
        if(error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }else{
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        }
        this.isLoading = false
        return false;
      });
    },
    async send_invite_check(){
      this.clearErrors()
      let hasErrors = false;

      if(this.pending_invites >= 10){
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        hasErrors = true;
        return false;
      }

      let amount = parseInt(this.formData.amount)
      if (amount < this.min || amount > this.max) {
        this.addError('amount', this.$t('affiliate.payitforward_error_invite_amount',{ min: this.min, max: this.max }))
        hasErrors = true;
        return false
      }
      /*if (amount > this.profile.withdraw_balance) {
        this.addError('amount', this.$t('affiliate.payitforward_error_balance'))
        return false
      }*/
      if (this.formData.email === this.profile.email) {
        this.addError('email', this.$t('affiliate.payitforward_error_own_email'))
        hasErrors = true;
        return false
      }

      this.isLoading = true;
      await axios.post(this.$api.affiliate + '/pif/check_invite_email', { email: this.formData.email }).then(response => {
        this.isLoading = false
        if(response.data.data.success === 0){
          this.addError('email', this.$t('affiliate.payitforward_error_email_exists'))
          hasErrors = true;
        }
      }).catch(error => {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
        }
        if(error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }else{
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        }
        this.isLoading = false
        hasErrors = true;
      });

      if(hasErrors){
        return false;
      }

      if (this.profile.mfa_method) {
        this.showMfa = true
      } else {
        this.send_invite(null)
      }
    },
    send_invite (token = null) {
      this.showMfa = false
      this.clearErrors()
      this.isLoading = true
      axios.post(this.$api.affiliate + '/pif/invite', { ...this.formData, token }).then(response => {
        this.$store.dispatch('showSuccessNotification', this.$t('affiliate.payitforward_invited'))
        this.formData.amount = null
        this.formData.name = null
        this.formData.email = null
        this.isLoading = false
        this.load_invites();
      }).catch(error => {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
        }
        if(error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }else{
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        }
        this.isLoading = false
      })
    }
  }
};
</script>
