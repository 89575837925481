<template>
  <div>
    <Loader :loading="isLoading" loader-style="overflow: hidden">
      <div class="form-group">
        <div class="input-group">

          <MaskedInput
              v-model="values.amount"
              type="text"
              class="form-control"
              placeholder="0.00"
              :mask="mask"
              :guide="false"
              :disabled="profile.limited_mode"
          />
          <div class="input-group-append">
            <span class="input-group-text kt-font-bold">
              <a href="#" @click.prevent="enterMaxReinvest()">MAX</a>
            </span>
          </div>
          <div class="input-group-append">
            <button
                class="btn btn-primary"
                @click="reinvestCheck"
                :disabled="profile.limited_mode"
            ><span class="d-none d-sm-inline">{{ $t('deposit.reinvest_button') }}</span><span class="d-inline d-sm-none"><i class="fas fa-forward"></i></span></button>
          </div>
        </div>

        <div
            v-if="hasErrors"
            class="invalid-feedback"
            style="display: block;"
        >
          {{ errors['amount'][0] }}
        </div>
      </div>
    </Loader>

    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="reinvest"
      @hidden="hideMfa"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'
import Loader from '../global/Loader'
import { btcMask, eurMask } from 'js/helpers/inputMasks'

export default {
  name: 'ReinvestForm',
  components: {
    Loader
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data () {
    return {
      values: {
        amount: null
      },
      isLoading: false,
      showMfa: false,
      action: 'deposit_reinvest'
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    mask () {
      if (this.profile && this.profile.account_selected) {
        if (this.profile.account_selected === 'EUR') {
          return eurMask
        } else if (this.profile.account_selected === 'BTC') {
          return btcMask
        }
      }
      return null
    }
  },
  methods: {
    reinvestCheck () {
      this.clearErrors()
      let amount = parseFloat(this.values.amount)
      if (!amount || amount <= 0) {
        this.addError('amount', this.$t('deposit.reinvest_error_amount'))
        return false
      }
      if (amount > this.profile.withdraw_balance) {
        this.addError('amount', this.$t('deposit.reinvest_error_balance'))
        return false
      }
      let minReinvest = this.profile.reinvest_minimum_eur;
      let minReinvestDisplay = this.profile.reinvest_minimum_eur_display;

      if (this.profile.account_selected === 'EUR') {
        minReinvest = this.profile.reinvest_minimum_eur;
        minReinvestDisplay = this.profile.reinvest_minimum_eur_display;
      }else if(this.profile.account_selected === 'BTC'){
        minReinvest = this.profile.reinvest_minimum_btc;
        minReinvestDisplay = this.profile.reinvest_minimum_btc_display;
      }
      if (amount < minReinvest) {
        this.addError('amount', this.$t('deposit.reinvest_error_minimum') + minReinvestDisplay)
        return false
      }
      if (this.profile.mfa_method) {
        this.showMfa = true
      } else {
        this.reinvest(null)
      }
    },
    reinvest (token = null) {
      this.showMfa = false
      this.clearErrors()
      this.isLoading = true
      axios.post(this.$api.profitReinvest, { amount: parseFloat(this.values.amount), token }).then(response => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.reinvest_pending'))
        this.values.amount = null
        this.$emit('done')
        this.isLoading = false
      }).catch(error => {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
        }
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        this.isLoading = false
      })
    },
    enterMaxReinvest () {
      this.values.amount = this.profile.withdraw_balance.toString();
    }
  }
}
</script>
