<template>
  <div>
    <Loader :loading="isLoading" loader-style="overflow: hidden">

      <!--<div
        v-if="!profile.account_verified"
        class="alert alert-danger"
        role="alert"
      >
        <div class="alert-text">
          {{ $t('withdraw.reinvest_verify') }}
        </div>
      </div>-->

      <p>{{ $t('withdraw.reinvest_details') }}</p>

      <div class="form-group">
        <h4 v-html="$t('withdraw.reinvest_amount', { amount: values.amount })"></h4>
        <div>
          <b-form-input
            id="automaticReinvest"
            v-model="values.amount"
            @change="checkAutomaticReinvest"
            type="range"
            min="0"
            max="100"
            step="10"
            :disabled="profile.limited_mode || profile.is_demo/* || !profile.account_verified*/"
          ></b-form-input>
        </div>
      </div>

      <p>{{ $t('withdraw.reinvest_details2') }}</p>

    </Loader>
    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="automaticReinvest"
      @hidden="hideMFAAutomaticReinvest"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import requiresMfa from '../../mixins/requiresMfa'
import { btcMask, eurMask } from '../../helpers/inputMasks'

export default {
  name: 'TransferForm',
  components: {
    Loader
  },
  mixins: [
    requiresMfa
  ],
  data () {
    return {
      values: {
        amount: 0
      },
      isLoading: false,
      showMfa: false,
      action: 'automatic_reinvest',
      token: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created(){
    this.values.amount = this.profile.automatic_reinvest;
  },
  methods: {
    hideMFAAutomaticReinvest (){
      this.values.amount = this.profile.automatic_reinvest;
      this.showMfa = false;
    },
    checkAutomaticReinvest (){
      if (this.profile.mfa_enabled) {
        this.showMfa = true;
      }else{
        this.automaticReinvest();
      }
    },
    automaticReinvest (token = null) {
      this.showMfa = false;
      this.isLoading = true
      axios.post(this.$api.profitAutomaticReinvest, {
        reinvest: this.values.amount,
        token
      }).then(response => {
        this.$store.dispatch('showSuccessNotification', this.$t('withdraw.reinvest_success'))
        this.$emit('updated')
        this.isLoading = false
      }).catch(error => {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
        } else {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }
        this.isLoading = false
      })
    }
  }
}
</script>
