<template>
  <KycForm />
</template>

<script>
import KycForm from '../components/Account/KycForm'
export default {
  name: 'KycPage',
  components: { KycForm }
}
</script>
