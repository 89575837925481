<template>
  <ReferralBasePage>
    <template v-slot:header>
      <h1 class="kt-margin-b-0">{{ $t('affiliate.downline_title') }}</h1>
    </template>

    <div class="row">
      <div class="col-xl-12">
        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body">
            <h4>{{ $t('affiliate.referred') }}</h4>
            <div class="row kt-margin-t-20 kt-margin-b-20">
              <div class="col-md-6">
                <input
                  v-model="filterText"
                  type="text"
                  :placeholder="$t('affiliate.downline_search')"
                  class="form-control"
                />
              </div>
              <div class="col-md-6">
                <label class="kt-checkbox kt-margin-t-10">
                  <input
                    v-model="onlyDeposited"
                    type="checkbox"
                    :true-value="1"
                    :false-value="0"
                  />
                  &nbsp;{{ $t('affiliate.downline_deposited') }}
                  <span></span>
                </label>
              </div>
            </div>

            <Loader :loading="isLoading" loader-style="overflow: hidden">
              <div class="table-responsive">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th><a href="#" @click.prevent="changeSort('registered')">{{ $t('affiliate.registered_at') }} {{ sortBy === 'registered'?(sortDesc?"&#8593;":"&#8595;"):'' }}</a></th>
                      <th>{{ $t('affiliate.downline_full_name') }}</th>
                      <th>{{ $t('affiliate.downline_email') }}</th>
                      <th>{{ $t('affiliate.downline_deposited2') }}</th>
                      <th><a href="#" @click.prevent="changeSort('deposited')">{{ $t('affiliate.downline_total') }} {{ sortBy === 'deposited'?(sortDesc?"&#8593;":"&#8595;"):'' }}</a></th>
                    </tr>
                  </thead>
                  <tbody v-if="data.length > 0">
                    <tr v-for="item in data">
                      <td>{{ item.registered_display }}</td>
                      <td>{{ item.name }}</td>
                      <td>{{ item.email }}</td>
                      <td>
                        <template v-if="item.deposited">
                          {{ $t('affiliate.downline_yes') }}
                        </template>
                      </td>
                      <td>{{ item.total_deposits_display }}</td>
                    </tr>
                  </tbody>
                  <tbody v-if="data.length === 0">
                    <tr>
                      <td colspan="5" style="text-align: center;">
                        {{ $t('affiliate.downline_no_data') }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <b-pagination
                v-model="page"
                :total-rows="totalRows"
                :per-page="perPage"
              ></b-pagination>

            </Loader>
          </div>
        </div>
      </div>
    </div>
  </ReferralBasePage>
</template>

<script>
import DatePicker from "vuejs-datepicker";
import subYears from "date-fns/subYears";
import format from "date-fns/format";
import getTime from "date-fns/getTime";
import distanceInWordsStrict from "date-fns/formatDistanceStrict";
import Loader from "../components/global/Loader";
import ReferralBasePage from "./ReferralBasePage";
import DateRangePicker from "vue2-daterange-picker";

export default {
  name: "ReferralDownlinePage",
  components: {
    DateRangePicker,
    Loader,
    ReferralBasePage
  },
  data() {
    return {
      onlyDeposited: 0,
      filterText: "",
      isLoading: false,
      data: [],
      isSearching: false,
      perPage: null,
      totalRows: null,
      page: 1,
      sortBy: "registered",
      sortDesc: true,
      dateFormat: "yyyy-MM-dd"
    };
  },
  computed: {
  },
  watch: {
    onlyDeposited() {
      this.updateData();
    },
    filterText() {
      this.updateData();
    },
    /*"dateRange.startDate"(newVal, oldVal) {
      if (newVal !== oldVal && this.endDate) {
        this.updateData();
      }
    },
    "dateRange.endDate"(newVal, oldVal) {
      if (newVal !== oldVal && this.startDate) {
        this.updateData();
      }
    },*/
    page: function(){
      this.updateData();
    },
    sortBy: function(){
      this.updateData();
    },
    sortDesc: function(){
      this.updateData();
    }
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      //this.isLoading = true;
      let params = {
        filter: this.filterText,
        deposited: this.onlyDeposited,
        page: this.page,
        sort: this.sortBy,
        sort_desc: this.sortDesc
      };
      axios
        .get(this.$api.affiliate + "/downline", { params })
        .then(response => {
          this.data = response.data.data;
          this.perPage = response.data.meta.per_page;
          this.totalRows = response.data.meta.total;
          this.isLoading = false;
        })
        .catch(error => {
          this.isLoading = false;
        });
    },
    updateDateRange(startDate,endDate) {
      this.updateData();
    },
    changeSort(field) {
      if(this.sortBy === field){
        this.sortDesc = !this.sortDesc;
      }
      if(this.sortBy !== field){
        this.sortDesc = true;
        this.sortBy = field;
      }
    }
  }
};
</script>

<style>
.daterange-custom {
  height: auto;
  padding: 4px 10px;
}
</style>
