<template>
  <div class="withdrawals_box_right withdrawalssum_box_right boxs">
    <div class="downline_flickbox summary_flickbox boxs">
      <div class="withdrawals_history">
        <h4>{{ $t('affiliate.withdraw_history') }}</h4>
      </div>
      <div class="carousel carousel2_team ">
        <!--  <div class="downline_flickbox boxs "> -->
        <div class="boxs demo_demo_demp table-responsive-xl">
          <div v-if="dailyReturnsPaginated.length > 0">
            <b-table
              show-empty
              striped
              hover
              :items="dailyReturnsPaginated"
            ></b-table>

            <b-pagination
              v-model="pageNumber"
              :total-rows="dailyReturnsPageCount"
              :per-page="tableSize"
              class="my-0"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReferralWithdrawalsHistory',
  components: {},
  props: {
    dailyData: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      pageNumber: 1,
      tableSize: 5,
      fields: [
        {
          key: 'date',
          label: 'Date',
          sortable: true
        },
        {
          key: 'amount_total',
          label: 'Amount',
          sortable: true
        }
      ]
    }
  },
  computed: {
    dailyReturnsNeedsPagination () {
      if (!this.dailyData) {
        return false
      }
      return Object.keys(this.dailyData).length > this.tableSize
    },
    dailyReturnsPageCount () {
      if (!this.dailyData) {
        return 1
      }
      let l = Object.keys(this.dailyData).length
      let s = this.tableSize
      return Math.ceil(l / s)
    },
    dailyReturnsPaginated () {
      if (!this.dailyData) {
        return []
      }
      const start = (this.pageNumber - 1) * this.tableSize
      const end = start + this.tableSize
      const keys = Object.keys(this.dailyData).slice(start, end)
      let result = []
      keys.forEach(key => {
        result.push(this.dailyData[key])
      })
      return result
    }
  }
}
</script>
