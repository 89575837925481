<template>
  <div>
    <div class="row">
      <div class="col-xl-12">

        <div class="kt-portlet kt-portlet--tabs">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-toolbar">
              <ul class="nav nav-tabs nav-tabs-line nav-tabs-line-brand nav-tabs-line-2x nav-tabs-line-right nav-tabs-bold" role="tablist">
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :class="{active: $route.name === 'referrals'}"
                    :to="{name: 'referrals'}"
                    role="tab"
                  >
                    {{ $t('affiliate.summary') }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :class="{active: $route.name === 'referralLinks'}"
                    :to="{name: 'referralLinks'}"
                    role="tab"
                  >
                    {{ $t('affiliate.links') }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :class="{active: $route.name === 'referralAnalytics'}"
                    :to="{name: 'referralAnalytics'}"
                    role="tab"
                  >
                    {{ $t('affiliate.analytics') }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :class="{active: $route.name === 'referralMaterials'}"
                    :to="{name: 'referralMaterials'}"
                    role="tab"
                  >
                    {{ $t('affiliate.materials') }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :class="{active: $route.name === 'referralDownline'}"
                    :to="{name: 'referralDownline'}"
                    role="tab"
                  >
                    {{ $t('affiliate.downline') }}
                  </router-link>
                </li>
                <li class="nav-item">
                  <router-link
                    class="nav-link"
                    :class="{active: $route.name === 'referralPayitforward'}"
                    :to="{name: 'referralPayitforward'}"
                    role="tab"
                  >
                    {{ $t('affiliate.payitforward') }}&nbsp;<i class="fas fa-flask" style="font-size:0.9em;position:relative;top:-5px;"></i>
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="tab-content">
              <div class="tab-pane active">
                <slot name="header"></slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="accordion accordion-solid accordion-toggle-plus kt-margin-b-20" role="tablist">
      <b-card no-body>
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle="'selfaffiliation'" class="card-title kt-font-danger"><i class="fas fa-exclamation-circle kt-font-danger"></i> {{ $t('affiliate.self_affiliation_title') }}</div>
        </b-card-header>
        <b-collapse :id="'selfaffiliation'" :visible="false" accordion="referrals_selfaffiliation" role="tabpanel">
          <b-card-body>
            <div v-html="$t('affiliate.self_affiliation')"></div>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body>
        <b-card-header header-tag="header" role="tab">
          <div v-b-toggle="'guidelines'" class="card-title kt-font-danger"><i class="fas fa-exclamation-circle kt-font-danger"></i> {{ $t('affiliate.guidelines_title') }}</div>
        </b-card-header>
        <b-collapse :id="'guidelines'" :visible="false" accordion="referrals_guidelines" role="tabpanel">
          <b-card-body>
            <div v-html="$t('affiliate.guidelines')"></div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>

    <slot></slot>
  </div>
</template>

<script>
  import {mapState} from "vuex";

  export default {
    name: 'ReferralBasePage',
    components: {},
    data () {
      return {
        pif_allowed_users: [
          'egorix@gmail.com',
          'test@example.com',
          'stevethecatcooper@gmail.com'
        ]
      }
    },
    computed: {
      ...mapState({
        profile: state => state.authUser.profile
      })
    },
  }
</script>
