<template>
  <Loader :loading="isLoading">
    <div class="text-center">
      <p>Due to external data attacks we need to secure our systems and can not store these data anymore.</p>
      <p>As all of your transactions are always accessible on blockchains, you can comprehend these at any time.</p>
    </div>
    <!--<div class="table-responsive">
      <table class="table table-striped table-bordered table-hover" id="deposit_table">
        <thead>
          <tr>
            <th nowrap="">{{ $t('withdraw.history_type') }}</th>
            <th nowrap="">{{ $t('withdraw.history_status') }}</th>
            <th nowrap="">{{ $t('withdraw.history_amount_initial') }}</th>
            <th nowrap="">{{ $t('withdraw.history_amount_final') }}</th>
            <th nowrap="">{{ $t('withdraw.history_created') }}</th>
            <th nowrap="">{{ $t('withdraw.history_approved') }}</th>
            <th nowrap="">{{ $t('withdraw.history_sent') }}</th>
          </tr>
        </thead>
        <tbody v-if="withdraws">
          <tr v-for="withdraw in withdraws">
            <td nowrap="">
              {{ withdraw.operation }}
              <div v-if="withdraw.type === 'withdraw' && withdraw.status === 'pending' && withdraw.wallet_title">
                <small>to {{ withdraw.wallet_title }}</small>
              </div>
            </td>
            <td nowrap="">
              <span
                class="kt-badge kt-badge--inline kt-badge--pill"
                :class="{
                    'kt-badge--danger': withdraw.status === 'declined',
                    'kt-badge--success': (withdraw.status === 'approved' && withdraw.type !== 'withdraw') || (withdraw.status === 'sent' && withdraw.type === 'withdraw'),
                    'kt-badge--warning': (withdraw.status === 'pending') || (withdraw.status === 'approved' && withdraw.type === 'withdraw'),
                  }"
              >
                {{ $t('withdraw.status_' + withdraw.status) }}
              </span>
              <template v-if="withdraw.type === 'withdraw' && withdraw.status === 'pending' && !withdraw.emergency_withdrawal_id">
                &nbsp;<a href="javascript:void(0)" @click.prevent="cancelPending(withdraw.id)"><i class="fas fa-trash-alt kt-font-danger"></i></a>
              </template>
            </td>
            <td nowrap="">{{ withdraw.amount }}<i v-if="(withdraw.type === 'profit' && withdraw.partial_profit)" style="color:red;" class="fas fa-exclamation-circle ml-1" v-b-tooltip.hover :title="$t('withdraw.history_partial')"></i></td>
            <td nowrap="" class="text-bold">
              <template v-if="withdraw.type === 'withdraw' && withdraw.status === 'approved'">{{ $t('withdraw.not_sent') }}</template>
              <template v-else>{{ withdraw.amount_final || '-' }}</template>
            </td>
            <td nowrap="">{{ withdraw.date.display }}</td>
            <td nowrap="">{{ withdraw.date_approved ? withdraw.date_approved.display : '' }}</td>
            <td nowrap="">{{ withdraw.date_approved ? withdraw.date_sent.display : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
    ></b-pagination>
    <div>
      <i class="fas fa-exclamation-circle ml-1"></i> - {{ $t('withdraw.history_partial') }}
    </div>-->
  </Loader>
</template>

<script>
import Loader from "../global/Loader";
import CJSAesJson from "@/vendor/cjsf";
export default {
  name: 'WithdrawalActivity',
  components: {
    Loader
  },
  data () {
    return {
      withdraws: [],
      currentPage: 1,
      totalRows: null,
      perPage: null,
      isLoading: false,
    }
  },
  created () {
    this.getHistory()
  },
  watch: {
    currentPage: function(){
      this.getHistory();
    }
  },
  methods: {
    getHistory () {
      /*this.isLoading = true;
      axios.get(this.$api.withdraw + '/index?page=' + this.currentPage).then(response => {
        this.isLoading = false;

        let checksum = response.headers['content-checksum'];
        let data = CJSAesJson.decrypt(response.data.data, checksum);

        this.withdraws = data.data;
        this.perPage = data.meta.per_page;
        this.totalRows = data.meta.total;
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
      })*/
    },
    cancelPending (id) {
      if(confirm('Are you sure?')){
        this.isLoading = true;
        axios.post(this.$api.withdraw + '/cancel',{
          id: id
        }).then(response => {
          this.isLoading = false;
          this.$store.dispatch('showSuccessNotification', this.$t('withdraw.cancel_success'))
          this.getHistory()
        }).catch(error => {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        })
      }
    }
  }
}
</script>
