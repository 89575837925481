<template>
  <div>
    <div class="kt-portlet">
      <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
          <div class="col-md-12 col-lg-6 col-xl-6">
            <StatisticsBox
              :value="profile.deposit_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.deposit_balance_btc || null"
              :value-in-eur="profile.deposit_balance_eur || null"
              :value-in-eth="profile.deposit_balance_eth || null"
              dont-format
              icon="invest"
              :title="$t('dashboard.invested_balance')"
              :dots-tooltip="$t('dashboard.invested_balance_tooltip')"
              :has-warning="!!profile.deposit_balance_below_min"
              :info-text="$t('dashboard.invested_balance_low')"
              :info-tooltip="$t('dashboard.invested_balance_low_tooltip',{ minimum: profile.invested_balance_minimum_display})"
              :info="profile.deposit_has_idle?$t('deposit.idle_detected'):null"
            />
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <StatisticsBox
              :value="profile.withdraw_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.withdraw_balance_btc || null"
              :value-in-eur="profile.withdraw_balance_eur || null"
              :value-in-eth="profile.withdraw_balance_eth || null"
              dont-format
              color="warning"
              icon="balance"
              :title="$t('dashboard.withdrawable_balance')"
              :dots-tooltip="$t('dashboard.withdrawable_balance_tooltip')"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-if="profile.nft_not_allowed">
      <div class="alert alert-danger">
        <div class="alert-text">
          <p>Due to the request of the Austrian Financial Market Authority (FMA Austria) to establish the legal order, Austrian citizens can no longer participate in the NFT issue until further notice.</p>
        </div>
      </div>
    </div>
    <div v-else-if="!profile.deposit_balance_below_min" class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">YieldNodes NFT</h3>
        </div>
      </div>

      <div class="alert alert-warning">
        <div class="alert-text">
          <p>NFT Claim period is over and claiming is no longer possible. For questions, please contact our support team.</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
//import NFTForm from "@/components/Account/NFTForm.vue";
import VerificationInfo from '../components/Withdrawal/VerificationInfo'
import Loader from "@/components/global/Loader";
import StatisticsBox from '../components/functional/StatisticsBox'

export default {
  name: 'NFTPage',
  components: {
    StatisticsBox,
    VerificationInfo,
    Loader
  },
  mixins: [
  ],
  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
  },
  methods: {
    update () {
      this.$store.dispatch('setAuthUser')
    }
  }
}
</script>
