<template>
  <div class="row">
    <div class="col-xl-12">
      <transition
          name="fade"
          mode="out-in"
      >
        <router-view :key="$route.fullPath" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AccountPage',
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  }
}
</script>
