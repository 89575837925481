<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12">

      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
                <i class="fas fa-search-dollar"></i>
            </span>
            <h3 class="kt-portlet__head-title">
              {{ $t('title.audit_feb2021') }}
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <b><router-link :to="{name: 'audits'}"><i class="fas fa-undo"></i> Previous Audits</router-link></b>
          </div>
        </div>
          <div class="kt-portlet__body">
            <h2 class="kt-margin-b-20 text-center">Audit and Feasibility Check on : YieldNodes.com<br />Quartal 1, 2021</h2>

            <hr class="kt-separator">
            <div class="kt-font-lg text-center kt-font-bold">On the 12.02.2021 we invited 5 Auditors* to have a look at the numbers within Yieldnodes.</div>
            <hr class="kt-separator">

            <p class="kt-margin-t-20 kt-margin-b-20">The Following people served as auditors*:</p>

            <div class="kt-portlet kt-portlet--bordered kt-portlet--mobile kt-portlet--head-lg">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Antony Kemble</b> from YieldNodes Monitor
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-sm">
                    <p>Over the past 15 years, Anthony has developed numerous popular trading systems. He's often participating in the international conferences and research projects. Together with his friend and partner Ronald they are founders of the famous GPS Forex Robot and other successful expert advisors for Forex.</p>
                      <p>In November 2019 guys joined beta testers of YieldNodes and recently launched a website - Yield Nodes Monitor, where they share their experience and will rapidly notify about the latest news of the project.</p>
                    <p><a href="https://yieldnodes.org/" target="_blank">yieldnodes.org <i class="fas fa-external-link-alt kt-font-md"></i></a></p>
                  </div>
                  <div class="col-sm-3">
                    <img src="~images/auditors/anthony2.png" class="img-fluid mx-auto">
                  </div>
                </div>
              </div>
            </div>

            <div class="kt-portlet kt-portlet--bordered kt-portlet--mobile kt-portlet--head-lg">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Jonathan Platts</b> from Tradewise
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-sm">
                    <p>Jonathan has been investing and growing his own private portfolio for over 15 years, initially trading stocks using derivatives such as spread betting. Since then he has invested in a broad range of markets, and after having a number of bad experiences decided to launch Trade Wise as a way of sharing these negative experiences and warning against potential scams.</p>
                    <p>Trade Wise now has thousands of followers from all over the world and Jonathan continues to test and review new investment opportunities in an honest and transparent way.</p>
                    <p><a href="https://tradewise.community/top-3-services/" target="_blank">tradewise.community <i class="fas fa-external-link-alt kt-font-md"></i></a></p>
                  </div>
                  <div class="col-sm-3">
                    <img src="~images/auditors/jonathan.jpg" class="img-fluid mx-auto">
                  </div>
                </div>
              </div>
            </div>

            <div class="kt-portlet kt-portlet--bordered kt-portlet--mobile kt-portlet--head-lg">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Graham Laurie</b> from Cashmaster
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-sm">
                    <p>Established in 2007, Cash Master has spent the last fourteen years aquiring and testing every kind of financial trading, passive investing and sports betting related system or service on the market; from simple horse racing and football systems to financial trading with stocks, index, options and forex systems and services, as well as passive investment opportunities such as property investments, angel investment clubs and even cryptocurrency trading, mining, noding and crypto investment opportunities.</p>
                    <p><a href="https://www.cash-master.com/" target="_blank">cash-master.com <i class="fas fa-external-link-alt kt-font-md"></i></a></p>
                  </div>
                  <div class="col-sm-3">
                    <img src="~images/auditors/graham.jpg" class="img-fluid mx-auto">
                  </div>
                </div>
              </div>
            </div>

            <div class="kt-portlet kt-portlet--bordered kt-portlet--mobile kt-portlet--head-lg">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Janna</b> from Jannafx
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-sm">
                    <p>Janna is a trader with 15 years experience in the Forex market. Originally from Russia, she moved to the UK in 2004, where she developed her trading strategy. Since then, the results of her part-time trading activities have funded a literal "world tour" with extended stays in some 50 countries, and included a multi-contintent trip lasting 9 months. Prompted by her keen interest in helping others achieve success in FX, she started a YouTube channel called "JannaFX" in 2013 to offer free Forex education, training in the strategy she uses, and her market insights to its 30k+ subscribers.</p>
                    <p><a href="http://jannafx.com/" target="_blank">jannafx.com <i class="fas fa-external-link-alt kt-font-md"></i></a></p>
                  </div>
                  <div class="col-sm-3">
                    <img src="~images/auditors/zhanna.jpg" class="img-fluid mx-auto">
                  </div>
                </div>
              </div>
            </div>

            <div class="kt-portlet kt-portlet--bordered kt-portlet--mobile kt-portlet--head-lg">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Juergen Bappert</b> from Expert FX
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <div class="row">
                  <div class="col-sm">
                    <p>German: Dipl.Volkswirt u. Volljurist, 1975 5 Monate Praktikum bei der EU-Kom. in Brüssel, Importgroßhandel, Telefonvertrags-Geschäft, 15 Jahre Managed Forex u. ab Anfang 2020 in Yieldnodes aktiv.</p>
                    <p><a href="https://forexorcrypto.com/audit-feb122021/" target="_blank">forexorcrypto.com <i class="fas fa-external-link-alt kt-font-md"></i></a></p>
                  </div>
                  <div class="col-sm-3">
                    <img src="~images/auditors/juergen.png" class="img-fluid mx-auto">
                  </div>
                </div>
              </div>
            </div>
            <div class="kt-font-lg">

              <p class="kt-margin-t-20"><b>Full Disclosure:</b> All Auditors* have Yieldnodes Accounts. Some for longer, some for less. They were choosen by us because they all have quite an audience either on youtube or on the web in general and are considered trustworthy analyzers by the public.</p>

              <p>Stefan Hoermann - CEO and partner of YieldNodes had a 10 minute Video call with all of the Auditors*, logging into the administrative area of Yieldnodes (admin) showing the general ammount of deposits (plus compounds, the withdrawals, the amount of active and signed up users.</p>

              <p><b>No individual data was shown for privacy purposes.</b></p>

              <p>Afterwards:</p>

              <p>Urs Schwinger - Masternode Architect of Yieldnodes and partner had a 20-50 Minute Video call on skype doing a Screenshare (showing his Desktop)  with each of the Auditors* showing:</p>

              <p>Masternodes, Staking ammounts, wallets and a very in depth Excel calculation showing in detail all holdings of YieldNodes.</p>


              <p>The Auditors* could make their own assessment and pretty easily compare the sums and values to affirm that assets have been generated and that yieldnodes is in good health.</p>

              <h4 class="kt-margin-b-20 kt-margin-t-30">Questions</h4>

              <p><b>Q:</b> Looks awesome. Will you do audits regularly from now on?</p>

              <p><b>A:</b> We will try to do them twice a year or yearly at least but we can not make promises as it was quite an undertaking and we like to focus on the noding and staking. This audit is also only acessible in the members area and we do not use it as a marketing means. Its to reward you, our fellow member who put trust in us and helped us realize the vision that is YieldNodes.</p>

              <p class="kt-margin-t-10"><b>Q:</b> Does this mean Yieldnodes is a 100% safe Opportunity and I shall invest what I can?</p>

              <p><b>A:</b> Absolutely NOT! This Audit has been done to show that.</p>

              <h3 class="kt-margin-b-20 text-center">WE <u>PRACTICE</u> WHAT WE PREACH!</h3>

              <p>Since the start of YieldNodes in October 2019 we have generated over 10% monthly in value and we wanted to give our members who stood with us some peace of mind. Because Talk is cheap so we wanted to put the money were our mouth is.</p>

              <p>YieldNodes is going strong but it is still</p>

              <ul>
                <li><b>NOT</b> regulated or supervised by any financial Entity</li>
                <li>STILL a <b>VERY RISKY</b> participation model</li>
                <li><b>NOT</b> Trading Cryptos and <b>NOT</b> a Bitcoin pegged Investment</li>
                <li>By <b>NO MEANS</b> a bank account.</li>
              </ul>

              <p>So use the information we provided to do your own risk assessment and understand that the funds are always at risk of being lost and there are absolutely <b>NO</b> guarantees.</p>

              <p>We do what we do with Honesty and Integrity but it is as much a wild ride for us as it is for you so read and absorb what you can and always make sure you can live with the decisions you make.</p>

              <p>That being said, we are grateful for your participation and humbled by your trust and we are ready and hungry to prove ourselves to you and the world to build out the decentralized economy that is the long term vision: <b>DECENOMY</b></p>

              <p>Sincerely,<br/><br/>

              Urs & Steve<br/>
              Yieldnodes Partners.</p>

            </div>

            <h3 class="text-center kt-margin-t-30 kt-margin-b-20">Signed Audits (click to enlarge)</h3>

            <div class="row">
              <div class="col-sm"><a href="/assets/audit/jonathan_t.png" target="_blank"><img src="~images/auditors/jonathan_t.png" class="img-fluid img-thumbnail"></a></div>
              <div class="col-sm"><a href="/assets/audit/graham_t.png" target="_blank"><img src="~images/auditors/graham_t.png" class="img-fluid img-thumbnail"></a></div>
              <div class="col-sm"><a href="/assets/audit/zhanna_t.png" target="_blank"><img src="~images/auditors/zhanna_t.png" class="img-fluid img-thumbnail"></a></div>
              <div class="col-sm"><a href="/assets/audit/anthony_t.png" target="_blank"><img src="~images/auditors/anthony_t.png" class="img-fluid img-thumbnail"></a></div>
              <div class="col-sm"><a href="/assets/audit/jurgen_t.png" target="_blank"><img src="~images/auditors/jurgen_t.png" class="img-fluid img-thumbnail"></a></div>
            </div>


            <p class="kt-margin-t-20">*The term Auditor in our case refers to people who were allowed a peak behind the curtain and does not imply they are working in this official capacity. They are regular people that were choosen for the job and accepted it.</p>

          </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'AuditPage',
  components: {
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {

  },
  methods: {

  }
}
</script>
