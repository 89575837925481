<template>
  <div>
    <div class="kt-portlet">
      <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              :value="profile.deposit_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.deposit_balance_btc || null"
              :value-in-eur="profile.deposit_balance_eur || null"
              :value-in-eth="profile.deposit_balance_eth || null"
              dont-format
              icon="invest"
              :title="$t('dashboard.invested_balance')"
              :dots-tooltip="$t('dashboard.invested_balance_tooltip')"
              :has-warning="!!profile.deposit_balance_below_min"
              :info-text="$t('dashboard.invested_balance_low')"
              :info-tooltip="$t('dashboard.invested_balance_low_tooltip',{ minimum: profile.invested_balance_minimum_display})"
              :info="profile.deposit_has_idle?$t('deposit.idle_detected'):null"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              icon="balance"
              :title="$t('deposit.pending')"
              :value="profile.deposit_pending_display_nc"
              :currency="profile.currency_sign"
              :value-in-btc="profile.deposit_pending_btc"
              :value-in-eur="profile.deposit_pending_eur"
              :value-in-eth="profile.deposit_pending_eth"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              dont-format
              color="success"
              :dots-tooltip="$t('deposit.pending_tooltip')"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              :value="profile.withdraw_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.withdraw_balance_btc || null"
              :value-in-eur="profile.withdraw_balance_eur || null"
              :value-in-eth="profile.withdraw_balance_eth || null"
              dont-format
              color="warning"
              icon="balance"
              :title="$t('dashboard.withdrawable_balance')"
              :dots-tooltip="$t('dashboard.withdrawable_balance_tooltip')"
            />
          </div>
        </div>
      </div>
    </div>

    <!--<NewUserAlert
      v-if="profile.deposit_first_time"
    />-->

    <EmergencyWithdrawalWarning
      v-if="profile.has_emergency_withdrawal"
      :amount="profile.emergency_withdrawal_amount"
      :wallet="profile.emergency_withdrawal_wallet"
    />

    <!--<PendingBonusDepositReminder
      v-if="profile.has_pending_deposit_bonus"
    />-->

    <BalanceWarning
      v-if="!profile.deposit_first_time && profile.deposit_balance_below_min"
      :invested_minimum="profile.invested_balance_minimum_display"
    />

    <!--<IdleDeposits
      v-if="profile.deposit_has_idle"
      :idle="profile.deposit_idle"
    />-->

    <div class="row">
      <div class="col-xl-8 col-lg-8 col-md-12">

        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('deposit.title') }}</h3>
            </div>
            <div class="kt-portlet__head-toolbar">
              <button @click.prevent="openVoucherModal()" class="btn btn-sm btn-primary" :disabled="profile.limited_mode"><span class="d-none d-sm-inline"><i class="fas fa-money-check-alt"></i> {{ $t('deposit.voucher') }}</span><span class="d-inline d-sm-none">{{ $t('deposit.voucher_xs') }}</span></button>
            </div>
          </div>
          <div class="kt-portlet__body">
            <DepositForm />
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4">

        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('deposit.reinvest') }}</h3>
            </div>
          </div>
          <div class="kt-portlet__body">

            <p>{{ $t('deposit.reinvest_details') }}</p>
            <ReinvestForm
              @done="updateProfile"
            />
          </div>
        </div>

        <!--<div v-if="profile.deposit_buy_allowed" class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">Get deposit bonus</h3>
            </div>
          </div>
          <div class="kt-portlet__body">

            <p>You can pay for someone's emergency withdrawal and obtain 20% increase of your deposit.</p>
            <button @click="listDepositsForSale" class="btn btn-success btn-block">Show available options</button>
          </div>
        </div>-->
      </div>

      <div class="col-xl-12">

        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('deposit.history') }}</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <DepositActivity
              ref="depositActivity"
            />
          </div>
        </div>
      </div>
    </div>
    <VoucherModal
      v-if="voucherModal"
      :show="voucherModal"
      @hidden="voucherModal = false"
      @done="updateProfile"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import StatisticsBox from '../components/functional/StatisticsBox'
import BalanceWarning from '../components/functional/BalanceWarning'
import IdleDeposits from '../components/functional/IdleDeposits'
import DepositForm from '../components/Deposit/DepositForm'
import ReinvestForm from '../components/Deposit/ReinvestForm'
import DepositActivity from '../components/Deposit/DepositActivity'
import VoucherModal from '../components/Deposit/VoucherModal'
import moment from 'moment'
import NewUserAlert from '@/components/functional/NewUserAlert';
import EmergencyWithdrawalWarning from '@/components/functional/EmergencyWithdrawalWarning';
import PendingBonusDepositReminder from '@/components/functional/PendingBonusDepositReminder';

export default {
  name: 'DepositsPage',
  components: {
    EmergencyWithdrawalWarning,
    NewUserAlert,
    StatisticsBox,
    DepositForm,
    ReinvestForm,
    DepositActivity,
    VoucherModal,
    BalanceWarning,
    IdleDeposits,
    PendingBonusDepositReminder
  },
  data () {
    return {
      isLoading: false,
      voucherModal: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
},
  methods: {
    updateProfile () {
      this.$store.dispatch('setAuthUser')
      this.$refs.depositActivity.getHistory()
    },
    moment: function () {
      return moment();
    },
    openVoucherModal(){
      this.voucherModal = true;
    },
    listDepositsForSale() {
      this.$router.push({ name: 'deposit_buy' });
    }
  }
}
</script>
