<template>
  <div>
    <!-- begin:: Aside -->
    <button
      class="kt-aside-close "
      id="kt_aside_close_btn"
      @click.prevent="closeSidebar"
    >
      <i class="fas fa-times" />
    </button>
    <div class="kt-aside  kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop kt-margin-t-30"
         id="kt_aside">

      <!-- begin:: Aside Menu -->
      <div class="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
           id="kt_aside_menu_wrapper">
        <div id="kt_aside_menu" class="kt-aside-menu " data-ktmenu-vertical="1"
             data-ktmenu-scroll="0">
          <ul class="kt-menu__nav ">
            <li
                class="kt-menu__item"
                :class="{'kt-menu__item--active': $route.name === 'dashboard'}"
                aria-haspopup="true"
            >
              <router-link :to="{name: 'dashboard'}"
                  class="kt-menu__link "
              ><i
                  class="kt-menu__link-icon fas fa-home"></i><span
                  class="kt-menu__link-text">{{ $t('title.dashboard') }}</span></router-link></li>
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'reports'}"
            >
                <router-link  :to="{name: 'reports'}" class="kt-menu__link "
                  :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-chart-bar"></i><span
                class="kt-menu__link-text">{{ $t('title.reports') }}</span></router-link></li>
            <!--<li
              class="kt-menu__item "
              aria-haspopup="true"
              :class="{'kt-menu__item--active': $route.name === 'p2p_sells'}"
            >
              <router-link :to="{name: 'p2p_sells'}"
                           class="kt-menu__link"
                  :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-comments-dollar"></i><span
                class="kt-menu__link-text">Claims</span></router-link></li>-->
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'deposit'}"
            >
                <router-link :to="{name: 'deposit'}"
                                                               class="kt-menu__link "
                             :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-briefcase"></i><span
                class="kt-menu__link-text">{{ $t('title.deposit') }}</span></router-link></li>
            <!--<li
              class="kt-menu__item "
              aria-haspopup="true"
              :class="{'kt-menu__item--active': $route.name === 'otc'}"
            >
              <router-link :to="{name: 'otc'}"
                           class="kt-menu__link "
                  :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon far fa-gem"></i><span
                class="kt-menu__link-text">{{ $t('title.otc') }}</span></router-link></li>-->
            <!--<li v-if="profile.deposit_buy_allowed"
                class="kt-menu__item"
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'deposit_buy'}"
            >
                <router-link :to="{name: 'deposit_buy'}"
                                                               class="kt-menu__link "
                  :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-gift"></i><span
                  class="kt-menu__link-text">{{ $t('title.deposit_buy') }}<sup>beta</sup></span></router-link></li>-->
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'withdrawal'}"
            >
                <router-link :to="{name: 'withdrawal'}"
                                                               class="kt-menu__link "
                             :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-coins"></i><span
                class="kt-menu__link-text">{{ $t('title.withdrawal') }}</span></router-link></li>
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'referrals'}"
            >
                <router-link :to="{name: 'referrals'}"
                                                               class="kt-menu__link "
                             :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-users"></i><span
                class="kt-menu__link-text">{{ $t('title.affiliate') }}</span></router-link></li>
            <!--<li class="kt-menu__section ">
              <h4 class="kt-menu__section-text">{{ $t('title.profile2') }}</h4>
              <i class="kt-menu__section-icon fas fa-ellipsis-h"></i>
            </li>-->
            <li class=""><hr></li>
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'profile'}"
            >
                <router-link :to="{name: 'profile'}"
                                                               class="kt-menu__link "
                             :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon far fa-user"></i><span
                class="kt-menu__link-text">{{ $t('title.profile') }}</span></router-link></li>
            <li
              v-if="!profile.deposit_balance_below_min"
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'nft'}"
            >
                <router-link :to="{name: 'nft'}"
                                                               class="kt-menu__link "
                             :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-industry"></i><span
                class="kt-menu__link-text">{{ $t('title.nft') }}</span></router-link></li>
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'preferences'}"
            >
                <router-link :to="{name: 'preferences'}"
                                                               class="kt-menu__link "
                ><i
                class="kt-menu__link-icon fas fa-cog"></i><span
                class="kt-menu__link-text">{{ $t('title.settings') }}</span></router-link></li>
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === '2fa'}"
            >
                <router-link :to="{name: '2fa'}" class="kt-menu__link "
                ><i
                class="kt-menu__link-icon fas fa-user-lock"></i><span
                class="kt-menu__link-text">{{ $t('title.security') }}</span></router-link></li>
            <li
                class="kt-menu__item "
                aria-haspopup="true"
                :class="{'kt-menu__item--active': $route.name === 'kyc'}"
            >
                <router-link :to="{name: 'kyc'}" class="kt-menu__link "
                             :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-id-card"></i><span
                class="kt-menu__link-text">{{ $t('title.kyc') }}</span></router-link></li>

            <li class=""><hr></li>
            <!--<li
              class="kt-menu__item "
              aria-haspopup="true"
              :class="{'kt-menu__item--active': $route.name === 'performance'}"
            >
              <router-link :to="{name: 'performance'}"
                           class="kt-menu__link "
                  :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-rocket"></i><span
                class="kt-menu__link-text">{{ $t('title.performance') }}</span></router-link></li>-->
            <li
              class="kt-menu__item "
              aria-haspopup="true"
              :class="{'kt-menu__item--active': $route.name === 'coinsaudit'}"
            >
              <router-link :to="{name: 'coinsaudit'}"
                           class="kt-menu__link " target="_blank"
                ><i
                class="kt-menu__link-icon fas fa-search-dollar"></i><span
                class="kt-menu__link-text">Audit Coin Reserves</span></router-link></li>
            <li
              class="kt-menu__item "
              aria-haspopup="true"
              :class="{'kt-menu__item--active': $route.name === 'messages'}"
            >
              <router-link :to="{name: 'messages'}"
                           class="kt-menu__link "
                           :class="profile.nft_claimed ? 'disabled' : ''"
              ><i
                class="kt-menu__link-icon fas fa-archive"></i><span
                class="kt-menu__link-text">{{ $t('title.messages') }}</span></router-link></li>
            <li
              class="kt-menu__item "
              aria-haspopup="true"
              :class="{'kt-menu__item--active': $route.name === 'faq'}"
            >
              <router-link :to="{name: 'faq'}"
                           class="kt-menu__link "
                           :class="profile.nft_claimed ? 'disabled' : ''"
              ><i
                class="kt-menu__link-icon fas fa-question-circle"></i><span
                class="kt-menu__link-text">{{ $t('title.contact') }}</span></router-link></li>
            <!--<li
              class="kt-menu__item "
              aria-haspopup="true"
              :class="{'kt-menu__item--active': $route.name === 'faq'}"
            >
              <a href="https://knowledgebase.yieldnodes.com" target="_blank"
                           class="kt-menu__link "
                  :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-book"></i><span
                class="kt-menu__link-text">{{ $t('title.knowledgebase') }}</span></a></li>-->
            <!--<li
              class="kt-menu__item"
              aria-haspopup="true"
            >
              <a class="kt-menu__link" href="#" @click.prevent="openFeedbackModal"
                  :class="profile.nft_claimed ? 'disabled' : ''"
                ><i
                class="kt-menu__link-icon fas fa-comment-dots"></i><span
                class="kt-menu__link-text">{{ $t('title.feedback') }}</span></a></li>-->
          </ul>
        </div>
      </div>
    </div>

    <FeedbackModal
      v-if="feedbackModal"
      :show="feedbackModal"
      @hidden="feedbackModal = false"
    ></FeedbackModal>
  </div>
  <!-- end:: Aside Menu -->
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import HeaderDropdown from './HeaderDropdown'
  // import { clearCache, hideSidebar, showSidebar } from '../../helpers/sidebar'
  // import { scrollTop } from '../../helpers/dom'
  import isMobile from '../../helpers/isMobile'
  import FeedbackModal from "@/components/global/FeedbackModal";

  export default {
    components: {
      FeedbackModal,
      HeaderDropdown
    },
    data () {
      return {
        fixed: false,
        height: null,
        offsetTop: null,
        feedbackModal: false
      }
    },
    computed: {
      isMobile () {
        return isMobile()
      },
      ...mapState({
        profile: state => state.authUser.profile,
        sidebar: state => state.sidebar.open
      })
    },
    created() {
      /*setTimeout(() => {
        let element = document.getElementById('ynl');
        if(element) {
          fetch(this.$api.refresh).then(() => {

          }).catch(() => {

          });
        }
      }, 3000);*/
    },
    methods: {
      ...mapActions([
        'closeSidebar'
      ]),
      closeSidebarIfOpen () {

      },
      openFeedbackModal () {
        this.feedbackModal = true;
      }
    }
  }
</script>
