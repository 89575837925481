<template>
  <Loader :loading="isLoading">
    <div class="kt-portlet__body">
      <div :class="(profile.mfa_method === 'phone' || profile.mfa_method === 'authenticator')?'alert alert-success':'alert alert-secondary'" role="alert">
        <div class="alert-text">
          {{ $t('mfa.method') }}
          <strong>
            <template v-if="profile.mfa_method === 'phone'">
              {{ $t('mfa.sms') }}
            </template>
            <template v-else-if="profile.mfa_method === 'authenticator'">
              {{ $t('mfa.app') }}
            </template>
            <template v-else-if="profile.mfa_method === 'email'">
              {{ $t('mfa.email2') }}
            </template>
            <template v-else>
              {{ $t('mfa.none') }}
            </template>
          </strong>
        </div>
      </div>

      <!-- Authenticator App MFA -->
      <div class="row">
        <div class="col-md-9">
          <h3>{{ $t('mfa.app_title3') }}</h3>
          <p v-html="$t('mfa.app_description')"></p>
        </div>
        <div class="col-md-3">
          <div class="kt-margin-b-20">
            <template v-if="profile.mfa_method === null">
              <a href="#" class="btn btn-primary" @click.prevent="showAuthenticatorForm(null)">{{ $t('mfa.select') }}</a>
            </template>
            <template v-else-if="profile.mfa_method === 'phone'">
              <a href="#" class="btn btn-primary" @click.prevent="fromPhoneToAuth">{{ $t('mfa.app_switch') }}</a>
            </template>
            <template v-else-if="profile.mfa_method === 'email'">
              <a href="#" class="btn btn-primary" @click.prevent="fromEmailToAuth">{{ $t('mfa.app_switch') }}</a>
            </template>
            <template v-else-if="profile.mfa_method === 'authenticator'">
              <a href="#" class="btn btn-secondary disabled"><i class="fas fa-check"></i> {{ $t('mfa.selected') }}</a>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-if="profile.mfa_enabled && profile.mfa_method === 'authenticator'"
          class="col-md-8 col-lg-6"
        >
          <MfaSettingsForm />
        </div>
      </div>

      <div class="kt-separator kt-separator--space-md"></div>

      <!-- EMAIL MFA -->
      <div class="row">
        <div class="col-md-9">
          <h3>{{ $t('mfa.email_title') }}</h3>
          <p v-html="$t('mfa.email_description')"></p>
        </div>
        <div class="col-md-3">
          <div class="kt-margin-b-20">
            <template v-if="profile.mfa_method === null">
              <a href="#" class="btn btn-primary" @click.prevent="mfaEmail(null)">{{ $t('mfa.select') }}</a>
            </template>
            <template v-else-if="profile.mfa_method === 'phone'">
              <a href="#" class="btn btn-primary" @click.prevent="fromPhoneToEmail">{{ $t('mfa.email_switch') }}</a>
            </template>
            <template v-else-if="profile.mfa_method === 'authenticator'">
              <a href="#" class="btn btn-primary" @click.prevent="fromAuthToEmail">{{ $t('mfa.email_switch') }}</a>
            </template>
            <template v-else-if="profile.mfa_method === 'email'">
              <a href="#" class="btn btn-secondary disabled"><i class="fas fa-check"></i> {{ $t('mfa.selected') }}</a>
            </template>
          </div>
        </div>
      </div>
      <div class="row">
        <div
          v-if="profile.mfa_enabled && profile.mfa_method === 'email'"
          class="col-md-8 col-lg-6"
        >
          <MfaSettingsForm />
        </div>
      </div>


      <!-- SMS MFA -->
      <template v-if="profile.phone_allowed">
        <div class="kt-separator kt-separator--space-md"></div>
        <div class="row">
          <div class="col-md-9">
            <h3>{{ $t('mfa.sms') }}</h3>
            <p v-html="$t('mfa.sms_description')"></p>
          </div>
          <div class="col-md-3">
            <div class="kt-margin-b-20">
              <template v-if="profile.mfa_method === null">
                <a href="#" class="btn btn-secondary disabled" @click.prevent="mfaPhone">{{ $t('mfa.select') }}</a>
              </template>
              <template v-else-if="profile.mfa_method === 'phone'">
                <a href="#" class="btn btn-secondary disabled"><i class="fas fa-check"></i> {{ $t('mfa.selected') }}</a>
              </template>
              <template v-else-if="profile.mfa_method === 'authenticator'">
                <a href="#" class="btn btn-secondary disabled" @click.prevent="fromAuthToPhone">{{ $t('mfa.sms_switch') }}</a>
              </template>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            v-if="profile.mfa_enabled && profile.mfa_method === 'phone'"
            class="col-md-6 col-lg-6"
          >
            <MfaSettingsForm />
          </div>
          <div
            class="col-md-6 col-lg-6">
            <PhoneVerificationForm />
          </div>
        </div>
      </template>
    </div>
    <MfaModal
      v-if="showAuthEnableMfa"
      :show="showAuthEnableMfa"
      action="authenticator_enable"
      :method="profile.mfa_method"
      @verified="showAuthenticatorForm"
      @hidden="showAuthEnableMfa = false"
    />
    <MfaModal
      v-if="showPhoneEnableMfa"
      :show="showPhoneEnableMfa"
      action="mfa_set_phone"
      :method="profile.mfa_method"
      @verified="mfaSetPhone"
      @hidden="showPhoneEnableMfa = false"
    />
    <MfaModal
      v-if="showEmailEnableMfa"
      :show="showEmailEnableMfa"
      action="mfa_set_email"
      :method="profile.mfa_method"
      @verified="mfaEmail"
      @hidden="showEmailEnableMfa = false"
    />
    <AuthEnableModal
      v-if="authModal"
      :show="authModal"
      :token="authToken"
      @verified="authEnabled"
      @hidden="authModal = false"
    />
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'
import Loader from '../global/Loader'
import AuthEnableModal from './AuthEnableModal'
import MfaSettingsForm from "./MfaSettingsForm"
import PhoneVerificationForm from './PhoneVerificationForm'

export default {
  name: 'MfaMethodForm',
  components: {
    Loader,
    AuthEnableModal,
    MfaSettingsForm,
    PhoneVerificationForm
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      showAuthMfa: false,
      showAuthEnableMfa: false,
      showEmailEnableMfa: false,
      authModal: false,
      error: null,
      showMfa: false,
      showPhoneEnableMfa: false,
      authToken: null
    }
  },
  computed: {
    hasError () {
      if (!this.error) {
        return false
      }
      return this.error.length > 0
    },
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
    this.phoneNumber = this.profile.phone
  },
  methods: {
    mfaPhone () {
      this.$store.dispatch('showErrorNotification', 'This feature is not available at the moment');
      return;

      if (this.profile.phone_verified) {
        if (this.profile.mfa_enabled) {
          this.showPhoneEnableMfa = true
        } else {
          this.mfaSetPhone()
        }
      } else {
        this.$store.dispatch('showErrorNotification', this.$t('mfa.phone_error'))
      }
    },
    showAuthenticatorForm (token = null) {
      this.showAuthEnableMfa = false
      this.$nextTick(() => {
        this.authToken = token
        this.authModal = true
      })
    },
    authEnabled () {
      this.authModal = false
      this.isLoading = true
      this.$store.dispatch('setAuthUser').then(response => {
        this.isLoading = false
      })
      this.$store.dispatch('showSuccessNotification', this.$t('mfa.app_success'))
    },
    fromAuthToPhone () {
      this.$store.dispatch('showErrorNotification', 'This feature is not available at the moment');
      return;

      if (this.profile.phone_verified) {
        this.showPhoneEnableMfa = true;
      }else{
        this.$store.dispatch('showErrorNotification', this.$t('mfa.phone_error'))
      }
    },
    fromPhoneToAuth () {
      this.showAuthEnableMfa = true
    },
    fromEmailToAuth () {
      this.showAuthEnableMfa = true
    },
    fromAuthToEmail () {
      this.showEmailEnableMfa = true
    },
    fromPhoneToEmail () {
      this.showEmailEnableMfa = true
    },
    mfaSetPhone (token) {
      axios.patch(this.$api.mfaPhone, { 'token': token }).then(response => {
        this.showPhoneEnableMfa = false
        this.isLoading = true
        this.$store.dispatch('setAuthUser').then(response => {
          this.isLoading = false
        })
        this.$store.dispatch('showSuccessNotification', this.$t('mfa.sms_switch_success'))
      }).catch(error => {
        this.showPhoneEnableMfa = false
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
      })
    },
    mfaEmail (token) {
      axios.patch(this.$api.mfaEmail, { 'token': token }).then(response => {
        this.showEmailEnableMfa = false
        this.isLoading = true
        this.$store.dispatch('setAuthUser').then(response => {
          this.isLoading = false
        })
        this.$store.dispatch('showSuccessNotification', this.$t('mfa.email_switch_success'))
      }).catch(error => {
        this.showPhoneEnableMfa = false
        this.$store.dispatch('showErrorNotification', this.$t('general.error'))
      })
    }

  }
}
</script>
