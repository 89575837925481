<template>
    <div
            v-if="profile"
            style="width: 100%"
    >
        <HeaderBar :profile_name="profile.first_name+' '+profile.last_name"/>

        <div class="kt-grid kt-grid--hor kt-grid--root">
            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper" style="padding-top: 0">

                    <div class="kt-body kt-grid__item kt-grid kt-grid--hor kt-grid--stretch" id="kt_body">
                        <div class="kt-container kt-grid kt-grid--ver">

                            <transition name="slide">
                              <Sidebar/>
                            </transition>

                            <div
                              v-if="sidebar"
                              class="kt-aside-overlay"
                              @click.prevent="closeSidebar"
                            />

                            <!-- end:: Aside -->
                            <div class="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
                                 id="kt_content">

                                <!-- begin:: Content -->
                                <div class="kt-container  kt-container--fluid  kt-grid__item kt-grid__item--fluid">
                                    <transition
                                            name="fade"
                                            mode="out-in"
                                    >
                                        <router-view :key="$route.fullPath" />
                                    </transition>
                                </div>
                            </div>
                        </div>
                    </div>

                    <FooterComponent/>
                    <Notification/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import HeaderBar from '../components/functional/HeaderBar'
  import Notification from '../components/global/Notification.vue'
  import Sidebar from '../components/functional/Sidebar'
  // import ProfileVerification from '../components/ProfileVerification';
  import FooterComponent from '../components/functional/FooterComponent'

  export default {
    name: 'Default',
    components: {
      HeaderBar,
      Notification,
      Sidebar,
      // ProfileVerification,
      FooterComponent
    },
    computed: {
      ...mapState({
        sidebar: state => state.sidebar.open
      }),
      profile: {
        get() {
          return this.$store.state.authUser.profile
        },
        set() {
        }
      },
      isReferralPage() {
        return this.$route.matched.some(m => m.path === '/referrals')
      }
    },
    methods: {
      ...mapActions([
        'closeSidebar'
      ])
    }
  }
</script>

<style>
  .slide-leave-active,
  .slide-enter-active {
    transition: 1s;
  }
  .slide-enter {
    transform: translate(100%, 0);
  }
  .slide-leave-to {
    transform: translate(-100%, 0);
  }
</style>
