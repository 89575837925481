<template>
  <div class="kt-widget24">
    <div class="kt-widget24__details">
      <div class="kt-widget24__info">
        <h4 class="kt-widget24__title">
          {{ title }}
        </h4>
      </div>
      <span
        class="kt-widget24__stats text-nowrap"
        :class="'kt-font-' + color + (type === 'masternoded' && nft_claimed ? ' text-crossed' : '')"
      >
        <template v-if="value !== null">
          {{ currency }}
          <span class="counter-value">{{ value }}</span>
        </template>
        <template v-else>
          <span class="counter-value"><i class="fas fa-angle-double-down"></i></span>
        </template>
      </span>
    </div>
    <template v-if="valueInBtc || valueInEur || valueInEth">
      <div class="kt-margin-t-10"></div>
    </template>
    <div v-if="valueInBtc" class="kt-widget24__action">
      <span class="kt-widget24__change">
        Bitcoin
      </span>
      <span
          class="kt-widget24__number text-nowrap"
          :class="'kt-font-' + color + (type === 'masternoded' && nft_claimed ? ' text-crossed' : '')"
      >
        {{ valueInBtc }}
      </span>
    </div>
    <div v-if="valueInEur" class="kt-widget24__action">
      <span class="kt-widget24__change">
        Euro
      </span>
      <span
          class="kt-widget24__number text-nowrap"
          :class="'kt-font-' + color + (type === 'masternoded' && nft_claimed ? ' text-crossed' : '')"
      >
        {{ valueInEur }}
      </span>
    </div>
    <div v-if="valueInEth" class="kt-widget24__action">
      <span class="kt-widget24__change">
        Ethereum
      </span>
      <span
          class="kt-widget24__number text-nowrap"
          :class="'kt-font-' + color + (type === 'masternoded' && nft_claimed ? ' text-crossed' : '')"
      >
        {{ valueInEth }}
      </span>
    </div>
    <div v-if="valueInSapp" class="kt-widget24__action">
      <span class="kt-widget24__change">
        SAPP
      </span>
      <span
          class="kt-widget24__number text-nowrap"
          :class="'kt-font-' + color + (type === 'masternoded' && nft_claimed ? ' text-crossed' : '')"
      >
        {{ valueInSapp }}
      </span>
    </div>
    <template v-if="nft_claimed">
      <div v-if="info" class="kt-margin-t-15 text-center nft_warning" v-html="info"></div>
    </template>
    <template v-else>
      <div v-if="info" class="kt-margin-t-10 kt-font-warning" v-html="info"></div>
    </template>
  </div>
</template>

<script>

export default {
  name: 'StatisticsBox',
  components: {
  },
  props: {
    icon: {
      type: String,
      default: 'invest',
      validator: function (value) {
        return ['invest', 'balance'].indexOf(value) !== -1
      }
    },
    title: {
      type: String,
      required: true
    },
    currency: {
      type: String,
      default: '€'
    },
    decimals: {
      type: Number,
      default: 2
    },
    value: {
      type: [String, Number],
      default: null,
      required: false
    },
    valueInBtc: {
      type: [String, Number],
      required: false
    },
    valueInEur: {
      type: [String, Number],
      required: false
    },
    valueInEth: {
      type: [String, Number],
      required: false
    },
    valueInSapp: {
      type: [String, Number],
      required: false
    },
    dotsTooltip: {
      type: String,
      default: null
    },
    hasWarning: {
      type: Boolean,
      default: false
    },
    infoText: {
      type: String,
      default: null
    },
    infoTooltip: {
      type: String,
      default: null
    },
    dontFormat: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'brand',
      validator: function (value) {
        return ['warning', 'brand', 'success', 'danger'].indexOf(value) !== -1
      }
    },
    info: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    nft_claimed: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    svgIcon () {
      return icons[this.icon]
    }
  }
}
</script>

<style lang="scss">
  .text-crossed {
    text-decoration: line-through;
  }
  .nft_warning {
    font-size: 1.05em;
    font-weight: 600;
    color: #d52020;
  }
</style>
