<template>
  <div>
    <div v-if="!profile.deposit_first_time" class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">Set Discord Username</h3>
        </div>
      </div>
      <DiscordForm />
    </div>

    <div v-if="!profile.deposit_first_time" class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">{{ $t('profile.contact_title2') }}</h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p>{{ $t('profile.contact_details') }}</p>
        <h4 class="mb-2"><b>{{ $t('profile.contact_status') }}</b>
          <span v-if="profile.inactive_period" class="kt-font-success"> {{ $t('profile.contact_enabled') }}</span>
          <span v-else class="kt-font-danger"> {{ $t('profile.contact_requires_setup') }}</span></h4>
        <div class="kt-align-center"><a href="#" @click.prevent="setEmergencyContact" class="btn btn-primary">
          <i class="far fa-life-ring"></i> <span v-if="profile.inactive_period">{{ $t('profile.contact_change') }}</span><span v-else>{{ $t('profile.contact_setup') }}</span> {{ $t('profile.contact') }}
        </a></div>
      </div>
    </div>
    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title" v-html="$t('profile.required_title')"></h3>
        </div>
      </div>
      <ProfileForm />
    </div>

    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="showEmergencyModal"
      @hidden="hideMfa"
    />

    <EmergencyContactModal
      v-if="emergencyModal"
      :show="emergencyModal"
      :token="token"
      @hidden="emergencyModal = false"
      @done="hideEmergencyModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProfileForm from '../components/Account/ProfileForm'
import EmergencyContactModal from '../components/Account/EmergencyContactModal'
import requiresMfa from '../mixins/requiresMfa'
import DiscordForm from "@/components/Account/DiscordForm";
import NFTForm from "@/components/Account/NFTForm.vue";

export default {
  name: 'ProfilePage',
  components: {
    NFTForm,
    ProfileForm,
    DiscordForm,
    EmergencyContactModal,
  },
  mixins: [
    requiresMfa,
  ],
  data () {
    return {
      showMfa: false,
      emergencyModal: false,
      isLoading: false,
      action: 'emergency_contact',
      token: null
    }
  },

  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
    /*if (!this.profile.logged_in && this.profile.phone.length > 0) {
      axios.patch(this.$api.login).then((response)=>{}).catch((response)=>{})
    }*/
  },
  methods: {
    setEmergencyContact () {
      if(this.profile.mfa_method === null){
        this.showEmergencyModal();
      }else {
        this.verifyMfa(this.action);
      }
    },
    showEmergencyModal (token = null) {
      this.hideMfa();
      this.$nextTick(() => {
        this.token = token
        this.emergencyModal = true
      })
    },
    verifyMfa (action) {
      this.action = action
      this.showMfa = true
    },
    hideMfa () {
      this.showMfa = false
    },
    hideEmergencyModal () {
      this.emergencyModal = false
      this.update();
    },
    update () {
      this.$store.dispatch('setAuthUser')
    }
  }
}
</script>
