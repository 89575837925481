<template>
  <Loader :loading="isLoading">
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover" id="sessions_table">
        <thead>
          <tr>
            <th nowrap>Device Name</th>
            <th nowrap>Last Login</th>
            <th nowrap>Expires At</th>
            <th nowrap></th>
          </tr>
        </thead>
        <tbody v-if="sessions.length">
          <tr v-for="session in sessions">
            <td nowrap>{{ session.name }}</td>
            <td nowrap><abbr v-b-tooltip.hover :title="session.last_used ? session.last_used.full : ''">{{ session.last_used ? session.last_used.human : '' }}</abbr></td>
            <td nowrap><abbr v-b-tooltip.hover :title="session.expires ? session.expires.full : ''">{{ session.expires ? session.expires.human : '' }}</abbr></td>
            <td nowrap><a href="#" @click.prevent="revoke_token(session.id)">Revoke</a></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="4" class="text-center">No Sessions Found</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
    ></b-pagination>
  </Loader>
</template>

<script>
import Loader from "../global/Loader";
export default {
  name: 'AppSessions',
  components: {
    Loader
  },
  data () {
    return {
      sessions: [],
      currentPage: 1,
      totalRows: null,
      perPage: null,
      isLoading: false
    }
  },
  created () {
    this.getSessions()
  },
  watch: {
    currentPage: function(){
      this.getSessions();
    }
  },
  methods: {
    getSessions () {
      this.isLoading = true;
      axios.get(this.$api.sessions + '?page=' + this.currentPage).then(response => {
        this.isLoading = false;
        this.sessions = response.data.data
        this.perPage = response.data.meta.per_page;
        this.totalRows = response.data.meta.total;
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.isLoading = true;
      })
    },
    revoke_token(id) {
      this.isLoading = true;
      axios.post(this.$api.sessions + '/revoke',{ id: id }).then(response => {
        this.isLoading = false;
        this.getSessions();
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.isLoading = true;
      })

    }
  }
}
</script>
