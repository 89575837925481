<template>
  <div>

    <EmergencyWithdrawalWarning
      v-if="profile.has_emergency_withdrawal"
      :amount="profile.emergency_withdrawal_amount"
      :wallet="profile.emergency_withdrawal_wallet"
    />

    <div class="kt-portlet">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">(BETA) YieldNodes Deposit Bonus Packages (+20%)</h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <p><b>How it works:</b> You can buy balances from people leaving via Emergency Withdrawal. For whatever personal reason people regularly request Emergency Withdrawals and take an account loss of 25%.<br>
          You can now "Purchase" their Withdrawal and instantly gain a <b>20% free bonus profit</b>. Remaining 5% Are flowing back into the YieldNodes Masternode Pool for the benefit of everyone (NOT a fee pocketed by Yieldnodes!)</p>

        <p>We see this as a win for everyone. People who want to leave, can leave the program as usual. People who know us and are commited to Yieldnodes long term can gain a hefty <b>20% Bonus</b>. And 5% go to strengthen the program overall.<br>
          We will carefully monitor the program and make necessary adjustments over time.</p>

        <p>What happens if Emergency Withdrawals are not bought?<br>
          - We will credit that as it has always been and pay members out, no change :)</p>



      </div>
    </div>

    <template v-if="true">

      <div class="row">
        <div class="col-lg-8">

          <div class="kt-portlet" v-if="!profile.has_pending_deposit_bonus">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Available Deposit Bonuses</h3>
              </div>
            </div>
            <div class="kt-portlet__body">
              <Loader :loading="isLoading">
                <div>
                  <em>Click to select deposit bonus</em>
                </div>
                <div class="table-responsive">
                  <table class="table table-striped table-bordered table-hover" id="deposit_buy_table">
                    <thead>
                    <tr>
                      <th nowrap="">Deposit Amount</th>
                      <th nowrap="">Price in Crypto</th>
                      <th nowrap="">Expires at</th>
                    </tr>
                    </thead>
                    <tbody v-if="emergency_withdraws.length > 0">
                    <template v-for="emergency_withdraw in emergency_withdraws">
                      <tr style="cursor: pointer;" @click="select_id(emergency_withdraw.id)">
                        <td nowrap="">
                          {{ emergency_withdraw.amount_bonus_display }}
                        </td>
                        <td>
                          <b>{{ emergency_withdraw.bonus_price_btc_display }}</b> or <b>{{ emergency_withdraw.bonus_price_trx_display }}</b> or <b>{{ emergency_withdraw.bonus_price_trx_usdc_display }}</b>
                        </td>
                        <td nowrap="">
                          <abbr class="" v-b-tooltip.hover :title="emergency_withdraw.expires_at.display_human">{{ emergency_withdraw.expires_at.display }}</abbr>
                        </td>
                      </tr>
                    </template>
                    </tbody>
                    <tbody v-else>
                    <tr>
                      <td v-if="emergency_withdraws_delay" colspan="4" class="text-center">Important: You can only buy (or request) one emergency withdrawal every <b>48 hours</b>.</td>
                      <td v-else colspan="4" class="text-center">No emergency withdrawals available</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
                <!--<b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                ></b-pagination>-->
              </Loader>

            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="kt-portlet">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">Subscribe to Updates</h3>
              </div>
            </div>
            <div class="kt-portlet__body">
              <SubscribeForm />
            </div>
          </div>
        </div>
      </div>
      <!--<div class="kt-portlet" v-else>-->
      <div v-if="profile.has_pending_deposit_bonus" class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">Your Pending Deposit Bonus Request</h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <div v-if="pending_deposit_bonus_disabled">
            <p>Request has expired...</p>
            <div v-if="profile.has_pending_deposit_bonus && profile.pending_deposit_bonus && profile.pending_deposit_bonus.id"><a href="#" @click.prevent="cancel_deposit_bonus(profile.pending_deposit_bonus.id)" class="btn btn-info">Find new available deposit bonus</a></div>
          </div>
          <div v-else>
            <Loader :loading="isLoading">
              <div class="row">
                <div class="col-md-6">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">
                      <div>
                        Deposit size:
                        <div class="float-right">{{ profile.pending_deposit_bonus.amount_bonus_display }}</div>
                      </div>
                    </li>
                    <li class="list-group-item">
                      <div class="kt-font-bold">
                        Send in BTC:
                        <div class="float-right">{{ profile.pending_deposit_bonus.amount_btc_display }} <i style="cursor:pointer" id="amount_copy" @click="copyWalletAmount" class="fas fa-copy"></i></div>
                      </div>
                      <div class="kt-margin-b-20 kt-margin-t-20">
                        <div v-if="!profile.deposit_wallet" class="text-center">
                          <Loader :loading="isLoading2" loader-style="overflow: hidden">
                            <button
                              class="btn btn-brand"
                              type="button"
                              @click.prevent="getWallet"
                            >
                              {{ $t('deposit.btc_generate_wallet') }}
                            </button>
                          </Loader>
                        </div>
                        <div v-if="profile.deposit_wallet" class="kt-margin-b-20 kt-margin-t-20">
                          <Loader :loading="isLoading2" loader-style="overflow: hidden">
                            <div class="input-group kt-margin-b-20">
                              <div class="input-group-prepend">
                                <span class="input-group-text kt-font-bold">BTC</span>
                              </div>
                              <input type="text" class="form-control" id="address" :value="profile.deposit_wallet" readonly="">
                              <div class="input-group-append">
                                <button
                                  id="wallet_copy"
                                  type="button"
                                  class="btn btn-secondary"
                                  @click="copyWalletAddress"
                                ><i class="far fa-copy"></i></button>
                                <button
                                  id="copyQR"
                                  v-if="profile.deposit_wallet_qr"
                                  type="button"
                                  class="btn btn-primary"
                                  @click="showQRcodemodal"><i class="fas fa-qrcode"></i> QR</button>
                              </div>
                            </div>
                            <div v-if="profile.deposit_wallet_expires" class="alert alert-solid-warning alert-bold" role="alert">
                              <div class="alert-text text-left"><i class="fas fa-exclamation-triangle"></i> {{ $t('deposit.btc_expires', { date: formatDate(profile.deposit_wallet_expires) }) }}</div>
                            </div>
                          </Loader>
                        </div>
                      </div>
                      <div class="text-center">or</div>
                    </li>
                    <li class="list-group-item">
                      <div class="kt-font-bold">
                        Send in USDT (trc-20):
                        <div class="float-right">{{ this.profile.pending_deposit_bonus.amount_trx_display }} <i id="amount_trx_copy" style="cursor:pointer" @click="copyTrxWalletAmount" class="fas fa-copy"></i></div>
                      </div>
                      <template v-if="!profile.deposit_wallet_trx">
                        <div class="kt-margin-b-10 kt-margin-l-10">
                          <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_details') }}
                        </div>

                        <div class="text-center kt-margin-b-20">
                          <Loader :loading="isLoading2" loader-style="overflow: hidden">
                            <button
                              class="btn btn-brand"
                              type="button"
                              @click.prevent="getWalletTrx"
                            >
                              {{ $t('deposit.trx_generate_wallet') }}
                            </button>
                          </Loader>
                        </div>
                      </template>

                      <div v-if="profile.deposit_wallet_trx">
                        <Loader :loading="isLoading2" loader-style="overflow: hidden">
                          <div class="kt-margin-t-20 kt-margin-b-10 kt-margin-l-10">
                            <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_address') }}
                          </div>
                          <div class="input-group kt-margin-t-10 kt-margin-b-20">
                            <div class="input-group-prepend">
                              <span class="input-group-text kt-font-bold">TRC-20</span>
                            </div>
                            <input type="text" class="form-control" id="address_trx" :value="profile.deposit_wallet_trx" readonly="">
                            <div class="input-group-append">
                              <button
                                id="wallet_trx_copy"
                                type="button"
                                class="btn btn-secondary"
                                @click="copyTrxWalletAddress"
                              ><i class="far fa-copy"></i></button>
                            </div>
                          </div>
                        </Loader>
                      </div>
                      <div class="text-center">or</div>
                    </li>
                    <li class="list-group-item">
                      <div class="kt-font-bold">
                        Send in USDC (trc-20):
                        <div class="float-right">{{ this.profile.pending_deposit_bonus.amount_trx_usdc_display }} <i id="amount_trx_usdc_copy" style="cursor:pointer" @click="copyTrxUsdcWalletAmount" class="fas fa-copy"></i></div>
                      </div>
                      <template v-if="!profile.deposit_wallet_trx">
                        <div class="kt-margin-b-10 kt-margin-l-10">
                          <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_details') }}
                        </div>

                        <div class="text-center kt-margin-b-20">
                          <Loader :loading="isLoading2" loader-style="overflow: hidden">
                            <button
                              class="btn btn-brand"
                              type="button"
                              @click.prevent="getWalletTrx"
                            >
                              {{ $t('deposit.trx_generate_wallet') }}
                            </button>
                          </Loader>
                        </div>
                      </template>

                      <div v-if="profile.deposit_wallet_trx">
                        <Loader :loading="isLoading2" loader-style="overflow: hidden">
                          <div class="kt-margin-t-20 kt-margin-b-10 kt-margin-l-10">
                            <i class="fas fa-arrow-alt-circle-right"></i> {{ $t('deposit.trx_usdc_address') }}
                          </div>
                          <div class="input-group kt-margin-t-10 kt-margin-b-20">
                            <div class="input-group-prepend">
                              <span class="input-group-text kt-font-bold">TRC-20</span>
                            </div>
                            <input type="text" class="form-control" id="address_trx" :value="profile.deposit_wallet_trx" readonly="">
                            <div class="input-group-append">
                              <button
                                id="wallet_trx_copy"
                                type="button"
                                class="btn btn-secondary"
                                @click="copyTrxWalletAddress"
                              ><i class="far fa-copy"></i></button>
                            </div>
                          </div>
                        </Loader>
                      </div>
                    </li>
                  </ul>
                  <div v-if="profile.pending_deposit_bonus && profile.pending_deposit_bonus.id"><a href="#" @click.prevent="cancel_deposit_bonus(profile.pending_deposit_bonus.id)">Cancel this pending deposit bonus</a></div>
                </div>
                <div class="col-md-6">
                  <p class="kt-font-bold">Deposit Bonus Expires in:</p>
                  <div v-if="timer_active" class="lead kt-margin-b-20">
                    <div class="alert alert-warning">
                      <span v-if="hours > 0" class="kt-font-bold kt-margin-r-10">{{ hours }} {{ hours > 1 ? 'hours' : 'hour' }} </span><span v-if="minutes > 0" class="kt-font-bold  kt-margin-r-10">{{ minutes }} {{ minutes > 1 ? 'minutes' : 'minute' }}</span><span v-if="seconds > 0" class="kt-font-bold">{{ seconds }} {{ seconds > 1 ? 'seconds' : 'second' }}</span>
                    </div>
                    <div class="kt-margin-t-10">at {{ expires_at_local }} your time.</div>
                  </div>
                  <p v-else class="lead">Expired!</p>
                  <p>If your transaction is not sent and confirmed on the blockchain before that timer runs out - you will lose your chance to get deposit bonus.</p>
                  <p>You are allowed to send deposit bonus requests only once every 48 hours, even if you cancelled previous request!</p>
                </div>
              </div>
            </Loader>
          </div>
        </div>
      </div>

      <div class="alert alert-warning">
        <div class="alert-text">
          <p><b>Important:</b> The requested Sum needs to be sent in ONE transaction and on time. If this does not happen the Bonus will not be applied and it's seen as a regular deposit. (Regular deposit without Bonus!)</p>

          <p>We can NOT issue refunds on this nor reverse that action because it would encourage bonus deposit hunting and require a lot of manpower on top. Please understand that you incur a certain degree of risk here.</p>

          <p><b>Strictly prohibited:</b> using browser bots or automation to refresh the page or obtain the bonus, reserving bonus request for someone else, selling bonus requests, sending multiple requests in a row and not completing them. These or other kinds of abuse will be detected and could lead to bonus/account termination and penalties.</p>
        </div>
      </div>


      <QRModal
        v-if="showQRmodal && profile.deposit_wallet && profile.deposit_wallet_qr"
        :show="showQRmodal"
        @hidden="showQRmodal = false"
      />

      <DepositBonusModal
        v-if="showDepositBonusModal"
        :id="selected_id"
        :show="showDepositBonusModal"
        @submit="request_deposit_bonus"
        @hidden="cancel"
      />
    </template>
    <template v-else>
      <div class="alert alert-danger" role="alert">
        <div class="alert-icon"><i class="fas fa-ban"></i></div>
        <div class="alert-text">You will be able to use this feature as soon as you do your first deposit in the system.</div>
      </div>
    </template>
  </div>
</template>

<script>
import axios from 'axios';
import { mapState } from 'vuex'
import EmergencyWithdrawalWarning from '@/components/functional/EmergencyWithdrawalWarning';
import Loader from "@/components/global/Loader";
import copyTextToClipboard from "@/helpers/clipboard";
import moment from "moment";
import QRModal from "@/components/Deposit/QRModal";
import DepositBonusModal from "@/components/DepositBonus/DepositBonusModal";
import PendingBonusDepositReminder from "@/components/functional/PendingBonusDepositReminder";
import SubscribeForm from "@/components/DepositBonus/SubscribeForm";

export default {
  name: 'DepositBonusPage',
  components: {
    EmergencyWithdrawalWarning,
    Loader,
    QRModal,
    DepositBonusModal,
    PendingBonusDepositReminder,
    SubscribeForm
  },
  data () {
    return {
      isLoading: false,
      isLoading2: false,
      currentPage: 1,
      totalRows: null,
      perPage: null,
      showQRmodal: false,
      emergency_withdraws: [],
      emergency_withdraws_delay: false,
      hours: 0,
      minutes: 0,
      seconds: 0,
      timer_active: false,
      timer_expires: null,
      showDepositBonusModal: false,
      selected_id: null,
      timeout_link: null,
      interval_id: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile,
    }),
    pending_deposit_bonus_disabled(){
      if(this.profile.has_pending_deposit_bonus){
        let expires_at = moment.unix(this.profile.pending_deposit_bonus.expires_at_timestamp);
        if(expires_at.isAfter()){
          return false;
        }
      }
      return true;
    },
    expires_at_local(){
      if(this.profile.pending_deposit_bonus.expires_at_timestamp){
        return moment.unix(this.profile.pending_deposit_bonus.expires_at_timestamp).format('D.MM.YYYY H:mm z');
      }
    }
  },
  created () {
    if(this.profile){
      let external_id = this.profile.id;
      /*OneSignal.push(function() {
        OneSignal.setExternalUserId(external_id);
      });*/
    }

    if(!this.profile.deposit_buy_allowed){
      this.$router.push({name: 'dashboard'});
      return;
    }

    if(!this.profile.has_pending_deposit_bonus){
      this.getDepositBonuses();
    }else{
      this.launchTimer();
    }
  },
  watch: {
    /*currentPage: function(){
      this.getDepositBonuses();
    }*/
  },
  methods: {
    getDepositBonuses () {
      this.isLoading = true
      this.emergency_withdraws_delay = false;
      //axios.get(this.$api.depositBonus + '/list?page=' + this.currentPage).then(response => {
      axios.get(this.$api.depositBonus + '/list').then(response => {
        this.emergency_withdraws = response.data.data
        //this.perPage = response.data.meta.per_page;
        //this.totalRows = response.data.meta.total;
        this.isLoading = false
      }).catch(error => {
        this.emergency_withdraws = [];
        //this.totalRows = null;
        if(error.response && error.response.status === 403) {
          this.emergency_withdraws_delay = true;
        }else if(error.response && error.response.status === 429) {
          this.$store.dispatch('showErrorNotification', 'Failed to load the list. You are refreshing the page too often!')
        }else{
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
        this.isLoading = false;
      })
    },
    launchTimer () {
      if(this.profile.has_pending_deposit_bonus){
        this.timer_expires = moment.unix(this.profile.pending_deposit_bonus.expires_at_timestamp);
        if(this.timer_expires.isAfter() && !this.timer_active){
          this.timer_active = true;
          this.calc_hms();

          this.refresh_profile();

          clearInterval(this.interval_id);
          let t = this;
          this.interval_id = setInterval(function() {
            t.calc_hms();
          },1000);
        }
      }
    },
    stopTimer() {
      //this.currentPage = 1;
      this.getDepositBonuses();
      clearInterval(this.interval_id);
      this.timer_active = false;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.selected_id = null;
    },
    refresh_profile() {
      if(this.$router.currentRoute.name === 'deposit_buy') {
        this.$store.dispatch('setAuthUser').then(() => {
          if (!this.profile.pending_deposit_bonus_disabled) {
            this.timeout_link = setTimeout(this.refresh_profile, 120000);
          }else{
            this.stopTimer();
          }
        });
      }
    },
    calc_hms() {
      let timestamp_now = moment().unix();
      let expires_at = this.profile.has_pending_deposit_bonus ? this.profile.pending_deposit_bonus.expires_at_timestamp : 0;
      let diff = expires_at - timestamp_now;
      if(diff <= 0){
        this.stopTimer();
      }
      if(Math.floor(diff/3600) >= 1){
        this.hours = Math.floor(diff/3600);
        diff -= this.hours * 3600;
      }
      if(Math.floor(diff/60) >= 1){
        this.minutes = Math.floor(diff/60);
        diff -= this.minutes * 60;
      }
      this.seconds = diff;
    },
    select_id(id) {
      this.selected_id = id;
      this.showDepositBonusModal = true;
    },
    cancel() {
      this.showDepositBonusModal = false;
      this.stopTimer();
    },
    request_deposit_bonus(id) {
      this.selected_id = null;
      this.isLoading = true
      axios.post(this.$api.depositBonus + '/request', { id: id }).then(response => {
        this.$store.dispatch('setAuthUser').then(() => {
          this.isLoading = false;
          this.launchTimer();
        });
        this.$store.dispatch('showSuccessNotification', 'Deposit bonus request has been sent! Send your deposit as soon as possible!')
      }).catch(error => {
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }else{
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
        this.getDepositBonuses();
      })
    },
    cancel_deposit_bonus(id) {
      this.isLoading = true;
      axios.post(this.$api.depositBonus + '/cancel', { id: id }).then(response => {
        this.$store.dispatch('setAuthUser').then(() => {
          this.stopTimer();
          this.isLoading = false;
        });
        this.$store.dispatch('showSuccessNotification', 'Deposit bonus request has been cancelled')
      }).catch(error => {
        this.getDepositBonuses();
        this.$store.dispatch('setAuthUser').then(() => {
          this.isLoading = false;
        });
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
      })
    },
    showQRcodemodal() {
      this.showQRmodal = true;
    },
    copyWalletAddress () {
      copyTextToClipboard(this.profile.deposit_wallet, 'wallet_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    copyWalletAmount () {
      copyTextToClipboard(this.profile.pending_deposit_bonus.amount_btc, 'amount_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.amount_copied'))
      }).catch(error => {

      })
    },
    copyTrxWalletAddress () {
      copyTextToClipboard(this.profile.deposit_wallet_trx, 'wallet_trx_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    copyTrxWalletAmount () {
      copyTextToClipboard(this.profile.pending_deposit_bonus.amount_trx, 'amount_trx_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.amount_copied'))
      }).catch(error => {

      })
    },
    copyTrxUsdcWalletAmount () {
      copyTextToClipboard(this.profile.pending_deposit_bonus.amount_trx_usdc, 'amount_trx_usdc_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.amount_copied'))
      }).catch(error => {

      })
    },
    formatDate (date) {
      return moment.utc(date).format('LLL')
    },
    getWallet () {
      this.isLoading2 = true
      axios.get(this.$api.depositWallet).then(response => {
        this.$store.dispatch('setAuthUser').then(() => {
          this.isLoading2 = false;
        });
        this.isLoading2 = false
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('deposit.address_error'));
        this.isLoading2 = false
      })
    },
    getWalletTrx () {
      this.isLoading2 = true
      axios.get(this.$api.depositWallet + '/trx').then(response => {
        this.$store.dispatch('setAuthUser').then(() => {
          this.isLoading2 = false;
        });
        this.isLoading2 = false
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('deposit.address_error'));
        this.isLoading2 = false
      })
    },

  }
}
</script>
