<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('mfa.app_setup_title')"
      @hidden="hideModal"
      no-close-on-backdrop
      no-close-on-esc
      header-close-content=""
    >
      <Loader :loading="isLoading">
        <p v-html="$t('mfa.app_setup_details')"></p>
        <p>{{ $t('mfa.app_setup_qr') }}</p>
        <div v-if="authenticator.qr" class="kt-margin-b-10 text-center">
          <img
            :src="authenticator.qr"
            alt="qr-code"
          >
        </div>
        <div v-if="authenticator.secret" class="text-center kt-margin-b-20">
          <span class="kt-bg-light">{{ $t('mfa.app_setup_backup') }} <b>{{ authenticator.secret }}</b></span>
        </div>

        <div
          v-if="errors.code"
          class="alert alert-danger"
        >
          {{ errors.code[0] }}
        </div>
        <b-form @submit.prevent="verify" class="kt-form form-inline d-flex justify-content-center">
          <div class="input-group">
            <MaskedInput
              ref="codeInput"
              id="code"
              v-model="code"
              type="text"
              class="form-control text-center mb-sm"
              placeholder="123 456"
              :mask="[/\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/]"
              :guide="false"
              placeholder-char="_"
              @keydown.delete="inputKeydown"
            />
          </div>
          <button class="btn btn-brand kt-margin-l-5">{{ $t('general.setup') }}</button>
        </b-form>
      </Loader>
      <div slot="modal-footer" />
    </b-modal>
  </Portal>
</template>

<script>
import * as Sentry from '@sentry/browser';
import axios from 'axios';
import { mapState } from 'vuex'
import { getNumeric } from '../../helpers/strings'
import Loader from 'js/components/global/Loader'

export default {
  name: 'AuthEnableModal',
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: true,
      code: null,
      errors: {},
      authenticator: {},
      modalOpen: this.show
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  watch: {
    code (newVal) {
      if (newVal && getNumeric(newVal).length === 6) {
        this.verify()
      }
    }
  },
  created () {
    axios.get(this.$api.appAuthenticatorEnable).then((response) => {
      this.authenticator = response.data.data
      this.isLoading = false
    }).catch((error) => {
      Sentry.captureException(error);
      this.isLoading = false
      this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'));
    })
  },

  mounted () {
    this.$nextTick(() => {
      if (this.$refs.codeInput && this.$refs.codeInput.$refs.input) {
        this.$refs.codeInput.$refs.input.focus()
      }
    })
  },
  methods: {
    verify () {
      this.isLoading = true
      this.errors = {}
      axios.post(this.$api.appAuthenticatorEnable, {
        code: getNumeric(this.code),
        token: this.token ? this.token : null
      }).then((response) => {
        this.$store.dispatch('setAuthUser').then((response) => {
          this.isLoading = false
          this.$emit('verified')
        })
      }).catch((error) => {
        if (error.response && error.response.data.errors) {
          this.errors = error.response.data.errors
        }
        if (error.response.status === 403) {
          Sentry.captureException(error);
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'));
        }
        this.isLoading = false
      })
    },
    hideModal () {
      this.$emit('hidden')
    },
    inputKeydown (event) {
      const value = event.target.value;
      const char = value[value.length - 1];
      if (isNaN(parseInt(char)) || char === ' ') {
        event.target.value = value.substring(0, value.length - 1)
      }
    }
  }
}
</script>
