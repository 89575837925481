<template>
  <div>
    <Loader :loading="isLoading">
      <p>{{ $t('affiliate.withdraw_title') }}</p>

      <div class="alert alert-secondary" role="alert">
        <div class="alert-text">{{ $t('affiliate.withdrawable') }} <b>{{ creditsDisplay }}</b></div>
      </div>

      <form
        @submit.prevent="withdraw"
      >
        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text text-bold">
            <template v-if="profile.currency === 'BTC'">
              <i class="fab fa-bitcoin"></i>
            </template>
            <template v-else>
              <i class="fas fa-euro-sign"></i>
            </template>
            </span>
          </div>
          <MaskedInput
            v-model="amount"
            type="text"
            class="form-control"
            :class="{'is-invalid': errors.length}"
            :placeholder="$t('affiliate.enter_amount')"
            :mask="mask"
            :guide="false"
            :disabled="profile.limited_mode"
          />
          <div class="input-group-append">
            <span class="input-group-text kt-font-bold">
              <a href="#" @click.prevent="enterMaxWithdraw()">MAX</a>
            </span>
          </div>

          <div class="input-group-append">
            <button type="submit" class="btn btn-primary sweet_alert" :disabled="profile.limited_mode">{{ $t('affiliate.move') }}</button>
          </div>
          <div
            v-if="errors.length"
            class="invalid-feedback"
          >
            {{ errors[0] }}
          </div>
        </div>
      </form>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { eurMask, btcMask } from 'js/helpers/inputMasks'
import Loader from 'js/components/global/Loader'

export default {
  name: 'CreditsWithdrawForm',
  components: {Loader},
  props: {
    credits: {
      type: Number,
      default: 0
    },
    creditsDisplay: {
      type: String,
      default: ''
    },
    minWidthrawal: {
      type: Number,
      default: 0
    },
    minWidthrawalDisplay: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      amount: null,
      errors: [],
      isLoading: false
    }
  },
  computed: {
    mask () {
      if (this.profile && this.profile.account_selected) {
        if (this.profile.account_selected === 'EUR') {
          return eurMask
        } else if (this.profile.account_selected === 'BTC') {
          return btcMask
        }
      }
      return null
    },
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  methods: {
    showError (error) {
      this.errors.push(error)
    },
    withdraw () {
      this.errors = []
      let amount = parseFloat(this.amount)
      if (!amount || amount <= 0) {
        this.showError(this.$t('verify.amount_incorrect'))
        return false
      }
      if (this.credits < amount) {
        this.showError(this.$t('affiliate.amount_large'))
        return false
      }
      if (this.minWidthrawal > amount) {
        this.showError(this.$t('affiliate.amount_small',{ amount: this.minWidthrawalDisplay }))
        return false
      }
      this.isLoading = true
      axios.post(this.$api.affiliate + '/withdraw', { amount: this.amount }).then(response => {
        this.amount = null
        this.isLoading = false
        this.$emit('done');
        this.$store.dispatch('showSuccessNotification', this.$t('affiliate.withdraw_success'))
      }).catch(error => {
        if (error.response && error.response.data && error.response.data.errors) {
          this.errors = error.response.data.errors
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
        this.isLoading = false
      })
    },
    enterMaxWithdraw() {
      this.amount = this.credits.toString();
    }
  }
}
</script>
