<template>
  <div
    class="alert alert-success"
    role="alert"
  >
    <div class="alert-icon d-none d-sm-block"><i class="fas fa-chart-line"></i></div>
    <div class="alert-text">
      <div class="row align-items-center">
        <div class="col-md-8" v-html="$t('dashboard.new_user_alert')"></div>
        <div class="col-md-4 text-right">
          <router-link
            :to="{ name: 'performance'}"
            class="btn btn-dark btn-sm"
          >{{ $t('dashboard.new_user_alert_button') }}</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NewUserAlert',
  components: {
  },
  props: {
  },
  computed: {

  }
}
</script>
