<template>
  <div>
      <Loader :loading="isLoading" loader-style="overflow: hidden">

        <div
          v-if="!profile.account_verified"
          class="alert alert-danger"
          role="alert"
        >
          <div class="alert-text">
            {{ $t('withdraw.account_not_verified') }}
          </div>
        </div>

        <p>{{ $t('withdraw.transfer_details') }}</p>

        <div class="alert alert-secondary" role="alert">
          <div class="alert-text">{{ $t('withdraw.transfer_amount') }} <b>{{ released.balance_display }}</b></div>
        </div>

        <div class="input-group">
          <div class="input-group-prepend">
            <span class="input-group-text text-bold">
                <template v-if="profile.currency === 'BTC'">
                  <i class="fab fa-bitcoin"></i>
                </template>
                <template v-else>
                  <i class="fas fa-euro-sign"></i>
                </template>
            </span>
          </div>

          <MaskedInput
            v-model="values.amount"
            type="text"
            class="form-control"
            placeholder="0.00"
            :mask="mask"
            :guide="false"
            :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
          />
          <!--<div class="input-group-append">
            <span class="input-group-text kt-font-bold">
              <a href="#" @click.prevent="enterMaxTransfer()">MAX</a>
            </span>
          </div>-->

          <div class="input-group-append">
            <button
              disabled="disabled"
              @click.prevent="transferCheck" class="btn btn-primary sweet_alert" :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified" type="button"><span class="d-none d-sm-inline">{{ $t('withdraw.transfer_button') }}</span><span class="d-inline d-sm-none"><i class="fas fa-forward"></i></span></button>
          </div>

        </div>

        <div
          v-if="hasErrors && errors['amount']"
          class="invalid-feedback"
          style="display: block;"
        >
          {{ errors['amount'][0] }}
        </div>
      </Loader>
    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="transferModal"
      @hidden="hideMfa"
    />
    <TransferModal
      v-if="showTransfer"
      :amount="values.amount"
      :show="showTransfer"
      @submit="transfer"
      @hidden="showTransfer = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'
import { btcMask, eurMask } from '../../helpers/inputMasks'
import TransferModal from './TransferModal'

export default {
  name: 'TransferForm',
  components: {
    Loader,
    TransferModal
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data () {
    return {
      released: {},
      values: {
        amount: null
      },
      isLoading: false,
      showMfa: false,
      showTransfer: false,
      action: 'withdraw_decrease',
      token: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    mask () {
      if (this.profile && this.profile.account_selected) {
        if (this.profile.account_selected === 'EUR') {
          return eurMask
        } else if (this.profile.account_selected === 'BTC') {
          return btcMask
        }
      }
      return null
    }
  },
  created () {
    this.getReleasedBalance()
  },
  methods: {
    getReleasedBalance () {
      axios.get(this.$api.depositReleasedBalance).then(response => {
        this.released = response.data.data
      })
    },
    transferCheck () {
      this.clearErrors()
      let amount = parseFloat(this.values.amount)
      if (!amount || amount <= 0) {
        this.addError('amount', this.$t('withdraw.transfer_verify_amount'))
        return false
      }
      if (this.released.balance < amount) {
        this.addError('amount', this.$t('withdraw.transfer_verify_amount_high'))
        return false
      }
      if (this.released.balance < this.profile.withdrawal_minimum) {
        this.addError('amount', this.$t('withdraw.transfer_verify_amount_min') + this.profile.withdrawal_minimum_display)
        return false
      }
      if (this.profile.mfa_enabled) {
        this.showMfa = true
      } else {
        this.transferModal()
      }
    },
    transferModal (token = null) {
      this.showMfa = false
      this.$nextTick(() => {
        this.token = token
        this.showTransfer = true
      })
    },
    transfer (values) {
      this.clearErrors()
      this.isLoading = true
      this.showTransfer = false
      axios.post(this.$api.depositWithdraw, {
        amount: parseFloat(this.values.amount),
        token: this.token,
        ...values
      }).then(response => {
        this.values.amount = null
        this.$store.dispatch('showSuccessNotification', this.$t('withdraw.transfer_success'))
        this.$emit('updated')
        this.getReleasedBalance()
        this.isLoading = false
        this.token = null
      }).catch(error => {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        } else {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }
        this.isLoading = false
      })
    },
    enterMaxTransfer() {
      this.values.amount = this.released.balance.toString();
    }
  }
}
</script>
