<template>
  <div>
    <Loader
      :loading="isLoading"
      loader-style="overflow: hidden;"
    >
      <div
        v-if="!profile.account_verified"
        class="alert alert-danger"
        role="alert"
      >
        <div class="alert-text">
          {{ $t('withdraw.account_not_verified') }}
        </div>
      </div>


      <b-tabs nav-class="nav-tabs-line nav-tabs-line-3x nav-tabs-line-brand" v-model="current_tab_index" @input="reset_forms">
        <b-tab
            :title="$t('withdraw.btc')"
            active
        >
          <div class="kt-margin-b-15 text-center">
            <a href="#" @click.prevent="showWalletsModal" class="btn btn-primary" :class="profile.limited_mode ? 'disabled' : ''">
              <i class="fas fa-wallet"></i> <span class="d-none d-sm-inline">{{ $t('withdraw.manage_wallets') }}</span><span class="d-inline d-sm-none">{{ $t('withdraw.manage_wallets_xs') }}</span>
            </a>
          </div>

          <p>{{ $t('withdraw.enter_amount', { currency: profile.currency }) }}</p>

          <div class="kt-margin-b-10">
            <b-form-select v-model="values.wallet" :options="walletsApproved" :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified || !walletsApproved">
              <template #first>
                <b-form-select-option :value="null" disabled v-text="!walletsApproved?$t('withdraw.select_placeholder_empty'):$t('withdraw.select_placeholder',{ currency: current_currency })"></b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div
            v-if="hasErrors && errors['wallet']"
            class="invalid-feedback"
            style="display: block;"
          >
            {{ errors['wallet'][0] }}
          </div>
          <div
            v-if="hasErrors && errors['type']"
            class="invalid-feedback"
            style="display: block;"
          >
            {{ errors['type'][0] }}
          </div>


          <div class="form-group">
            <div class="input-group kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <template v-if="profile.currency === 'BTC'">
                    <i class="fab fa-bitcoin"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-euro-sign"></i>
                  </template>
                </span>
              </div>
              <MaskedInput
                  v-model="values.amount"
                  type="text"
                  class="form-control"
                  placeholder="0.00"
                  :mask="mask"
                  :guide="false"
                  :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                    @click="withdrawCheckMfa(current_currency)"
                    :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
                ><span class="d-none d-sm-inline">{{ $t('withdraw.button') }}</span><span class="d-inline d-sm-none"><i class="fas fa-forward"></i></span></button>
              </div>

            </div>
            <div
              v-if="hasErrors && errors['amount']"
              class="invalid-feedback"
              style="display: block;"
            >
              {{ errors['amount'][0] }}
            </div>
          </div>

          <div class="alert alert-secondary kt-margin-b-0" role="alert">
            <div class="alert-icon"><i class="fab fa-bitcoin"></i></div>
            <div class="alert-text">{{ $t('withdraw.details',{ currency: current_currency }) }}</div>
          </div>
        </b-tab>
        <b-tab>
          <template #title>
            <i class="far fa-gem"></i>{{ $t('withdraw.sapp') }}
          </template>
          <div class="kt-margin-b-15 text-center">
            <a href="#" @click.prevent="showWalletsModal" class="btn btn-primary" :class="profile.limited_mode ? 'disabled' : ''">
              <i class="fas fa-wallet"></i> <span class="d-none d-sm-inline">{{ $t('withdraw.manage_wallets') }}</span><span class="d-inline d-sm-none">{{ $t('withdraw.manage_wallets_xs') }}</span>
            </a>
          </div>

          <p class="text-center"><a href="https://sappcoin.com/" target="_blank">{{ $t('withdraw.sapp_wallet') }}</a></p>

          <p>{{ $t('withdraw.enter_amount', { currency: profile.currency }) }}</p>

          <div class="kt-margin-b-10">
            <b-form-select v-model="values.wallet" :options="walletsApproved" :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified || !walletsApproved">
              <template #first>
                <b-form-select-option :value="null" disabled v-text="!walletsApproved?$t('withdraw.select_placeholder_empty'):$t('withdraw.select_placeholder',{ currency: current_currency })"></b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div
            v-if="hasErrors && errors['wallet']"
            class="invalid-feedback"
            style="display: block;"
          >
            {{ errors['wallet'][0] }}
          </div>
          <div
            v-if="hasErrors && errors['type']"
            class="invalid-feedback"
            style="display: block;"
          >
            {{ errors['type'][0] }}
          </div>


          <div class="form-group">
            <div class="input-group kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <template v-if="profile.currency === 'BTC'">
                    <i class="fab fa-bitcoin"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-euro-sign"></i>
                  </template>
                </span>
              </div>
              <MaskedInput
                  v-model="values.amount"
                  type="text"
                  class="form-control"
                  placeholder="0.00"
                  :mask="mask"
                  :guide="false"
                  :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                    @click="withdrawCheckMfa(current_currency)"
                    :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
                ><span class="d-none d-sm-inline">{{ $t('withdraw.button') }}</span><span class="d-inline d-sm-none"><i class="fas fa-forward"></i></span></button>
              </div>

            </div>
            <div
                v-if="hasErrors && errors['amount']"
                class="invalid-feedback"
                style="display: block;"
            >
              {{ errors['amount'][0] }}
            </div>
          </div>

          <div class="alert alert-secondary kt-margin-b-0" role="alert">
            <div class="alert-icon"><i class="fas fa-gem"></i></div>
            <div class="alert-text">{{ $t('withdraw.details',{ currency: current_currency }) }}</div>
          </div>
        </b-tab>

        <b-tab>
          <template #title>
            <i class="fas fa-dollar-sign"></i>{{ $t('withdraw.trx') }}
          </template>
          <div class="kt-margin-b-15 text-center">
            <a href="#" @click.prevent="showWalletsModal" class="btn btn-primary" :class="profile.limited_mode ? 'disabled' : ''">
              <i class="fas fa-wallet"></i> <span class="d-none d-sm-inline">{{ $t('withdraw.manage_wallets') }}</span><span class="d-inline d-sm-none">{{ $t('withdraw.manage_wallets_xs') }}</span>
            </a>
          </div>

          <p>{{ $t('withdraw.enter_amount', { currency: profile.currency }) }}</p>

          <div class="kt-margin-b-10">
            <b-form-select v-model="values.wallet" :options="walletsApproved" :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified || !walletsApproved">
              <template #first>
                <b-form-select-option :value="null" disabled v-text="!walletsApproved?$t('withdraw.select_placeholder_empty'):$t('withdraw.select_placeholder',{ currency: current_currency })"></b-form-select-option>
              </template>
            </b-form-select>
          </div>
          <div
            v-if="hasErrors && errors['wallet']"
            class="invalid-feedback"
            style="display: block;"
          >
            {{ errors['wallet'][0] }}
          </div>
          <div
            v-if="hasErrors && errors['type']"
            class="invalid-feedback"
            style="display: block;"
          >
            {{ errors['type'][0] }}
          </div>


          <div class="form-group">
            <div class="input-group kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <template v-if="profile.currency === 'BTC'">
                    <i class="fab fa-bitcoin"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-euro-sign"></i>
                  </template>
                </span>
              </div>
              <MaskedInput
                v-model="values.amount"
                type="text"
                class="form-control"
                placeholder="0.00"
                :mask="mask"
                :guide="false"
                :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-primary"
                  @click="withdrawCheckMfa(current_currency)"
                  :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
                ><span class="d-none d-sm-inline">{{ $t('withdraw.button') }}</span><span class="d-inline d-sm-none"><i class="fas fa-forward"></i></span></button>
              </div>

            </div>
            <div
              v-if="hasErrors && errors['amount']"
              class="invalid-feedback"
              style="display: block;"
            >
              {{ errors['amount'][0] }}
            </div>
          </div>

          <div class="alert alert-secondary kt-margin-b-0" role="alert">
            <div class="alert-icon"><i class="fas fa-dollar-sign"></i></div>
            <div class="alert-text" v-html="$t('withdraw.details_trx')"></div>
          </div>
        </b-tab>


        <!--<b-tab
            v-if="profile.currency === 'EUR'"
            :title="$t('withdraw.wire')"
        >
          <div class="kt-margin-b-20 pull-right">
            <a href="#" @click.prevent="showBankModal" class="btn btn-primary">
              <i class="fas fa-cog"></i> {{ $t('withdraw.manage_wire') }}
            </a>
          </div>
          <p>{{ $t('withdraw.enter_amount', { currency: profile.currency }) }}</p>

          <div class="kt-margin-b-10">
            <b-form-select v-model="values.wire_details" :options="wireDetailsApproved"></b-form-select>
          </div>


          <div class="form-group">
            <div class="input-group input-group-lg kt-margin-b-20">
              <div class="input-group-prepend">
                <span class="input-group-text" id="basic-addon1">
                  <template v-if="profile.currency === 'BTC'">
                    <i class="fab fa-bitcoin"></i>
                  </template>
                  <template v-else>
                    <i class="fas fa-euro-sign"></i>
                  </template>
                </span>
              </div>
              <MaskedInput
                v-model="values.amount"
                type="text"
                class="form-control"
                placeholder="0.00"
                :mask="mask"
                :guide="false"
                :disabled="profile.limited_mode || profile.is_demo || !profile.account_verified"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-lg btn-primary"
                  @click="withdrawCheckMfa('EUR')"
                >
                  {{ $t('withdraw.button') }}
                </button>
              </div>

            </div>
            <div
              v-if="hasErrors"
              class="invalid-feedback"
              style="display: block;"
            >
              {{ errors['amount'][0] }}
            </div>
          </div>

        </b-tab>-->
      </b-tabs>
      <div class="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>

      <div class="alert alert-outline-danger kt-margin-b-0" role="alert">
        <div class="alert-icon"><i class="fas fa-exclamation-circle"></i></div>
        <div class="alert-text">
          <div v-html="$t('withdraw.note', { interval: withdrawalInterval() })"></div>
          <div class="kt-align-center"><a href="#" @click.prevent="showInfoModal" class="btn btn-sm btn-info">
            <i class="fas fa-info-circle"></i> {{ $t('withdraw.more_info') }}
          </a></div>
        </div>
      </div>
    </Loader>

    <MfaModal
        v-if="showMfa"
        :show="showMfa"
        :action="action"
        :method="profile.mfa_method"
        @verified="withdraw"
        @hidden="hideMfa"
    />

    <WithdrawInfoModal
        v-if="infoModal"
        :show="infoModal"
        @hidden="infoModal = false"
        @done="hideInfoModal"
    />

    <WithdrawWalletsModal
        v-if="walletsModal"
        :show="walletsModal"
        :wallets="wallets"
        @hidden="walletsModal = false"
        @update="getWallets"
        @done="hideWalletsModal"
    />

    <!--<WithdrawBankModal
        v-if="bankModal"
        :show="bankModal"
        :wallets="wireDetails"
        @hidden="bankModal = false"
        @done="hideWireDetailsModal"
    />-->
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  import Dropdown from '../functional/Dropdown'
  import WithdrawWalletsModal from './WithdrawWalletsModal'
  import WithdrawBankModal from './WithdrawBankModal'
  import requiresMfa from '../../mixins/requiresMfa'
  import formHasErrors from '../../mixins/formHasErrors'
  import { btcMask, eurMask } from '../../helpers/inputMasks'
  import moment from 'moment'
  import WithdrawInfoModal from "./WithdrawInfoModal";
  import Loader from "@/components/global/Loader";

  export default {
    name: 'WithdrawalForm',
    components: {
      WithdrawInfoModal,
      Dropdown,
      WithdrawWalletsModal,
      WithdrawBankModal,
      Loader
    },
    mixins: [
      requiresMfa,
      formHasErrors
    ],
    data () {
      return {
        values: {
          amount: null,
          wallet: null,
          wire_details: null,
          type: null
        },
        wallets: [],
        wireDetails: [],
        showMfa: false,
        walletsModal: false,
        infoModal: false,
        bankModal: false,
        isLoading: false,
        action: 'withdraw_request',
        current_tab_index: 0,
      }
    },
    computed: {
      current_currency() {
        switch(this.current_tab_index){
          case 0:
            return 'BTC';
          case 1:
            return 'SAPP';
          case 2:
            return 'USDT';
        }
        return 'BTC';
      },
      walletsApproved () {
        let approved = []
        this.wallets.forEach(item => {
          if (item.status === 'approved' && item.currency === this.current_currency) {
            approved.push({
              text: item.title + ' [' + item.currency + ']',
              value: item.id
            })
          }
        })
        return approved
      },
      wireDetailsApproved () {
        let approved = []
        this.wireDetails.forEach(item => {
          if (item.status === 'approved') {
            approved.push({
              text: item.title,
              value: item.id
            })
          }
        })
        return approved
      },
      ...mapState({
        profile: state => state.authUser.profile
      }),
      mask () {
        if (this.profile && this.profile.account_selected) {
          if (this.profile.account_selected === 'EUR') {
            return eurMask
          } else if (this.profile.account_selected === 'BTC') {
            return btcMask
          }
        }
        return null
      }
    },
    created () {
      this.getWallets()
      this.getWireDetails()
    },
    methods: {
      reset_forms () {
        this.values.amount = null;
        this.values.wallet = null;
        this.values.wire_details = null;
        this.values.type = null;
        this.clearErrors();
      },
      hideInfoModal () {
        this.infoModal = false
      },
      hideWalletsModal () {
        this.walletsModal = false
        this.getWallets()
      },
      hideWireDetailsModal () {
        this.bankModal = false
        this.getWireDetails()
      },
      getWallets () {
        if (this.profile.is_demo || this.profile.limited_mode) {
          return
        }
        axios.get(this.$api.withdrawWallet).then(response => {
          this.wallets = response.data.data
        }).catch(error => {})
      },
      getWireDetails () {
        if (this.profile.is_demo) {
          return
        }
        axios.get(this.$api.withdrawBankDetails).then(response => {
          this.wireDetails = response.data.data
        }).catch(error => {})
      },
      withdrawCheckMfa (currency) {
        this.clearErrors()
        let amount = parseFloat(this.values.amount)
        this.values.type = currency

        if ((this.values.type === 'BTC' || this.values.type === 'SAPP' || this.values.type === 'USDT') && !this.values.wallet) {
          this.addError('amount', this.$t('withdraw.select_wallet'), true)
          return false
        } else if (this.values.type === 'EUR' && !this.values.wire_details) {
          this.addError('amount', this.$t('withdraw.select_wire'), true)
          return false
        }

        if (!amount || amount <= 0) {
          this.addError('amount', this.$t('withdraw.verify_amount'))
          return false
        }
        if (amount > this.profile.withdraw_balance) {
          this.addError('amount', this.$t('withdraw.verify_amount_larger'))
          return false
        }
        if (amount < this.profile.withdrawal_minimum) {
          this.addError('amount', this.$t('withdraw.verify_min_withdrawal') + this.profile.withdrawal_minimum_display)
          return false
        }

        if (amount > this.profile.withdrawal_limit_after && this.profile.withdrawal_limit_after > 0) {
          let fullBalance = this.profile.deposit_balance + this.profile.withdraw_balance
          if (amount > (fullBalance * this.profile.withdrawal_limit_percent) / 100) {
            this.addError('amount', this.$t('withdraw.verify_max_withdrawal') +
              ((fullBalance * this.profile.withdrawal_limit_percent) / 100))
            return false
          }
        }

        if (this.profile.mfa_enabled) {
          this.showMfa = true
        } else {
          this.withdraw()
        }
      },
      withdraw (token = null) {
        this.clearErrors()
        this.isLoading = true
        this.showMfa = false
        axios.post(this.$api.withdraw, {
          amount: parseFloat(this.values.amount),
          wallet: (this.values.type === 'BTC' || this.values.type === 'SAPP' || this.values.type === 'USDT') ? this.values.wallet : this.values.wire_details,
          type: this.values.type,
          token
        }).then(response => {
          this.values.amount = null
          this.values.wallet = null
          this.values.wire_details = null
          this.values.type = null
          this.$store.dispatch('showSuccessNotification', this.$t('withdraw.success'))
          this.$emit('updated')
          this.isLoading = false
        }).catch(error => {
          if (error.response) {
            if (error.response.status === 422) {
              this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
              this.setErrors(error.response.data.errors)
            }else if(error.response.data && error.response.data.data && error.response.data.data.message){
              this.$store.dispatch('showErrorNotification', error.response.data.data.message)
            }else{
              this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
            }
          }
          this.isLoading = false
        })
      },
      showInfoModal () {
        this.infoModal = true
      },
      showWalletsModal () {
        if(this.profile.limited_mode){
          this.$store.dispatch('showErrorNotification', 'This function is currently disabled');
          return
        }
        this.walletsModal = true
      },
      showBankModal () {
        this.bankModal = true
      },
      moment: function () {
        return moment();
      },
      withdrawalInterval () {
        let intbase = moment().date(1);
        if(moment().date() > 15){
          intbase = moment().date(1).add(1,'months')
        }
        return intbase.format('MMM Do') + ' - ' + intbase.date(15).format('MMM Do');
      }
    }
  }
</script>
