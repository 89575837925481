<template>
  <Loader :loading="isLoading">
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover" id="trades_table">
        <thead>
          <tr>
            <th nowrap>Status</th>
            <th nowrap>Trade Details</th>
            <th nowrap>Created</th>
            <th nowrap>Sent</th>
          </tr>
        </thead>
        <tbody v-if="trades">
          <tr v-for="trade in trades">
            <td nowrap>
              <span
                class="kt-badge  kt-badge--inline kt-badge--pill"
                :class="{
                  'kt-badge--danger': trade.status === 'cancelled',
                  'kt-badge--success': trade.status === 'sent',
                  'kt-badge--warning': trade.status === 'pending',
                }"
              >
                 {{ trade.status_display }}
              </span>
            </td>
            <td nowrap>
              <div class="text-bold">{{ trade.amount_initial_display }} <i class="fas fa-long-arrow-alt-right"></i> {{ trade.amount_display }}</div>
              <small>To: {{ trade.address }}</small>
            </td>
            <td nowrap >{{ trade.created_at ? trade.created_at.display : '' }}</td>
            <td nowrap >{{ trade.sent_at ? trade.sent_at.display : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
    ></b-pagination>
  </Loader>
</template>

<script>
import Loader from "../global/Loader";
export default {
  name: 'OTCActivity',
  components: {
    Loader
  },
  data () {
    return {
      trades: [],
      currentPage: 1,
      totalRows: null,
      perPage: null,
      isLoading: false
    }
  },
  created () {
    this.getHistory()
  },
  watch: {
    currentPage: function(){
      this.getHistory();
    }
  },
  methods: {
    getHistory () {
      this.isLoading = true;
      axios.get(this.$api.otc + '?page=' + this.currentPage).then(response => {
        this.isLoading = false;
        this.trades = response.data.data
        this.perPage = response.data.meta.per_page;
        this.totalRows = response.data.meta.total;
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.isLoading = true;
      })
    }
  }
}
</script>
