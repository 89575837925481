<template>
  <Loader :loading="isLoading" :full-page="true">

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">

        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <span class="kt-portlet__head-icon">
                  <i class="fas fa-question-circle"></i>
              </span>
              <h3 class="kt-portlet__head-title">
                {{ $t('support.title') }}
              </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
            </div>
          </div>
            <div class="kt-portlet__body">
              <!--<h3 class="kt-margin-b-20">{{ $t('support.intro_header') }}</h3>

              <div v-html="$t('support.intro_content')"></div>-->
              <p>EXCEPTIONAL MEDIA LIMITED<br>
              C/O SUITE 1905, 19/F,<br>
              TOWER TWO LIPPO CENTRE,<br>
              89 QUEENSWAY, ADMIRALTY,<br>
                HONG KONG</p>

              <p><a href="mailto:support@yieldnodes.com">support@yieldnodes.com</a></p>
            </div>
        </div>


        <!--<div class="accordion accordion-solid accordion-toggle-plus" role="tablist">
          <template v-for="(q,i) in localeQuestions">
            <b-card no-body>
              <b-card-header header-tag="header" role="tab">
                <div v-b-toggle="'accordion-'+i" class="card-title">{{ q.q }}</div>
              </b-card-header>
              <b-collapse :id="'accordion-'+i" :visible="i === 0" accordion="faq" role="tabpanel">
                <b-card-body v-html="q.a">
                </b-card-body>
              </b-collapse>
            </b-card>
          </template>
        </div>-->

      </div>
    </div>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '../components/global/Loader'

export default {
  name: 'ReportsPage',
  components: {
    Loader
  },
  data () {
    return {
      /*questions: [
        {
          q: 'How long is YieldNodes operating?',
          a: '<p>YieldNodes was launched in October 2019 for the first pool participants. It since then yielded to its participants over 10.3% average per month. Thank you all for your support :)</p>'
        },
        {
          q: 'Do you charge deposit fees / withdrawal fees?',
          a: '<p>We urge investors to use Bitcoin for depositing as its great to transfer value. We do NOT charge any deposit or withdrawal fees with Bitcoin.</p><p>We allow depositing also via different other merchants (Credit Card, Wire Transfer) however using 3th party processors that do charge a fee . This fee is entirely charged by the 3rd party and not by YieldNodes.</p>'
        },
        {
          q: 'How much does YieldNodes earn from this?',
          a: '<p>YieldNodes distributes profits monthly on a 87.5:12.5 basis. 87.5% is recieved by the members, 12.5% by YieldNodes. This accounts for all profits generated.. Masternoding as well as 3rd party earnings from YieldNodes partners.</p>'
        },
        {
          q: 'How can I withdraw my profits?',
          a: '<p>We pay out withdrawals in Bitcoin only. You can request a withdrawal anytime, but better between the 1st and 15th of the month. You can do it in Withdrawal section. It will be paid on the 7-11th of the next month! Should you miss the window it will be paid the 7-11th of the next month after!</p><p><b>Update 25.05.2021:</b> We now support Sapphire (SAPP) Withdrawals. Sapphire withdrawals are credited always on the 4th of the subsequent month. Everything you request between 1-15th of the month is credited to your SAPP Wallet (<a href="https://sappcoin.com/" target="_blank">get your wallet here</a>) on the 4th of the next month. Its processed a bit earlier than the BTC Withdrawals because we do not need to exchange it making it a step easier for us.</p>'
        },
        {
          q: 'Why withdrawal takes so long?',
          a: '<p>This is due to the nature of the business. We MasterNode every penny in the program as efficiently as possible. This is how we manage the returns we pay! We plan withdrawals and amounts carefully and only with a long heads up period we can do what we do!</p><p>Would we offer daily withdrawals or weekly we would need to hold a lot of BTC to satisfy the clients and this would in turn diminish the returns.</p>'
        },
        {
          q: 'Are the returns consistent?',
          a: '<p>We can not guarantee consistent returns or safety of capital! YieldNodes is a very experimental way of generating money. However the business model worked well also trough the COVID-19 crisis in March 2020. The lowest we generated so far was 5.8% then, the highest 19.2% in February 2021.</p>'
        },
        {
          q: 'The returns displayed are with or without your commission priced in?',
          a: '<p>The returns you see in your account are your gains, 15% commission is already excluded.</p>'
        },
        {
          q: 'What is the minimum deposit?',
          a: '<p>The minimum is 500 EUR unless we run a promotion.</p>'
        },
        {
          q: 'Is it possible to make a small test deposit to my account before I transfer a larger amount?',
          a: '<p>Yes, the system is flexible enough to do this. you can deposit any amount you like but it will start "working" once the minimum of 500 Euro is reached!</p>'
        },
        {
          q: 'I deposited but my deposit is not showing yet, why?',
          a: '<p>The deposit is confirmed in under 24 hours manually due to security reasons (always checked manually and verified). You can see your deposit in Pending field, in Deposit section until it\'s confirmed.</p>'
        },
        {
          q: 'How can I deposit using a credit card?',
          a: '<p>We have added several 3rd party options. that convert against a fee such as Walleteze, Transak or Onramper Remember YieldNodes only gets to work once we obtain the BTC or USDT (tether). The 3rd party options are all not within yieldnodes so make sure if issues arise to message those providers!</p>'
        },
        {
          q: 'What are the values in BTC and Ethereum inside the members area?',
          a: '<p>These values are only shown for informational purposes so you have an idea what your money is if it is converted into Bitcoin or Ethereum. Your initial deposit will always be stored in EUR and all the calculations are with EUR amounts.</p>'
        },
        {
          q: 'Is this a Bitcoin Pool?',
          a: '<p>No! We use Bitcoin only as a value transferring currency. As MasterNoding requires us to setup servers and buy masternodable coins, your deposited BTC amount is to be converted right after we recieve it to generate the returns! If you look for BTC speculation YieldNodes is not for you.</p>'
        },
        {
          q: 'Are you regulated or licensed?',
          a: '<p>No we are neither regulated nor licensed and do not claim to be. YieldNodes does not trade cryptocurrencies nor engage in any Stocks or Forex trading activities. It\'s a rent server pool program that pools members funds to place in masternodes.</p>'
        },
        {
          q: 'Again, how high is the risk level of YieldNodes?',
          a: '<p>We have written a risk assessment that is less "lawyerish" to give you some more perspective. Feel free to read it to get a better perspective here: <a href="https://members.yieldnodes.com/en/risk-assessment" target="_blank">https://members.yieldnodes.com/en/risk-assessment</a>.</p>'
        },
        {
          q: 'I made a Deposit XXX minutes (hours) ago! Its not reflected in my account. Can you help?',
          a: '<p>First of all every bitcoin transaction takes some time to be confirmed. Secondly in YieldNodes all deposits are verified manually. We do this 2x-3x times a day and fix it agains the EUR exchange price. Please give us up to 24 hours for approval. You will see your deposit inside the members area under deposits in "idle" state right after it\'s approved plus get a confirmation e-mail.</p>'
        },
        {
          q: 'I paid in via Transak, Coinify or Walleteze or another 3rd-party payment processor and I get an error or a blank screen etc..',
          a: '<p>We use 3rd party payment processors to have your assets exchange to Bitcoin (even if we are a EUR-based participation Pool) so we can exchange the Bitcoin for masternodable cryptocurrency (such as Sapphire for example).</p>' +
            '<p>Even tough they look as if they are YieldNodes (as we integrated them into the Deposit page) they are 3rd party services with own support departments and own policies.</p>' +
            '<p>We do not have direct bank transfers and relay on merchants as we would need to take your payment, exchange it with an exchanger (absorb added fees) then receive the Bitcoin and exchange.. a lot of added shenanigans to the operation that we try to keep slim and streamlined (for your benefit!). If you have issues with deposits please contact the processor you used directly as they can resolve the issue usually in a timely manner. If you contact us we can only contact them in turn and it will take much longer to get to the bottom (as well as take our attention of the making money part).</p>' +
            '<p>YieldNodes takes "charge" once we receive the Bitcoin in the account.</p>'
        },
        {
          q: 'I was verified already (all green) and now im unverified again! Why is that?',
          a: '<p>When you submit documents for verification it shows the green color right of the bat! Please give us some time (up to 48 hours) for verification! Also reload the verification page in case as sometimes you see a cached version!</p>'
        },
        {
          q: 'I am having an issue verifying my address proof for the KYC.',
          a: '<p>Please be patient it takes awhile as it is a process done by people not robots.</p><p>Passport or identification document is pretty straightforward, same for the risk disclaimer that you need to download, sign and reupload (picture of the signed page is also okay).</p><p>Most issues that prevent a successful KYC is the utility Bill. Remember the Address needs to match the address inside your profile information of YieldNodes. If you did not fill the profile info it cannot be matched against!</p>'
        },
        {
          q: 'The stats in the members area don\'t seem to update? Why?',
          a: '<p>The statistics are "soft" calculations only. Your profit stats update every 1st of the month in the evening.</p>'
        },
        {
          q: 'How do I know this is a real opportunity?',
          a: '<p>We are very transparent in who we are and what we do. We also have a great track record so far and we do not claim to have found the Holy Grail of passive profits. YieldNodes is a very experimental way of generating money and the danger is very real that your investment might be lost in part or in full. Do not treat YieldNodes as a bank account. Make sure you understand what you are investing in and read our Agreement very carefully as it requires signature that you have understood the risks!</p><p>That being said we pledge to be honest and transparent as good as we can and keep doing our very best! We are MasterNoding with our own capital alongside our participants so we try to have our mutual intrests at heart!</p>'
        },
        {
          q: 'Do you trade crypto? Is this similiar to Forex?',
          a: '<p>We do not trade, we are not traders and we do not engage in any Forex activities!</p>'
        },
        {
          q: 'I\'m already with you since 2 weeks and the weekly earnings have been published but my account doesn\'t seem to reflect them, why?',
          a: '<p>Remember we update the stats every 1st of the month so you have to be a little patient!</p>'
        },
        {
          q: 'My BTC balance seems to have diminished how can this be?',
          a: '<p>If Bitcoin is rising this can happen as your deposit is in EUR and fixed at it! If Bitcoin crashes your BTC "shown" value will increase as again you are fixed at EUR value.</p>'
        },
        {
          q: 'I would like to compound my earnings! Can I do that?',
          a: '<p>Yes you can. Either manually after the 1st of the month (profit crediting day) until the 15th, or once you submitted KYC and compiled all information automatically under the withdrawal tab in the members area. You can set any value between 10-100% and it will automatically compound that amount and leave the non compounded one for withdrawal. Remember if you would like to have withdrawable balance to turn off or reduce the compounding before the end of the month or it will be locked!</p>'
        },
        {
          q: 'I’ve had a release date, but I’m not seeing  any withdrawal amount on the dashboard.',
          a: '<p>Each deposit has its own lock period of 6 months that unlocks automatically. Once that happens the balance STILL generates yield profits. You can NOW however manually move it to the "withdrawable" balance!</p><p>To do so go to the withdrawal tab and look for the section: "Transfer Noded Balance" You can transfer your balance there to withdrawable. And after that process was verified actually withdraw. CAREFUL: If you do this and thend ecide you want to reinvest it again it will go into a new 6 months lock. If you want to keep generating revenue don\'t transfer the Balance :)</p>'
        },
        {
          q: 'Is there a referral program?',
          a: '<p>We offer a referral program that pays 5% of all deposits of your referrals (first deposit and subsequent deposits). The 5% is not paid on compounding profits.</p>'
        },
        {
          q: 'Were can I find my referral links?',
          a: '<p>Inside the members area under Affiliate section you can find links and banners to help in your marketing efforts. Remember to market our program ethically and make your prospects aware of the risks. It\'s only fair :)</p>'
        },
        {
          q: 'How can I have my affiliate commissions masternoded? Is that possible?',
          a: '<p>Yes it is. Go to the Affiliate section and on the right side you can move the affiliate commissions to the withdrawable balance.' +
            'Then go to the Deposit section (always inside the YieldNodes members area) and on the right side you can deposit your withdrawable balance to the masternoded balance. After a short manual verification you are good to go!</p>'
        },
        {
          q: 'Do you have a mobile app?',
          a: '<p>Yes, we have recently released mobile app for Android. Here is the <a href="https://play.google.com/store/apps/details?id=com.yieldnodes" target="_blank">link to Google Play Market</a>. For iOS the app will follow.</p>'
        },
        {
          q: 'I have a custom question, how can I contact you?',
          a: '<p>If your question has not been answered please do NOT hesitate to email us here: <a href="mailto:support@yieldnodes.com" target="_blank">support@yieldnodes.com</a></p><p>Alternatively you can contact our CEO Steve trough Whatsapp at: +35699957217</p><p>Be careful, there are lots of scammers with similar skype names, nicknames, etc. Always be careful and double-check all the information.</p><p>Thank you for being part of the YieldNodes family! We know trust is not coming easy nowadays and we value it deeply!</p><p>Sincerely,<br/>Urs, Steve, Dima and Yegor<br/>The YieldNodes Core Team.</p>'
        },
      ],
      questions_de: [
        {
          q: 'Wie lange ist YieldNodes mittlerweile aktiv?',
          a: '<p>YieldNodes wurde im Oktober 2019 für die ersten Teilnehmer geöffnet. Seither wurde eine durchschnittliche monatliche Rendite von 10,3 Prozent für alle Einzahlenden erzielt. Wir danken für die Unterstützung :)</p>'
        },
        {
          q: 'Erheben Sie Gebühren für das Verwahren / Auszahlen eingezahlter Beträge?',
          a: '<p>Wie halten Investoren an, Bitcoins für die Einzahlung zu verwenden, diese eignen sich sehr gut für den Transfer. Für Bitcoins erheben wir KEINE Verwahrungs- und Auszahlungsgebühren.</p><p>Wir lassen aber auch andere Einzahlungsmöglichkeiten zu (Kreditkarte, Überweisung), verwenden dafür aber Drittanbieter, die Gebühren erheben. Diese Gebühren werden nicht von YieldNodes eingezogen.</p>'
        },
        {
          q: 'Wie profitiert YieldNodes davon?',
          a: '<p>YieldNodes verteilt die Rendite auf der Basis von 87.5:12.5. Die Mitglieder erhalten 87.5%, YieldNodes 12.5%. Das gilt für alle erwirtschafteten Gewinne.. Masternoding genauso wie Gewinne durch Drittanbieter, die Partner von YieldNodes sind.</p>'
        },
        {
          q: 'Wie kann ich meine Gewinne auszahlen lassen?',
          a: '<p>Wir zahlen nur in Bitcoins aus. Sie können eine Auszahlung jederzeit anfordern, am besten jedoch zwischen dem <b>1. und 15. eines Monats</b>. Das können Sie im Bereich "Auszahlen" veranlassen. Die Auszahlung erfolgt am <b>7-11. des Folgemonats</b>! Veranlassen Sie eine Auszahlung nach dem 15. eines Monats erfolgt die Auszahlung am 7-11. des <b>übernächsten</b> Monats!</p><p><b>Update 25.05.21:</b> Wir bieten jetzt auch Auszahlungen in Sapphire (SAPP) an. Sapphire Auszahlungen erfolgen am 4. eines Monats. Jede angeforderte Auszahlung in Sapphire zwischen dem 1. und dem 15. eines Monats wird Ihrem Sapphire Wallet am 4. des Folgemonats gutgeschrieben (<a href="https://sappcoin.com/" target="_blank">hier erhalten Sie ein Sapphire Wallet</a>). Die Auszahlung erfolgt früher als bei Bitcoin Abhebungen da wir nicht zuerst umtauschen müssen, was es uns leichter macht.</p>'
        },
        {
          q: 'Warum dauert es solange bis zur Auszahlung?',
          a: '<p>Das liegt in der Natur unseres Geschäftsmodells. Wir "Masternoden" jeden Cent so effizient wie möglich in das Programm. So erwirtschaften wir die Renditen, die wir ausschütten! Wir planen die Auszahlungen sorgfältig und das ist nur mit entsprechend langer Vorlaufzeit möglich!</p>\n<p>Würden wir tägliche oder wöchentliche Auszahlungen ermöglichen wollen ginge das nur durch das Bereithalten von vielen Bitcoins (die wir dann nicht gewinnbringend nutzen könnten) und das würde im Gegenzug die Renditen verringern.</p>'
        },
        {
          q: 'Sind die Renditen konstant?',
          a: '<p>Die Sicherheit des eingesetzten Kapitals und konstante Renditen daraus können wir nicht garantieren! YieldNodes ist eine sehr experimentelle Art der Gewinnerzeugung. Das Geschäftsmodell funktionierte jedoch auch in der COVID-19 Krise im März 2020. Die niedrigste monatliche Rendite, die wir bisher erzielten, betrug 5,8%, die höchste 19,2% im Februar 2021.</p>'
        },
        {
          q: 'Ist in den angezeigten Renditen der 15% Abzug durch YieldNodes schon berücksichtigt?',
          a: '<p>Ja, die angezeigten Renditen sind Ihr Reingewinn.</p>'
        },
        {
          q: 'Was ist die Mindesthöhe für eine Einzahlung zum Masternoden?',
          a: '<p>Die Mindesthöhe beträgt 500 EUR, es sei denn wir führen eine Sonderaktion durch.</p>'
        },
        {
          q: 'Ist es möglich eine kleine Testeinzahlung für meinen Account zu tätigen ehe ich eine größere Summe einzahle?',
          a: '<p>Ja, das System ist flexibel genug um das zu ermöglichen. Sie können jeden kleinen Betrag einzahlen, das Masternoden beginnt jedoch erst ab 500 EUR Gesamteinzahlung.</p>'
        },
        {
          q: 'Ich habe eingezahlt, meine Einzahlung ist aber noch nicht in meinem Account zu sehen, warum?',
          a: '<p>Aus Sicherheitsgründen wird die Einzahlung händisch in unter 24 Stunden bearbeitet und bestätigt (immer manuell überprüft und verifiziert). Sie können Ihre Einzahlung unter dem Menüpunkt "Einzahlen" im Feld "Ausstehend" sehen, bis sie bestätigt wird.</p>'
        },
        {
          q: 'Wie kann ich mit Kreditkarte einzahlen?\n',
          a: '<p>Wir haben mehrere Optionen durch Drittanbieter wie Walleteze, Transak oder Onramper hinzugefügt. Diese wandeln Fiatgeld gegen Gebühr um. YieldNodes tritt erst in Aktion wenn wir Bitcoins oder USDT (Tether) erhalten. Die Drittanbieter gehören nicht zu YieldNodes, sollte es also Probleme beim Einzahlvorgang geben, wenden Sie sich bitte direkt an die Drittanbieter.</p>'
        },
        {
          q: 'Was bedeuten die Bitcoin und Ethereum Werte im Mitgliederbereich?',
          a: '<p>Diese Werte dienen nur zu Informationszwecken, damit Sie sehen können was Ihr Geld in Bitcoins oder Ethereum wert ist. Ihre Einzahlung wird anfänglich in EUR deponiert, alle Berechnungen laufen in EUR ab.</p>'
        },
        {
          q: 'Ist das hier ein Bitcoin Pool?',
          a: '<p>Nein! Wir nutzen Bitcoin nur als Umtauschwährung. Da MasterNoding erfordert, dass wir Server aufsetzen und masternodefähige Coins kaufen, werden Ihre eingezahlten Bitcoins sofort umgewandelt um Gewinne generieren zu können! Wenn es Ihnen um Bitcoin Spekulation geht, ist YieldNodes nicht das Richtige für Sie.</p>'
        },
        {
          q: 'Sind Sie reguliert oder lizensiert?',
          a: '<p>Nein, wir sind weder reguliert noch lizensiert und behaupten auch nicht, es zu sein. YieldNodes handelt nicht mit Kryptowährungen und ist auch nicht im Forex Trading involviert. YieldNodes ist ein Programm das Server anmietet und dort die gesammelten Einzahlungen seiner Mitglieder auf die Platzierung von Masternodes verwendet.</p>'
        },
        {
          q: 'Nochmals, wie hoch ist das Risiko bei YieldNodes?',
          a: '<p>Wir haben eine Risikoeinschätzung verfasst, die nicht so sehr „juristisch formuliert“ ist, um Ihnen eine bessere Informationsgrundlage zu diesem Aspekt zu geben. Sie können sie hier aufrufen: <a href="https://members.yieldnodes.com/en/risk-assessment" target="_blank">https://members.yieldnodes.com/en/risk-assessment</a>.</p>'
        },
        {
          q: 'Ich habe vor XXX Minuten (oder Stunden) eine Einzahlung getätigt! Sie erscheint nicht in meinem Account. Können Sie helfen?',
          a: '<p>Zunächst einmal benötigt jede Bitcoin Transaktion etwas Zeit um bestätigt zu werden. Desweiteren werden alle Einzahlungen bei YieldNodes manuell verifiziert. Wir machen das zwei- bis dreimal am Tag und übernehmen dabei den aktuellen Wert in EUR. Geben Sie uns bitte 24 Stunden für die Genehmigung Ihrer Einzahlung. Nach der Genehmigung können Sie Ihre Einzahlung im Mitgliederbereich unter dem Menüpunkt „Einzahlen“ als Meldung „Sie haben eine Einzahlung im Leerlauf!“ sehen und erhalten zusätzlich eine Bestätigungsmail.</p>'
        },
        {
          q: 'Ich habe über Transak, Coinify, Walleteze oder einem anderen Drittanbieter eingezahlt, bekomme aber eine Fehlermeldung, leeren Bildschirm etc…',
          a: '<p>Bei der Einzahlung nutzen wir Drittanbieter für die Umwandlung in Bitcoins (auch wenn wir ein auf EUR basierter Teilnahmepool sind), um diese Bitcoins in masternodfähige Kryptowährung (z.B. Sapphire) umtauschen zu können.</p>' +
            '<p>Auch wenn diese Drittanbieter in die YieldNodes Website eingebunden sind (im Bereich Einzahlungen) sind sie dennoch eigenständige Dienstleister mit eigenen Supportabteilungen und Richtlinien.</p>' +
            '<p>Wir stehen nicht direkt mit Banken in Verbindung (was eine komplizierte Angelegenheit ist), stattdessen verlassen wir uns auf Drittanbieter, bei denen das der Fall ist. Wären wir es, müssten wir Ihre Fiatgeldeinzahlung unter vielen Regularien entgegennehmen, sie selbst umtauschen (gegen Gebühren), daraufhin die Bitcoins erhalten und wieder umtauschen… viel Zusatzaufwand für ein Unternehmen, das wir schlank und geradlinig (zu Ihrem Gewinn!) halten möchten.</p>' +
            '<p>Wenn Sie Probleme bei der Einzahlung über einen Drittanbieter haben, kontaktieren Sie bitte diesen direkt, denn dieser kann sich zeitnah darum kümmern. Wenn Sie uns kontaktieren, können wir Ihre Anfrage nur an den Drittanbieter weiterleiten, was deutlich länger dauert (und Ihnen damit mehr Zeit zum Rendite erzielen nimmt). </p>' +
            '<p>YieldNodes selbst tritt in Aktion, sobald die Bitcoins auf Ihrem Account sind.</p>'
        },
        {
          q: 'Ich war schon verifiziert (alles grün), doch nun bin ich wieder unverifiziert. Warum das?',
          a: '<p>Wenn Sie Ihre Dokumente einreichen wird die Anzeige automatisch grün! Geben Sie uns bitte für eine manuelle Überprüfung etwas Zeit (bis zu 48 Stunden). Sie können die Verifizierungsseite auch noch einmal laden, für den Fall, dass sie eine Version aus dem Cache Speicher sehen!</p>'
        },
        {
          q: 'Ich habe Probleme bei der Verifikation meiner Adresse.',
          a: '<p>Haben Sie bitte etwas Geduld, die Verifikation wird von Menschen durchgeführt, nicht von Robotern.</p><p>Recht einfach ist die Vorlage des Ausweises oder eines anderen Identifikationsdokuments, ebenso das Einreichen des Haftungsausschlusses, den Sie von unserer Seite herunterladen müssen. Letzteren müssen Sie unterschreiben und wieder hochladen (ein Foto davon zu machen und dieses hochzuladen genügt auch).</p><p>Das größte Hindernis für ein erfolgreiches KYC („Know Your Customer“) Prozedere ist das Beibringen einer Rechnung mit sichtbarer Adresse (z.B. Strom- und Gasrechnung). Denken Sie daran, dass die angezeigte Adresse in der Rechnung mit der der bei YieldNodes hinterlegten Adresse übereinstimmen muss. Ohne Eintragung einer Adresse in Ihren YieldNodes Account kann kein Adressvergleich durchgeführt werden!</p>'
        },
        {
          q: 'Die Zahlen und Statistiken in meinem Mitgliederbereich ändern sich nicht. Warum?',
          a: '<p>Die Zahlen und Statistiken Ihrer Gewinne ändern sich nicht in Echtzeit. Eine Aktualisierung erfolgt immer am Abend des Erstens eines Monats.</p>'
        },
        {
          q: 'Woher weiß ich, dass das hier eine echte Gelegenheit ist?',
          a: '<p>Wir sind sehr transparent hinsichtlich dessen wer wir sind und was wir tun. Wir können sehr gute Referenzen vorweisen, erheben dabei aber nicht den Anspruch, den heiligen Gral des passiven Einkommens entdeckt zu haben. YieldNodes ist ein sehr experimenteller Weg zur Generierung von Gewinnen und es besteht die sehr realistische Gefahr, dass Ihr eingezahltes Kapital teilweise oder gänzlich verloren geht. Sie sollten YieldNodes nicht einem Bankkonto gleichsetzen. Stellen Sie sicher, dass Sie verstehen worin Sie investieren und lesen Sie den Haftungsausschluss sehr sorgfältig. Dabei ist es erforderlich, dass Sie ihn unterschreiben um zu dokumentieren, dass Sie die Risiken verstanden haben!</p><p>Das vorausgesetzt versichern wir Ihnen, dass wir so seriös und transparent wie möglich sind und weiterhin unser Bestes tun! Beim Masternoding setzen wir auch Teile unseres eigenen Kapitals ein, so dass ein Erfolg nicht nur sehr im Interesse der Mitglieder, sondern auch sehr in unserem eigenen Interesse liegt.</p>'
        },
        {
          q: 'Handeln Sie mit Krypto? Ähnelt das hier einer Forex?',
          a: '<p>Wir handeln nicht, sind keine Händler und sind auch nicht in Forex Aktivitäten involviert!</p>'
        },
        {
          q: 'Ich bin bereits seit zwei Wochen Mitglied, die wöchentlichen Renditen wurden veröffentlicht, dennoch wirken sie sich nicht auf meinen Account aus. warum?',
          a: '<p>Denken Sie bitte daran, dass die Zahlen immer zum Ersten eines Monats abends aktualisiert werden, haben Sie bitte also etwas Geduld!</p>'
        },
        {
          q: 'Mein Bitcoinbestand scheint sich verringert zu haben, wie das?',
          a: '<p>Das passiert, wenn der Bitcoinkurs steigt, denn Ihre Einlage ist in EUR ausgewiesen und bleibt dabei unverändert. Demzufolge entspricht dann der Bestand an EUR einem verringertem Wert in Bitcoins (das Ganze umgekehrt wenn der Bitcoinkurs fällt).</p>'
        },
        {
          q: 'Ich möchte meine Gewinne reinvestieren, ist das möglich?',
          a: '<p>Ja, das ist möglich. Entweder manuell nach dem Ersten eines Monats (Tag der Ertragsausschüttung) bis zum 15. oder, nachdem das KYC Procedere vollzogen wurde, automatisch mit dem entsprechenden Regler („Automatischer Zinseszins“) unter dem Menüpunkt „Auszahlen“ im Mitgliederbereich. Sie können dabei jeden Wert in Zehnerschritten zwischen 0% und 100% Reinvestition einstellen. Damit wird automatisch reinvestiert, der mögliche Rest des Gewinns steht zur Auszahlung zur Verfügung.</p>\n<p>Beachten Sie bitte: Reinvestierte Gewinne sind wieder sechs Monate für die Auszahlung gesperrt! Wenn Sie also mehr ausgezahlt haben möchten, verringern Sie den Anteil des zu reinvestierenden Gewinns vor Ende des Monats, sonst steht er für die Auszahlung nicht zur Verfügung.</p>'
        },
        {
          q: 'Die Sperrfrist bis zur Auszahlbarkeit ist abgelaufen, auf dem Dashboard erscheint aber kein auszahlbarer Betrag.',
          a: '<p>Jede Einzahlung bringt jeweils eine neue sechsmonatige Sperre mit sich, die automatisch abläuft. Wenn das eingetreten ist, generiert <b>immer noch</b> das gesamte Masternodegutgaben Gewinne. <b>Jetzt</b> können Sie jedoch die freigegebenen Beträge manuell in den Bereich „Auszahlbar“ übertragen.</p><p>Klicken Sie um das zu tun den Menüpunkt „Auszahlen“ an, begeben Sie sich dann zu dem Bereich „Masternode Guthaben transferieren“ auf der rechten Seite unten. Dort können Sie sehen, wieviel Ihrer Einlage zur Auszahlung berechtigt ist. Nun können Sie alles oder einen Teil davon in den Bereich „Auszahlbar“ übertragen. Wenn dieser Prozess verifiziert ist können Sie den Betrag abheben.</p>\n<p>VORSICHT: Wenn Sie es sich anders überlegen und doch wieder reinvestieren wird für den jeweiligen Betrag eine neue sechsmonatige Sperre ausgelöst. Sie sollten das vermeiden wenn Sie Einkommen erhalten möchten :)</p>'
        },
        {
          q: 'Gibt es ein Empfehlungsprogramm?',
          a: '<p>Wir bieten ein Empfehlungsprogramm an, dass Ihnen 5% der Einzahlungen (Erst- und Folgeeinzahlungen) von über Ihren Empfehlungslinks angemeldeten Mitgliedern gutschreibt. Diese 5% unterliegen keiner sechsmonatigen Auszahlungssperre.</p>'
        },
        {
          q: 'Wo finde ich meine Empfehlungslinks?',
          a: '<p>Unter dem Menüpunkt „Partnerprogramm“ im Mitgliederbereich finden Sie Links und Banner zur Unterstützung Ihrer Marketingbemühungen. Denken Sie bitte daran, verantwortungsvoll zu empfehlen und mögliche Interessenten auf die Risiken hinzuweisen. Das ist nur fair :)</p>'
        },
        {
          q: 'Ist es möglich meine Einnahmen aus Empfehlungen für das Masternoding zu verwenden und wenn ja, wie?',
          a: '<p>Ja, das ist möglich. Begeben Sie sich dafür im Bereich „Partnerprogramm“ zum Bereich „Einnahmen zum aktiven Masternode Guthaben hinzufügen“ auf der rechten Seite. Dort können Sie einstellen, wieviel Ihrem Masternode Guthaben hinzugefügt werden soll. Nach einer kurzen Überprüfung ist alles erledigt.</p>'
        },
        {
          q: 'Haben Sie eine Mobile App?',
          a: '<p>Ja, wir haben kürzlich eine mobile App für Android veröffentlicht. Here is the <a href="https://play.google.com/store/apps/details?id=com.yieldnodes" target="_blank">link to Google Play Market</a>. For iOS the app will follow.</p>'
        },
        {
          q: 'Meine Frage wird hier nicht beantwortet, wie kann ich Sie kontaktieren?',
          a: '<p>In diesem Fall zögern Sie NICHT uns an diese Adresse eine Mail zu schicken: <a href="mailto:support@yieldnodes.com" target="_blank">support@yieldnodes.com</a></p><p>Alternativ können Sie unseren CEO Stefan über Whatsapp mit der Nummer +35699957217.</p><p>Es gibt Betrüger, welche missbräuchlich denselben Skypenamen und Profilfoto verwenden. </p><p>Danke, dass Sie Teil der YieldNodes Familie sind! Uns ist bewusst, dass man heutzutage nicht leicht Vertrauen vergibt und wir wissen Ihres sehr zu schätzen!</p><p>Mit freundlichen Grüßen\n<br>' +
            'Urs, Stefan, Dima und Yegor\n<br>' +
            'Das YieldNodes Kernteam.\n</p>'
        },
      ],*/
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    /*localeQuestions(){
      let questions = this.questions;
      switch (this.$i18n.locale){
        case 'en':
          questions = this.questions;
          break;
        case 'de':
          questions = this.questions_de;
          break;
      }
      return questions;
    }*/
  },
  created () {

  },
  methods: {

  }
}
</script>
