<template>
  <div>
    <Loader :loading="isLoading" loader-style="overflow: hidden">

      <p v-html="$t('withdraw.donate_support')"></p>

      <div
        v-if="hasErrors && errors['amount']"
        class="invalid-feedback"
        style="display: block;"
      >
        {{ errors['amount'][0] }}
      </div>

      <div class="input-group kt-margin-b-15">
        <div class="input-group-prepend">
          <span class="input-group-text text-bold">
              <template v-if="profile.currency === 'BTC'">
                <i class="fab fa-bitcoin"></i>
              </template>
              <template v-else>
                <i class="fas fa-euro-sign"></i>
              </template>
          </span>
        </div>

        <MaskedInput
          v-model="values.amount"
          type="text"
          class="form-control"
          placeholder="0.00"
          :mask="mask"
          :guide="false"
          :disabled="profile.limited_mode || profile.is_demo"
        />

        <div class="input-group-append">
            <span class="input-group-text kt-font-bold">
              <a href="#" @click.prevent="enterMaxDonation()">MAX</a>
            </span>
        </div>
      </div>

      <AccountInput
        id="comment"
        type="textarea"
        v-model="values.comment"
        name="comment"
        :errors="errors"
        :label="$t('withdraw.donate_comment')"
        :placeholder="$t('withdraw.donate_comment_placeholder')"
        :cols="0"
        :disabled="profile.limited_mode"
      />

      <button
        disabled="disabled"
        @click.prevent="donateCheck()" class="btn btn-primary sweet_alert" :disabled="profile.limited_mode || profile.is_demo" type="button">{{ $t('withdraw.donate_button') }}</button>

    </Loader>
    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="donate"
      @hidden="hideMfa"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'
import { btcMask, eurMask } from '../../helpers/inputMasks'
import TransferModal from './TransferModal'
import AccountInput from "@/components/forms/AccountInput";

export default {
  name: 'DonateForm',
  components: {
    Loader,
    TransferModal,
    AccountInput
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data () {
    return {
      values: {
        amount: null,
        comment: null
      },
      isLoading: false,
      showMfa: false,
      action: 'donate',
      token: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    mask () {
      if (this.profile && this.profile.account_selected) {
        if (this.profile.account_selected === 'EUR') {
          return eurMask
        } else if (this.profile.account_selected === 'BTC') {
          return btcMask
        }
      }
      return null
    }
  },
  created () {

  },
  methods: {
    donateCheck () {
      this.clearErrors()
      let amount = parseFloat(this.values.amount)
      if (!amount || amount <= 0) {
        this.addError('amount', this.$t('withdraw.donate_verify_amount'))
        return false
      }
      if (amount > this.profile.withdraw_balance) {
        this.addError('amount', this.$t('withdraw.donate_verify_amount_high'))
        return false
      }
      if (amount < 1) {
        this.addError('amount', this.$t('withdraw.donate_verify_amount_min') + '1 EUR')
        return false
      }
      if (this.profile.mfa_enabled) {
        this.showMfa = true
      } else {
        this.donate()
      }
    },
    donate (token = null) {
      this.clearErrors()
      this.isLoading = true
      this.showTransfer = false
      axios.post(this.$api.donate, {
        amount: parseFloat(this.values.amount),
        comment: this.values.comment,
        token: token
      }).then(response => {
        this.values.amount = null
        this.values.comment = null
        this.$store.dispatch('showSuccessNotification', this.$t('withdraw.donate_success'))
        this.$emit('updated')
        this.token = null
        this.showMfa = false
        this.isLoading = false
      }).catch(error => {
        this.showMfa = false
        this.isLoading = false
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        } else {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }
      })
    },
    enterMaxDonation() {
      this.values.amount = this.profile.withdraw_balance.toString();
    }
  }
}
</script>
