<template>
  <Portal
    to="subModals"
    :disabled="!usePortal"
  >
    <b-modal
      v-model="modalOpen"
      cancel-disabled
      ok-disabled
      :title="$t('withdraw.transfer_confirm_title')"
      no-close-on-backdrop
      no-close-on-esc
      @hidden="hideModal"
      header-close-content=""
    >
      <p v-html="$t('withdraw.transfer_approve', { amount: amount, currency: profile.account_selected })"></p>

      <div class="form-check kt-margin-b-10">
        <input
          id="dontWithdraw"
          v-model="values.dont_withdraw"
          class="form-check-input"
          type="checkbox"
          style="height: auto; margin-top: 4px;"
        >
        <label
          class="form-check-label"
          for="dontWithdraw"
        >
          {{ $t('withdraw.transfer_checkbox') }}
        </label>
      </div>

      <div
        v-show="!values.dont_withdraw"
      >
        <p class="kt-margin-t-10">{{ $t('withdraw.transfer_select_wallet') }}</p>
        <div class="kt-margin-b-20">
          <b-form-select v-model="values.wallet" :options="walletsApproved" :disabled="profile.is_demo || !profile.account_verified || !walletsApproved">
            <template #first>
              <b-form-select-option :value="null" disabled v-text="!walletsApproved?$t('withdraw.select_placeholder_empty'):$t('withdraw.select_placeholder2')"></b-form-select-option>
            </template>
          </b-form-select>
          <div
            v-if="hasErrors && errors['wallet']"
            class="invalid-feedback"
            style="display: block;"
          >
            {{ errors['wallet'][0] }}
          </div>
        </div>
      </div>
      <button
        type="button"
        class="btn btn-primary"
        @click.prevent="checkForm"
      >
        {{ $t('general.submit') }}
      </button>
      <div slot="modal-footer" />
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Dropdown from '../functional/Dropdown'
import formHasErrors from '../../mixins/formHasErrors'

export default {
  components: {
    Dropdown
  },
  mixins: [
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    amount: {
      type: [String, Number],
      default: null
    }
  },
  data () {
    return {
      modalOpen: this.show,
      wallets: [],
      values: {
        dont_withdraw: true,
        wallet: null
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    walletsApproved () {
      let approved = []
      this.wallets.forEach(item => {
        if (item.status === 'approved') {
          approved.push({
            text: item.title + ' [' + item.currency + ']',
            value: item.id
          })
        }
      })
      return approved
    }
  },
  created () {
    this.getWallets()
  },
  methods: {
    checkForm() {
      this.clearErrors()
      if(!this.values.dont_withdraw){
        if(!this.values.wallet){
          this.addError('wallet', this.$t('withdraw.transfer_select_wallet'))
          this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
          return false
        }
      }

      return this.submit();
    },
    getWallets () {
      if (this.profile.is_demo) {
        return
      }
      axios.get(this.$api.withdrawWallet).then(response => {
        this.wallets = response.data.data
      }).catch(error => {})
    },
    hideModal () {
      this.$emit('hidden')
    },
    submit () {
      this.$emit('submit', { wallet: this.values.dont_withdraw ? null : this.values.wallet, withdraw: !this.values.dont_withdraw })
    }
  }
}
</script>
