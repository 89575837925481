<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('deposit.wire_title')"
      @hidden="hideModal"
      size="lg"
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <ul
          v-if="wireDataEur"
          class="wire-details"
        >
          <li class="row">
            <div class="col-sm-9 offset-sm-3 value">
              <h4>{{ $t('deposit.wire_account_details_title') }}</h4>
            </div>
          </li>
          <li
            v-for="(item, index) in wireDataEur"
            :key="item.name"
            class="row"
          >
            <div class="col-sm-3 title">
              {{ item.name }}
            </div>
            <div class="col-sm-9 value">
              {{ item.value }}
            </div>
          </li>

          <li class="row">
            <div class="col-sm-3 title">
              {{ $t('deposit.wire_account_amount') }}
            </div>
            <div class="col-sm-9 value">
              {{ amountDisplay }}
            </div>
          </li>

          <li class="row">
            <div class="col-sm-3 title text-danger kt-font-bold">
              {{ $t('deposit.wire_account_reference') }}
            </div>
            <div class="col-sm-9 value">
              <p class="text-danger kt-font-bold">
                {{ referenceId }}
              </p>
              <p class="text-warning">
                <i class="fas fa-exclamation-triangle"></i> <span v-html="$t('deposit.wire_account_reference_info')"></span>
              </p>
            </div>
          </li>
        </ul>
        <ul
          v-if="wireDataGbp"
          class="wire-details"
        >
          <li class="row">
            <div class="col-sm-9 offset-sm-3 value">
              <h4>{{ $t('deposit.wire_account_details_title_gbpusd') }}</h4>
            </div>
          </li>
          <li
            v-for="(item, index) in wireDataGbp"
            :key="item.name"
            class="row"
          >
            <div class="col-sm-3 title">
              {{ item.name }}
            </div>
            <div class="col-sm-9 value">
              {{ item.value }}
            </div>
          </li>

          <li class="row">
            <div class="col-sm-3 title">
              {{ $t('deposit.wire_account_amount') }}
            </div>
            <div class="col-sm-9 value">
              {{ amountGbpDisplay }} or {{ amountUsdDisplay }}
            </div>
          </li>

          <li class="row">
            <div class="col-sm-3 title text-danger kt-font-bold">
              {{ $t('deposit.wire_account_reference') }}
            </div>
            <div class="col-sm-9 value">
              <p class="text-danger kt-font-bold">
                {{ referenceId }}
              </p>
              <p class="text-warning">
                <i class="fas fa-exclamation-triangle"></i> <span v-html="$t('deposit.wire_account_reference_info')"></span>
              </p>
            </div>
          </li>
        </ul>
        <div class="text-center text-info"><i class="fas fa-info-circle"></i> <span v-html="$t('deposit.wire_account_note')"></span></div>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col">
            <button @click.prevent="deposit" type="button" class="btn btn-brand">{{ $t('deposit.wire_account_confirm') }}</button>&nbsp;
            <button @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.cancel') }}</button>
          </div>
          <div class="col text-success"><i class="fas fa-info-circle"></i> {{ $t('deposit.wire_account_confirm_details') }}</div>
        </div>
      </div>
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'

export default {
  name: 'WireModal',
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    amount: {
      type: [String, Number],
      required: true
    },
    amountDisplay: {
      type: [String, Number],
      required: true
    },
    amountGbpDisplay: {
      type: [String, Number],
      required: true
    },
    amountUsdDisplay: {
      type: [String, Number],
      required: true
    },
    token: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      modalOpen: this.show,
      wireDataEur: {},
      wireDataGbp: {},
      referenceId: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
    this.getWireDetails()
    this.getReferenceId()
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    getWireDetails () {
      this.isLoading = true
      axios.get(this.$api.deposit + '/wire_details').then(response => {
        this.wireDataEur = response.data.data.eur;
        this.wireDataGbp = response.data.data.gbp;
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    getReferenceId () {
      axios.get(this.$api.deposit + '/reference').then(response => {
        this.referenceId = response.data.data.id
      }).catch(error => {})
    },
    deposit () {
      this.isLoading = true
      axios.post(this.$api.deposit + '/wire', {
        ref_id: this.referenceId,
        amount: this.amount,
        token: this.token
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.wire_account_success'))
        this.hideModal()
      }).catch(error => {
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else if (error.response.status === 422) {
          let errors = error.response.data.errors
          this.$store.dispatch('showErrorNotification', errors[Object.keys(errors)[0]][0])
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
        this.hideModal()
      })
    }
  }
}
</script>
