<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      title="Sell your balance with discount"
      @hidden="hideModal"
      size="lg"
      no-close-on-backdrop
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <div>
          <div
            v-if="hasError"
            class="alert alert-danger"
          >
            <div class="alert-text">
              <i class="fas fa-exclamation-circle"></i>
              {{ errorMsg }}
            </div>
          </div>


          <template v-if="!p2pSellAllowed">
            <div
              class="alert alert-danger"
              role="alert"
            >
              <div class="alert-text">
                <h4 class="alert-heading">Balance selling is not possible</h4>
                <p v-if="p2pSellDisallowedReason">{{ p2pSellDisallowedReason }}</p>
              </div>
            </div>
          </template>
          <template v-else>

            <p v-if="balance.balance" class="lead kt-font-bold">Your balance: {{ balance.balance_display }}</p>

            <form @submit.prevent="p2pSellCheckMfa" class="kt-margin-t-30 kt-margin-b-20">
              <div class="kt-margin-b-20">
                <b-form-group
                  label="Balance for sale:"
                  label-for="value"
                  :state="!(errors.hasOwnProperty('value') && errors.value[0])"
                  :invalid-feedback="errors.value[0]"
                >
                  <b-input-group>
                    <b-form-input v-model="values.value" @change="checkBalanceValue" id="value" placeholder="How much you want to sell?" />
                    <b-input-group-append>
                      <b-button variant="success" @click.prevent="setMaxBalance">MAX</b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </div>

              <div class="kt-margin-b-20">
                <b-form-group
                  label="Final Price:"
                  label-for="discount"
                  :state="!(errors.hasOwnProperty('discount') && errors.discount[0])"
                  :invalid-feedback="errors.discount[0]"
                >
                  <b-form-select v-model="values.discount" :options="discount_options" id="discount">
                    <template #first>
                      <b-form-select-option :value="null" v-text="'Select selling price option'" disabled></b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>

              <div class="kt-margin-b-20">
                <b-form-group
                  label="BTC Address:"
                  label-for="wallet_btc"
                  :state="!(errors.hasOwnProperty('wallet_btc') && errors.wallet_btc[0])"
                  :invalid-feedback="errors.wallet_btc[0]"
                  description="You can add more wallets in 'Withdrawal' > 'Manage Withdrawal Wallets'"
                >
                  <b-form-select v-model="values.wallet_btc" :options="walletsBTCApproved" id="wallet_btc" :disabled="walletsBTCApproved.length === 0">
                    <template #first>
                      <b-form-select-option :value="null" v-text="walletsBTCApproved.length === 0?'You have no BTC wallets. Add them in \'Withdrawal\' > \'Manage Withdrawal Wallets\'.':'Select BTC wallet'"></b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>

              <div class="kt-margin-b-20">
                <b-form-group
                  label="USDT (TRC-20) Address:"
                  label-for="wallet_usdt"
                  :state="!(errors.hasOwnProperty('wallet_usdt') && errors.wallet_usdt[0])"
                  :invalid-feedback="errors.wallet_usdt[0]"
                  description="You can add more wallets in 'Withdrawal' > 'Manage Withdrawal Wallets'"
                >
                  <b-form-select v-model="values.wallet_usdt" :options="walletsUSDTApproved" id="wallet_usdt" :disabled="walletsUSDTApproved.length === 0">
                    <template #first>
                      <b-form-select-option :value="null" v-text="walletsUSDTApproved.length === 0?'You have no USDT wallets. Add them in \'Withdrawal\' > \'Manage Withdrawal Wallets\'.':'Select USDT (TRC-20) wallet'"></b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>

              <div class="kt-margin-b-20">
                <b-form-group
                  label="USDC (TRC-20) Address:"
                  label-for="wallet_usdc"
                  :state="!(errors.hasOwnProperty('wallet_usdc') && errors.wallet_usdc[0])"
                  :invalid-feedback="errors.wallet_usdc[0]"
                  description="You can add more wallets in 'Withdrawal' > 'Manage Withdrawal Wallets'"
                >
                  <b-form-select v-model="values.wallet_usdc" :options="walletsUSDCApproved" id="wallet_usdc" :disabled="walletsUSDCApproved.length === 0">
                    <template #first>
                      <b-form-select-option :value="null" v-text="walletsUSDCApproved.length === 0?'You have no USDC wallets. Add them in \'Withdrawal\' > \'Manage Withdrawal Wallets\'.':'Select USDC (TRC-20) wallet'"></b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
              </div>

              <div class="alert alert-secondary" role="alert">
                <div class="alert-text">
                  <p class="kt-font-lg">Balance for sale: <b>{{ balanceForSaleDisplay }}</b></p>
                  <p class="kt-font-lg">You will receive: <b>{{ priceOfSaleDisplay }}</b> ({{values.discount}}%)</p>
                  <p v-if="values.wallet_btc" class="kt-font-lg kt-margin-l-10">in BTC: <b>{{ priceOfSaleBTC }}</b> to <b>{{ selectedBTCWallet }}</b></p>
                  <p v-if="values.wallet_usdt" class="kt-font-lg kt-margin-l-10">in USDT: <b>{{ priceOfSaleUSDT }}</b> to <b>{{ selectedUSDTWallet }}</b></p>
                  <p v-if="values.wallet_usdc" class="kt-font-lg kt-margin-l-10">in USDC: <b>{{ priceOfSaleUSDC }}</b> to <b>{{ selectedUSDCWallet }}</b></p>
                  <p v-if="values.wallet_btc || values.wallet_usdt || values.wallet_usdc"><em><b>Note:</b> Final rate is calculated at the moment of request creation and stays the same until the request is cancelled.</em></p>
                </div>
              </div>

              <div class="form-group">
                <label class="kt-checkbox" style="font-weight: bold;">
                  <input
                    v-model="values.agreed"
                    type="checkbox"
                    class="form-control"
                    value="1"
                  >
                  Yes, I agree with terms and conditions of the balance selling feature
                  <span></span>
                </label>
                <span
                  v-if="errors.hasOwnProperty('agreed')"
                  class="invalid-feedback"
                  :style="{display: errors.hasOwnProperty('agreed') ? 'block' : 'none'}"
                >{{ errors.agreed[0] }}</span>
              </div>

              <div class="form-group kt-margin-b-15 m-signature-pad">
                <div
                  v-if="errors.hasOwnProperty('signature') && errors.signature[0]"
                  class="alert alert-danger kt-margin-t-5"
                >{{ errors.signature[0] }}</div>

                <div
                  class="m-signature-pad--body"
                  :class="{'signature-pad-container--error': needsSingature}"
                >
                  <canvas
                    ref="signaturePadCanvas"
                    class="signature-pad"
                  />
                </div>
                <div class="m-signature-pad--footer">
                  <p>
                    <i class="fas fa-info-circle" />
                    Please, draw your real signature in the above area
                  </p>
                  <button
                    type="button"
                    class="btn btn-outline-info btn-sm"
                    @click="clearPad"
                  >
                    Clear Signature
                  </button>
                </div>
              </div>


              <div class="row">
                <div class="col">
                  <button
                    type="submit" class="btn btn-primary btn-lg" :disabled="quickCheckP2PSell">Create Balance Sale Request</button>
                </div>
              </div>
            </form>
            <div class="alert alert-secondary" role="alert">
              <div class="alert-text">
                <h3>Balance Selling Rules and Conditions:</h3>
                <p>(Be aware that this function requires some basic blockchain understanding, however mostly by the potential buyer)</p>
                <p>You can have only <b>one active balance selling request</b> at the same time. You can cancel request until it is not accepted by any buyer or once purchase request from buyer is expired.</p>
                <p>Once accepted - buyer will have <b>~24 hours</b> to purchase your balance or he can cancel his request during that timeframe. To confirm that transfer was done - buyer has to submit transaction id of his purchase which contains exact amount of crypto (or more) than required in your selling request.</p>
                <p>Once the transaction id is submitted by the buyer - our system checks blockchain for that transaction and it's amount. If the transaction belongs to one of your wallets and is confirmed - your balance will be immediately transferred to buyer.</p>
                <p>Alternatively, you will be able to manually confirm that you have received the required amount, in that case buyer will not need to supply transaction id.</p>
                <p>Never use the supplied wallet address to receive crypto transactions, while your selling request exists. Using this wallet for incoming transactions could potentially break the validation system and your balance will be transferred. That's why we do not recommend to use actively used exchange wallet addresses for selling requests.</p>
                <p><b>Make sure that wallet addresses</b>, that you have entered in the selling request are <b>correct and belong to you</b>. Since the wallet addresses which are provided in selling requests do not belong to YieldNodes - any recovery of buyer funds is <b>absolutely impossible</b>. If the transfer of funds to indicated wallet will be confirmed - your balance will be transferred to buyer without any option to recover.</p>
                <p>Final rate for BTC, USDT and USDC amounts is taken (average rate according to cryptocompare.com) at the moment of request creation and stays the same until the request is cancelled or user manually triggers an update.</p>
                <p>In case of any error coming either from YieldNodes side or when the amount of transaction is not correct when buyer is submitting transaction id - expiration of purchase request is disabled. In this case if the sale is not resolved by the seller manually for uncomfortable amount of time - you will have to contact our support team to resolve the situation with your selling request yourself.</p>
              </div>
            </div>
          </template>
        </div>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button
              @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>

    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="p2pSell"
      @hidden="hideMfa"
    />

  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import requiresMfa from "@/mixins/requiresMfa";
import formHasErrors from "@/mixins/formHasErrors";
import SignaturePad from 'signature_pad'

export default {
  name: 'P2PSellModal',
  components: {
    Loader
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMfa: false,
      isLoading: true,
      modalOpen: this.show,
      hasError: false,
      errorMsg: null,
      errors: null,
      balance: {
        allowed: true,
        reason: null,
        balance: null,
        balance_display: null,
        rate_btc: null,
        rate_usdt: null,
        rate_usdc: null
      },
      wallets_btc: [],
      wallets_usdt: [],
      wallets_usdc: [],
      values: {
        wallet_btc: null,
        wallet_usdt: null,
        wallet_usdc: null,
        value: null,
        discount: null,
        signature: null,
        agreed: false
      },
      discount_options: {
        75: '75% of balance',
        80: '80% of balance',
        90: '90% of balance',
        100: '100% of balance',
        110: '110% of balance',
        120: '120% of balance',
        130: '130% of balance',
        140: '140% of balance',
        150: '150% of balance',
      },
      action: "p2p_sell",
      signturePad: null,
      needsSingature: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    p2pSellAllowed(){
      return this.balance.allowed;
    },
    p2pSellDisallowedReason(){
      return this.balance.reason;
    },
    walletsBTCApproved () {
      let approved = []
      this.wallets_btc.forEach(item => {
        approved.push({
          text: item.address + ' (' + item.title + ')',
          value: item.id
        })
      })
      return approved
    },
    walletsUSDTApproved () {
      let approved = []
      this.wallets_usdt.forEach(item => {
        approved.push({
          text: item.address + ' (' + item.title + ')',
          value: item.id
        })
      })
      return approved
    },
    walletsUSDCApproved () {
      let approved = []
      this.wallets_usdc.forEach(item => {
        approved.push({
          text: item.address + ' (' + item.title + ')',
          value: item.id
        })
      })
      return approved
    },
    selectedBTCWallet () {
      if(!this.values.wallet_btc) return '';
      let address;
      this.wallets_btc.forEach(item => {
        if(item.id === this.values.wallet_btc){
          address = item.address;
        }
      })
      return address;
    },
    selectedUSDTWallet () {
      if(!this.values.wallet_usdt) return '';
      let address;
      this.wallets_usdt.forEach(item => {
        if(item.id === this.values.wallet_usdt){
          address = item.address;
        }
      })
      return address;
    },
    selectedUSDCWallet () {
      if(!this.values.wallet_usdc) return '';
      let address;
      this.wallets_usdc.forEach(item => {
        if(item.id === this.values.wallet_usdc){
          address = item.address;
        }
      })
      return address;
    },
    balanceForSaleDisplay () {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      });
      if(this.values.value > 0){
        return formatter.format(this.values.value)
      }else{
        return 0;
      }
    },
    priceOfSale () {
      return this.values.value * this.values.discount / 100;
    },
    priceOfSaleDisplay () {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(this.priceOfSale);
    },
    priceOfSaleBTC () {
      if(!this.balance.rate_btc) return 0;
      let converted = this.priceOfSale / this.balance.rate_btc
      return converted.toFixed(8);
    },
    priceOfSaleUSDT () {
      if(!this.balance.rate_usdt) return 0;
      let converted = this.priceOfSale / this.balance.rate_usdt
      return converted.toFixed(2);
    },
    priceOfSaleUSDC () {
      if(!this.balance.rate_usdc) return 0;
      let converted = this.priceOfSale / this.balance.rate_usdc
      return converted.toFixed(2);
    },
    quickCheckP2PSell () {
      if(!this.values.value) return true;
      if(!this.values.discount) return true;
      if(!this.values.agreed) return true;
      if(!this.values.wallet_btc && !this.values.wallet_usdt && !this.values.wallet_usdc) return true;
      return false;
    }
  },
  created () {
    this.loadBalance();
  },
  beforeDestroy () {
    this.signaturePad.off()
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    loadBalance() {
      this.isLoading = true
      axios.get(this.$api.p2p_sell + '/balance').then(response => {
        if(response.data.data.balance) {
          this.balance = response.data.data.balance;

          this.signaturePad = new SignaturePad(this.$refs.signaturePadCanvas, {
            onBegin: () => {
              if (this.needsSingature) {
                this.needsSingature = false
              }
            }
          })

          //window.addEventListener('resize', this.resizeCanvas)
          setTimeout(this.resizeCanvas, 1000)

        }else{
          this.balance.allowed = false;
          this.balance.reason = this.$t('general.error');
        }
        if(response.data.data.wallets_btc) {
          this.wallets_btc = response.data.data.wallets_btc;
        }
        if(response.data.data.wallets_usdt) {
          this.wallets_usdt = response.data.data.wallets_usdt;
        }
        if(response.data.data.wallets_usdc) {
          this.wallets_usdc = response.data.data.wallets_usdc;
        }
        this.isLoading = false
      }).catch(error => {
        console.log(error);
        this.isLoading = false
        this.balance.allowed = false;
        this.balance.reason = this.$t('general.unknown_error')
      })
    },
    setMaxBalance () {
      this.values.value = this.balance.balance;
    },
    checkBalanceValue(){
      this.clearErrors()

      if(this.values.value < 100){
        this.addError('value', 'This is below allowed minimum!')
        return false;
      }

      if(this.values.value > this.balance.balance){
        this.addError('value', 'This more balance than you have available!')
        return false;
      }

      return true;
    },
    p2pSellCheckMfa() {
      this.clearErrors()

      if(!this.checkBalanceValue()){
        return false;
      }

      if (!this.values.wallet_btc && !this.values.wallet_usdt && !this.values.wallet_usdc) {
        this.addError('wallet_btc', 'You need to select at least one wallet address!', true)
        this.addError('wallet_usdt', 'You need to select at least one wallet address!', true)
        this.addError('wallet_usdc', 'You need to select at least one wallet address!', true)
        return false
      }

      if (this.signaturePad.isEmpty()) {
        this.needsSingature = true
        this.$store.dispatch('showErrorNotification', 'Your real signature is required for this agreement!')
        this.addError('signature', 'Your real signature is required for the agreement!', true)
        return false;
      }
      this.values.signature = this.signaturePad.toDataURL('image/svg+xml');

      if (!this.values.agreed) {
        this.addError('agreed', 'You need to agree with terms and conditions', true)
        return false
      }

      if (this.profile.mfa_enabled) {
        this.showMfa = true;
        this.isLoading = true
      }else {
        this.$store.dispatch('showErrorNotification', 'You cannot use this feature until you set up MFA for your account!')
      }
    },
    p2pSell (token = null) {
      this.showMfa = false
      this.isLoading = true
      this.hasError = false
      this.errorMsg = null
      axios.post(this.$api.p2p_sell + '/create', {
        btc_wallet_id: this.values.wallet_btc,
        usdt_wallet_id: this.values.wallet_usdt,
        usdc_wallet_id: this.values.wallet_usdc,
        value: this.values.value,
        discount: this.values.discount,
        signature: this.values.signature,
        agreed: this.values.agreed,
        token
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', 'Your Sell Request is Sent!')
        this.$emit('done');
        this.hideModal();
      }).catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.status === 422) {
          let errors = error.response.data.errors
          this.$store.dispatch('showErrorNotification', errors[Object.keys(errors)[0]][0])
          this.errorMsg = errors[Object.keys(errors)[0]][0];
        } else if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.hasError = true;
          this.errorMsg = error.response.data.data.message;
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else {
          this.hasError = true;
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.errorMsg = this.$t('general.error')
        }
      })
    },
    hideMfa () {
      this.isLoading = false;
      this.showMfa = false
    },
    clearPad () {
      this.signaturePad.clear()
    },
    resizeCanvas () {
      let ratio = Math.max(window.devicePixelRatio || 1, 1)
      const canvasEl = this.$refs.signaturePadCanvas
      if (typeof canvasEl === 'object' && 'nodeType' in canvasEl) {
        canvasEl.width = canvasEl.offsetWidth * ratio
        canvasEl.height = canvasEl.offsetHeight * ratio
        canvasEl.getContext('2d').scale(ratio, ratio)
      }
      this.signaturePad.clear()
    }

  }
}
</script>

<style>
.m-signature-pad {
  display: block;
}
.m-signature-pad--body {
  position: relative;
  margin: 0 auto;
}
canvas.signature-pad {
  display: block;
  width: 70%;
  height: 200px;
  margin: 5px auto;
  border: #aaa 1px dashed;
}
@media (max-width: 768px){
  canvas.signature-pad {
    width: 90%;
  }
}
.signature-pad-container--error canvas.signature-pad {
  border-color: red;
}
.m-signature-pad--footer {
  text-align: center;
}
</style>
