<template>
  <Loader :loading="isLoading">
    <div class="kt-portlet kt-portlet--height-fluid">
      <div class="kt-portlet__head kt-portlet__space-x">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            Yield Nodes <small>{{ $t('dashboard.perf_title') }}</small>
          </h3>
        </div>
      </div>
      <div class="kt-portlet__body">
        <div class="row align-items-center kt-margin-b-30">
          <div class="col-md-5 offset-md-1">
            <h5 class="kt-widget12__desc">{{ $t('dashboard.perf_deposit') }}</h5>
            <div class="kt-widget12__value kt-margin-r-15">
              <div class="form-inline">
                <div class="input-group">
                  <div class="input-group-prepend"><span class="input-group-text"><i class="fas fa-euro-sign"></i></span></div>
                  <input type="text" class="form-control" placeholder="5000" v-model="deposit">
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button" @click.prevent="getData" :disabled="set_deposit == deposit">{{ $t('dashboard.perf_update') }}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6" v-if="stats">
            <h6>{{ $t('dashboard.perf_total_profit') }}</h6>
            <h6 class="kt-font-bolder text-success">{{ stats.totals.profit }} ({{ stats.totals.profit_percent }}%)</h6>
            <h6 class="kt-margin-t-15">{{ $t('dashboard.perf_total_profit_c') }}</h6>
            <h6 class="kt-font-bolder text-success">{{ stats.totals.profit_c }} ({{ stats.totals.profit_c_percent }}%)</h6>
          </div>
        </div>
        <line-chart
          id="fundPerformanceChart"
          element="kt_morris_2"
          xkey="month"
          :ykeys="['profit_c','profit','BTC','DJI','XAU']"
          :labels="['YieldNodes (Compounded)','YieldNodes','BTC','Dow Jones','Gold']"
          :lineColors="['#fc4141','#ae4322','#ffb28d','#fcb7ff','#eee33f']"
          hideHover="true"
          resize="true"
          continuousLine="true"
          :data="stats.monthly"
          :yLabelFormat="function(t){ return '€' + t.toLocaleString(); }"
          :hoverCallback="chartHoverCallback"
        />

      </div>
    </div>
  </Loader>
</template>

<style>
.morris-hover.morris-default-style {
  background-color: rgba(255,255,255,0.95);
  text-align: left;
  font-family: inherit;
  font-size: inherit;
}
#fundPerformanceChart{
  height: 400px;
}
@media (max-width: 768px) {
  #fundPerformanceChart{
    height: 300px;
  }
}
</style>

<script>
import { mapState } from 'vuex'
import Loader from '../../components/global/Loader'
import { LineChart } from 'vue-morris'

export default {
  name: 'FundPerformance',
  components: {
    Loader,
    LineChart
  },
  data () {
    return {
      stats: {
        monthly: [],
        totals: {}
      },
      isLoading: false,
      deposit: 5000,
      set_deposit: 5000
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created: function () {
    this.getData();
  },
  methods: {
    getData () {
      if(this.deposit < 500){
        this.deposit = 500;
      }
      this.isLoading = true
      axios.get(this.$api.fund_performance,{params: {deposit: this.deposit}}).then(response => {
        if(response.data.data){
          this.stats = response.data.data;
          this.set_deposit = this.deposit;
        }
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        if(error.response.data && error.response.data.data && error.response.data.data.message){
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }else{
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        }
      })
    },
    chartHoverCallback (index, options, content, row) {
      let colors = ['#fc4141','#ae4322','#ffb28d','#fcb7ff','#eee33f'];
      return "<div class='morris-hover-row-label'>"+row.month+"</div><div class='morris-hover-point' style='color: "+colors[0]+"'>\n" +
        "  YieldNodes (Compounded):\n" +
        "€" + row.profit_c.toLocaleString() + "\n" +
        "</div><div class='morris-hover-point' style='color: "+colors[1]+"'>\n" +
        "  YieldNodes:\n" +
        "€" + row.profit.toLocaleString() + "\n" +
        "</div><div class='morris-hover-point' style='color: "+colors[2]+"'>\n" +
        "  BTC:\n" +
        "€" + row.BTC.toLocaleString() + "\n" +
        "</div><div class='morris-hover-point' style='color: "+colors[3]+"'>\n" +
        "  Dow Jones:\n" +
        "€" + row.DJI.toLocaleString() + "\n" +
        "</div><div class='morris-hover-point' style='color: "+colors[4]+"'>\n" +
        "  Gold:\n" +
        "€" + row.XAU.toLocaleString() + "\n" +
        "</div>";
    }

  }
}
</script>
