<template>
  <div class="kt-portlet kt-portlet--height-fluid">
    <div class="kt-portlet__head">
      <div class="kt-portlet__head-label">
        <h3 class="kt-portlet__head-title">
          {{ $t('messages.title') }}
        </h3>
      </div>
      <div class="kt-portlet__head-toolbar"></div>
    </div>
    <div class="kt-portlet__body">
      <Loader :loading="isLoading">
        <div class="kt-notification mb-3">
          <template v-for="message in messages">
            <router-link :to="{name: 'message', params: {messageId: message.id}}" class="kt-notification__item">
              <div class="kt-notification__item-details">
                <div class="kt-notification__item-title">
                  {{ message.subject }}
                </div>
                <div class="kt-notification__item-time">
                  {{ message.date }}
                </div>
              </div>
            </router-link>
          </template>
        </div>
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
          ></b-pagination>
        </div>
      </Loader>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Loader from '../components/global/Loader'

  export default {
    name: 'MessagesPage',
    components: {
      Loader,
    },
    data () {
      return {
        messages: {},
        isLoading: false,
        currentPage: 1,
        totalRows: null,
        perPage: null
      }
    },
    computed: {
      ...mapState({
        profile: state => state.authUser.profile
      })
    },
    created () {
      this.loadMessages()
    },
    watch: {
      currentPage: function(){
        this.loadMessages();
      }
    },
    methods: {
      loadMessages () {
        this.isLoading = true
        axios.get(this.$api.messages + '?page=' + this.currentPage).then(response => {
          this.messages = response.data.data
          this.perPage = response.data.meta.per_page;
          this.totalRows = response.data.meta.total;
          this.isLoading = false
        }).catch(error => {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.isLoading = false
        })
      },
    }
  }
</script>
