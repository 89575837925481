<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      cancel-disabled
      ok-disabled
      size="lg"
      :title="modalTitle || this.$t('withdraw.more_info_title')"
      @hidden="hideModal"
      header-close-content=""
    >
      <div class="text-center kt-margin-b-10">
        <a href="https://www.youtube.com/watch?v=-Jyvpm75-uY" target="_blank" class="vt"><i class="fab fa-youtube"></i> {{ $t('deposit.tutorial') }}</a>
      </div>

      <div v-html="$t('withdraw.more_info_details')"></div>

      <div slot="modal-footer">
        <div class="text-right">
          <button type="button" class="btn btn-default" @click="$emit('done')">{{ $t('general.close') }}</button>
        </div>
      </div>
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
// import DropDown from '../functional/Dropdown'
import Loader from '../global/Loader'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'
import AccountInput from '../forms/AccountInput'

export default {
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String
    },
  },
  data () {
    return {
      modalOpen: this.show,
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  watch: {
    show (val) {
      this.modalOpen = val
    }
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
  }
}
</script>
