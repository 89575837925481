<template>
  <div class="row login-pages">
    <div class="col-md-6 offset-md-3 login-wrapper">
      <div
        style="background: #fff; padding: 20px; padding-bottom: 100px;"
      >
        <h4>
          {{ $t('blocked.title') }}
        </h4>
        <p>
          {{ $t('blocked.details') }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BlockedPage',
  components: {},
  data () {
    return {}
  }
}
</script>
