<template>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ $t('profile.note_title') }}</h3>
          </div>
        </div>

        <NotificationsForm />
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ $t('profile.password_title') }}</h3>
          </div>
        </div>
        <PasswordChangeForm />
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ $t('profile.privacy_title') }}</h3>
          </div>
        </div>

        <PrivacyForm />
      </div>
    </div>
  </div>
</template>

<script>
import PasswordChangeForm from '../components/Account/PasswordChangeForm'
import NotificationsForm from '../components/Account/NotificationsForm'
import PrivacyForm from '../components/Account/PrivacyForm'
export default {
  name: 'PreferencesPage',
  components: {
    PasswordChangeForm,
    NotificationsForm,
    PrivacyForm
  }
}
</script>
