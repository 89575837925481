<template>
  <Loader :loading="isLoading">
    <div class="kt-portlet">
      <b-form @submit.prevent="checkMfa" class="kt-form">
        <div class="kt-portlet__body kt-margin-b-0">
          <h5 class="kt-margin-b-15">{{ $t('mfa.mode_title') }}:</h5>
          <div class="form-group kt-margin-b-0">
            <div class="kt-radio-list">
              <label class="kt-radio">
                <input
                  v-model="mfaType"
                  type="radio"
                  value="disable"
                >
                {{ $t('mfa.deactivate') }}
                <span />
              </label>
              <label class="kt-radio">
                <input
                  v-model="mfaType"
                  type="radio"
                  value="simple"
                >
                {{ $t('mfa.mode_important') }}
                <span />
              </label>
              <label class="kt-radio">
                <input
                  v-model="mfaType"
                  type="radio"
                  value="full"
                >
                {{ $t('mfa.mode_all') }}
                <span />
              </label>
            </div>
          </div>
          <div class="kt-margin-t-15">
            <button type="submit" :disabled="mfaType === initialMfaType" class="btn btn-primary">{{ $t('general.save') }}</button>
          </div>
        </div>
      </b-form>
    </div>
    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="saveSettings"
      @hidden="hideMfa"
    />
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import requireMfa from '../../mixins/requiresMfa'

export default {
  name: 'MfaSettingsForm',
  components: { Loader },
  mixins: [ requireMfa ],
  data () {
    return {
      isLoading: false,
      showMfa: false,
      mfaType: null,
      initialMfaType: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
    if (this.profile.mfa_enabled) {
      if (this.profile.mfa_simple) {
        this.mfaType = 'simple'
      } else {
        this.mfaType = 'full'
      }
    } else {
      this.mfaType = 'disable'
    }
    this.initialMfaType = this.mfaType;
  },
  methods: {
    checkMfa () {
      if (this.profile.mfa_method) {
        this.verifyMfa('mfa_settings')
      } else {
        this.saveSettings()
      }
    },
    saveSettings (token = null) {
      this.showMfa = false
      this.isLoading = true
      axios.patch(this.$api.mfa, { mfa_type: this.mfaType, token }).then(response => {
        this.initialMfaType = this.mfaType;
        this.$store.dispatch('setAuthUser')
        this.$store.dispatch('showSuccessNotification', this.$t('mfa.mode_success'))
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    }
  }
}
</script>
