<template>
  <div
    v-if=""
    class="alert alert-danger"
    role="alert"
  >
    <div class="alert-text">
      <i class="fas fa-exclamation-triangle"></i>
      &nbsp;<span>Your account under surveillance due to "YNL" malware use!</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'YNLWarning',
  components: {
  },
  props: {
  },
  computed: {

  }
}
</script>
