<template>
  <Loader :loading="isLoading">
    <form
        novalidate="novalidate"
        class="kt-form kt-form--label-right"
        @submit.prevent="updateDiscord"
    >
      <div class="kt-portlet__body">
        <div class="kt-section kt-section--first kt-margin-b-0">
          <div class="kt-section__body">
            <AccountInput
                id="discord"
                v-model="formData.discord"
                name="discord"
                :errors="errors"
                label="Discord username"
                placeholder="username#12345"
                :cols="3"
                :disabled="profile.discord_checked === 1"
                description='Enter your discord username with id, for example: "<b>myusername#12345</b>"'
            />
          </div>
        </div>
      </div>
      <div class="kt-portlet__foot">
        <div class="kt-form__actions">
          <div class="row">
            <div class="col-lg-3 col-xl-3">
            </div>
            <div class="col-lg-9 col-xl-9">
              <button type="submit" class="btn btn-success" :disabled="profile.discord_checked === 1">Save</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import AccountInput from '../forms/AccountInput'
import Loader from '../global/Loader'
import formHasErrors from '../../mixins/formHasErrors'

export default {
  name: 'DiscordForm',
  components: {
    AccountInput,
    Loader
  },
  mixins: [
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      formField: 'formData',
      formData: {
        discord: null
      }
    }
  },
  watch: {
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  mounted () {
    this.formData.discord = this.profile.discord;
  },
  methods: {
    updateDiscord () {
      this.clearErrors()
      this.isLoading = true

      axios.post(this.$api.updateUserProfile + '/discord', this.formData)
        .then((response) => {
          this.isLoading = false
          this.$store.dispatch('setAuthUser')
          this.$store.dispatch('showSuccessNotification', 'Success')
        }).catch((error) => {
          this.isLoading = false
          if (error.response.data) {
            if (error.response.status === 422) {
              this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
              this.setErrors(error.response.data.errors)
            }else if(error.response.status === 400 && error.response.data && error.response.data.data.message){
              this.$store.dispatch('showErrorNotification', error.response.data.data.message)
            }
          }
        })
    },
  }
}
</script>
