<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <router-view :key="$route.fullPath" />
  </transition>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ReferralsPage',
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  }
}
</script>
