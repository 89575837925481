<template>
  <div>
    <div
      class="boxs"
      :class="boxClass"
    >
      <h3>{{ title }}</h3>
    </div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'TransparentBox',
  props: {
    boxClass: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    }
  }
}
</script>
