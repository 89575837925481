<template>
  <Loader :loading="isLoading">
    <div class="text-center">
      <p>Due to external data attacks we need to secure our systems and can not store these data anymore.</p>
      <p>As all of your transactions are always accessible on blockchains, you can comprehend these at any time.</p>
    </div>
    <!--<div class="table-responsive">
      <table class="table table-striped table-bordered table-hover" id="deposit_table">
        <thead>
          <tr>
            <th nowrap>{{ $t('deposit.activity_type') }}</th>
            <th nowrap>{{ $t('deposit.activity_status') }}</th>
            <th nowrap>{{ $t('deposit.activity_amount_initial') }}</th>
            <th nowrap>{{ $t('deposit.activity_amount_final') }}</th>
            <th nowrap>{{ $t('deposit.activity_date') }}</th>
            <th nowrap>{{ $t('deposit.activity_date_noded') }}</th>
            <th nowrap>{{ $t('deposit.activity_date_release') }}</th>
          </tr>
        </thead>
        <tbody v-if="deposits">
          <tr v-for="deposit in deposits">
            <td nowrap>{{ deposit.operation }}<template v-if="deposit.deposit_type"> ({{ deposit.deposit_type }})</template> <a v-if="deposit.invoice_pay" :href="deposit.invoice_pay" target="_blank" :title="$t('deposit.activity_open_invoice')"><i class="fas fa-file-invoice-dollar"></i></a></td>
            <td nowrap>
              <span
                class="kt-badge  kt-badge--inline kt-badge--pill"
                :class="{
                  'kt-badge--danger': deposit.status === 'cancelled',
                  'kt-badge--success': deposit.status === 'approved',
                  'kt-badge--warning': deposit.status === 'pending',
                }"
              >
                 {{ deposit.status_display === 'Idle' ? $t('deposit.status_idle') : $t('deposit.status_' + deposit.status) }}
              </span>
            </td>
            <td nowrap>{{ deposit.amount_initial }}</td>
            <td nowrap class="text-bold">{{ deposit.amount }}</td>
            <td nowrap>{{ deposit.date.display }}</td>
            <td nowrap>{{ deposit.traded_since ? deposit.traded_since.display : '' }}</td>
            <td nowrap :class="{ 'kt-font-bold kt-font-success': deposit.released }">{{ deposit.release_date ? deposit.release_date.display : '' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
    ></b-pagination>-->
  </Loader>
</template>

<script>
import Loader from "../global/Loader";
import CJSAesJson from "@/vendor/cjsf";

export default {
  name: 'DepositActivity',
  components: {
    Loader
  },
  data () {
    return {
      deposits: [],
      currentPage: 1,
      totalRows: null,
      perPage: null,
      isLoading: false
    }
  },
  created () {
    this.getHistory()
  },
  watch: {
    currentPage: function(){
      this.getHistory();
    }
  },
  methods: {
    getHistory () {
      /*this.isLoading = true;
      axios.get(this.$api.deposit + '/index?page=' + this.currentPage).then(response => {
        this.isLoading = false;

        let checksum = response.headers['content-checksum'];
        let data = CJSAesJson.decrypt(response.data.data, checksum);

        this.deposits = data.data;
        this.perPage = data.meta.per_page;
        this.totalRows = data.meta.total;
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.isLoading = true;
      })*/
    }
  }
}
</script>
