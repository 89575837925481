<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('withdraw.emergency_withdrawal_title')"
      @hidden="hideModal"
      size="lg"
      no-close-on-backdrop
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <div>
          <div
            v-if="hasError"
            class="alert alert-danger"
          >
            <div class="alert-text">
              <i class="fas fa-exclamation-circle"></i>
              {{ errorMsg }}
            </div>
          </div>

          <div v-html="$t('withdraw.emergency_note',{ commission: commission })"></div>

          <hr class="kt-margin-t-20">

          <template v-if="!emergencyWithdrawalAllowed">
            <div
              class="alert alert-danger"
              role="alert"
            >
              <div class="alert-text">
                <h4 class="alert-heading">{{ $t('withdraw.emergency_not_allowed') }}</h4>
                <p v-if="emergencyDisallowedReason">{{ emergencyDisallowedReason }}</p>
              </div>
            </div>
          </template>
          <template v-else>

            <p v-if="balance.combined" class="lead kt-font-bold">{{ $t('withdraw.emergency_combined') }}: {{ balance.combined_display }}</p>
            <p v-if="balance.withdrawable" class="lead kt-font-bold">{{ $t('withdraw.emergency_withdrawable',{ commission: commission }) }}: {{ balance.withdrawable_display }}</p>

            <form @submit.prevent="emergencyWithdrawCheckMfa" class="kt-margin-t-30 kt-margin-b-20">
              <div class="kt-margin-b-20">
                <b-form-select v-model="values.wallet" :options="walletsApproved">
                  <template #first>
                    <b-form-select-option :value="null" disabled v-text="!walletsApproved?$t('withdraw.select_placeholder_empty'):$t('withdraw.select_placeholder',{ currency: 'BTC' })"></b-form-select-option>
                  </template>
                </b-form-select>
                <span
                  v-if="errors.hasOwnProperty('wallet')"
                  class="invalid-feedback"
                  :style="{display: errors.hasOwnProperty('wallet') ? 'block' : 'none'}"
                >{{ errors.wallet[0] }}</span>
              </div>

              <div class="form-group">
                <label class="kt-checkbox" style="font-weight: bold;">
                  <input
                    v-model="values.agreed"
                    type="checkbox"
                    class="form-control"
                    value="1"
                  >
                  {{ $t('withdraw.emergency_checkbox',{ commission: commission }) }}
                  <span></span>
                </label>
                <span
                  v-if="errors.hasOwnProperty('agreed')"
                  class="invalid-feedback"
                  :style="{display: errors.hasOwnProperty('agreed') ? 'block' : 'none'}"
                >{{ errors.agreed[0] }}</span>
              </div>
              <div class="row">
                <div class="col-md-offset-3 col-md-6">
                  <button
                    :disabled="profile.limited_mode"
                    type="submit" class="btn btn-primary">{{ $t('withdraw.emergency_submit') }}</button>
                </div>
              </div>
            </form>
          </template>
        </div>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button
              @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>

    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="withdraw"
      @hidden="hideMfa"
    />

  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import requiresMfa from "@/mixins/requiresMfa";
import formHasErrors from "@/mixins/formHasErrors";

export default {
  name: 'EmergencyWithdrawalModal',
  components: {
    Loader
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMfa: false,
      isLoading: true,
      modalOpen: this.show,
      hasError: false,
      errorMsg: null,
      voucher: null,
      errors: null,
      balance: {
        allowed: true,
        reason: null,
        withdrawable: null,
        withdrawable_display: null,
        combined: null,
        combined_display: null,
      },
      commission: 25,
      wallets: [],
      values: {
        wallet: null,
        agreed: false
      },
      action: "emergency_withdrawal"
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    emergencyWithdrawalAllowed(){
      return this.balance.allowed;
    },
    emergencyDisallowedReason(){
      return this.balance.reason;
    },
    walletsApproved () {
      let approved = []
      this.wallets.forEach(item => {
        approved.push({
          text: item.title + '[' + item.currency + ']',
          value: item.id
        })
      })
      return approved
    },
  },
  created () {
    this.loadBalances();
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    loadBalances() {
      this.isLoading = true
      axios.get(this.$api.emergencyWithdrawBalances).then(response => {
        if(response.data.data.commission) {
          this.commission = response.data.data.commission;
        }
        if(response.data.data.balance) {
          this.balance = response.data.data.balance;
        }else{
          this.balance.allowed = false;
          this.balance.reason = this.$t('general.error');
        }
        if(response.data.data.wallets) {
          this.wallets = response.data.data.wallets;
        }
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.balance.allowed = false;
        this.balance.reason = this.$t('general.unknown_error')
      })
    },
    emergencyWithdrawCheckMfa() {
      this.clearErrors()

      if (!this.values.wallet) {
        this.addError('wallet', this.$t('withdraw.emergency_wallet_not_selected'), true)
        return false
      }
      if (!this.values.agreed) {
        this.addError('agreed', this.$t('withdraw.emergency_not_agreed'), true)
        return false
      }

      if (this.profile.mfa_enabled) {
        this.showMfa = true;
        this.isLoading = true
      }else {
        this.withdraw();
      }
    },
    withdraw (token = null) {
      this.showMfa = false
      this.isLoading = true
      this.hasError = false
      this.errorMsg = null
      axios.post(this.$api.emergencyWithdraw, {
        wallet: this.values.wallet,
        agreed: this.values.agreed,
        token
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', this.$t('withdraw.emergency_success'))
        this.$emit('done');
        this.hideModal();
      }).catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.errorMsg = error.response.data.data.message;
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else if (error.response.status === 422) {
          let errors = error.response.data.errors
          this.$store.dispatch('showErrorNotification', errors[Object.keys(errors)[0]][0])
          this.errorMsg = error.errors[Object.keys(errors)[0]][0];
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.errorMsg = this.$t('general.error')
        }
      })
    },
    hideMfa () {
      this.isLoading = false;
      this.showMfa = false
    }

  }
}
</script>
