<template>
  <Loader
    :loading="isLoading"
  >
    <b-form @submit.prevent="updatePrivacy">
      <div class="kt-portlet__body">
        <div class="form-group">

          <div class="kt-checkbox-list">
            <label class="kt-checkbox">
              <input
                v-model="privacy.s_referral_info"
                type="checkbox"
              >
              {{ $t('profile.privacy_referral_info') }}
              <span class="checkmark"/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="privacy.s_referral_balance"
                type="checkbox"
              >
              {{ $t('profile.privacy_referral_balance') }}
              <span/>
            </label>
          </div>

        </div>
      </div>
      <div class="kt-portlet__foot">
        <div class="kt-form__actions">
          <div class="row">
            <div class="col-xl-12">
              <button type="submit" class="btn btn-success">{{ $t('profile.save') }}</button>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Loader>
</template>

<script>
  import Loader from '../global/Loader'

  export default {
    name: 'PrivacyForm',
    components: {
      Loader
    },
    data () {
      return {
        privacy: {
          s_referral_info: null,
          s_referral_balance: null,
        },
        isLoading: false
      }
    },
    created () {
      this.loadPrivacy()
    },
    methods: {
      loadPrivacy () {
        this.isLoading = true
        axios.get(this.$api.privacy).then(response => {
          this.privacy = response.data.data
          this.isLoading = false
        }).catch(e => {
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
          this.isLoading = false
        })
      },
      updatePrivacy () {
        this.isLoading = true
        axios.patch(this.$api.privacy, this.privacy).then(response => {
          this.$store.dispatch('showSuccessNotification', this.$t('profile.privacy_success'))
          this.isLoading = false
        }).catch(error => {
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
          this.isLoading = false
        })
      }
    }
  }
</script>
