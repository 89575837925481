<template>
  <div
    v-if="optionsFormatted"
    v-click-outside="close"
    class="custom-select"
    :class="{'custom-select2': small}"
  >
    <div
      class="select-selected"
      :class="{'select-arrow-active': open}"
      @click="toggleOpen"
    >
      {{ selectedName }}
    </div>
    <div
      class="select-items"
      :class="{'select-hide': !open}"
    >
      <div
        v-for="option in optionsFormatted"
        :key="option.value"
        :class="{'same-as-selected': option.value === selected}"
        @click="select(option.value)"
      >
        {{ option.name }}
      </div>
      <slot @click="toggleOpen" />
    </div>
  </div>
</template>

<script>
import ClickOutside from '../../helpers/clickOutside'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import isNumber from 'lodash/isNumber'
import head from 'lodash/head'
import last from 'lodash/last'

export default {
  name: 'Dropdown',
  directives: {
    ClickOutside
  },
  props: {
    options: {
      type: Array,
      required: true
    },
    value: {
      type: [String, Number],
      default: null
    },
    small: {
      type: Boolean,
      default: false
    },
    defaultLast: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selected: this.value,
      open: false
    }
  },
  computed: {
    selectedName () {
      let selectedEl = this.findOption(this.selected)
      return selectedEl ? selectedEl.name : ''
    },
    optionsFormatted () {
      let formatted = []
      this.options.forEach(item => {
        if (isObject(item)) {
          formatted.push(item)
        } else if (isNumber(item) || isString(item)) {
          formatted.push({ name: item, value: item })
        }
      })
      return formatted
    }
  },
  watch: {
    value (newValue) {
      if (this.findOption(newValue)) {
        this.selected = newValue
      }
    },
    selected (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', this.selected)
      }
    },
    optionsFormatted (newValue, oldValue) {
      if (newValue !== oldValue && !this.selected && this.optionsFormatted) {
        let first = head(this.optionsFormatted)
        if (first && 'value' in first) {
          this.selected = first.value
        }
      }
    }
  },
  mounted () {
    if (!this.value && this.optionsFormatted.length > 0) {
      if (this.defaultLast) {
        this.selected = last(this.optionsFormatted).value
      } else {
        this.selected = head(this.optionsFormatted).value
      }
    }
  },
  methods: {
    toggleOpen () {
      this.open = !this.open
    },
    select (value) {
      this.selected = value
      this.open = false
    },
    close () {
      this.open = false
    },
    findOption (value) {
      return this.optionsFormatted.find((el) => el.value === value)
    }
  }
}
</script>
