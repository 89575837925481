<template>
  <div class="form-row form-group">
    <label class="col-form-label col-xl-3 col-lg-3" :for="prefix + '_day'">
      {{ label }}
    </label>
    <div class="col-lg-9 col-xl-6 form-inline">
      <select
        :id="prefix + '_day'"
        v-model="date.birthday_day"
        class="form-control col bday-select"
        :class="{'is-invalid': hasErrors}"
        @change="handleInput"
      >
        <option
          disabled
          :value="null"
          selected
          hidden
        >
          {{ $t('profile.day') }}
        </option>
        <option
          v-for="n in 31"
          :key="n"
          :value="n"
        >
          {{ n }}
        </option>
      </select>
      <select
        :id="prefix + '_month'"
        v-model="date.birthday_month"
        class="form-control col ml-1 bday-select"
        :class="{'is-invalid': hasErrors}"
        @change="handleInput"
      >
        <option
          disabled
          :value="null"
          selected
          hidden
        >
          {{ $t('profile.month') }}
        </option>
        <option
          v-for="(month, key) in months"
          :key="key"
          :value="key"
        >
          {{ month }}
        </option>
      </select>
      <select
        :id="prefix + '_year'"
        v-model="date.birthday_year"
        class="form-control col ml-1 bday-select"
        :class="{'is-invalid': hasErrors}"
        @change="handleInput"
      >
        <option
          disabled
          :value="null"
          selected
          hidden
        >
          {{ $t('profile.year') }}
        </option>
        <option
          v-for="year in range(1900,(new Date().getFullYear()) + 1)"
          :key="year"
          :value="year"
        >
          {{ year }}
        </option>
      </select>
    </div>

    <div
      v-if="hasErrors"
      class="invalid-feedback"
      style="display: block;"
    >
      <div v-for="error in errorMessages">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
import range from 'lodash/range'

export default {
  name: 'BirthdayInput',
  components: {},
  props: {
    label: {
      type: String,
      default: 'Birthday'
    },
    value: {
      type: Object,
      default: () => {
        return { birthday_day: null, birthday_month: null, birthday_year: null }
      }
    },
    prefix: {
      type: String,
      default: 'birthday'
    },
    errors: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data () {
    return {
      date: this.value,
      months: {
        1: this.$t('profile.jan'),
        2: this.$t('profile.feb'),
        3: this.$t('profile.mar'),
        4: this.$t('profile.apr'),
        5: this.$t('profile.may'),
        6: this.$t('profile.jun'),
        7: this.$t('profile.jul'),
        8: this.$t('profile.aug'),
        9: this.$t('profile.sep'),
        10: this.$t('profile.oct'),
        11: this.$t('profile.nov'),
        12: this.$t('profile.dec')
      }
    }
  },
  computed: {
    hasErrors: {
      cache: false,
      get () {
        let hasErrors = false;
        ['day', 'month', 'year'].forEach(item => {
          let hasError = this.errors.hasOwnProperty(this.prefix + '_' + item) &&
            Array.isArray(this.errors[this.prefix + '_' + item]) &&
            this.errors[this.prefix + '_' + item].length > 0
          if (hasError) {
            hasErrors = true
          }
        })
        return hasErrors
      }
    },
    errorMessages () {
      if (this.hasErrors) {
        let errors = [];
        ['day', 'month', 'year'].forEach(item => {
          if (this.errors.hasOwnProperty(this.prefix + '_' + item)) {
            errors.push(this.errors[this.prefix + '_' + item][0])
          }
        })
        return errors
      }
      return null
    }
  },
  watch: {
    value (newValue) {
      this.date = newValue
    }
  },
  methods: {
    handleInput (value, el) {
      this.$nextTick(() => {
        this.$emit('input', this.date)
      })
    },
    range
  }
}
</script>
