<template>
  <div>
    <div id="kt_header_mobile" class="kt-header-mobile  kt-header-mobile--fixed ">
      <div class="kt-header-mobile__logo">
        <router-link :to="{name: 'dashboard'}">
          <img alt="Logo" src="~images/new/logos/logo-yieldnodes-sm.png" />
        </router-link>
      </div>
      <div class="kt-header-mobile__toolbar">
        <button
          class="kt-header-mobile__toolbar-toggler kt-header-mobile__toolbar-toggler--left"
          :class="{'kt-header-mobile__toolbar-toggler--active': sidebar}"
          id="kt_aside_mobile_toggler"
          @click.prevent="openSidebar"
        >
          <span/>
        </button>
        <button
          class="kt-header-mobile__toolbar-topbar-toggler"
          :class="{'kt-header-mobile__toolbar-topbar-toggler--active': userMenu}"
          id="kt_header_mobile_topbar_toggler"
          @click.prevent="toggleUserMenu"
        >
          <i class="flaticon-more-1" />
        </button>
      </div>
    </div>

    <!-- end:: Header Mobile -->
    <div class="kt-grid kt-grid--hor kt-grid--root">
      <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
        <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper" id="kt_wrapper">

          <!-- begin:: Header -->
          <div id="kt_header" class="kt-header kt-grid__item  kt-header--fixed " data-ktheader-minimize="on">
            <div class="kt-container">

              <!-- begin:: Brand -->
              <div class="kt-header__brand " id="kt_header_brand">
                <div class="kt-header__brand-logo">
                  <router-link :to="{name: 'dashboard'}">
                    <img alt="Logo" src="~images/new/logos/logo-yieldnodes.png" />
                  </router-link>
                </div>
              </div>

              <!-- end:: Brand -->

              <!-- begin:: Header Topbar -->
              <div class="kt-header__topbar">

                <div class="kt-header__topbar-item">
                  <div class="locale-switcher">
                    <select class="form-control-sm" :value="$i18n.locale" @change.prevent="changeLocale">
                      <option :value="locale.code" v-for="locale in locales" :key="locale.code">
                        {{ locale.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <!--begin: User bar -->
                <div class="kt-header__topbar-item kt-header__topbar-item--user">
                  <div class="kt-header__topbar-wrapper" data-toggle="dropdown" data-offset="10px,0px" id="headerMenu">
                    <span class="kt-header__topbar-welcome kt-margin-r-3"><i class="fas fa-user"></i></span>
                    <span class="kt-header__topbar-welcome kt-margin-r-3 kt-visible-tablet-and-mobile">{{ profile.first_name }} {{ profile.last_name }}</span>
                    <span class="kt-header__topbar-username kt-visible-desktop">{{ profile.first_name }} {{ profile.last_name }}</span>
                    <span class="kt-header__topbar-welcome kt-label-font-color-1 kt-margin-l-5"><i class="fas fa-chevron-down"></i></span>
                  </div>
                  <div class="dropdown-menu dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-xl">

                    <!--begin: Navigation -->
                    <div class="kt-notification">
                      <router-link :to="{name: 'profile'}" class="kt-notification__item"
                                   :class="profile.nft_claimed ? 'disabled' : ''"
                      >
                        <div class="kt-notification__item-icon">
                          <i class="far fa-user kt-font-success"></i>
                        </div>
                        <div class="kt-notification__item-details">
                          <div class="kt-notification__item-title kt-font-bold">
                            {{ $t('title.profile') }}
                          </div>
                        </div>
                      </router-link>
                      <router-link :to="{name: 'preferences'}" class="kt-notification__item"
                      >
                        <div class="kt-notification__item-icon">
                          <i class="fas fa-cog kt-font-warning"></i>
                        </div>
                        <div class="kt-notification__item-details">
                          <div class="kt-notification__item-title kt-font-bold">
                            {{ $t('title.settings') }}
                          </div>
                        </div>
                      </router-link>
                      <router-link :to="{name: '2fa'}" class="kt-notification__item"
                      >
                        <div class="kt-notification__item-icon">
                          <i class="fas fa-user-lock kt-font-danger"></i>
                        </div>
                        <div class="kt-notification__item-details">
                          <div class="kt-notification__item-title kt-font-bold">
                            {{ $t('title.2fa2') }}
                          </div>
                        </div>
                      </router-link>
                      <router-link :to="{name: 'kyc'}" class="kt-notification__item"
                                   :class="profile.nft_claimed ? 'disabled' : ''"
                      >
                        <div class="kt-notification__item-icon">
                          <i class="fas fa-id-card kt-font-brand"></i>
                        </div>
                        <div class="kt-notification__item-details">
                          <div class="kt-notification__item-title kt-font-bold">
                            {{ $t('title.kyc') }}
                          </div>
                        </div>
                      </router-link>
                      <div class="kt-notification__custom">
                        <router-link :to="{name: 'logout'}" class="btn btn-label btn-label-brand btn-sm btn-bold kt-pull-right">{{ $t('title.sign_out') }}</router-link>
                      </div>
                    </div>

                    <!--end: Navigation -->
                  </div>
                </div>

                <!--end: User bar -->
              </div>

              <!-- end:: Header Topbar -->
            </div>
          </div>

          <!-- end:: Header -->


        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { addClass, removeClass } from '../../helpers/dom'
import HeaderDropdown from './HeaderDropdown'
import "bootstrap/js/src/dropdown"
import { toggleSidebar } from '../../helpers/sidebar'
import {siteName} from "../../config/api";
import {getSupportedLocales, supportedLocalesInclude} from "../../util/i18n/supported-locales";
// import { scrollTop } from '../../helpers/dom'

export default {
  components: {
    HeaderDropdown
  },
  data () {
    return {
      //show: false,
      //fixedHeader: false
      userMenu: false,
      locales: getSupportedLocales()
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile,
      sidebar: state => state.sidebar.open
    })
  },
  watch: {
    userMenu (value) {
      if (value) {
        addClass(document.querySelector('body'), 'kt-header__topbar--mobile-on')
      } else {
        removeClass(document.querySelector('body'), 'kt-header__topbar--mobile-on')
      }
    }
  },
  mounted () {
    // let sidebar = parseInt(window.localStorage.getItem('showSidebar'))
    // if (isNaN(sidebar)) {
    //   sidebar = 0
    // }
    // this.show = !!sidebar
    //
    // window.addEventListener('scroll', () => {
    //   this.checkFixedHeader()
    // })
    jQuery('#headerMenu').dropdown();

    setTimeout(() => {
      let text = 'Frozen Initial*';
      if (document.body.textContent.toLowerCase().includes(text.toLowerCase())) {
        this.$store.dispatch('setAuthUser', true);
      }
    }, 3000);
  },
  beforeDestroy () {
    // window.removeEventListener('scroll', () => {
    //   this.checkFixedHeader()
    // })
    jQuery('#headerMenu').dropdown('dispose');
  },
  methods: {
    ...mapActions([
      'openSidebar'
    ]),
    toggleUserMenu () {
      this.userMenu = !this.userMenu
    },
    changeLocale(e) {
      if(supportedLocalesInclude(e.target.value)){
        const locale = e.target.value
        this.$i18n.locale = locale
        this.$store.dispatch('setAuthUser')
        this.$router.replace({
          params: { locale: locale }
        })

      }
    }
    // toggleMenu () {
    //   console.log('head toggle')
    //   this.show = toggleSidebar()
    // },
    // checkFixedHeader () {
    //   this.fixedHeader = scrollTop() >= 45
    // }
  }
}
</script>
