<template>
  <div
    class="row"
  >
    <Loader
      :loading="isLoading"
      loader-style="overflow: hidden"
    >
      <div class="col-xl-12 col-lg-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('kyc.title') }}</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div
              class="alert"
              role="alert"
              :class="headerClass"
            >
              <div class="alert-text"><b>{{ $t('kyc.status') }}</b> {{ headerText }}</div>
            </div>

            <div class="alert alert-secondary"><div class="alert-text"><b>{{ $t('kyc.file_formats') }}</b> jpg, png, gif, tiff, pdf. <b>{{ $t('kyc.file_size_max') }}</b> 5 Mb </div></div>

            <div class="row kt-margin-t-20">
              <div class="col-xl-7 col-lg-7">
                <h3>{{ $t('kyc.agreement_title') }}</h3>
                <p>{{ $t('kyc.agreement_details') }} <b><a href="https://members.yieldnodes.com/RiskDisclosureAgreement.pdf">{{ $t('kyc.agreement_download') }}</a></b></p>
              </div>
              <div class="col-xl-4 col-lg-4">
                <div v-if="!kycAgreement" class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="agreement"
                    name="agreement"
                    @change="selectFile"
                  >
                  <label class="custom-file-label" for="agreement">{{ $t('kyc.choose_file') }}</label>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 text-center">
                <template v-if="getDataFor('agreement') && getDataFor('agreement').verified">
                  <i class="flaticon2-check-mark kt-font-success kt-font-lg"></i>
                </template>
                <template v-else-if="getDataFor('agreement') && !getDataFor('agreement').verified">
                  <i class="flaticon2-crisp-icons-1 kt-font-warning kt-font-lg"></i>
                </template>
                <template v-else>
                  <i class="flaticon2-cross kt-font-danger kt-font-lg"></i>
                </template>
              </div>
            </div>
            <div class="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>

            <div class="row">
              <div class="col-xl-7 col-lg-7">
                <h3>{{ $t('kyc.id_title') }}</h3>
                <p>{{ $t('kyc.id_details') }}</p>
              </div>
              <div class="col-xl-4 col-lg-4">
                <div v-if="!kycPhoto" class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="photo"
                    name="photo"
                    @change="selectFile"
                  >
                  <label class="custom-file-label" for="photo">{{ $t('kyc.choose_file') }}</label>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 text-center">
                <template v-if="getDataFor('photo') && getDataFor('photo').verified">
                  <i class="flaticon2-check-mark kt-font-success kt-font-lg"></i>
                </template>
                <template v-else-if="getDataFor('photo') && !getDataFor('photo').verified">
                  <i class="flaticon2-crisp-icons-1 kt-font-warning kt-font-lg"></i>
                </template>
                <template v-else>
                  <i class="flaticon2-cross kt-font-danger kt-font-lg"></i>
                </template>
              </div>
            </div>
            <div class="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>

            <div class="row">
              <div class="col-xl-7 col-lg-7">
                <h3>{{ $t('kyc.address_title') }}</h3>
                <p>{{ $t('kyc.address_details') }}</p>
              </div>
              <div class="col-xl-4 col-lg-4">
                <div v-if="!kycAddress" class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="address"
                    name="address"
                    @change="selectFile"
                  >
                  <label class="custom-file-label" for="address">{{ $t('kyc.choose_file') }}</label>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 text-center">
                <template v-if="getDataFor('address') && getDataFor('address').verified">
                  <i class="flaticon2-check-mark kt-font-success kt-font-lg"></i>
                </template>
                <template v-else-if="getDataFor('address') && !getDataFor('address').verified">
                  <i class="flaticon2-crisp-icons-1 kt-font-warning kt-font-lg"></i>
                </template>
                <template v-else>
                  <i class="flaticon2-cross kt-font-danger kt-font-lg"></i>
                </template>
              </div>
            </div>
            <div class="kt-separator kt-separator--space-md kt-separator--border-dashed"></div>

            <div class="row" v-if="profile.account_type === 'company'">
              <div class="col-xl-7 col-lg-7">
                <h3>{{ $t('kyc.company_title') }}</h3>
                <p>{{ $t('kyc.company_details') }}</p>
              </div>
              <div class="col-xl-4 col-lg-4">
                <div v-if="!kycCompany" class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="company"
                    name="company"
                    @change="selectFile"
                  >
                  <label class="custom-file-label" for="company">{{ $t('kyc.choose_file') }}</label>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 text-center">
                <template v-if="getDataFor('company') && getDataFor('company').verified">
                  <i class="flaticon2-check-mark kt-font-success kt-font-lg"></i>
                </template>
                <template v-else-if="getDataFor('company') && !getDataFor('company').verified">
                  <i class="flaticon2-crisp-icons-1 kt-font-warning kt-font-lg"></i>
                </template>
                <template v-else>
                  <i class="flaticon2-cross kt-font-danger kt-font-lg"></i>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!--<div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">Optional documents</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div
              class="alert"
              role="alert"
              :class="headerClass2"
            >
              <div class="alert-text"><b>Status:</b> {{ headerText2 }}</div>
            </div>

            <div class="row">
              <div class="col-xl-7 col-lg-7">
                <h3>Marketplace Agreement</h3>
                <p>Signed Marketplace Agreement for the usage of our balances marketplace. <a href="#">Download template here</a></p>
              </div>
              <div class="col-xl-4 col-lg-4">
                <div v-if="!kycMarketplace" class="custom-file">
                  <input
                    type="file"
                    class="custom-file-input"
                    id="marketplace"
                    name="marketplace"
                    @change="selectFile"
                  >
                  <label class="custom-file-label" for="marketplace">{{ $t('kyc.choose_file') }}</label>
                </div>
              </div>
              <div class="col-xl-1 col-lg-1 text-center">
                <template v-if="getDataFor('marketplace') && getDataFor('marketplace').verified">
                  <i class="flaticon2-check-mark kt-font-success kt-font-lg"></i>
                </template>
                <template v-else-if="getDataFor('marketplace') && !getDataFor('marketplace').verified">
                  <i class="flaticon2-crisp-icons-1 kt-font-warning kt-font-lg"></i>
                </template>
                <template v-else>
                  <i class="flaticon2-cross kt-font-danger kt-font-lg"></i>
                </template>
              </div>
            </div>

          </div>
        </div>-->
      </div>
    </Loader>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Loader from '../global/Loader'

export default {
  name: 'KycForm',
  components: { Loader },
  data () {
    return {
      isLoading: false,
      kyc: []
    }
  },
  computed: {
    kycAgreement () {
      return this.getDataFor('agreement')
    },
    kycAddress () {
      return this.getDataFor('address')
    },
    kycPhoto () {
      return this.getDataFor('photo')
    },
    kycCompany () {
      return this.getDataFor('company')
    },
    kycMarketplace () {
      return this.getDataFor('marketplace')
    },
    filesUploaded () {
      let verified_agreement = false;
      let verified_company = false;
      let verified_photo = false;
      let verified_address = false;
      this.kyc.forEach(item => {
        if(item.type === 'agreement'){
          verified_agreement = true;
        }
        if(item.type === 'company'){
          verified_company = true;
        }
        if(item.type === 'photo'){
          verified_photo = true;
        }
        if(item.type === 'address'){
          verified_address = true;
        }
      });

      if(this.profile.account_type === 'individual'){
        if(verified_agreement && verified_address && verified_photo){
          return true;
        }
      }else {
        if (verified_agreement && verified_address && verified_photo && verified_company) {
          return true;
        }
      }
      return false;
    },
    filesUploaded2 () {
      let verified_marketplace = false;
      this.kyc.forEach(item => {
        if(item.type === 'marketplace'){
          verified_marketplace = true;
        }
      });

      if(verified_marketplace){
        return true;
      }
      return false;
    },
    filesVerified () {
      return this.profile.kyc_verified
    },
    filesVerified2 () {
      return this.profile.kyc_verified2
    },
    headerClass () {
      if (!this.filesUploaded) {
        return 'alert-danger'
      } else if (this.filesUploaded && !this.filesVerified) {
        return 'alert-warning'
      } else if (this.filesUploaded && this.filesVerified) {
        return 'alert-success'
      } else {
        return 'alert-brand'
      }
    },
    headerText () {
      if (this.filesUploaded && !this.filesVerified) {
        return 'Documents are awaiting confirmation'
      } else if (this.filesUploaded && this.filesVerified) {
        return 'Documents are verified'
      } else if (!this.filesUploaded) {
        return 'Documents not uploaded!'
      } else {
        return 'Documents not uploaded!'
      }
    },
    headerClass2 () {
      if (!this.filesUploaded2) {
        return 'alert-danger'
      } else if (this.filesUploaded2 && !this.filesVerified2) {
        return 'alert-warning'
      } else if (this.filesUploaded2 && this.filesVerified2) {
        return 'alert-success'
      } else {
        return 'alert-brand'
      }
    },
    headerText2 () {
      if (this.filesUploaded2 && !this.filesVerified2) {
        return 'Documents are awaiting confirmation'
      } else if (this.filesUploaded2 && this.filesVerified2) {
        return 'Documents are verified'
      } else if (!this.filesUploaded2) {
        return 'Documents not uploaded!'
      } else {
        return 'Documents not uploaded!'
      }
    },
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
    this.getKycFiles()
  },
  methods: {
    getKycFiles () {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        axios.get(this.$api.kycFiles).then((response) => {
          this.kyc = response.data.data
          this.isLoading = false
          resolve()
        }).catch(error => {
          this.isLoading = false
          reject(error)
        })
      })
    },
    getDataFor (type) {
      if (this.kyc) {
        let kyc = this.kyc
        for (let i = 0; i < kyc.length; i++) {
          if (kyc[i].type === type) {
            return kyc[i]
          }
        }
      }
      return null
    },
    selectFile (e) {
      let name = e.target.name
      let files = e.target.files || e.dataTransfer.files
      if (files.length) {
        let file = files[0]
        let formData = new FormData()
        formData.append(name, file)
        this.isLoading = true
        axios.post(this.$api.kycFiles, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then((response) => {
          this.getKycFiles().then(() => {
            this.$store.dispatch('setAuthUser').then(() => {
              this.$store.dispatch('showSuccessNotification', this.$t('kyc.success'))
            })
            this.$nextTick(() => {
              this.$forceUpdate()
            })
          }).catch(error => {})
          this.isLoading = false
        }).catch(error => {
          if (error.response && error.response.status === 422) {
            if (error.response.data.errors[name].length){
              this.$store.dispatch('showErrorNotification', error.response.data.errors[name][0])
            }else{
              this.$store.dispatch('showErrorNotification', error.response.data.message)
            }
          } else {
            this.$store.dispatch('showErrorNotification', this.$t('kyc.failed'))
          }
          this.isLoading = false
        })
      } else {
        this.$store.dispatch('showErrorNotification', this.$t('kyc.empty'))
      }
    }
  }
}
</script>
