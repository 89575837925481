<template>
  <div class="row">
    <div class="col">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
              <span class="kt-portlet__head-icon d-none d-sm-block">
                  <i class="fas fa-chart-line"></i>
              </span>
            <h3 class="kt-portlet__head-title">
              <template v-if="!estimate.week1">
                {{ $t('dashboard.previous_month') }}
              </template>
              <template v-else>
                <span v-html="$t('dashboard.weekly_estimated', { month: estimate.current_month_display })"></span>
              </template>
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <b>{{ $t('dashboard.profit_previous_month', {profit: estimate.previous_month_profit, month: estimate.previous_month_display}) }}</b>
          </div>
        </div>
        <div class="kt-portlet__body  kt-portlet__body--fit">
          <div class="row row-no-padding row-col-separator-xl row-col-separator-lg">
            <template v-if="estimate.week1">
              <div v-if="estimate.week1" class="col-md-12 col-lg-4 col-xl-4">

                <div class="kt-widget1">
                  <div class="kt-widget1__item">
                    <div class="kt-widget1__info">
                      <h3 class="kt-widget1__title">{{ $t('dashboard.week1_title') }}</h3>
                      <span class="kt-widget1__desc">{{ estimate.week1_period }}</span>
                    </div>
                    <span v-if="estimate.week1" class="kt-widget1__number kt-font-success">{{ estimate.week1 }}%</span>
                    <span v-else class="kt-widget1__number">{{ $t('dashboard.unknown') }}</span>
                  </div>
                </div>

              </div>
              <div class="col-md-12 col-lg-4 col-xl-4">

                <div class="kt-widget1">
                  <div class="kt-widget1__item">
                    <div class="kt-widget1__info">
                      <h3 class="kt-widget1__title">{{ $t('dashboard.week2_title') }}</h3>
                      <span class="kt-widget1__desc">{{ estimate.week2_period }}</span>
                    </div>
                    <span v-if="estimate.week2" class="kt-widget1__number kt-font-success">{{ estimate.week2 }}%</span>
                    <span v-else class="kt-widget1__number">{{ $t('dashboard.unknown') }}</span>
                  </div>
                </div>

              </div>
              <div class="col-md-12 col-lg-4 col-xl-4">

                <div class="kt-widget1">
                  <div class="kt-widget1__item">
                    <div class="kt-widget1__info">
                      <h3 class="kt-widget1__title">{{ $t('dashboard.week3_title') }}</h3>
                      <span class="kt-widget1__desc">{{ estimate.week3_period }}</span>
                    </div>
                    <span v-if="estimate.week3" class="kt-widget1__number kt-font-success">{{ estimate.week3 }}%</span>
                    <span v-else class="kt-widget1__number">{{ $t('dashboard.unknown') }}</span>
                  </div>
                </div>

              </div>
            </template>
            <template v-else>
              <div class="col-xl-12">

                <div class="kt-widget1">
                  <div class="kt-widget1__item">
                    <div class="kt-widget1__info">
                      <h3 class="kt-widget1__title">{{ $t('dashboard.month_title',{ month: estimate.previous_month_display }) }}</h3>
                      <span class="kt-widget1__desc">{{ $t('dashboard.credited') }}</span>
                    </div>
                    <span class="kt-widget1__number kt-font-success">{{ estimate.previous_month_profit }}%</span>
                  </div>
                </div>

              </div>

            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/global/Loader";
import axios from "axios";
import {mapState} from "vuex";

export default {
  name: 'EstimatedProfits',
  components: {
    Loader
  },
  props: {
  },
  data() {
    return {
      estimate: {},
      isLoading: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
  },
  created: function() {
    this.getEstimate()
  },
  methods: {
    getEstimate () {
      this.isLoading = true
      axios.get(this.$api.estimateProfits).then(response => {
        this.estimate = response.data.data
        this.isLoading = false
      }).catch(error => {
        this.estimate = {}
        this.isLoading = false
      })
    },

  }
}
</script>
