<template>
  <div
    v-if=""
    class="alert alert-danger"
    role="alert"
  >
    <div class="alert-text">
      <i class="fas fa-exclamation-triangle"></i>
      &nbsp;<span v-html="$t('dashboard.invested_balance_warning', { minimum: invested_minimum})"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalanceWarning',
  components: {
  },
  props: {
    invested_minimum: {
      type: String,
      default: null
    }
  },
  computed: {

  }
}
</script>
