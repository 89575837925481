<template>
  <ReferralBasePage>
    <template v-slot:header>
      <div class="row">
        <div class="col-xl-6 col-lg-6">
          <h1 class="kt-margin-b-0">{{ $t('affiliate.summary_title') }}</h1>
        </div>
        <div class="col-xl-6 col-lg-6">
          <div class="alert alert-elevate alert-light kt-margin-b-0" role="alert">
            <div class="alert-text">{{ $t('affiliate.comm_type') }}&nbsp;
              <template v-if="profile.aff_id">
                <b v-if="profile.aff_cpa">{{ profile.aff_cpa_percent }}% {{ $t('affiliate.cpa') }}
                  <template v-if="profile.aff_profit">,&nbsp;</template></b>
                <b v-if="profile.aff_profit">{{ profile.aff_profit_percent }}% {{ $t('affiliate.of_profits') }}</b>
              </template>
              <template v-else>
                {{ $t('general.none') }}
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>
    <Loader :loading="isLoading">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="kt-portlet">
            <div class="kt-portlet__body">
              <div class="row row-no-padding row-col-separator-xl row-col-separator-lg">
                <div class="col-xl-12">

                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title kt-font-bold">{{ $t('affiliate.credits_balance') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-warning">{{ summary.credits_display }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.deposited_referrals') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ summary.total_users_deposited }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.total_earnings') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ summary.total_earnings_display }}</span>
                    </div>
                    <div class="kt-widget1__item" v-if="profile.aff_tier2">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.total_earnings_2tier') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ summary.total_earnings_second_tier_display }}</span>
                    </div>
                    <div class="kt-widget1__item" v-if="profile.aff_tier2">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.deposited_referrals_2tier') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ summary.total_users_deposited_second_tier }}</span>
                    </div>
                    <div class="kt-widget1__item" v-if="profile.aff_has_network">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.registered_network') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ summary.total_users_registered_network }}</span>
                    </div>
                    <div class="kt-widget1__item" v-if="profile.aff_has_network">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.total_earnings_network') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ summary.total_earnings_network_display }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.total_epc') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ summary.epc_display }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.withdrawals_to_acc') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ summary.total_withdrawals_display }}</span>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="kt-portlet">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">{{ $t('affiliate.withdraw_title2') }}</h3>
              </div>
            </div>
            <div class="kt-portlet__body">
              <CreditsWithdrawForm
                :credits="summary.credits_withdrawable"
                :credits-display="summary.credits_withdrawable_display"
                :min-widthrawal="summary.minimum_withdrawal"
                :min-widthrawal-display="summary.minimum_withdrawal_display"
                v-on:done="update"
              />
            </div>
          </div>
        </div>
      </div>
    </Loader>
  </ReferralBasePage>
</template>

<script>
import CreditsWithdrawForm from '../components/Referral/CreditsWithdrawForm'
import ReferralWithdrawalsHistory from '../components/Referral/ReferralWithdrawalsHistory'
import Loader from '../components/global/Loader'
import ReferralBasePage from './ReferralBasePage'
import {mapState} from "vuex";

export default {
  name: 'ReferralSummaryPage',
  components: {
    ReferralBasePage,
    Loader,
    ReferralWithdrawalsHistory,
    CreditsWithdrawForm
  },
  data () {
    return {
      summary: {},
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
    this.updateData()
  },
  methods: {
    updateData () {
      this.isLoading = true
      axios.get(this.$api.affiliate + '/summary').then(response => {
        this.summary = response.data.data
        this.isLoading = false
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.isLoading = false
      })
    },
    update() {
      this.$store.dispatch('setAuthUser');
      this.updateData();
    }
  }
}
</script>
