<template>
  <ReferralBasePage>
    <template v-slot:header>
      <h1 class="kt-margin-b-0">{{ $t('affiliate.materials_title') }}</h1>
    </template>

    <div class="row">
      <div class="col-xl-12">

        <div class="kt-portlet kt-portlet--mobile">
          <div class="kt-portlet__body">
            <div class="row">
              <div v-for="banner in banners" class="col-md-6 mb-5">
                <img :src="bannerImageURL(banner.name)" class="img-fluid d-block mx-auto mb-2">
                <textarea :id="'banner-'+banner.name" class="form-control" rows="3" readonly>&lt;a href="{{ link }}" target="_blank"&gt;&lt;img src="{{ linkImageURL(banner.name) }}" border="0" alt=""/&gt;&lt;/a&gt;</textarea>
                <div class="text-right mt-1">
                  <button
                    :data-clipboard-target="'#banner-'+ banner.name"
                    class="btn btn-default btn-sm copy-to-cb"
                  >
                    {{ $t('affiliate.copy_clipboard') }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ReferralBasePage>
</template>

<script>
import ReferralBasePage from './ReferralBasePage'
import {mapState} from "vuex";
import Clipboard from "clipboard";
export default {
  name: 'ReferralMaterialsPage',
  components: {ReferralBasePage},
  data() {
    return {
      links: {},
      loading: true,
      copy: null,
      link: null,
      banners: [
        {
          name: '120_600_1'
        },
        {
          name: '120_600_2'
        },
        {
          name: '160_600_1'
        },
        {
          name: '160_600_2'
        },
        {
          name: '200_200_1'
        },
        {
          name: '200_200_2'
        },
        {
          name: '200_200_3'
        },
        {
          name: '250_250_1'
        },
        {
          name: '250_250_2'
        },
        {
          name: '250_250_3'
        },
        {
          name: '300_250_1'
        },
        {
          name: '300_250_2'
        },
        {
          name: '300_600_1'
        },
        {
          name: '300_600_2'
        },
        {
          name: '336_280_1'
        },
        {
          name: '336_280_2'
        },
        {
          name: '468_60_1'
        },
        {
          name: '468_60_2'
        },
        {
          name: '468_60_3'
        },
        {
          name: '728_90_1'
        },
        {
          name: '728_90_2'
        },
        {
          name: '728_90_3'
        },
        {
          name: '970_90_1'
        },
        {
          name: '970_90_2'
        },
        {
          name: '970_90_3'
        },
        {
          name: '1140_150_1'
        },
        {
          name: '1140_150_2'
        },
        {
          name: '1140_150_3'
        },
      ],
    };
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created() {
    axios
      .get(this.$api.affiliate + "/links")
      .then(response => {
        this.links = response.data.data;
        this.link = this.links.home + '?a=' + this.profile.aff_id;
        this.isLoading = false;

        this.copy = new Clipboard(".copy-to-cb");
        this.copy.on("success", e => {
          this.$store.dispatch("showSuccessNotification", this.$t('affiliate.code_copied'));
          e.clearSelection();
        });

      })
      .catch(error => {
        this.$store.dispatch(
          "showErrorNotification",
          this.$t('general.unknown_error')
        );
      });
  },
  methods: {
    linkImageURL: function(name){
      return 'https://members.yieldnodes.com/assets/b/'+name+'.png';
    },
    bannerImageURL: function(name){
      return '/assets/b/'+name+'.png';
    }
  },
  beforeDestroy() {
    if (this.copy) {
      this.copy.destroy();
    }
  }
}
</script>
