<template>
  <div>
    <VerificationInfo v-if="!profile.account_verified" />

    <div class="kt-portlet">
      <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              :value="profile.deposit_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.deposit_balance_btc || null"
              :value-in-eur="profile.deposit_balance_eur || null"
              :value-in-eth="profile.deposit_balance_eth || null"
              dont-format
              icon="invest"
              :title="$t('dashboard.invested_balance')"
              :dots-tooltip="$t('dashboard.invested_balance_tooltip')"
              :has-warning="!!profile.deposit_balance_below_min"
              :info-text="$t('dashboard.invested_balance_low')"
              :info-tooltip="$t('dashboard.invested_balance_low_tooltip',{ minimum: profile.invested_balance_minimum_display})"
              :info="profile.deposit_has_idle?$t('deposit.idle_detected'):null"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              icon="balance"
              :title="$t('withdraw.pending')"
              :value="profile.withdraw_pending_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.withdraw_pending_btc || null"
              :value-in-eur="profile.withdraw_pending_eur || null"
              :value-in-eth="profile.withdraw_pending_eth || null"
              dont-format
              color="success"
              :dots-tooltip="$t('withdraw.pending_tooltip')"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              :value="profile.withdraw_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.withdraw_balance_btc || null"
              :value-in-eur="profile.withdraw_balance_eur || null"
              :value-in-eth="profile.withdraw_balance_eth || null"
              dont-format
              color="warning"
              icon="balance"
              :title="$t('dashboard.withdrawable_balance')"
              :dots-tooltip="$t('dashboard.withdrawable_balance_tooltip')"
            />
          </div>
        </div>
      </div>
    </div>

    <!--<NewUserAlert
      v-if="profile.deposit_first_time"
    />-->

    <EmergencyWithdrawalWarning
      v-if="profile.has_emergency_withdrawal"
      :amount="profile.emergency_withdrawal_amount"
      :wallet="profile.emergency_withdrawal_wallet"
    />

    <!--<PendingBonusDepositReminder
      v-if="profile.has_pending_deposit_bonus"
    />-->

    <BalanceWarning
      v-if="!profile.deposit_first_time && profile.deposit_balance_below_min"
      :invested_minimum="profile.invested_balance_minimum_display"
    />

    <!--<IdleDeposits
      v-if="profile.deposit_has_idle"
      :idle="profile.deposit_idle"
    />-->

    <div class="row">
      <div class="col-xl-7 col-lg-7 col-md-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('withdraw.title') }}</h3>
            </div>
            <div class="kt-portlet__head-toolbar">
              <a
                href="javascript:void(0)" @click.prevent="openEmergencyWithdrawalModal" class="btn btn-sm btn-danger" :class="profile.limited_mode ? 'disabled' : ''"><span class="d-none d-sm-inline"><i class="fas fa-ambulance"></i> {{ $t('withdraw.emergency_button') }}</span><span class="d-inline d-sm-none"><i class="fas fa-ambulance"></i> {{ $t('withdraw.emergency_button_xs') }}</span></a>
            </div>
          </div>
          <div class="kt-portlet__body">
            <WithdrawalForm @updated="update" />
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title"><i class="fas fa-hand-holding-medical"></i>&nbsp; {{ $t('withdraw.donate_title') }} <span class="flag-ua">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <DonateForm @updated="update" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-7 col-lg-7 col-md-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('withdraw.transfer') }}</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <TransferForm @updated="update" />
          </div>
        </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('withdraw.auto_reinvest') }}</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <AutomaticReinvestForm @updated="update" />
          </div>
        </div>
      </div>
    </div>

    <div class="col-xl-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ $t('withdraw.history') }}</h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <WithdrawalActivity ref="withdrawalActivity" />
        </div>
      </div>
    </div>

    <EmergencyWithdrawalModal
      v-if="emergencyModal"
      :show="emergencyModal"
      @hidden="emergencyModal = false"
      @done="update"
    />

  </div>
</template>

<script>
import axios from 'axios';
import StatisticsBox from '../components/functional/StatisticsBox'
import IdleDeposits from '../components/functional/IdleDeposits'
import WithdrawalForm from '../components/Withdrawal/WithdrawalForm'
import TransferForm from '../components/Withdrawal/TransferForm'
import WithdrawalActivity from '../components/Withdrawal/WithdrawalActivity'
import VerificationInfo from '../components/Withdrawal/VerificationInfo'
import AutomaticReinvestForm from '../components/Withdrawal/AutomaticReinvestForm'
import BalanceWarning from '../components/functional/BalanceWarning'
import EmergencyWithdrawalModal from '../components/Withdrawal/EmergencyWithdrawalModal'
import { mapState } from 'vuex'
import EmergencyWithdrawalWarning from '@/components/functional/EmergencyWithdrawalWarning';
import NewUserAlert from '@/components/functional/NewUserAlert';
import DonateForm from '@/components/Withdrawal/DonateForm';
import PendingBonusDepositReminder from "@/components/functional/PendingBonusDepositReminder";

export default {
  name: 'WithdrawalsPage',
  components: {
    NewUserAlert,
    EmergencyWithdrawalWarning,
    StatisticsBox,
    WithdrawalForm,
    TransferForm,
    WithdrawalActivity,
    VerificationInfo,
    AutomaticReinvestForm,
    BalanceWarning,
    IdleDeposits,
    EmergencyWithdrawalModal,
    DonateForm,
    PendingBonusDepositReminder
  },
  data () {
    return {
      isLoading: false,
      emergencyModal: false
    }
  },
  computed: mapState({
    profile: state => state.authUser.profile
  }),
  methods: {
    update () {
      this.$store.dispatch('setAuthUser')
      this.$refs.withdrawalActivity.getHistory()
    },
    openEmergencyWithdrawalModal() {
      if(this.profile.limited_mode){
        this.$store.dispatch('showErrorNotification', 'This function is currently disabled');
        return
      }
      this.emergencyModal = true;
    }
  }
}
</script>

<style>
  .flag-ua {
    background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjAwIDgwMCI+DQo8cGF0aCBmaWxsPSIjM2E3NWM0IiBkPSJtMCwwaDEyMDB2ODAwaC0xMjAweiIvPg0KPHBhdGggZmlsbD0iI2Y5ZGQxNiIgZD0ibTAsNDAwaDEyMDB2NDAwaC0xMjAweiIvPg0KPC9zdmc+DQo=);
    width: 100%;
    height: 66.666666666667%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: left top;
  }
</style>
