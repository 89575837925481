<template>
  <Loader :loading="isLoading">
    <div v-if="show && pollData" class="kt-portlet kt-portlet--tab">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 v-if="show_poll === true" class="kt-portlet__head-title">
            {{ pollData.question }}
          </h3>
          <h3 v-else class="kt-portlet__head-title">
            Poll Results: {{ pollData.question }}
          </h3>
        </div>
      </div>

      <div v-if="show_poll === true" class="kt-portlet__body">
        <b-form-group>
          <template v-for="(option_name , option_id) in pollData.options">
            <b-form-radio size="lg" v-model="selected_option" name="poll" :value="option_id" style="margin-bottom:5px;">{{ option_name }}</b-form-radio>
          </template>
        </b-form-group>
        <button @click.prevent="vote()" :disabled="selected_option === null" class="btn btn-primary">Save Selected Answer</button>
      </div>
      <div v-else-if="show_poll === false" class="kt-portlet__body">
        <div class="kt-widget12">
          <div class="kt-widget12__content">
            <template v-for="(option , option_id) in pollDataResults">
              <div class="kt-widget12__item">
                <div class="kt-widget12__info">
                  <span class="kt-widget12__desc">{{ option.name }}</span>
                  <div class="kt-widget12__progress">
                    <div class="progress kt-progress--sm">
                      <div class="progress-bar kt-bg-brand" role="progressbar" :style="'width: '+option.percent+'%'" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                    <span class="kt-widget12__stat">{{ option.percent }}%</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>

    </div>
  </Loader>
</template>

<script>
import Loader from "@/components/global/Loader";
import axios from "axios";

export default {
  name: 'Poll',
  components: {
    Loader
  },
  props: {
    id: {
      type: Number,
      default: null,
      required: true
    }
  },
  data() {
    return {
      pollData: null,
      pollDataResults: null,
      isLoading: false,
      show: false,
      show_poll: null,
      selected_option: null
    }
  },
  computed: {},
  created: function() {
    this.getPollData();
  },
  methods: {
    getPollData () {
      if(this.id === null) return;
      axios.get(this.$api.poll + '/' + this.id ).then(response => {
        this.isLoading = false;
        this.show = true;
        this.show_poll = response.data.data.show_poll;
        if(response.data.data.poll_data){
          this.pollData = response.data.data.poll_data;
        }
        if(response.data.data.data) {
          this.pollDataResults = response.data.data.data;
        }
      }).catch(error => {
        this.isLoading = false;
        this.show = false;
      })
    },
    vote(){
      if(this.selected_option === null) return;
      this.isLoading = true;

      axios.patch(this.$api.poll + '/' + this.id + '/vote', { option_id: this.selected_option } ).then(response => {
        this.getPollData()
      }).catch(error => {
        this.isLoading = false;
        if(error.response.data && error.response.data.data && error.response.data.data.message){
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        }else{
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
        }
      })

    }

  }
}
</script>
