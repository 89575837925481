<template>
  <div class="row">
    <div class="col-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ $t('mfa.title2') }}</h3>
          </div>
        </div>
        <MfaMethodForm />
      </div>
    </div>
    <div class="col-12">
      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <h3 class="kt-portlet__head-title">{{ $t('security.sessions_title') }}</h3>
          </div>
        </div>
        <div class="kt-portlet__body">
          <AppSessions />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import MfaMethodForm from '../components/Account/MfaMethodForm'
import MfaSettingsForm from '../components/Account/MfaSettingsForm'
import PhoneVerificationForm from '../components/Account/PhoneVerificationForm'
import { mapState } from 'vuex'
import AppSessions from "../components/Account/AppSessions";

export default {
  name: 'MfaPage',
  components: { MfaSettingsForm, MfaMethodForm, PhoneVerificationForm, AppSessions },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  }
}
</script>
