<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('dashboard.feedback_title')"
      @hidden="hideModal"
      no-close-on-backdrop
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <div>
          <div
            v-if="hasError"
            class="alert alert-danger"
          >
            <div class="alert-text">
              <i class="fas fa-exclamation-circle"></i>
              {{ errorMsg }}
            </div>
          </div>

          <div v-html="$t('dashboard.feedback_note')"></div>

          <hr class="kt-margin-t-20">

          <form @submit.prevent="send_feedback">
            <textarea v-model="feedback_message" class="form-control kt-margin-b-10" :placeholder="$t('dashboard.feedback_placeholder')"></textarea>
            <span
              v-if="errors.hasOwnProperty('message')"
              class="invalid-feedback kt-margin-b-10"
              :style="{display: errors.hasOwnProperty('message') ? 'block' : 'none'}"
            >{{ errors.message[0] }}</span>

            <button type="submit" class="btn btn-primary">{{ $t('dashboard.feedback_submit') }}</button>
          </form>
        </div>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>

  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import formHasErrors from "@/mixins/formHasErrors";

export default {
  name: 'FeedbackModal',
  components: {
    Loader
  },
  mixins: [
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      modalOpen: this.show,
      hasError: false,
      errorMsg: null,
      feedback_message: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
  },
  created()  {
    this.feedback_message = null;
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    send_feedback () {
      this.isLoading = true
      this.hasError = false
      this.errorMsg = null
      axios.post(this.$api.feedback, {
        message: this.feedback_message
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', this.$t('dashboard.feedback_success'))
        this.feedback_message = null;
        //this.$emit('done');
        this.hideModal();
      }).catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.status === 422) {
          this.errorMsg = this.$t('general.errors_below');
          this.setErrors(error.response.data.errors)
        } else if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.errorMsg = error.response.data.data.message;
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.errorMsg = this.$t('general.error')
        }
      })
    },
    hideMfa () {
      this.isLoading = false;
      this.showMfa = false
    }

  }
}
</script>
