<template>
  <Loader :loading="isLoading">
    <div class="kt-portlet kt-portlet--height-fluid">
      <div class="kt-portlet__head">
        <div class="kt-portlet__head-label">
          <h3 class="kt-portlet__head-title">
            {{ message.subject }}
          </h3>
        </div>
        <div class="kt-portlet__head-toolbar">
          {{ message.date }}
        </div>
      </div>
      <div class="kt-portlet__body">
        <div v-html="message.body"></div>
        <div class="mt-5 text-center">
          <router-link :to="{name: 'messages'}" class="btn btn-secondary">{{ $t('messages.back') }}</router-link>
        </div>
      </div>
    </div>
  </Loader>
</template>

<script>
  import {mapState} from "vuex";
  import Loader from '../components/global/Loader'

  export default {
    name: 'MessagePage',
    components: {
      Loader,
    },
    data () {
      return {
        message: {},
        isLoading: false
      }
    },
    computed: {
      ...mapState({
        profile: state => state.authUser.profile
      })
    },
    created () {
      this.loadMessage()
    },
    methods: {
      loadMessage () {
        let id = this.$route.params.messageId
        this.isLoading = true
        axios.get(this.$api.messages + '/' + id).then(response => {
          this.message = response.data.data
          this.isLoading = false
        }).catch(error => {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.isLoading = false
        })
      },
    }
  }
</script>
