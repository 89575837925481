<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('deposit.wire2_invoice_title')"
      @hidden="hideModal"
      no-close-on-backdrop
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <template v-if="!confirmed">
          <ul class="wire-details">
            <li class="row">
              <div class="col-sm-5 title">{{ $t('deposit.wire2_invoice_email') }}</div>
              <div class="col-sm-7 value">{{ profile.email }}</div>
            </li>
            <li class="row">
              <div class="col-sm-5 title">{{ $t('deposit.wire2_invoice_fullname') }}</div>
              <div class="col-sm-7 value">{{ profile.first_name }} {{ profile.last_name }}</div>
            </li>
            <li class="row">
              <div class="col-sm-5 title">{{ $t('deposit.wire2_invoice_address') }}</div>
              <div class="col-sm-7 value">
                <div>{{ profile.address }}</div>
                <div v-if="profile.address2">{{ profile.address2 }}</div>
                <div>{{ profile.city }} {{ profile.zipcode }}, {{ profile.country_name }}</div>
              </div>
            </li>
            <li class="row">
              <div class="col-sm-5 title kt-font-bold">{{ $t('deposit.wire2_invoice_amount') }}</div>
              <div class="col-sm-7 value kt-font-bold">{{ amountDisplay }} EUR</div>
            </li>
            <li class="row">
              <div class="col">
                {{ $t('deposit.wire2_invoice_note') }}
              </div>
            </li>
          </ul>
          <div class="kt-margin-t-10 kt-align-center">
            <div
              v-if="hasError"
              class="alert alert-danger"
            >
              <div class="alert-text">
                <i class="fas fa-exclamation-circle"></i>
                {{ errorMsg }}
              </div>
            </div>

            <button @click.prevent="confirmAPIModal" type="button" class="btn btn-lg btn-primary">{{ $t('deposit.wire2_invoice_confirm') }}</button>
          </div>
        </template>
        <template v-else>

          <h2 class="kt-align-center kt-margin-b-20">{{ $t('deposit.wire2_invoice_success') }}</h2>

          <div class="kt-align-center kt-margin-b-20">
            <a :href="invoice.data.invoice_pay" target="_blank" class="btn btn-lg btn-success">{{ $t('deposit.wire2_invoice_open') }}</a>
          </div>
          <div class="kt-align-center">
            <a :href="invoice.data.invoice_download" target="_blank" class="btn btn-sm btn-secondary">{{ $t('deposit.wire2_invoice_download') }}</a>
          </div>

        </template>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.cancel') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'

export default {
  name: 'WireModal',
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    amount: {
      type: [String, Number],
      required: true
    },
    amountDisplay: {
      type: [String, Number],
      required: true
    }
  },
  data () {
    return {
      isLoading: false,
      modalOpen: this.show,
      confirmed: false,
      hasError: false,
      errorMsg: null,
      invoice: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    confirmAPIModal () {
      this.isLoading = true
      this.hasError = false
      this.errorMsg = null
      axios.post(this.$api.depositApiWireDetails, {
        amount: this.amount
      }).then(response => {
        this.invoice = response.data.data
        this.confirmed = true
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.wire2_invoice_success'))
      }).catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.errorMsg = error.response.data.data.message;
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else if (error.response.status === 422) {
          let errors = error.response.data.errors
          this.$store.dispatch('showErrorNotification', errors[Object.keys(errors)[0]][0])
          this.errorMsg = error.errors[Object.keys(errors)[0]][0];
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.errorMsg = this.$t('general.error')
        }
      })
    }
  }
}
</script>
