<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      title="Buy balance with discount"
      @hidden="hideModal"
      size="lg"
      no-close-on-backdrop
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <div>
          <div
            v-if="hasError"
            class="alert alert-danger"
          >
            <div class="alert-text">
              <i class="fas fa-exclamation-circle"></i>
              {{ errorMsg }}
            </div>
          </div>


          <template v-if="!p2pBuyAllowed">
            <div
              class="alert alert-danger"
              role="alert"
            >
              <div class="alert-text">
                <h4 class="alert-heading">Balance selling is not possible</h4>
                <p v-if="p2pBuyDisallowedReason">{{ p2pBuyDisallowedReason }}</p>
              </div>
            </div>
          </template>
          <template v-else>

            <form @submit.prevent="p2pSellRequestCheckMfa" class="kt-margin-t-30 kt-margin-b-20">
              <div class="alert alert-secondary" role="alert">
                <div class="alert-text">
                  <p class="kt-font-lg">Balance for sale: <b>{{ p2p_sell.data.amount_initial_display }}</b></p>
                  <p class="kt-font-lg">You need to pay: <b>{{ p2p_sell.data.amount_real_display }}</b> ({{ p2p_sell.data.discount_real }}%)</p>
                  <p v-if="p2p_sell.data.has_btc_wallet" class="kt-font-lg kt-margin-l-10">in BTC: <b>{{ p2p_sell.data.amount_btc_display }}</b></p>
                  <p v-if="p2p_sell.data.has_usdt_wallet" class="kt-font-lg kt-margin-l-10">in USDT: <b>{{ p2p_sell.data.amount_usdt_display }}</b></p>
                  <p v-if="p2p_sell.data.has_usdc_wallet" class="kt-font-lg kt-margin-l-10">in USDC: <b>{{ p2p_sell.data.amount_usdc_display }}</b></p>
                </div>
              </div>

              <div class="form-group">
                <label class="kt-checkbox" style="font-weight: bold;">
                  <input
                    v-model="values.agreed"
                    type="checkbox"
                    class="form-control"
                    value="1"
                  >
                  Yes, I agree with terms and conditions of the balance buying feature
                  <span></span>
                </label>
                <span
                  v-if="errors.hasOwnProperty('agreed')"
                  class="invalid-feedback"
                  :style="{display: errors.hasOwnProperty('agreed') ? 'block' : 'none'}"
                >{{ errors.agreed[0] }}</span>
              </div>

              <div class="form-group kt-margin-b-15 m-signature-pad">
                <div
                  v-if="errors.hasOwnProperty('signature') && errors.signature[0]"
                  class="alert alert-danger kt-margin-t-5"
                >{{ errors.signature[0] }}</div>

                <div
                  class="m-signature-pad--body"
                  :class="{'signature-pad-container--error': needsSingature}"
                >
                  <canvas
                    ref="signaturePadCanvas"
                    class="signature-pad"
                  />
                </div>
                <div class="m-signature-pad--footer">
                  <p>
                    <i class="fas fa-info-circle" />
                    Please, draw your real signature in the above area
                  </p>
                  <button
                    type="button"
                    class="btn btn-outline-info btn-sm"
                    @click="clearPad"
                  >
                    Clear Signature
                  </button>
                </div>
              </div>


              <div class="row">
                <div class="col">
                  <button
                    type="submit" class="btn btn-primary" :disabled="quickCheckP2PSellRequest">Create Buy Request</button>
                </div>
              </div>
            </form>
            <div class="alert alert-secondary" role="alert">
              <div class="alert-text">
                <h3>Balance Buying Rules and Conditions:</h3>
                <p>(Be aware that this function requires basic blockchain understanding and knowledge, especially for buyer)</p>
                <p>In order to purchase balance of someone with a discount - you would have to transfer an amount of price in selected cryptocurrency to appropriate wallet address, which the seller has entered in his selling request. Amounts and addresses will be displayed after the request is submitted and will be also sent to buyer by email.</p>
                <p>To correctly buy the balance - the buyer needs to transfer <b>EXACT</b> amount of crypto as mentioned in his request (or more), in <b>a single transaction</b> (and in a single output) to <b>correct</b> wallet address, which is displayed after submitting the request and also sent by email. (Be careful when you copy the wallet address, there are viruses which replace your copied crypto addresses, so always double-triple check the address displayed and the same address in the email).</p>
                <p><b>Important!</b> Do not transfer the amount from Binance internally without the use of blockchain! This will cause significant delays with approval of purchase!</p>
                <p><b>Required step:</b> After the transaction is signed and sent - buyer <b>has to submit the transaction id (hash)</b> of this transaction on this website in appropriate form, which will be shown after submitting buying request.</p>
                <p>YieldNodes will accept entered transaction id (hash) only if it is already present in the appropriate blockchain. Please, allow 2-10 minutes after the transaction is sent before trying to submit the transaction id.</p>
                <p>USDT and USDC addresses (if they are present in selling request) - are from TRON blockchain (TRC-20), so the buyer will have to transfer only appropriate TRC20 tokens on that blockchain.</p>
                <p>You can have only <b>one active balance buying request</b> at the same time. You can cancel request manually or it can be expired if you do not enter and save transaction id (hash) after you make a transaction. So be careful: send transaction and submit transaction id as soon as you submit this request.</p>
                <p>When sending transaction from the exchange - make sure that you have calculated and included the fee in the final amount that the target wallet will receive.</p>
                <p>Be aware that if you didn't submit and save transaction id before your buying request expires or even if you sent transaction within the last several hours before the expiration, but for some reason it didn't appear in the blockchain so our system couldn't validate your transaction id - your request may be expired and since this is P2P transaction - we will not be able to recover your funds in any way. So please, always hurry up and do the transfer as soon as you submit your request.</p>
                <p>In case of any error coming either from YieldNodes side or when the amount of transaction is not correct when buyer is submitting transaction id - expiration of purchase request is disabled. In this case if the sale is not resolved by the buyer manually for uncomfortable amount of time - you will have to contact our support team to resolve the situation with your buying request yourself.</p>
                <p>If the potential buyer will be making errors during entering the transaction id multiple times - the system will soon disable the ability to purchase balances.</p>
              </div>
            </div>
          </template>
        </div>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button
              @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>

    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      :action="action"
      :method="profile.mfa_method"
      @verified="p2pSellRequest"
      @hidden="hideMfa"
    />

  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import requiresMfa from "@/mixins/requiresMfa";
import formHasErrors from "@/mixins/formHasErrors";
import SignaturePad from 'signature_pad'

export default {
  name: 'P2PBuyModal',
  components: {
    Loader
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      showMfa: false,
      isLoading: true,
      modalOpen: this.show,
      hasError: false,
      errorMsg: null,
      errors: null,
      p2p_sell: {
        allowed: true,
        reason: null,
        data: {
          balance_display: null,
          price_display: null,
          discount: null,
          price_btc: null,
          price_btc_display: null,
          price_usdt: null,
          price_usdt_display: null,
          price_usdc: null,
          price_usdc_display: null,
        }
      },
      values: {
        signature: null,
        agreed: false
      },
      action: "p2p_sell_request",
      signturePad: null,
      needsSingature: false,
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    p2pBuyAllowed(){
      return this.p2p_sell.allowed;
    },
    p2pBuyDisallowedReason(){
      return this.p2p_sell.reason;
    },
    quickCheckP2PSellRequest () {
      if(!this.values.agreed) return true;
      return false;
    }
  },
  created () {
    this.loadP2PSell();
  },
  beforeDestroy () {
    this.signaturePad.off()
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    loadP2PSell() {
      this.isLoading = true
      axios.get(this.$api.p2p_sell + '/' + this.id + '/data').then(response => {
        if(response.data.data) {
          this.p2p_sell = response.data.data;

          this.signaturePad = new SignaturePad(this.$refs.signaturePadCanvas, {
            onBegin: () => {
              if (this.needsSingature) {
                this.needsSingature = false
              }
            }
          })

          //window.addEventListener('resize', this.resizeCanvas)
          setTimeout(this.resizeCanvas, 1000)
        }else{
          this.p2p_sell.allowed = false;
          this.p2p_sell.reason = this.$t('general.error');
        }
        this.isLoading = false
      }).catch(error => {
        console.log(error);
        this.isLoading = false
        this.p2p_sell.allowed = false;
        this.p2p_sell.reason = this.$t('general.unknown_error')
      })
    },
    p2pSellRequestCheckMfa() {
      this.clearErrors()

      if (!this.values.agreed) {
        this.addError('agreed', 'You need to agree with terms and conditions', true)
        return false
      }

      if (this.signaturePad.isEmpty()) {
        this.needsSingature = true
        this.$store.dispatch('showErrorNotification', 'Your real signature is required for this agreement!')
        this.addError('signature', 'Your real signature is required for the agreement!', true)
        return false;
      }
      this.values.signature = this.signaturePad.toDataURL('image/svg+xml');


      if (this.profile.mfa_enabled) {
        this.showMfa = true;
        this.isLoading = true
      }else {
        this.$store.dispatch('showErrorNotification', 'You cannot use this feature until you set up MFA for your account!')
      }
    },
    p2pSellRequest (token = null) {
      this.showMfa = false
      this.isLoading = true
      this.hasError = false
      this.errorMsg = null
      axios.post(this.$api.p2p_sell + '/request', {
        id: this.id,
        signature: this.values.signature,
        agreed: this.values.agreed,
        token
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', 'Your Buy Request is Sent!')
        this.$emit('done');
        this.hideModal();
      }).catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.status === 422) {
          let errors = error.response.data.errors
          this.$store.dispatch('showErrorNotification', errors[Object.keys(errors)[0]][0])
          this.errorMsg = errors[Object.keys(errors)[0]][0];
        } else if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.hasError = true;
          this.errorMsg = error.response.data.data.message;
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else {
          this.hasError = true;
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.errorMsg = this.$t('general.error')
        }
      })
    },
    hideMfa () {
      this.isLoading = false;
      this.showMfa = false
    },
    clearPad () {
      this.signaturePad.clear()
    },
    resizeCanvas () {
      let ratio = Math.max(window.devicePixelRatio || 1, 1)
      const canvasEl = this.$refs.signaturePadCanvas
      if (typeof canvasEl === 'object' && 'nodeType' in canvasEl) {
        canvasEl.width = canvasEl.offsetWidth * ratio
        canvasEl.height = canvasEl.offsetHeight * ratio
        canvasEl.getContext('2d').scale(ratio, ratio)
      }
      this.signaturePad.clear()
    }

  }
}
</script>

<style>
.m-signature-pad {
  display: block;
}
.m-signature-pad--body {
  position: relative;
  margin: 0 auto;
}
canvas.signature-pad {
  display: block;
  width: 70%;
  height: 200px;
  margin: 5px auto;
  border: #aaa 1px dashed;
}
@media (max-width: 768px){
  canvas.signature-pad {
    width: 90%;
  }
}
.signature-pad-container--error canvas.signature-pad {
  border-color: red;
}
.m-signature-pad--footer {
  text-align: center;
}
</style>
