<template>
  <Loader :loading="isLoading" :full-page="true">
    <div class="kt-portlet">
      <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
          <div class="col-md-12 col-lg-6 col-xl-6">
            <StatisticsBox
              :value="profile.deposit_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.deposit_balance_btc || null"
              :value-in-eur="profile.deposit_balance_eur || null"
              :value-in-eth="profile.deposit_balance_eth || null"
              dont-format
              icon="invest"
              :title="$t('dashboard.invested_balance')"
              :dots-tooltip="$t('dashboard.invested_balance_tooltip')"
              :has-warning="!!profile.deposit_balance_below_min"
              :info-text="$t('dashboard.invested_balance_low')"
              :info-tooltip="$t('dashboard.invested_balance_low_tooltip',{ minimum: profile.invested_balance_minimum_display})"
              :info="profile.deposit_has_idle?$t('deposit.idle_detected'):null"
            />
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <StatisticsBox
              :value="profile.withdraw_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.withdraw_balance_btc || null"
              :value-in-eur="profile.withdraw_balance_eur || null"
              :value-in-eth="profile.withdraw_balance_eth || null"
              dont-format
              color="warning"
              icon="balance"
              :title="$t('dashboard.withdrawable_balance')"
              :dots-tooltip="$t('dashboard.withdrawable_balance_tooltip')"
            />
          </div>
        </div>
      </div>
    </div>

    <!--<NewUserAlert
      v-if="profile.deposit_first_time"
    />-->

    <EmergencyWithdrawalWarning
      v-if="profile.has_emergency_withdrawal"
      :amount="profile.emergency_withdrawal_amount"
      :wallet="profile.emergency_withdrawal_wallet"
    />

    <!--<PendingBonusDepositReminder
      v-if="profile.has_pending_deposit_bonus"
    />-->

    <BalanceWarning
      v-if="!profile.deposit_first_time && profile.deposit_balance_below_min"
      :invested_minimum="profile.invested_balance_minimum_display"
    />

    <!--<IdleDeposits
      v-if="profile.deposit_has_idle"
      :idle="profile.deposit_idle"
    />-->

    <div class="row">
      <div class="col-xl-12 col-lg-12 col-md-12">

        <div
          class="alert alert-info"
          role="alert"
        >
          <div class="alert-text">
            <i class="fas fa-exclamation-triangle"></i>
            &nbsp;After detecting a data breaching extension to our website, we additionally got under hacker attacks. To avoid any further data breaches, we close that section for now. All of your data are securely stored.
          </div>
        </div>

        <!--
        <div class="kt-portlet kt-portlet--tabs">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">
                {{ $t('reports.title') }}
              </h3>
            </div>
            <div class="kt-portlet__head-toolbar">
              <ul class="nav nav-tabs nav-tabs-bold nav-tabs-line nav-tabs-line-right nav-tabs-line-brand" role="tablist">
                <li v-for="reportYear in report.years" class="nav-item">
                  <a class="nav-link" v-bind:class="{ active: year === reportYear }" data-toggle="tab" href="#" role="tab" @click.prevent="yearChange(reportYear)">
                    {{ reportYear }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="kt-portlet__body kt-portlet__body--fit">
            <div class="tab-content">
              <div class="tab-pane active" id="tab">

                <div v-if="report.yearly" class="row row-no-padding row-col-separator-xl row-col-separator-lg">
                  <div class="col-md-12 col-lg-6 col-xl-6">

                    <div class="kt-widget1">
                      <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                          <h3 class="kt-widget1__title">{{ $t('reports.net_profit') }}</h3>
                        </div>
                        <span class="kt-widget1__number kt-font-success">{{ report.yearly[year].net_profit }}</span>
                      </div>
                      <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                          <h3 class="kt-widget1__title">{{ $t('reports.avg_noded_balance') }}</h3>
                        </div>
                        <span class="kt-widget1__number kt-font-info">{{ report.yearly[year].avg_traded_balance }}</span>
                      </div>
                      <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                          <h3 class="kt-widget1__title">{{ $t('reports.fund_return',{ year: year }) }}</h3>
                        </div>
                        <span class="kt-widget1__number kt-font-brand">{{ report.yearly[year].payouts }}%</span>
                      </div>
                    </div>

                  </div>
                  <div class="col-md-12 col-lg-6 col-xl-6">

                    <div class="kt-widget1">
                      <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                          <h3 class="kt-widget1__title">{{ $t('reports.deposits') }}</h3>
                        </div>
                        <span class="kt-widget1__number kt-font-brand">{{ report.yearly[year].deposits }}</span>
                      </div>
                      <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                          <h3 class="kt-widget1__title">{{ $t('reports.withdrawals') }}</h3>
                        </div>
                        <span class="kt-widget1__number kt-font-success">{{ report.yearly[year].withdrawals }}</span>
                      </div>
                      <div class="kt-widget1__item">
                        <div class="kt-widget1__info">
                          <h3 class="kt-widget1__title">{{ $t('reports.affiliate_commission') }}</h3>
                        </div>
                        <span class="kt-widget1__number kt-font-warning">{{ report.yearly[this.year].affiliate_profit }}</span>
                      </div>
                    </div>

                  </div>
                </div>
                <div class="row kt-margin-t-20">
                  <div class="col-xl-12">
                    <bar-chart
                      id="reportChart"
                      :data="chartData"
                      xkey="y"
                      :ykeys="['a']"
                      :labels="[$t('reports.profit')]"
                      :barColors="['#2abe81']"
                      hideHover="true"
                    />
                  </div>
                </div>
                <div id="report_table_wrapper" class="dataTables_wrapper dt-bootstrap4 no-footer kt-margin-20">
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="table-responsive">
                        <table class="table table-striped table-bordered table-hover" id="report_table">
                          <thead>
                            <tr>
                              <th nowrap>{{ $t('reports.date') }}</th>
                              <th nowrap>{{ $t('reports.profit_p') }}</th>
                              <th nowrap>{{ $t('reports.profit_c',{ account: profile.account_selected }) }}</th>
                              <th nowrap>{{ $t('reports.invested') }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="repMonthly in reportMonthly">
                              <td nowrap>{{ repMonthly.date }}</td>
                              <td nowrap>{{ repMonthly.net_profit_percent }}%<i v-if="repMonthly.is_partial_profit" style="color:red;" class="fas fa-exclamation-circle ml-1" v-b-tooltip.hover :title="$t('withdraw.history_partial')"></i></td>
                              <td nowrap>{{ repMonthly.net_profit_display }}</td>
                              <td nowrap>{{ repMonthly.invested_average_display }}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div>
                        <i class="fas fa-exclamation-circle ml-1"></i> - {{ $t('withdraw.history_partial') }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
    </div>
    <!--
    <div class="row">
      <div class="col-xl-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">{{ $t('reports.download_report') }}</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <template v-if="pdf_report">
              <div v-for="data in pdf_report" class="mb-3">
                <div class="row">
                  <div class="col-sm-2 text-center">
                    <template v-if="data.report">
                      <a :href="'/api/user/report/pdf_download?type=year&year=' + data.year" target="_blank" class="btn btn-sm btn-secondary mr-1 mb-1 text-bold">{{ data.year }}:</a>
                    </template>
                    <template v-else>
                      <strong>{{ data.year }}:</strong>
                    </template>
                  </div>
                  <div class="col-sm-10">
                    <template v-for="month in data.data">
                      <a :href="'/api/user/report/pdf_download?type=month&month=' + month +'&year=' + data.year" target="_blank" class="btn btn-sm btn-secondary mr-1 mb-1">{{ month + '-' + data.year }}</a>&nbsp;
                    </template>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="alert alert-secondary">{{ $t('reports.no_pdf_reports') }}</div>
            </template>
          </div>
        </div>
      </div>
    </div>-->
  </Loader>
</template>

<script>
import StatisticsBox from '../components/functional/StatisticsBox'
import NewUserAlert from '../components/functional/NewUserAlert'
import BalanceWarning from '../components/functional/BalanceWarning'
import IdleDeposits from '../components/functional/IdleDeposits'
import { mapState } from 'vuex'
import Loader from '../components/global/Loader'
import { BarChart } from 'vue-morris'
import moment from 'moment'
import EmergencyWithdrawalWarning from "@/components/functional/EmergencyWithdrawalWarning";
import PendingBonusDepositReminder from "@/components/functional/PendingBonusDepositReminder";

export default {
  name: 'ReportsPage',
  components: {
    EmergencyWithdrawalWarning,
    Loader,
    StatisticsBox,
    BarChart,
    BalanceWarning,
    IdleDeposits,
    NewUserAlert,
    PendingBonusDepositReminder
  },
  data () {
    return {
      report: {},
      pdf_report: [],
      reportMonthly: [],
      year: null,
      month: null,
      isLoading: false
    }
  },
  computed: {
    /*months () {
      if (this.report.months && this.year && this.report.months.hasOwnProperty(this.year)) {
        return this.report.months[this.year]
      } else {
        return []
      }
    },
    monthlyReport () {
      if (this.reportMonthly && this.month && this.reportMonthly.hasOwnProperty(this.month)) {
        return this.reportMonthly[this.month]
      } else {
        return {}
      }
    },*/
    /*chartData() {
      if (!this.reportMonthly.length) {
        return []
      }
      return this.reportMonthly.map(item => {
        return {
          y: item.date,
          a: item.net_profit_display
        }
      })
    },*/
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  watch: {
    year (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.getFullReport()
      }
    }
  },
  created () {
    //this.getReportData();
    //this.getPDFReports();
  },
  methods: {
    /*getReportData () {
      this.isLoading = true
      axios.get(this.$api.reports + '/stats').then(response => {
        this.report = response.data.data
        this.yearChange(this.report.years[this.report.years.length - 1])
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    getPDFReports () {
      this.isLoading = true
      axios.get(this.$api.reports + '/pdf_available').then(response => {
        if(response.data.data.result){
          this.pdf_report = response.data.data.result;
        }
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    getFullReport () {
      this.isLoading = true
      axios.get(this.$api.reports, { params: { year: this.year } }).then(response => {
        this.reportMonthly = response.data.data
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
      })
    },
    yearChange (value) {
      if (this.year !== value) {
        this.year = value
      }
    },*/
  }
}
</script>
