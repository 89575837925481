<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12">

      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
                <i class="fas fa-search-dollar"></i>
            </span>
            <h3 class="kt-portlet__head-title">
              {{ $t('title.audits') }}
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
          </div>
        </div>
          <div class="kt-portlet__body">
            <h2 class="kt-margin-b-20 text-center">Bi-Yearly Audit & Feasibility Checks</h2>

            <div class="kt-portlet kt-portlet--height-fluid">
              <!--<div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    Audits history
                  </h3>
                </div>
                <div class="kt-portlet__head-toolbar">
                </div>
              </div>-->
              <div class="kt-portlet__body">

                <div class="kt-widget4">
                  <div class="kt-widget4__item">
                    <div class="kt-widget4__pic kt-widget4__pic--icon">
                      <i class="far fa-building"></i>
                    </div>
                    <router-link :to="{ name: 'audit_july2022' }" class="kt-widget4__title">
                      <b>YieldNodes Participants Audit in Malta</b> (19-21 July, 2022)
                    </router-link>
                    <div class="kt-widget4__tools">
                      <i class="fas fa-arrow-right"></i>
                    </div>
                  </div>
                  <div class="kt-widget4__item">
                    <div class="kt-widget4__pic kt-widget4__pic--icon">
                      <i class="far fa-building"></i>
                    </div>
                    <router-link :to="{ name: 'audit_fall2021' }" class="kt-widget4__title">
                      <b>On-Site Audit in Malta</b> (30-31 October, 2021)
                    </router-link>
                    <div class="kt-widget4__tools">
                      <i class="fas fa-arrow-right"></i>
                    </div>
                  </div>
                  <div class="kt-widget4__item">
                    <div class="kt-widget4__pic kt-widget4__pic--icon">
                      <i class="fas fa-comments-dollar"></i>
                    </div>
                    <router-link :to="{ name: 'audit_feb2021' }" class="kt-widget4__title">
                      <b>Audit and Feasibility Check</b> (Quartal 1, 2021)
                    </router-link>
                    <div class="kt-widget4__tools">
                      <i class="fas fa-arrow-right"></i>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
      </div>

      <!--<div class="kt-portlet">
        <AuditForm />
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AuditForm from "@/components/Audit/AuditForm";

export default {
  name: 'AuditPage',
  components: {
    AuditForm
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {

  },
  methods: {
  }
}
</script>
