<template>
  <ReferralBasePage>
    <template v-slot:header>
      <div class="row">
        <div class="col-xl-12">
          <h1 class="kt-margin-b-10">{{ $t('affiliate.analytics_title') }}</h1>
        </div>
        <div class="col-xl-7 col-lg-7 col-md-6 text-right">
          <v-select
            v-model="trackVariablesSelected"
            :placeholder="$t('affiliate.tracking_select')"
            :options="trackVariables"
            class="chosen-results"
            taggable
            multiple
            @input="updateData"
          />
        </div>
        <div class="col-xl-5 col-lg-5 col-md-6">
          <DateRangePicker
            :show-dropdowns="true"
            :auto-apply="true"
            v-model="dateRange"
            style="width: 100%"
            control-container-class="form-control reportrange-text daterange-custom"
            @update="updateDateRange"
          >
            <template v-slot:input="picker" style="min-width: 350px;">
              {{ startDateFormatted }} - {{ endDateFormatted }}
            </template>
          </DateRangePicker>
        </div>
      </div>
    </template>

    <Loader
      :loading="isLoading"
      :style="{'overflow: hidden;': isLoading}"
    >
      <div class="row">
        <div class="col-xl-12">
          <div class="kt-portlet">
            <div class="kt-portlet__body">
              <div class="row row-no-padding row-col-separator-xl row-col-separator-lg">
                <div class="col-xl-6 col-lg-6 col-md-12">

                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title kt-font-bold">{{ $t('affiliate.credits') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-warning">{{ report.credits_display }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.epc') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-warning">{{ report.epc }}</span>
                    </div>
                    <div class="kt-widget1__item" v-if="profile.aff_tier2">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title kt-font-bold">{{ $t('affiliate.credits_2tier') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-warning">{{ report.credits_2tier_display }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.withdrawals') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-success">{{ report.withdrawals_display }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-6 col-md-12">
                  <div class="kt-widget1">
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.clicks') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ report.clicks }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.registered') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ report.users_registered }}</span>
                    </div>
                    <div class="kt-widget1__item">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.deposited') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ report.users_deposited }}</span>
                    </div>
                    <div class="kt-widget1__item" v-if="profile.aff_tier2">
                      <div class="kt-widget1__info">
                        <h3 class="kt-widget1__title">{{ $t('affiliate.deposited_2tier') }}</h3>
                      </div>
                      <span class="kt-widget1__number kt-font-info">{{ report.users_deposited_2tier }}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12">
                  <table class="table table-striped table-bordered table-hover dataTable no-footer dtr-inline" id="affreport_table" role="grid" style="width: 953px;">
                    <thead>
                    <tr role="row">
                      <th nowrap="" class="sorting_disabled" rowspan="1" colspan="1" style="width: 135.5px;">{{ $t('affiliate.month') }}</th>
                      <th nowrap="" class="sorting_disabled" rowspan="1" colspan="1" style="width: 79.5px;">{{ $t('affiliate.clicks') }}</th>
                      <th nowrap="" class="sorting_disabled" rowspan="1" colspan="1" style="width: 133.5px;">{{ $t('affiliate.registered') }}</th>
                      <th nowrap="" class="sorting_disabled" rowspan="1" colspan="1" style="width: 128.5px;">{{ $t('affiliate.deposited') }}</th>
                      <th nowrap="" class="sorting_disabled" rowspan="1" colspan="1" style="width: 108.5px;">{{ $t('affiliate.credits2') }}</th>
                      <th nowrap="" class="sorting_disabled" rowspan="1" colspan="1" style="width: 69.5px;">{{ $t('affiliate.epc') }}</th>
                      <th nowrap="" class="sorting_disabled" rowspan="1" colspan="1" style="width: 155.5px;">{{ $t('affiliate.withdrawals') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr role="row" v-if="report.daily_data" v-for="daily in report.daily_data">
                      <td nowrap="" tabindex="0">{{ daily.date }}</td>
                      <td nowrap="">{{ daily.clicks }}</td>
                      <td nowrap="">{{ daily.registered }}</td>
                      <td nowrap="">{{ daily.deposited }}</td>
                      <td nowrap="">{{ daily.returns_display }}</td>
                      <td nowrap="">{{ daily.epc_display }}</td>
                      <td nowrap="">{{ daily.withdrawals_display }}</td>
                    </tr>
                    <tr role="row" v-if="report.monthly_data" v-for="(monthly, month) in report.monthly_data">
                      <td nowrap="" tabindex="0">{{ month }}</td>
                      <td nowrap="">{{ monthly.clicks }}</td>
                      <td nowrap="">{{ monthly.registered }}</td>
                      <td nowrap="">{{ monthly.deposited }}</td>
                      <td nowrap="">{{ monthly.returns_display }}</td>
                      <td nowrap="">{{ monthly.epc_display }}</td>
                      <td nowrap="">{{ monthly.withdrawals_display }}</td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="alert alert-secondary">
                <div class="alert-text">
                  <i class="fas fa-info-circle"></i> {{ $t('affiliate.clicks_stats_rule') }}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </Loader>
  </ReferralBasePage>
</template>

<script>
import format from 'date-fns/format'
import subMonths from 'date-fns/subMonths'
import subYears from 'date-fns/subYears'
import DatePicker from 'vuejs-datepicker'
import Loader from '../components/global/Loader'
import ReferralBasePage from './ReferralBasePage'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import {mapState} from "vuex";

export default {
  name: 'ReferralAnalyticsPage',
  components: {
    ReferralBasePage,
    Loader,
    DateRangePicker
  },
  data () {
    return {
      isLoading: true,
      trackVariables: [],
      trackVariablesSelected: [],
      report: {},
      dateRange: {
        startDate: subMonths(new Date(), 1),
        endDate: new Date()
      },
      dateFormat: 'yyyy-MM-dd'
    }
  },
  watch: {
    /*'dateRange.startDate' (newVal, oldVal) {
      if (newVal !== oldVal && this.endDate) {
        this.updateVars()
        this.updateData()
      }
    },
    'dateRange.endDate' (newVal, oldVal) {
      if (newVal !== oldVal && this.startDate) {
        this.updateVars()
        this.updateData()
      }
    }*/
  },
  created () {
    this.dateRange.startDate = subMonths(new Date(), 1)
    this.dateRange.endDate = new Date()
    this.updateVars()
    this.updateData()
  },
  computed: {
    startDateFormatted () {
      if (this.dateRange.startDate) {
        return format(this.dateRange.startDate, this.dateFormat)
      }
      return null
    },
    endDateFormatted () {
      if (this.dateRange.endDate) {
        return format(this.dateRange.endDate, this.dateFormat)
      }
      return null
    },
    ...mapState({
      profile: state => state.authUser.profile
    }),
  },
  methods: {
    updateDateRange(startDate,endDate) {
      this.updateVars()
      this.updateData()
    },
    updateVars () {
      let params = {
        start_date: format(this.dateRange.startDate, 'yyyy-MM-dd'),
        end_date: format(this.dateRange.endDate, 'yyyy-MM-dd')
      }
      axios.get(this.$api.affiliate + '/vars', { params }).then(response => {
        this.trackVariables = response.data.data
      }).catch(error => {

      })
    },
    updateData () {
      this.isLoading = true
      let vars = []
      /*this.trackVariablesSelected.forEach(val => {
        vars.push(this.trackVariables[val])
      })*/
      let params = {
        start_date: format(this.dateRange.startDate, 'yyyy-MM-dd'),
        end_date: format(this.dateRange.endDate, 'yyyy-MM-dd'),
        vars: this.trackVariablesSelected
      }
      axios.get(this.$api.affiliate + '/report', { params }).then(response => {
        this.report = response.data.data
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          if(error.response.status === 400){
            this.dateRange.startDate = subYears(new Date(), 1)
            this.dateRange.endDate = new Date()
          }
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
      })
    }
  }
}
</script>

<style>
  .daterange-custom {
    height: auto;
    padding: 4px 10px;
  }
  .daterangepicker {
    border: 0 none;
  }
</style>
