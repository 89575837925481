<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('profile.contact_title')"
      @hidden="hideModal"
      no-close-on-backdrop
      no-close-on-esc
      header-close-content=""
      size="lg"
    >
      <Loader :loading="isLoading">
        <form
          novalidate="novalidate"
          class="kt-form kt-form--label-right"
          @submit.prevent="updateEmergencyContact"
        >

          <InactivePeriodSelect
            id="inactive_period"
            v-model="formData.inactive_period"
            name="inactive_period"
            :errors="errors"
            :label="$t('profile.contact_period')"
            :cols="3"
            :description="$t('profile.contact_period_details')"
          />

          <AccountInput
            id="inactive_name"
            v-model="formData.inactive_name"
            name="inactive_name"
            :errors="errors"
            :label="$t('profile.contact_name')"
            :cols="3"
            :description="$t('profile.contact_name_details')"
          />

          <AccountInput
            id="inactive_email"
            v-model="formData.inactive_email"
            name="inactive_email"
            :errors="errors"
            :label="$t('profile.contact_email')"
            :cols="3"
            :description="$t('profile.contact_email_details')"
          />

          <AccountInput
            id="inactive_phone"
            v-model="formData.inactive_phone"
            name="inactive_phone"
            :errors="errors"
            :label="$t('profile.contact_phone')"
            :cols="3"
            :description="$t('profile.contact_phone_details')"
          />

          <AccountInput
            id="inactive_message"
            v-model="formData.inactive_message"
            name="inactive_message"
            :errors="errors"
            :label="$t('profile.contact_message')"
            :cols="3"
            type="textarea"
            :description="$t('profile.contact_message_details')"
          />

          <div class="kt-align-center">
            <button type="submit" class="btn btn-success">{{ $t('profile.contact_save') }}</button>
          </div>
        </form>
      </Loader>
      <div slot="modal-footer" />
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import { getNumeric } from '../../helpers/strings'
import Loader from 'js/components/global/Loader'
import InactivePeriodSelect from "../forms/PeriodSelect";
import AccountInput from "../forms/AccountInput";
import formHasErrors from '../../mixins/formHasErrors'

export default {
  name: 'EmergencyContactModal',
  components: {
    AccountInput,
    InactivePeriodSelect,
    Loader
  },
  mixins: [
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    token: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      isLoading: false,
      errors: {},
      formData: {
        inactive_period: null,
        inactive_name: null,
        inactive_email: null,
        inactive_phone: null,
        inactive_message: null,
      },
      modalOpen: this.show
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  watch: {
  },
  created () {
    this.formData.inactive_period = this.profile.inactive_period;
    this.formData.inactive_name = this.profile.inactive_name;
    this.formData.inactive_email = this.profile.inactive_email;
    this.formData.inactive_phone = this.profile.inactive_phone;
    this.formData.inactive_message = this.profile.inactive_message;
  },

  methods: {
    updateEmergencyContact () {
      this.clearErrors()
      this.isLoading = true
      axios.post(this.$api.updateUserEmergency, {
        inactive_period: this.formData.inactive_period,
        inactive_name: this.formData.inactive_name,
        inactive_email: this.formData.inactive_email,
        inactive_phone: this.formData.inactive_phone,
        inactive_message: this.formData.inactive_message,
        token: this.token
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', this.$t('profile.contact_success'))
        this.$emit('done');
      }).catch(error => {
        this.isLoading = false
        if (error.response) {
          if (error.response.status === 422) {
            this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
            this.setErrors(error.response.data.errors)
          } else if (error.response.data && error.response.data.data && error.response.data.data.message) {
            this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          } else {
            this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          }
        }
      });

    },
    hideModal () {
      this.$emit('hidden')
    }
  }
}
</script>
