<template>
  <div
    v-if=""
    class="alert alert-warning"
    role="alert"
  >
    <div class="alert-text">
      <h4 class="alert-heading">{{ $t('deposit.idle_title') }}</h4>
      <p>{{ $t('deposit.idle_info',{ days: 7 }) }}</p>
      <ul v-for="item in idle">
        <li v-html="$t('deposit.idle_deposit',{ amount: item.amount_formatted, since: item.traded_since })"></li>
      </ul>
      <!--<span v-html="$t('dashboard.invested_balance_warning', { minimum: invested_minimum})"></span>-->
    </div>
  </div>
</template>

<script>
export default {
  name: 'BalanceWarning',
  components: {
  },
  props: {
    idle: {
      type: Array,
      default: null
    }
  },
  computed: {

  }
}
</script>
