<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12">

      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
                <i class="fas fa-search-dollar"></i>
            </span>
            <h3 class="kt-portlet__head-title">
              {{ $t('title.audit_july2022') }}
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <b><router-link :to="{name: 'audits'}"><i class="fas fa-undo"></i> Previous Audits</router-link></b>
          </div>
        </div>
          <div class="kt-portlet__body">

            <July2022 />

          </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import July2022 from './July2022'

export default {
  name: 'AuditJuly2022Page',
  components: {
    July2022
  },
  data () {
    return {

    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {

  },
  methods: {

  }
}
</script>
