<template>
  <div>
    <VerificationInfo v-if="!profile.account_verified" />

    <div class="kt-portlet">
      <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              :value="profile.deposit_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.deposit_balance_btc || null"
              :value-in-eur="profile.deposit_balance_eur || null"
              :value-in-eth="profile.deposit_balance_eth || null"
              dont-format
              icon="invest"
              :title="$t('dashboard.invested_balance')"
              :dots-tooltip="$t('dashboard.invested_balance_tooltip')"
              :has-warning="!!profile.deposit_balance_below_min"
              :info-text="$t('dashboard.invested_balance_low')"
              :info-tooltip="$t('dashboard.invested_balance_low_tooltip',{ minimum: profile.invested_balance_minimum_display})"
              :info="profile.deposit_has_idle?$t('deposit.idle_detected'):null"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              icon="balance"
              :title="$t('otc.pending')"
              :value="null"
              currency="₿"
              :value-in-btc="profile.otc_pending_btc"
              :value-in-sapp="profile.otc_pending_sapp"
              :decimals="8"
              dont-format
              color="success"
            />
          </div>
          <div class="col-md-12 col-lg-4 col-xl-4">
            <StatisticsBox
              :value="profile.withdraw_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.withdraw_balance_btc || null"
              :value-in-eur="profile.withdraw_balance_eur || null"
              :value-in-eth="profile.withdraw_balance_eth || null"
              dont-format
              color="warning"
              icon="balance"
              :title="$t('dashboard.withdrawable_balance')"
              :dots-tooltip="$t('dashboard.withdrawable_balance_tooltip')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8 col-md-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">Convert BTC to SAPP</h3>
            </div>
            <div class="kt-portlet__head-toolbar">
            </div>
          </div>
          <div class="kt-portlet__body">
            <Loader :loading="isLoadingWallet" loader-style="overflow: hidden">
              <div
                v-if="!profile.account_verified"
                class="alert alert-danger"
                role="alert"
              >
                <div class="alert-text">
                  {{ $t('otc.account_not_verified') }}
                </div>
              </div>
              <template v-if="profile.account_verified">
                <template v-if="profile.otc_wallet">
                  <template v-if="!changeAddressMode">
                    <div class="kt-margin-b-10 kt-margin-l-10">
                      <i class="fas fa-arrow-alt-circle-right"></i> Use this wallet to send Bitcoins:
                    </div>
                    <div class="input-group kt-margin-b-20">
                      <div class="input-group-prepend">
                        <span class="input-group-text kt-font-bold">BTC</span>
                      </div>
                      <input type="text" class="form-control" id="address_btc" :value="profile.otc_wallet" readonly="">
                      <div class="input-group-append">
                        <button
                          id="wallet_btc_copy"
                          type="button"
                          class="btn btn-secondary btn-icon"
                          @click="copyBTCWalletAddress"
                        ><i class="far fa-copy"></i></button>
                      </div>
                    </div>
                    <div class="kt-margin-b-10 kt-margin-l-10">
                      <i class="fas fa-arrow-alt-circle-right"></i> SAPP will be sent here:
                    </div>
                    <div class="input-group kt-margin-b-20">
                      <div class="input-group-prepend">
                        <span class="input-group-text kt-font-bold">{{ profile.otc_currency_final }}</span>
                      </div>
                      <input type="text" class="form-control" id="address_sapp" :value="profile.otc_wallet_final" readonly="">
                      <div class="input-group-append">
                        <button
                          type="button"
                          class="btn btn-warning btn-icon"
                          @click="changeAddressModeOn"
                        ><i class="fas fa-pen"></i></button>
                      </div>
                    </div>

                    <div class="alert alert-secondary kt-margin-b-0" role="alert">
                      <div class="alert-icon"><i class="fas fa-info-circle"></i></div>
                      <div class="alert-text">
                        <p>Once we detect your transaction (might take up to 60 minutes after first confirmation) - your SAPP amount will be calculated using average exchange rate and saved.</p>
                        <p>SAPP payments are usually done during 24-48 hours after your transaction.</p>
                      </div>
                    </div>


                  </template>
                  <template v-else>
                    <div class="kt-section kt-section--first">
                      <div class="form-group">
                        <label>Edit Your SAPP Address:</label>
                        <input name="address" v-model="values.address" type="text" class="form-control" placeholder="Enter your SAPP address">
                        <div
                          v-if="hasErrors && errors['address']"
                          class="invalid-feedback"
                          style="display: block;"
                        >
                          {{ errors['address'][0] }}
                        </div>
                        <span class="form-text text-muted">Make sure you enter this address correctly. Your SAPP will be sent here.</span>
                      </div>
                      <div class="form-group">
                        <button @click="generateCheck('otc_wallet_change')" class="btn btn-primary">Edit Wallet Address</button>&nbsp;
                        <button
                          type="button"
                          class="btn btn-secondary"
                          @click="changeAddressModeOff"
                        >Cancel</button>

                      </div>
                    </div>

                  </template>
                </template>
                <template v-else>
                  <div class="kt-section kt-section--first">
                    <div class="form-group">
                      <label>Your SAPP Address:</label>
                      <input name="address" v-model="values.address" type="text" class="form-control" placeholder="Enter your SAPP address">
                      <div
                        v-if="hasErrors && errors['address']"
                        class="invalid-feedback"
                        style="display: block;"
                      >
                        {{ errors['address'][0] }}
                      </div>
                      <span class="form-text text-muted">Make sure you enter this address correctly. Your SAPP will be sent here.</span>
                    </div>
                    <div class="form-group">
                      <button
                        disabled="disabled"
                        @click="generateCheck('otc_wallet')" class="btn btn-primary">Generate Wallet</button>
                    </div>
                  </div>
                </template>
              </template>
            </Loader>
          </div>
        </div>

      </div>
      <div class="col-lg-4 col-md-12">

        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">BTC to SAPP Calculator</h3>
            </div>
            <div class="kt-portlet__head-toolbar">
            </div>
          </div>
          <div class="kt-portlet__body">

            <div>Enter BTC amount that you plan to exchange to SAPP:</div>
            <div class="input-group kt-margin-b-5">
              <div class="input-group-prepend">
                <span class="input-group-text"><i class="fab fa-bitcoin"></i></span>
              </div>
              <MaskedInput
                type="text"
                class="form-control"
                :value="btcAmount"
                placeholder="0.00"
                :mask="btcMask"
                :guide="false"
                @input="setBtcAmount"
              />
            </div>
            <div
              v-if="btcToSappExRate"
              class="alert alert-secondary"
            >
              <div class="alert-text">
                <div>Approximate SAPP Amount:</div>
                <p class="lead" style="margin-bottom:0"><b>{{ sappAmount }}</b></p>
                <small>Using Rate: {{ btcToSappExRate }}</small>
              </div>
            </div>
            <!--<div v-else-if="sappAmount && btcAmountHasError"
                 class="alert alert-danger"
            >
              Minimum BTC amount to exchange is {{ profile.otc_min_btc }}
            </div>-->

          </div>
        </div>
      </div>

      <div class="col-xl-12">

        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">Exchange History</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <OTCActivity
              ref="OTCActivity"
            />
          </div>
        </div>
      </div>
    </div>

    <MfaModal
      v-if="showMfa"
      :show="showMfa"
      action="otc_wallet"
      :method="profile.mfa_method"
      @verified="generate_wallet"
      @hidden="hideMfa"
    />

    <MfaModal
      v-if="showMfa2"
      :show="showMfa2"
      action="otc_wallet_change"
      :method="profile.mfa_method"
      @verified="change_address"
      @hidden="showMfa2 = false"
    />

  </div>


</template>

<script>
import { mapState } from 'vuex'
import StatisticsBox from '../components/functional/StatisticsBox'
import BalanceWarning from '../components/functional/BalanceWarning'
import OTCActivity from '../components/OTC/OTCActivity'
import moment from 'moment'
import NewUserAlert from '@/components/functional/NewUserAlert';
import EmergencyWithdrawalWarning from '@/components/functional/EmergencyWithdrawalWarning';
import VerificationInfo from '../components/Withdrawal/VerificationInfo'
import createNumberMask from "text-mask-addons/dist/createNumberMask";
import copyTextToClipboard from "@/helpers/clipboard";
import Loader from "@/components/global/Loader";
import requiresMfa from '../mixins/requiresMfa'
import formHasErrors from '../mixins/formHasErrors'

const btcMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 8,
  requireDecimal: false
})

export default {
  name: 'OTCPage',
  components: {
    Loader,
    VerificationInfo,
    EmergencyWithdrawalWarning,
    NewUserAlert,
    StatisticsBox,
    BalanceWarning,
    OTCActivity
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      btcAmount: null,
      sappAmount: 0,
      btcMask,
      isLoadingWallet: false,
      showMfa: false,
      action: null,
      showMfa2: false,
      values: {
        address: null
      },
      changeAddressMode: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    btcToSappExRate(){
      return this.profile.ex_rate_btc_sapp.toString();
    },
    btcAmountHasError(){
      return false;
      if(this.btcAmount > 0){
        if(this.btcAmount < this.profile.otc_min_btc){
          return true;
        }
      }
      return false
    }
  },
  methods: {
    updateProfile () {
      this.$store.dispatch('setAuthUser')
      this.$refs.OTCActivity.getHistory()
    },
    moment: function () {
      return moment();
    },
    setBtcAmount (value) {
      if (!value) {
        value = "0"
      }
      this.btcAmount = value.toString()
      if (this.profile.ex_rate_btc_sapp > 0) {
        this.sappAmount = (this.btcAmount * this.profile.ex_rate_btc_sapp).toFixed(2).toString()
      }else{
        this.sappAmount = "0"
      }
    },
    copyBTCWalletAddress () {
      copyTextToClipboard(this.profile.otc_wallet, 'wallet_btc_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('otc.address_copied'))
      }).catch(error => {

      })
    },
    changeAddressModeOn() {
      this.changeAddressMode = true;
      this.values.address = this.profile.otc_wallet_final;
    },
    changeAddressModeOff() {
      this.changeAddressMode = false;
      this.values.address = null;
    },
    generateCheck (action) {
      this.clearErrors()

      if(!this.values.address){
        this.addError('address', 'Please, insert your SAPP address!')
        return false;
      }

      this.isLoadingWallet = true
      axios.post(this.$api.otc + '/address_validate', { address: this.values.address }).then(response => {
        this.isLoadingWallet = false;
        if (this.profile.mfa_method) {
          if(action === 'otc_wallet'){
            this.showMfa = true
          }
          if(action === 'otc_wallet_change'){
            this.showMfa2 = true
          }
        } else {
          if(action === 'otc_wallet'){
            this.generate_wallet(null)
          }
          if(action === 'otc_wallet_change'){
            this.change_address(null)
          }
        }
      }).catch(error => {
        if (error.response) {
          if (error.response.data.errors) {
            this.setErrors(error.response.data.errors)
          }
        }
        this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
        this.isLoadingWallet = false
      })

    },
    generate_wallet(token = null) {
      this.showMfa = false
      this.clearErrors()
      this.isLoadingWallet = true
      axios.post(this.$api.otc + '/wallet', { address: this.values.address, currency: 'SAPP', token }).then(response => {
        this.$store.dispatch('showSuccessNotification', 'BTC Address has been generated!')
        this.values.address = null
        this.$store.dispatch('setAuthUser').then((response) => {
          this.isLoadingWallet = false
        })
      }).catch(error => {
        if (error.response) {
          if (error.response.status === 422) {
            this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
            if (error.response.data.errors) {
              this.setErrors(error.response.data.errors)
            }
          }else if(error.response.data && error.response.data.data && error.response.data.data.message){
            this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          }else{
            this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          }
        }
        this.isLoadingWallet = false
      })

    },
    change_address(token = null) {
      this.showMfa2 = false
      this.clearErrors()
      this.isLoadingWallet = true
      axios.post(this.$api.otc + '/wallet_change', { address: this.profile.otc_wallet, new_address: this.values.address, currency: 'SAPP', token }).then(response => {
        this.$store.dispatch('showSuccessNotification', 'Your new address has been saved!')
        this.changeAddressModeOff();
        this.values.address = null
        this.$store.dispatch('setAuthUser').then((response) => {
          this.isLoadingWallet = false
        })
      }).catch(error => {
        if (error.response) {
          if (error.response.status === 422) {
            this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
            if (error.response.data.errors) {
              this.setErrors(error.response.data.errors)
            }
          }else if(error.response.data && error.response.data.data && error.response.data.data.message){
            this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          }else{
            this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          }
        }
        this.isLoadingWallet = false
      })

    },


  }
}
</script>
