<template>
  <Loader :loading="isLoading">
    <div v-if="p2p_mode">
      <template v-if="p2p_mode === 'seller'">
        <h2 class="kt-margin-b-15">You are selling your balance</h2>
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover" id="p2p_table1">
            <tbody v-if="p2p_sell">
            <tr>
              <th class="col-md-4" nowrap>
                Amount for sale:
              </th>
              <td class="col-md-8" nowrap>
                {{ p2p_sell.amount_initial_display }}
              </td>
            </tr>
            <tr>
              <th nowrap>
                Price:
              </th>
              <td nowrap>
                {{ p2p_sell.amount_display }} ({{ p2p_sell.discount }}%)
              </td>
            </tr>
            <tr v-if="p2p_sell.amount_real_display !== p2p_sell.amount_display">
              <th nowrap>
                Price (current rate):
              </th>
              <td nowrap>
                {{ p2p_sell.amount_real_display }} ({{ p2p_sell.discount_real }}%)
              </td>
            </tr>
            <tr v-if="p2p_sell.btc_wallet">
              <th nowrap>
                Price in BTC & Wallet:
              </th>
              <td >
                <b>{{ p2p_sell.amount_btc_display }}</b> (to {{ p2p_sell.btc_wallet }})
              </td>
            </tr>
            <tr v-if="p2p_sell.usdt_wallet">
              <th nowrap>
                Price in USDT & Wallet:
              </th>
              <td >
                <b>{{ p2p_sell.amount_usdt_display }}</b> (to {{ p2p_sell.usdt_wallet }})
              </td>
            </tr>
            <tr v-if="p2p_sell.usdc_wallet">
              <th nowrap>
                Price in USDC & Wallet:
              </th>
              <td>
                {{ p2p_sell.amount_usdc_display }} (to {{ p2p_sell.usdc_wallet }})
              </td>
            </tr>
            <!--<tr v-if="p2p_sell.status === 'pending' && !p2p_sell.has_buyer && p2p_sell.can_update_price">
              <th></th>
              <td>
                <a href="#" @click.prevent="refreshCryptoPrice">Refresh Price in Crypto</a>
              </td>
            </tr>-->
            <tr>
              <th nowrap>
                Created at:
              </th>
              <td nowrap>
                {{ p2p_sell.created_at.full }} UTC
              </td>
            </tr>
            <tr>
              <th nowrap>
                Status:
              </th>
              <td nowrap>
                {{ p2p_sell.status_display }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <template v-if="p2p_sell && p2p_sell.has_buyer">
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover" id="p2p_table11">
              <tbody>
              <tr>
                <th class="col-md-4" nowrap>
                  Has Potential buyer:
                </th>
                <td class="col-md-8" nowrap>
                  Yes
                </td>
              </tr>
              <tr>
                <th nowrap>
                  Signed Agreement:
                </th>
                <td v-if="p2p_sell.has_agreement" nowrap>
                  <a :href="'/api/user/p2p_sell/'+ p2p_sell.id +'/download'" target="_blank">Download PDF</a>
                </td>
                <td v-else nowrap>
                  <em>Generating... Reload the page in 5 minutes to download.</em>
                </td>
              </tr>
              <tr v-if="p2p_sell.transaction_hash">
                <th>
                  Transaction Id (from buyer):
                </th>
                <td>
                  <div class="kt-font-md kt-margin-b-5">
                    <b>{{ p2p_sell.transaction_hash }} ({{ p2p_sell.transaction_currency }})</b><br><a v-if="p2p_sell.transaction_explorer" :href="p2p_sell.transaction_explorer" target="_blank" class="">Lookup in Explorer <i class="fas fa-external-link-alt"></i></a>
                  </div>
                  <div class="kt-font-lg kt-margin-b-5">
                    Currency: <b>{{ p2p_sell.transaction_currency }}</b>
                  </div>
                  <div class="kt-margin-b-5">If you see incoming amount on your wallet already, you can approve manually. Be careful and make sure you've received this amount!</div>
                  <button class="btn btn-primary btn-sm" @click.prevent="approvePrompt">Manually approve receipt of funds!</button>
                </td>
              </tr>
              <tr v-else>
                <th>
                  Transaction Id (from buyer):
                </th>
                <td>
                  <div class="kt-margin-b-10"><em>Buyer didn't submit transaction id (hash) yet</em></div>
                  <div class="kt-margin-b-5">If you see incoming amount on your wallet already, you can approve manually. Be careful and make sure you've received this amount!</div>
                  <button class="btn btn-primary btn-sm" @click.prevent="approvePrompt">Manually approve receipt of funds!</button>
                </td>
              </tr>
              <tr>
                <th>
                  Expires At:
                </th>
                <td>
                  <template v-if="p2p_sell.expires_at">
                    {{ p2p_sell.expires_at.full }} UTC
                  </template>
                  <template v-else>
                    Never, until resolved
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </template>
        <button v-if="p2p_sell && !p2p_sell.has_buyer" class="btn btn-sm btn-secondary" @click.prevent="cancelSellRequestPrompt">Cancel selling request</button>
        <hr>
      </template>

      <template v-else-if="p2p_mode === 'buyer'">
        <h2 class="kt-margin-b-15">You are buying balance</h2>
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover" id="p2p_table2">
            <tbody v-if="p2p_sell">
            <tr>
              <th class="col-md-4" nowrap>
                Amount for sale:
              </th>
              <td class="col-md-8" nowrap>
                {{ p2p_sell.amount_initial_display }}
              </td>
            </tr>
            <tr>
              <th nowrap>
                Price (current rate):
              </th>
              <td nowrap>
                {{ p2p_sell.amount_real_display }} ({{ p2p_sell.discount_real }}%)
              </td>
            </tr>
            <tr v-if="p2p_sell.btc_wallet">
              <th nowrap>
                in BTC & Address:
              </th>
              <td>
                <div class="alert alert-warning kt-margin-b-15">
                  Do not transfer from Binance (or other exchange) internally without the use of blockchain! This could cause significant delays with approval of purchase!
                </div>
                <div class="kt-font-lg kt-margin-b-5" @click="copyBTCAmount">{{ p2p_sell.amount_btc_display }} <i id="btc_amount_copy" style="cursor:pointer;margin-left:5px;" @click="copyBTCAmount" class="fas fa-copy"></i></div>
                <div class="kt-font-lg" @click="copyBTCAddress">{{ p2p_sell.btc_wallet }} <i id="btc_wallet_copy" style="cursor:pointer;margin-left:5px;" @click="copyBTCAddress" class="fas fa-copy"></i></div>
              </td>
            </tr>
            <tr v-if="p2p_sell.usdt_wallet">
              <th nowrap>
                in USDT (trc20) & Address:
              </th>
              <td >
                <div class="kt-font-lg kt-margin-b-5" @click="copyUSDTAmount">{{ p2p_sell.amount_usdt_display }} <i id="usdt_amount_copy" style="cursor:pointer;margin-left:5px;" @click="copyUSDTAmount" class="fas fa-copy"></i></div>
                <div class="kt-font-lg" @click="copyUSDTAddress">{{ p2p_sell.usdt_wallet }} <i id="usdt_wallet_copy" style="cursor:pointer;margin-left:5px;" @click="copyUSDTAddress" class="fas fa-copy"></i></div>
              </td>
            </tr>
            <tr v-if="p2p_sell.usdc_wallet">
              <th nowrap>
                in USDC (trc20) & Address:
              </th>
              <td>
                <div class="kt-font-lg kt-margin-b-5" @click="copyUSDCAmount">{{ p2p_sell.amount_usdc_display }} <i id="usdc_amount_copy" style="cursor:pointer;margin-left:5px;" @click="copyUSDCAmount" class="fas fa-copy"></i></div>
                <div class="kt-font-lg" @click="copyUSDCAddress">{{ p2p_sell.usdc_wallet }} <i id="usdc_wallet_copy" style="cursor:pointer;margin-left:5px;" @click="copyUSDCAddress" class="fas fa-copy"></i></div>
              </td>
            </tr>
            <tr>
              <th nowrap>
                Created at:
              </th>
              <td nowrap>
                {{ p2p_sell.created_at.full }} UTC
              </td>
            </tr>
            <tr>
              <th nowrap>
                Status:
              </th>
              <td nowrap>
                {{ p2p_sell.status_display }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover" id="p2p_table22">
            <tbody v-if="p2p_sell">
            <tr>
              <th nowrap>
                Signed Agreement:
              </th>
              <td v-if="p2p_sell.has_agreement" nowrap>
                <a :href="'/api/user/p2p_sell/'+ p2p_sell.id +'/download'" target="_blank">Download PDF</a>
              </td>
              <td v-else nowrap>
                <em>Generating... Reload the page in 5 minutes to download.</em>
              </td>
            </tr>
            <tr>
              <th class="col-md-4">
                Transaction Id (hash):
              </th>
              <td class="col-md-8">
                <h4 class="kt-margin-b-15">Already Transferred?</h4>
                <div
                  v-if="p2p_sell.transaction_hash"
                  class="alert alert-warning"
                >
                  <div class="alert-text">
                    <i class="fas fa-exclamation-circle"></i>
                    Your purchase is not complete, because either your saved transaction amount is lower than required in this balance selling request or you didn't submit correct hash format. Please, send remaining amount to the same address and contact our support to solve the issue manually in seller doesn't approve it manually within several days.
                  </div>
                </div>
                <div
                  v-if="hasError"
                  class="alert alert-danger"
                >
                  <div class="alert-text">
                    <i class="fas fa-exclamation-circle"></i>
                    {{ errorMsg }}
                  </div>
                </div>
                <b-form-group
                  label="Select Currency:"
                  label-for="input-1"
                >
                  <b-form-select id="input-1" v-model="transaction_currency" :options="currency_options"></b-form-select>
                </b-form-group>
                <b-form-group
                  label="Enter Transaction id (hash):"
                  label-for="input-2"
                >
                  <b-form-input
                    :state="hasErrorState" id="input-2" v-model="transaction_hash" placeholder="5e2100d61d51566d6125f86d181d81dede0dd026f7ef497b779d32813321f6f2"></b-form-input>
                </b-form-group>
                <b-button @click.prevent="saveTransactionId" variant="success">Submit & Check</b-button>
                <div class="kt-font-md kt-margin-t-10">Make sure that currency and transaction id (hash) is entered correctly and the transaction is already sent!</div>
              </td>
            </tr>
            <tr>
              <th>
                Expires At:
              </th>
              <td>
                <template v-if="p2p_sell.expires_at">
                  <div class="kt-margin-b-10">{{ p2p_sell.expires_at.full }} UTC</div>
                  <div v-if="timer_active" class="lead kt-margin-b-20">
                    <div class="alert alert-info">
                      <span v-if="hours > 0" class="kt-font-bold kt-margin-r-10">{{ hours }} {{ hours > 1 ? 'hours' : 'hour' }} </span><span v-if="minutes > 0" class="kt-font-bold  kt-margin-r-10">{{ minutes }} {{ minutes > 1 ? 'minutes' : 'minute' }}</span><span v-if="seconds > 0" class="kt-font-bold">{{ seconds }} {{ seconds > 1 ? 'seconds' : 'second' }}</span>
                    </div>
                    <div class="kt-margin-t-10">at {{ expires_at_local }} your time.</div>
                  </div>
                  <p v-else class="lead">Expired!</p>
                </template>
                <template v-else>
                  Never, until resolved
                </template>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <button class="btn btn-sm btn-danger" @click.prevent="cancelBuyRequestPrompt">Cancel buying request</button>
      </template>
      <!--<template v-if="p2p_mode === 'buy' || p2p_mode === 'seller'">
        <h2 class="kt-margin-b-15">Balances available for sale</h2>
        <div class="table-responsive kt-margin-t-15">
          <table class="table table-striped table-bordered table-hover" id="p2p_table">
            <thead>
              <tr>
                <th v-if="p2p_mode === 'buy' && profile.account_verified" nowrap></th>
                <th nowrap>Balance</th>
                <th nowrap>Price (current rate)</th>
                <th nowrap>Wallets</th>
              </tr>
            </thead>
            <tbody v-if="p2p_sells.length > 0">
              <tr v-for="sell in p2p_sells">
                <td v-if="p2p_mode === 'buy' && profile.account_verified" class="kt-align-center">
                  <button class="btn btn-primary btn-sm" @click.prevent="buy(sell.id)">Buy</button>
                </td>
                <td nowrap>
                  {{ sell.amount_initial_display }}
                </td>
                <td nowrap>
                  <b>{{ sell.amount_real_display }}</b> (<b>{{ sell.discount_real }}%</b>)
                </td>
                <td nowrap>
                  <template v-if="sell.has_btc_wallet">
                    <b-badge variant="warning">BTC</b-badge>&nbsp;
                  </template>
                  <template v-if="sell.has_usdt_wallet">
                    <b-badge variant="info">USDT (TRC20)</b-badge>&nbsp;
                  </template>
                  <template v-if="sell.has_usdc_wallet">
                    <b-badge variant="info">USDC (TRC20)</b-badge>&nbsp;
                  </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
            <tr>
              <td colspan="5" class="kt-align-center">
                No balances for sale at the moment
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
        ></b-pagination>
      </template>-->

      <!--<P2PBuyModal
        v-if="P2PModal"
        :show="P2PModal"
        :id="p2p_selected_id"
        @hidden="P2PModal = false"
        @done="getStatus"
      />-->
    </div>
  </Loader>
</template>

<script>
import Loader from "../global/Loader";
import requiresMfa from '../../mixins/requiresMfa'
import copyTextToClipboard from "@/helpers/clipboard";
import formHasErrors from '../../mixins/formHasErrors'
import P2PBuyModal from "@/components/P2PSells/P2PBuyModal";
import moment from "moment/moment";
import {mapState} from "vuex";

export default {
  name: 'P2PSellsActivity',
  components: {
    Loader,
    P2PBuyModal
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data () {
    return {
      p2p_mode: null,
      p2p_sells: [],
      p2p_sell: null,
      p2p_selected_id: null,
      currentPage: 1,
      totalRows: null,
      perPage: 30,
      isLoading: false,
      P2PModal: false,

      hours: 0,
      minutes: 0,
      seconds: 0,
      timer_active: false,
      timer_expires: null,
      interval_id: null,

      hasError: false,
      errorMsg: null,
      transaction_hash: null,
      transaction_currency: 'BTC',
    }
  },
  mounted () {
    this.getStatus();
  },
  watch: {
    currentPage: function(){
      this.getHistory();
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    expires_at_local(){
      if(this.p2p_sell && this.p2p_sell.expires_at.timestamp){
        return moment.unix(this.p2p_sell.expires_at.timestamp).format('D.MM.YYYY H:mm z');
      }
    },
    currency_options(){
      let currency_options = [];
      if(!this.p2p_sell) return currency_options;
      if(this.p2p_sell.btc_wallet){
        currency_options.push({
          text: 'BTC',
          value: 'BTC'
        });
      }
      if(this.p2p_sell.usdt_wallet){
        currency_options.push({
          text: 'USDT',
          value: 'USDT'
        });
      }
      if(this.p2p_sell.usdc_wallet){
        currency_options.push({
          text: 'USDC',
          value: 'USDC'
        });
      }
      return currency_options;
    },
    hasErrorState (){
      if(this.hasError === true){
        return false;
      }
      return null;
    }
  },
  methods: {
    getStatus() {
      this.isLoading = true;
      this.p2p_selected_id = null;
      this.p2p_sells = [];
      this.p2p_sell = null;

      axios.get(this.$api.p2p_sell + '/status').then(response => {
        this.isLoading = false;

        if(response.data && response.data.data && response.data.data.mode){
          this.p2p_mode = response.data.data.mode;
          this.$emit('refreshButtonStatus',this.p2p_mode);
        }
        if(this.p2p_mode === 'seller') {
          this.p2p_sell = response.data.data.p2p_sell;
          this.getHistory();
        }
        if(this.p2p_mode === 'buyer') {
          this.p2p_sell = response.data.data.p2p_sell;
          this.transaction_currency = this.p2p_sell.transaction_currency || 'BTC';
          this.transaction_hash = this.p2p_sell.transaction_hash;
          this.launchTimer();
        }
        if(this.p2p_mode === 'buy') {
          this.p2p_sell = null;
          this.getHistory();
        }

        this.totalRows = null;
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.p2p_mode = null;
        this.isLoading = false;
      })
    },
    getHistory () {
      this.isLoading = true;
      axios.get(this.$api.p2p_sell + '?page=' + this.currentPage).then(response => {
        this.isLoading = false;
        this.p2p_sells = response.data.data
        this.perPage = response.data.meta.per_page;
        this.totalRows = response.data.meta.total;
      }).catch(error => {
        this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        this.p2p_sells = []
        this.isLoading = false;
      })
    },
    buy (id) {
      this.p2p_selected_id = id;
      this.P2PModal = true;
    },
    approvePrompt() {
      if(prompt("IMPORTANT! CHECK YOUR WALLET FOR INCOMING TRANSACTION FIRST! This action is irreversible!\n\nIf you really want to approve that transfer was received on your wallet, please type \"approve\" word:") === 'approve'){
        this.approve();
      }else{
        this.$store.dispatch('showErrorNotification', 'Operation aborted!')
      }
    },
    approve() {
      this.isLoading = true
      axios.post(this.$api.p2p_sell + '/approve', {
        id: this.p2p_sell.id
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', 'Your Sell Request is Approved!')
        this.p2p_sell = null;
        this.getStatus();
      }).catch(error => {
        this.isLoading = false
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
      })
    },
    refreshCryptoPrice(){
      this.isLoading = true
      axios.patch(this.$api.p2p_sell + '/refresh', {
        id: this.p2p_sell.id
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', 'Your Sell Request crypto price has been updated!')
        this.p2p_sell = null;
        this.getStatus();
      }).catch(error => {
        this.isLoading = false
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
      })
    },
    cancelSellRequestPrompt() {
      if(prompt('If you really want to cancel, please type "cancel" word:') === 'cancel'){
        this.cancelSellRequest();
      }else{
        this.$store.dispatch('showErrorNotification', 'Operation aborted!')
      }
    },
    cancelSellRequest() {
      this.isLoading = true
      axios.post(this.$api.p2p_sell + '/' + this.p2p_sell.id + '/cancel_sell', {}).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', 'Your Sell Request is Cancelled!')
        this.p2p_sell = null;
        this.getStatus();
      }).catch(error => {
        this.isLoading = false
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
      })

    },
    cancelBuyRequestPrompt() {
      if(prompt('If you really want to cancel, please type "cancel" word:') === 'cancel'){
        this.cancelBuyRequest();
      }else{
        this.$store.dispatch('showErrorNotification', 'Operation aborted!')
      }
    },
    cancelBuyRequest() {
      this.isLoading = true
      axios.post(this.$api.p2p_sell + '/' + this.p2p_sell.id + '/cancel_buy', {}).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', 'Your Buy Request is Cancelled!')
        this.p2p_sell = null;
        this.getStatus();
      }).catch(error => {
        this.isLoading = false
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
      })
    },
    saveTransactionId() {
      this.hasError = false;
      this.errorMsg = null;
      if(!this.transaction_hash){
        this.hasError = true;
        this.errorMsg = 'Transaction id cannot be empty';
        return false;
      }
      /*if(this.transaction_hash.length !== 64){
        this.hasError = true;
        this.errorMsg = 'Transaction id is wrong format!';
        return false;
      }*/

      this.isLoading = true
      axios.post(this.$api.p2p_sell + '/transaction', {
        id: this.p2p_sell.id,
        transaction_currency: this.transaction_currency,
        transaction_hash: this.transaction_hash
      }).then(response => {
        this.isLoading = false
        if(response.status === 201){
          // approved
          this.$store.dispatch('showSuccessNotification', 'Your Buy Request is Approved!')
          this.p2p_sell = null;
          this.getStatus();
        }else if(response.status === 204){
          // saved
          this.$store.dispatch('showSuccessNotification', 'Your Transaction hash is saved! Expiration date is removed!')
          this.hasError = true;
          this.errorMsg = 'Transaction id has been found, but the amount seems to be lower than required! Make sure you\'ve sent enough crypto and contact our customer support if not... Your transaction id was sent to seller and saved. Expiration date is removed!';
          this.getStatus();
        }else if(response.status === 203){
          // saved
          this.$store.dispatch('showSuccessNotification', 'Your Transaction hash/id is saved! Expiration date is removed!')
          this.hasError = true;
          this.errorMsg = 'Transaction id/hash has been saved and seller notified. But since you didn\'t submit the right hash format - there is no way to verify your purchase automatically. Make sure you\'ve sent enough crypto to the correct wallet address. If the seller is not approving this manually for the long time - contact our customer support. But your expiration date is already removed. You can also re-submit correct hash if you find it.';
          this.getStatus();
        }else{
          this.getStatus();
        }
      }).catch(error => {
        this.isLoading = false
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          this.hasError = true;
          this.errorMsg = error.response.data.data.message;
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
        }
      })
    },


    launchTimer () {
      if(this.p2p_mode === 'buyer' && this.p2p_sell && this.p2p_sell.expires_at){
        this.timer_expires = moment.unix(this.p2p_sell.expires_at.timestamp);
        if(this.timer_expires.isAfter() && !this.timer_active){
          this.timer_active = true;
          this.calc_hms();

          clearInterval(this.interval_id);
          let t = this;
          this.interval_id = setInterval(function() {
            t.calc_hms();
          },1000);
        }
      }
    },
    stopTimer() {
      clearInterval(this.interval_id);
      this.timer_active = false;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
      this.selected_id = null;
    },
    calc_hms() {
      let timestamp_now = moment().unix();
      let expires_at = (this.p2p_mode === 'buyer' && this.p2p_sell && this.p2p_sell.expires_at) ? this.p2p_sell.expires_at.timestamp : 0;
      let diff = expires_at - timestamp_now;
      if(diff <= 0){
        this.stopTimer();
      }
      if(Math.floor(diff/3600) >= 1){
        this.hours = Math.floor(diff/3600);
        diff -= this.hours * 3600;
      }
      if(Math.floor(diff/60) >= 1){
        this.minutes = Math.floor(diff/60);
        diff -= this.minutes * 60;
      }
      this.seconds = diff;
    },
    copyBTCAddress () {
      copyTextToClipboard(this.p2p_sell.btc_wallet, 'btc_wallet_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    copyBTCAmount () {
      copyTextToClipboard(this.p2p_sell.amount_btc, 'btc_amount_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.amount_copied'))
      }).catch(error => {

      })
    },
    copyUSDTAddress () {
      copyTextToClipboard(this.p2p_sell.usdt_wallet, 'usdt_wallet_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    copyUSDTAmount () {
      copyTextToClipboard(this.p2p_sell.amount_usdt, 'usdt_amount_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.amount_copied'))
      }).catch(error => {

      })
    },
    copyUSDCAddress () {
      copyTextToClipboard(this.p2p_sell.usdc_wallet, 'usdc_wallet_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.address_copied'))
      }).catch(error => {

      })
    },
    copyUSDCAmount () {
      copyTextToClipboard(this.p2p_sell.amount_usdc, 'usdc_amount_copy').then(() => {
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.amount_copied'))
      }).catch(error => {

      })
    },

  }
}
</script>
