import createNumberMask from 'text-mask-addons/dist/createNumberMask'

export const eurMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2,
  integerLimit: 7,
  requireDecimal: false
})
export const btcMask = createNumberMask({
  prefix: '',
  suffix: '',
  includeThousandsSeparator: false,
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 8,
  integerLimit: 3,
  requireDecimal: false
})
