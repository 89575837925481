<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('deposit.voucher_title')"
      @hidden="hideModal"
      size="lg"
      no-close-on-backdrop
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <div>
          <div
            v-if="hasError"
            class="alert alert-danger"
          >
            <div class="alert-text">
              <i class="fas fa-exclamation-circle"></i>
              {{ errorMsg }}
            </div>
          </div>
          <div class="form-group row kt-margin-b-10">
            <label class="offset-lg-3 col-lg-2 offset-md-2 col-md-3 col-form-label">{{ $t('deposit.voucher_code') }}</label>
            <div class="col-lg-4 col-md-5">
              <input type="text" v-model="voucher" class="form-control" placeholder="">
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-3 col-lg-2 offset-md-2 col-md-3"></div>
            <div class="col-lg-4 col-md-5">
              <button
                :disabled="profile.limited_mode"
                @click.prevent="confirmVoucher" type="button" class="btn btn-primary">{{ $t('deposit.voucher_apply') }}</button>
            </div>
          </div>
          <div class="kt-align-center kt-margin-t-20"><a href="https://sappshopping.com/" target="_blank">{{ $t('deposit.voucher_details_link') }}</a></div>

        </div>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'

export default {
  name: 'VoucherModal',
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      modalOpen: this.show,
      hasError: false,
      errorMsg: null,
      voucher: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    confirmVoucher () {
      if(!this.voucher){
        this.hasError = true
        this.$store.dispatch('showErrorNotification', this.$t('deposit.voucher_error_empty'))
        this.errorMsg = this.$t('deposit.voucher_error_empty')
        return;
      }

      this.isLoading = true
      this.hasError = false
      this.errorMsg = null
      axios.post(this.$api.depositVoucher, {
        voucher: this.voucher
      }).then(response => {
        this.isLoading = false
        this.$store.dispatch('showSuccessNotification', this.$t('deposit.voucher_success'))
        this.$emit('done');
        this.hideModal();
      }).catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.errorMsg = error.response.data.data.message;
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else if (error.response.status === 422) {
          if(error.response.data.errors){
            let errors = error.response.data.errors
            this.$store.dispatch('showErrorNotification', errors[Object.keys(errors)[0]][0])
            this.errorMsg = errors[Object.keys(errors)[0]][0];
          }else{
            this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
            this.errorMsg = this.$t('general.error')
          }
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.errorMsg = this.$t('general.error')
        }
      })
    }
  }
}
</script>
