<template>
  <Loader :loading="isLoading">
    <div class="kt-portlet__head">
      <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
                <i class="fas fa-user-plus"></i>
            </span>
        <h3 class="kt-portlet__head-title">
          {{ $t('title.request_audit') }}
        </h3>
      </div>
      <div class="kt-portlet__head-toolbar">
      </div>
    </div>
    <div class="kt-portlet__body">
      <p>Hello! We are doing our <b>Audit in Summer 2022</b> on <b>July 18-21</b>.</p>
      <p>Before you apply, make sure you have time to fly to Malta at that dates (flight costs need to be covered by yourself) hotel and stay is on us!</p>
      <p>Enter your data below! The Raffle will occur on the 3rd of June 2022 and a YouTube video will be posted on same day announcing the Winners! 5 Auditors will be randomly choosen and 5 will be handpicked by us getting the total to 10 auditors.</p>
      <p>Best of Luck and do please triplecheck you are available!</p>
      <form
        class="kt-form kt-form--label-right"
        @submit.prevent="createAuditRequest"
      >
        <div v-if="!requestedAudit('summer2022')" class="kt-section kt-section--first kt-margin-b-0 kt-margin-t-10">
          <div class="kt-section__body">
            <AccountInput
                id="name"
                v-model="formData.name"
                name="name"
                :errors="errors"
                :label="$t('profile.name_full')"
                :cols="3"
                :required="true"
            />

            <AccountInput
                id="email"
                v-model="formData.email"
                type="email"
                name="email"
                :errors="errors"
                :label="$t('profile.email')"
                :cols="3"
                :required="true"
            />

            <AccountInput
                ref="phoneInput"
                id="phone"
                v-model="formData.phone"
                type="phone"
                name="phone"
                :errors="errors"
                :label="$t('profile.phone')"
                :cols="3"
                :required="true"
            />

            <AccountInput
                id="address"
                v-model="formData.address"
                name="address"
                :errors="errors"
                :label="$t('profile.address')"
                :cols="3"
                :required="true"
            />

            <AccountInput
              id="reason"
              type="textarea"
              v-model="formData.reason"
              name="reason"
              :errors="errors"
              :label="$t('profile.audit_reason')"
              :cols="3"
              :required="false"
            />
          </div>
          <div class="kt-form__actions">
            <div class="row">
              <div class="col-lg-3 col-xl-3">
              </div>
              <div class="col-lg-9 col-xl-9">
                <button type="submit" class="btn btn-success">Send Request</button>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="kt-section kt-section--first kt-margin-b-0 kt-margin-t-10">
            <div class="kt-section__body">
              <div v-if="form_success" class="alert alert-success kt-margin-b-20">
                Your request was submitted!
              </div>
              <div class="alert alert-success">
                The form was already used or is expired.
              </div>
            </div>
          </div>
        </div>

      </form>
    </div>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import pick from 'lodash/pick'
import AccountInput from '../forms/AccountInput'
import BirthdayInput from '../forms/BirthdayInput'
import CountrySelect from '../forms/CountrySelect'
import Loader from '../global/Loader'
import formHasErrors from '../../mixins/formHasErrors'
import moment from "moment";

export default {
  name: 'AuditForm',
  components: {
    AccountInput,
    Loader
  },
  mixins: [
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      formField: 'formData',
      form_success: false,
      formData: {
        name: null,
        email: null,
        phone: null,
        phone_country: null,
        address: null,
        reason: null
      }
    }
  },
  watch: {
    'formData.phone' (val) {
      if (val && this.$refs.phoneInput && this.$refs.phoneInput.countryCode()) {
        this.formData.phone_country = this.$refs.phoneInput.countryCode().toUpperCase()
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  mounted () {
    this.formData.name = this.profile.first_name + ' ' + this.profile.last_name;
    this.formData.email = this.profile.email;
    this.formData.address = this.profile.address + ' ' + this.profile.address2 + '; ' + this.profile.zipcode + ' ' + this.profile.city + '; ' + this.profile.country;
    this.formData.phone = this.profile.phone;
    this.formData.phone_country = this.profile.phone_country;
  },
  methods: {
    createAuditRequest () {
      this.clearErrors()
      this.isLoading = true

      axios.post(this.$api.createAuditRequest, this.formData)
        .then((response) => {
          this.isLoading = false
          if (response.status >= 200 && response.status < 300) {
            this.$store.dispatch('setAuthUser')
            this.$store.dispatch('showSuccessNotification', this.$t('profile.audit_request_sent'))
            this.form_success = true;
          }
        }).catch((error) => {
          this.isLoading = false
          if (error.response) {
            if (error.response.status === 422) {
              this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
              this.setErrors(error.response.data.errors)
            }else{
              this.$store.dispatch('showErrorNotification', this.$t('general.error'))
            }
          }
        })
    },
    requestedAudit(name){
      if(moment().isAfter('2022-06-03 00:00:00')){
        return true;
      }
      if(this.profile.audit_requests && this.profile.audit_requests[name]){
        return true;
      }
      return false;
    }

  }
}
</script>
