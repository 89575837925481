<template>
  <div
    v-if="!pending_deposit_bonus_expired"
    class="alert alert-info"
    role="alert"
  >
    <div class="alert-text">
      <h4 class="alert-heading">You have Pending Bonus Deposit</h4>
      <p>You have sent a request to buy someone's emergency withdrawal to get bonus deposit. You should complete the operation as soon as possible, before it is expired at <span v-if="profile.pending_deposit_bonus.expires_at_timestamp" class="kt-font-bolder">{{ expires_at_local }}</span> your time.</p>
      <div><router-link :to="{ name: 'deposit_buy' }" class="btn btn-info">Check Bonus Deposit Details</router-link></div>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import moment from "moment";

export default {
  name: 'PendingBonusDepositReminder',
  components: {

  },
  props: {

  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile,
    }),
    pending_deposit_bonus_expired(){
      if(this.profile.has_pending_deposit_bonus){
        let expires_at = moment.unix(this.profile.pending_deposit_bonus.expires_at_timestamp);
        if(expires_at.isAfter()){
          return false;
        }
      }
      return true;
    },
    expires_at_local(){
      if(this.profile.pending_deposit_bonus.expires_at_timestamp){
        return moment.unix(this.profile.pending_deposit_bonus.expires_at_timestamp).format('D.MM.YYYY H:mm z');
      }
    }

  }
}
</script>
