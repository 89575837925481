<template>
  <b-form-group
    :description="description"
    :label="label"
    :label-for="id"
    :invalid-feedback="errorMessage"
    :state="state"
    :label-cols-xl="cols === 0 ? null : cols"
    :label-cols-ld="cols === 0 ? null : cols"
  >

    <select
      :id="id"
      v-model="content"
      class="form-control"
      :name="name"
      :autocomplete="autocomplete"
      :required="required"
      @change="handleChange"
    >
      <option :value="null">{{ $t('profile.disabled') }}</option>
      <option :value="6">{{ $t('profile.6mon') }}</option>
      <option :value="12">{{ $t('profile.1year') }}</option>
      <option :value="24">{{ $t('profile.2year') }}</option>
      <option :value="60">{{ $t('profile.5year') }}</option>
      <option :value="120">{{ $t('profile.10year') }}</option>
    </select>

  </b-form-group>
</template>

<script>
export default {
  name: 'InactivePeriodSelect',
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => {
        return {}
      }
    },
    id: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    cols: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    hasErrors: {
      cache: false,
      get () {
        return this.errors.hasOwnProperty(this.name) && Array.isArray(this.errors[this.name]) && this.errors[this.name].length > 0
      }
    },
    errorMessage () {
      if (this.hasErrors) {
        return this.errors[this.name][0]
      }
      return ''
    },
    state: {
      cache: false,
      get () {
        return this.hasErrors ? false : null
      }
    }
  },
  watch: {
    value (newValue) {
      this.content = newValue
    }
  },
  created () {
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    },
    handleChange () {
      this.$nextTick(() => {
        this.$emit('input', this.content)
      })
    }
  }

}
</script>
