<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      title="Request for deposit bonus"
      @hidden="hideModal"
      size="lg"
      no-close-on-backdrop
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >
        <div v-if="this.emergency_withdrawal">
          <div
            v-if="hasError"
            class="alert alert-danger"
          >
            <div class="alert-text">
              <i class="fas fa-exclamation-circle"></i>
              {{ errorMsg }}
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-3 col-lg-4">Deposit size:</div>
            <div class="col-lg-5">
              <b>{{ emergency_withdrawal.amount_bonus_display }}</b>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-2"></div>
            <div class="col-lg-8">
              <div class="alert alert-secondary">You confirm that you will receive abovementioned deposit (which contains ~20% bonus) if you send one of the amounts in crypto mentioned below:</div>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-3 col-lg-4">Amount in BTC:</div>
            <div class="col-lg-5">
              <b>~ {{ emergency_withdrawal.bonus_price_btc_display }}</b>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-3 col-lg-3"></div>
            <div class="col-lg-5 col-md-5">or</div>
          </div>
          <div class="form-group row kt-margin-b-20">
            <div class="offset-lg-3 col-lg-4">Amount in USDT (trc-20):</div>
            <div class="col-lg-5">
              <b>~ {{ emergency_withdrawal.bonus_price_trx_display }}</b>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-3 col-lg-3"></div>
            <div class="col-lg-5 col-md-5">or</div>
          </div>
          <div class="form-group row kt-margin-b-20">
            <div class="offset-lg-3 col-lg-4">Amount in USDC (trc-20):</div>
            <div class="col-lg-5">
              <b>~ {{ emergency_withdrawal.bonus_price_trx_usdc_display }}</b>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-2"></div>
            <div class="col-lg-8">
              <div class="alert alert-secondary">
                <div class="alert-text">
                  <p>You will find our exact crypto price as soon as you send request. You need to send <b>precisely</b> one of the amounts in crypto <b>right after</b> you submit the request.</p>
                  <p>If the correct amount (sent in a single transaction) is not confirmed on blockchain after the expiration time - you will not get bonus deposit.</p>
                  <p>In case of successful transaction of correct amount - you will receive two deposits: original one and a bonus deposit.</p>
                  <p>You are allowed to send deposit bonus requests only once every 48 hours, even if you cancelled previous request!</p>
                </div>
              </div>
            </div>
          </div>
          <div class="form-group row">
            <div class="offset-lg-3 col-lg-4">Request will expire:</div>
            <div class="col-lg-5">
              <b>~ {{ emergency_withdrawal.bonus_price_will_expire_at }}</b>
            </div>
          </div>
        </div>
      </Loader>
      <div slot="modal-footer">
        <div class="row">
          <div class="col text-right">
            <button @click.prevent="submit" type="button" class="btn btn-primary kt-margin-r-5">Send Request</button>
            <button @click.prevent="hideModal" type="button" data-dismiss="modal" class="btn btn-default">{{ $t('general.close') }}</button>
          </div>
        </div>
      </div>
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'

export default {
  name: 'DepositBonusModal',
  components: {
    Loader
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      isLoading: true,
      modalOpen: this.show,
      hasError: false,
      errorMsg: null,
      emergency_withdrawal: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {
    this.getEmergencyWithdrawal();
  },
  methods: {
    hideModal () {
      this.$emit('hidden');
    },
    getEmergencyWithdrawal () {
      this.isLoading = true
      this.hasError = false
      this.errorMsg = null
      axios.get(this.$api.depositBonus + '/get/' + this.id).then(response => {
        this.isLoading = false
        this.emergency_withdrawal = response.data.data;
      }).catch(error => {
        this.isLoading = false
        this.hasError = true
        if (error.response.data && error.response.data.data && error.response.data.data.message) {
          this.errorMsg = error.response.data.data.message;
          this.$store.dispatch('showErrorNotification', error.response.data.data.message)
        } else if (error.response.status === 422) {
          if(error.response.data.errors){
            let errors = error.response.data.errors
            this.$store.dispatch('showErrorNotification', errors[Object.keys(errors)[0]][0])
            this.errorMsg = errors[Object.keys(errors)[0]][0];
          }else{
            this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
            this.errorMsg = this.$t('general.error')
          }
        } else {
          this.$store.dispatch('showErrorNotification', this.$t('general.unknown_error'))
          this.errorMsg = this.$t('general.error')
        }
      })
    },
    submit(){
      this.$emit('submit',this.id)
      this.hideModal();
    }
  }
}
</script>
