<template>
  <Loader
    :loading="isLoading"
  >
    <b-form @submit.prevent="updateNotifications">
      <div class="kt-portlet__body">
        <div class="form-group">

          <div class="kt-checkbox-list">
            <label class="kt-checkbox">
              <input
                v-model="notifications.login"
                type="checkbox"
              >
              {{ $t('profile.note_login') }}
              <span class="checkmark"/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="notifications.deposit"
                type="checkbox"
              >
              {{ $t('profile.note_deposit') }}
              <span/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="notifications.request_withdraw_status_change"
                type="checkbox"
              >
              {{ $t('profile.note_withdrawal') }}
              <span/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="notifications.reinvest_profit"
                type="checkbox"
              >
              {{ $t('profile.note_reinvest') }}
              <span/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="notifications.decrease_deposit"
                type="checkbox"
              >
              {{ $t('profile.note_transfer') }}
              <span/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="notifications.password_change"
                type="checkbox"
              >
              {{ $t('profile.note_password') }}
              <span/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="notifications.profit_credited"
                type="checkbox"
              >
              {{ $t('profile.note_profit') }}
              <span/>
            </label>
            <label class="kt-checkbox">
              <input
                v-model="notifications.marketing"
                type="checkbox"
              >
              {{ $t('profile.note_marketing') }}
              <span/>
            </label>
            <!--next-->
            <label class="kt-checkbox">
              <input
                type="checkbox"
                checked
                disabled
              >
              <b v-html="$t('profile.note_withdrawal2')"></b>
              <span/>
            </label>
          </div>

        </div>
      </div>
      <div class="kt-portlet__foot">
        <div class="kt-form__actions">
          <div class="row">
            <div class="col-xl-12">
              <button type="submit" class="btn btn-success">{{ $t('profile.save') }}</button>
            </div>
          </div>
        </div>
      </div>
    </b-form>
  </Loader>
</template>

<script>
  import Loader from '../global/Loader'

  export default {
    name: 'NotificationsForm',
    components: {
      Loader
    },
    data () {
      return {
        notifications: {
          login: null,
          profit_payout: null,
          blog_post: null,
          deposit: null,
          request_withdraw_status_change: null,
          reinvest_profit: null,
          decrease_deposit: null,
          sms_messages: null,
          reminders: null,
          password_change: null,
          profit_credited: null,
          marketing: null,
        },
        isLoading: false
      }
    },
    created () {
      this.loadNotifications()
    },
    methods: {
      loadNotifications () {
        this.isLoading = true
        axios.get(this.$api.notifications).then(response => {
          this.notifications = response.data.data
          this.isLoading = false
        }).catch(e => {
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
          this.isLoading = false
        })
      },
      updateNotifications () {
        this.isLoading = true
        axios.patch(this.$api.notifications, this.notifications).then(response => {
          this.$store.dispatch('showSuccessNotification', this.$t('profile.note_success'))
          this.isLoading = false
        }).catch(error => {
          this.$store.dispatch('showErrorNotification', this.$t('general.error'))
          this.isLoading = false
        })
      }
    }
  }
</script>
