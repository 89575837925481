<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      cancel-disabled
      ok-disabled
      size="lg"
      :title="modalTitle || $t('withdraw.wallets_title2')"
      @hidden="hideModal"
      header-close-content=""
    >
      <Loader
        :loading="isLoading"
        loader-style="overflow: hidden;"
      >

        <b-form class="kt-form" @submit.prevent="addWalletValidate">
          <div class="kt-portlet kt-portlet--bordered">
            <div class="kt-portlet__head">
              <div class="kt-portlet__head-label">
                <h3 class="kt-portlet__head-title">
                  {{ $t('withdraw.add_wallet') }}
                </h3>
              </div>
              <div class="kt-portlet__head-toolbar">
                <div class="text-center kt-margin-b-10">
                  <a href="https://www.youtube.com/watch?v=-Jyvpm75-uY" target="_blank" class="vt"><i class="fab fa-youtube"></i> {{ $t('deposit.tutorial') }}</a>
                </div>
              </div>
            </div>

            <div class="kt-portlet__body">

              <AccountInput
                v-model="formData.currency"
                name="currency"
                type="select"
                :options="currencies_options"
                :errors="errors"
                required
                :label="$t('withdraw.add_wallet_currency')"
                :cols="3"
              />

              <AccountInput
                v-model="formData.title"
                name="title"
                :errors="errors"
                placeholder=""
                :label="$t('withdraw.add_wallet_title')"
                :cols="3"
              />

              <AccountInput
                v-model="formData.address"
                name="address"
                :errors="errors"
                placeholder=""
                :label="$t('withdraw.add_wallet_address')"
                :cols="3"
                :description="$t('withdraw.add_wallet_address_desc',{ currency: formData.currency })"
              />
              <div class="kt-form__actions text-right">
                <button
                  type="submit" class="btn btn-primary">{{ $t('withdraw.add_wallet_button') }}</button>
              </div>
            </div>

          </div>
        </b-form>
      </Loader>

      <div class="kt-section">
        <Loader
          :loading="isLoading2"
          loader-style="overflow: hidden;"
        >
          <div class="kt-section__content">
            <div class="table-responsive">
              <table class="table table-striped table-hover">
                <thead class="thead-dark">
                  <tr>
                    <th>{{ $t('withdraw.wallets_currency') }}</th>
                    <th>{{ $t('withdraw.wallets_title') }}</th>
                    <th>{{ $t('withdraw.wallets_address') }}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody v-if="wallets.length">
                  <tr v-for="wallet in wallets">
                    <td>{{wallet.currency}}</td>
                    <td>{{wallet.title}}</td>
                    <td>{{wallet.address}}</td>
                    <td><i class="fas fa-trash-alt" @click="deleteWallet(wallet)" style="cursor: pointer;"></i></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </Loader>
      </div>

      <div slot="modal-footer">
        <div class="text-right">
          <button type="button" class="btn btn-default" @click="$emit('done')">{{ $t('general.close') }}</button>
        </div>
      </div>
    </b-modal>
    <MfaModal
      v-if="showMfa"
      :use-portal="false"
      :show="showMfa"
      action="withdraw_add_wallet"
      :method="profile.mfa_method"
      @verified="addWallet"
      @hidden="hideMfa"
    />
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
// import DropDown from '../functional/Dropdown'
import Loader from '../global/Loader'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'
import AccountInput from '../forms/AccountInput'

export default {
  components: {
    AccountInput,
    // DropDown,
    Loader
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modalTitle: {
      type: String
    },
    currencies: {
      type: Array,
      default: () => ['BTC', 'SAPP', 'USDT', 'USDC']
    },
    wallets: {
      type: Array,
      default: () => []
    },
    current_currency: {
      type: String,
      default: 'BTC'
    }
  },
  data () {
    return {
      formField: 'formData',
      modalOpen: this.show,
      isLoading: false,
      isLoading2: false,
      showMfa: false,
      formData: {
        // currency: this.currencies[0],
        currency: 'BTC',
        title: null,
        address: null
      }
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    }),
    currencies_options () {
      let options = []
      this.currencies.forEach(item => {
        let txt = item;
        if(item === 'USDT'){
          txt = 'USDT-TRC20';
        }
        if(item === 'USDC'){
          txt = 'USDC-TRC20';
        }
        options.push({
          text: txt,
          value: item
        })
      })
      return options
    }
  },
  watch: {
    show (val) {
      this.modalOpen = val
    }
  },
  created() {

  },
  methods: {
    hideModal () {
      this.$emit('hidden')
    },
    addWalletValidate () {
      this.clearErrors()
      if (!this.formData.title) {
        this.addError('title', this.$t('withdraw.verify_title'))
        return false
      }
      if (!this.formData.address) {
        this.addError('address', this.$t('withdraw.verify_address'))
        return false
      }

      //this.addWallet(null)
      if (this.profile.mfa_enabled) {
         this.showMfa = true
      } else {
         this.addWallet(null)
      }
    },
    addWallet (token = null) {
      this.clearErrors()
      this.showMfa = false
      this.isLoading = true
      axios.post(this.$api.withdrawWallet, { ...this.formData, token }).then(response => {
        this.$store.dispatch('showSuccessNotification', this.$t('withdraw.wallets_success'))
        this.$emit('done')
        this.isLoading = false
      }).catch(error => {
        if (error.response.data.errors) {
          this.setErrors(error.response.data.errors)
        }
        this.$store.dispatch('showErrorNotification', this.$t('withdraw.wallets_error'))
        this.isLoading = false
      })
    },
    deleteWallet (wallet) {
      if(this.profile.limited_mode) return;
      if(!confirm(this.$t('general.are_you_sure'))) return;
      this.isLoading2 = true;
      axios.delete(this.$api.withdrawWalletRemove + '/'+wallet.id).then(response => {
        this.$store.dispatch('showSuccessNotification', this.$t('withdraw.wallets_remove_success'))
        this.$emit('update')
        this.isLoading2 = false;
      }).catch(error => {
        this.isLoading2 = false;
        if (error.response.status === 409) {
          if(error.response.data.data.message) {
            this.$store.dispatch('showErrorNotification', error.response.data.data.message)
          }
        }else {
          this.$store.dispatch('showErrorNotification', this.$t('withdraw.wallets_remove_error'))
        }
      })
    }
  }
}
</script>
