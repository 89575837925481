var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Loader',{attrs:{"loading":_vm.isLoading}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-12"},[_c('div',{staticClass:"kt-portlet"},[_c('div',{staticClass:"kt-portlet__head"},[_c('div',{staticClass:"kt-portlet__head-label"},[(_vm.profile.account_verified)?[(_vm.optional && !_vm.profile.kyc_verified2)?[_c('h3',{staticClass:"kt-portlet__head-title kt-font-warning"},[_vm._v("Profile Verification Complete, but Marketplace Verification is not.")])]:[_c('h3',{staticClass:"kt-portlet__head-title kt-font-success",domProps:{"innerHTML":_vm._s(_vm.$t('verify.profile_complete'))}})]]:[_c('h3',{staticClass:"kt-portlet__head-title kt-font-danger",domProps:{"innerHTML":_vm._s(_vm.$t('verify.profile_incomplete'))}})]],2)]),_c('div',{staticClass:"kt-portlet__body"},[_c('div',{staticClass:"kt-list-timeline"},[_c('div',{staticClass:"kt-list-timeline__items"},[_c('div',{staticClass:"kt-list-timeline__item"},[_c('span',{staticClass:"kt-list-timeline__badge ",class:{
                      'kt-list-timeline__badge--success': _vm.profile.email_verified,
                      'kt-list-timeline__badge--danger': !_vm.profile.email_verified
                    }}),_c('span',{staticClass:"kt-list-timeline__text",class:{
                      'kt-font-success': _vm.profile.email_verified,
                      'kt-font-danger': !_vm.profile.email_verified
                    }},[(_vm.profile.email_verified)?[_vm._v(" "+_vm._s(_vm.$t('verify.email_confirmed'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('verify.email_not_confirmed'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.resendEmail.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('verify.email_resend'))+" ")])]],2)]),_c('div',{staticClass:"kt-list-timeline__item"},[_c('span',{staticClass:"kt-list-timeline__badge ",class:{
                      'kt-list-timeline__badge--success': _vm.profile.profile_complete,
                      'kt-list-timeline__badge--danger': !_vm.profile.profile_complete
                    }}),_c('span',{staticClass:"kt-list-timeline__text",class:{
                      'kt-font-success': _vm.profile.profile_complete,
                      'kt-font-danger': !_vm.profile.profile_complete
                    }},[(_vm.profile.profile_complete)?[_vm._v(" "+_vm._s(_vm.$t('verify.profile_info_complete'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('verify.profile_info_incomplete'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.scrollTo('profile', '#mainContentArea')}}},[_vm._v(" "+_vm._s(_vm.$t('verify.profile_info_fill'))+" ")])]],2)]),_c('div',{staticClass:"kt-list-timeline__item"},[_c('span',{staticClass:"kt-list-timeline__badge ",class:{
                      'kt-list-timeline__badge--success': _vm.profile.mfa_enabled,
                      'kt-list-timeline__badge--danger': !_vm.profile.mfa_enabled
                    }}),_c('span',{staticClass:"kt-list-timeline__text",class:{
                      'kt-font-success': _vm.profile.mfa_enabled,
                      'kt-font-danger': !_vm.profile.mfa_enabled
                    }},[(_vm.profile.mfa_enabled)?[_vm._v(" "+_vm._s(_vm.$t('verify.mfa_set'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('verify.mfa_not_set'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.scrollTo('2fa', '#mainContentArea')}}},[_vm._v(" "+_vm._s(_vm.$t('verify.mfa_set_link'))+" ")])]],2)]),_c('div',{staticClass:"kt-list-timeline__item"},[_c('span',{staticClass:"kt-list-timeline__badge ",class:{
                    'kt-list-timeline__badge--success': _vm.profile.kyc_verified,
                    'kt-list-timeline__badge--warning': _vm.profile.kyc_awaiting && !_vm.profile.kyc_verified,
                    'kt-list-timeline__badge--danger': !_vm.profile.kyc_awaiting && !_vm.profile.kyc_verified
                  }}),_c('span',{staticClass:"kt-list-timeline__text",class:{
                    'kt-font-success': _vm.profile.kyc_verified,
                    'kt-font-warning': _vm.profile.kyc_awaiting && !_vm.profile.kyc_verified,
                    'kt-font-danger': !_vm.profile.kyc_awaiting && !_vm.profile.kyc_verified
                  }},[(_vm.profile.kyc_verified)?[_vm._v(" "+_vm._s(_vm.$t('verify.kyc_ok'))+" ")]:(_vm.profile.kyc_awaiting)?[_vm._v(" "+_vm._s(_vm.$t('verify.kyc_review'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('verify.kyc_not_ok'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.scrollTo('kyc', '#mainContentArea')}}},[_vm._v(" "+_vm._s(_vm.$t('verify.kyc_upload'))+" ")])]],2)])])]),(_vm.optional)?_c('div',{staticClass:"kt-list-timeline kt-margin-t-20"},[_c('div',{staticClass:"kt-list-timeline__items"},[_c('div',{staticClass:"kt-list-timeline__item"},[_c('span',{staticClass:"kt-list-timeline__badge ",class:{
                        'kt-list-timeline__badge--success': _vm.profile.kyc_verified2,
                        'kt-list-timeline__badge--warning': _vm.profile.kyc_awaiting2 && !_vm.profile.kyc_verified2,
                        'kt-list-timeline__badge--danger': !_vm.profile.kyc_awaiting2 && !_vm.profile.kyc_verified2
                      }}),_c('span',{staticClass:"kt-list-timeline__text",class:{
                        'kt-font-success': _vm.profile.kyc_verified2,
                        'kt-font-warning': _vm.profile.kyc_awaiting2 && !_vm.profile.kyc_verified2,
                        'kt-font-danger': !_vm.profile.kyc_awaiting2 && !_vm.profile.kyc_verified2
                      }},[(_vm.profile.kyc_verified2)?[_vm._v(" Marketplace Agreement has been verified! ")]:(_vm.profile.kyc_awaiting2)?[_vm._v(" Marketplace Agreement is in review. ")]:[_vm._v(" Marketplace Agreement is not uploaded. "),_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.scrollTo('kyc', '#mainContentArea')}}},[_vm._v(" Upload ")])]],2)])])]):_vm._e()])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }