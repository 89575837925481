<template>
  <div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12">

      <div class="kt-portlet">
        <div class="kt-portlet__head">
          <div class="kt-portlet__head-label">
            <span class="kt-portlet__head-icon">
                <i class="fas fa-search-dollar"></i>
            </span>
            <h3 class="kt-portlet__head-title">
              {{ $t('title.audit_fall2021') }}
            </h3>
          </div>
          <div class="kt-portlet__head-toolbar">
            <b><router-link :to="{name: 'audits'}"><i class="fas fa-undo"></i> Previous Audits</router-link></b>
          </div>
        </div>
          <div class="kt-portlet__body">
            <h1 class="kt-margin-b-5 text-center">On-Site Audit of YieldNodes.com</h1>
            <h2 class="kt-margin-b-30 text-center">October 30-31.2021 in Malta</h2>

            <p class="lead">As part of our commitment to be transparent in what we do, we pledged to make bi-annual audits of the funds we work with at YieldNodes.com</p>
            <p class="lead">We wanted to prove beyond any shadow of a doubt that the company is <b>well funded</b>, and that the deposits and compounds match the working capital held by the company i.e. that profits are <b>truly being generated</b>, and that the coin networks and projects are <b>flourishing</b>.</p>
            <p class="lead">This is <em>no guarantee of a risk free future</em> - rather a review to confirm that what has been done over the last 2 years has worked according to plan, and has been done to high standards of honesty and morality.</p>
            <p class="lead">For this audit, we went a little further and decided to invite the auditors to the YieldNodes / Decenomy offices to meet some of the team personally, as well as getting more insight into our plans for the future and what we like to do with the various projects.</p>
            <p class="lead">It was especially important that the auditors were able to meet <b>the “architect” of this concept - Urs Schwinger</b>, and his team that work in the background and allow YieldNodes / Decenomy to shine.</p>
            <hr class="kt-separator kt-separator--dashed" />
            <div class="row">
              <div class="col-md-6">
                <p>To be fully transparent, we first sent an invitation to all of our YieldNodes members and asked if they wanted to apply for the chance to come and visit us. This was sent only to members because we believe we need to focus on working for our partners, not simply to convince outsiders. The highest priority here at YieldNodes will always be our members, which is why this report is linked INSIDE the members’ area rather than being available outside as cheap publicity.</p>
                <p>We received roughly 50 applications from potential auditors, so we selected candidates from a completely randomized selection thanks to a freely-available service on the random.org website that allowed us to enter the names of the applicants and randomize it!</p>
                <p>We filmed the process as further proof of transparency, and the full video is available here on <a href="https://www.youtube.com/watch?v=rZV9l4DsuiE&t=7s" target="_blank">YouTube</a></p>
              </div>
              <div class="col-md-6">
                <b-embed
                  type="iframe"
                  src="https://www.youtube.com/embed/rZV9l4DsuiE"
                  aspect="16by9"
                  allowfullscreen />
                <br>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <p class="lead"><b>The winners were:</b></p>
                <ul>
                  <li>Angela Seaman</li>
                  <li>Liam Inwood</li>
                  <li>Christian Ossiander</li>
                </ul>
              </div>
              <div class="col-sm-6">
                <p>…plus 2 people in reserve:</p>
                <ul>
                  <li>Josh Peach</li>
                  <li>Iain Muirhead</li>
                </ul>
              </div>
            </div>

            <p>As it turned out, Mr Ossiander was unable to make the trip, so gave up his position to Josh Peach, who happily filled the gap.</p>
            <p>In addition to those 3, Mr Jürgen Bappert (a long-time member from Germany, who was also part of the February audit) joined the group.</p>
            <p>Jonathan Platts and Graham Laurie were also unable to travel so will conduct an Online Audit at a later date (with updates posted here)</p>
            <p>Full Disclosure: We covered the cost of accommodation and meals, but the auditors paid for their own flights.</p>

            <hr class="kt-separator kt-separator--dashed" />

            <h2 class="kt-margin-t-20 kt-margin-b-30">The Auditors</h2>

            <div class="kt-portlet kt-portlet--bordered">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Angela Seaman</b>
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <p><img src="~images/auditors/2/angela.jpg" class="m-1 rounded d-block float-right">I started investing in Yield Nodes in May 2021. I came across the investment opportunity by chance. We were in our local branch of Halfords having a reversing camera fitted to our VW T5 camper van and were discussing crypto currencies with the fitter. He was only a young lad and he mentioned that he had started to invest in YieldNodes with some success. My curiosity got the better of me and when I arrived home, I started to do some research, came across some very favourable reviews and eventually took the plunge and transferred my first amount of bitcoin.</p>
                <p><b>My background</b> – I am now happily retired</p>
                <p>Up until March 2019, I was employed by BP International Limited and left after 20 years’ service, securing a very generous redundancy package (2 years’ salary) that coincided nicely with early retirement. I couldn’t believe my luck!</p>
                <p>My last 10 years at BP, I was based in Canary Wharf working for BP Treasury’s Cash and Banking team working closely with the BP Treasury traders. I was lucky enough to work with a well-respected and highly experienced team and became involved in the following areas to name a few:</p>
                <ul>
                  <li>Credit risk management – Bank counterparties</li>
                  <li>Opening & Closing Bank accounts around the world in various currencies</li>
                  <li>Non-USD currency reporting to manage the group’s currency requirements</li>
                  <li>Global cash forecasting and the production of the group’s cash & debt report used to manage the BP group’s liquidity risk</li>
                  <li>Providing information to both internal and external auditors relating to cash and banking matters</li>
                </ul>

                <p>I consider myself to be an amateur investor. I have most of my money tied up in Funds using the Hargreaves Lansdown platform. I have a small amount of Crypto invested using Crypto.com and as mentioned above have some investments with Yield Nodes. I’m not averse to taking a small amount of risk and so far this has been to my advantage.</p>
                <p>I have a lot of spare time on my hands and watch my investments carefully, always on the lookout for new opportunities. I wanted to get involved with the Yield Nodes Audit to obtain a better understanding of the process and better understanding of the risks involved. I am absolutely delighted to have been successful in my application.</p>
              </div>
            </div>

            <div class="kt-portlet kt-portlet--bordered">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Liam Inwood</b>
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <p><img src="~images/auditors/2/liam.jpg" class="m-1 rounded d-block float-right">Liam Inwood Bio - the director of a bathroom & wetroom installation company, I am very interested in Passive incomes, Multiple streams of income along with trading Currency & Crypto Currencies.<br>
                  <br>
                  Very happy to of been picked to help with the YieldNodes audit.
                </p>
              </div>
            </div>

            <div class="kt-portlet kt-portlet--bordered">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Josh Peach</b>
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <p><img src="~images/auditors/2/josh.jpg" class="m-1 rounded d-block float-right">"Josh from P&F Capital:<br>
                  <br>
                  Josh is a keen entrepreneur in his 20’s, from the UK. He has been involved in algorithmic trading and other passive investment opportunities for a few years now. He has been invested in the YieldNodes programme for almost one year at the time of the audit and considers the programme to be a key component of P&F Capital - his company which aims to interfuse new technologies and platforms with more traditional assets."
                </p>
              </div>
            </div>

            <div class="kt-portlet kt-portlet--bordered">
              <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                  <h3 class="kt-portlet__head-title">
                    <b>Jürgen Bappert</b>
                  </h3>
                </div>
              </div>
              <div class="kt-portlet__body">
                <p><img src="~images/auditors/juergen.png" class="m-1 rounded d-block float-right">Juergen Bappert from Expert FX<br><br>
                  German: Dipl.Volkswirt u. Volljurist, 1975 5 Monate Praktikum bei der EU-Kom. in Brüssel, Importgroßhandel, Telefonvertrags-Geschäft, 15 Jahre Managed Forex u. ab Anfang 2020 in Yieldnodes aktiv.<br>
                  <br>
                  <a href="https://forexorcrypto.com" target="_blank">forexorcrypto.com</a>

                </p>
              </div>
            </div>

            <p class="lead">Full Disclosure: All Auditors* hold funded YieldNodes accounts.</p>

            <hr class="kt-separator kt-separator--dashed" />

            <h2 class="kt-margin-t-20 kt-margin-b-30">The Audit</h2>

            <p class="lead"><b>Stefan Hoermann</b> - CEO and partner of YieldNodes had a 10-15 minute video call with all of the Auditors*, logging into the administrative area of Yieldnodes (admin) showing the general balance of deposits (plus compounds, withdrawals, and the numbers of active and registered users. The video call also covered how we work, how we see the history, how we can check what was deposited (and when), etc.<br>
              No individual data was disclosed for privacy reasons, except the account of the auditor on the video call.</p>

            <p class="lead">Afterwards:</p>
            <p class="lead"><b>Urs Schwinger</b>, Masternode Architect of Yieldnodes and partner, met with all of the auditors in the Decenomy / YieldNodes offices.</p>

            <div class="row">
              <div class="col-md-6">
                <div class="kt-portlet kt-portlet--bordered kt-portlet--fit">
                  <div class="kt-portlet__body">
                    <a :href="images[0].src" @click.prevent="index = 0"><img :src="images[0].src" class="img-fluid mx-auto d-block"></a>
                  </div>
                  <div class="kt-portlet__foot p-2">
                    Description: {{ images[0].title }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="kt-portlet kt-portlet--bordered kt-portlet--fit">
                  <div class="kt-portlet__body">
                    <a :href="images[1].src" @click.prevent="index = 1"><img :src="images[1].src" class="img-fluid mx-auto d-block"></a>
                  </div>
                  <div class="kt-portlet__foot p-2">
                    Description: {{ images[1].title }}
                  </div>
                </div>
              </div>
            </div>


            <p class="lead">Urs showed wallets, staking balances, the nodes at work, how much capital is allocated to the various chains, the balancing, the tech, etc. in a transparent manner.</p>
            <p class="lead"><b>IMPORTANT:</b></p>
            <p class="lead">The auditors could calculate and confirm that the balances held in the Decenomy ecosystem <b>match and exceed</b> the amount necessary to cover deposits and compounded profits.</p>
            <p>Afterwards, it was time to explore Malta a bit:</p>

            <div class="row">
              <div class="col-md-6">
                <div class="kt-portlet kt-portlet--bordered kt-portlet--fit">
                  <div class="kt-portlet__body">
                    <a :href="images[2].src" @click.prevent="index = 2"><img :src="images[2].src" class="img-fluid mx-auto d-block"></a>
                  </div>
                  <div class="kt-portlet__foot p-2">
                    Description: {{ images[2].title }}
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="kt-portlet kt-portlet--bordered kt-portlet--fit">
                  <div class="kt-portlet__body">
                    <a :href="images[3].src" @click.prevent="index = 3"><img :src="images[3].src" class="img-fluid mx-auto d-block"></a>
                  </div>
                  <div class="kt-portlet__foot p-2">
                    Description: {{ images[3].title }}
                  </div>
                </div>
              </div>
            </div>

            <p class="lead">Finally, each of the auditors* signed a document (of their own free will, of course) that what they saw pleased them :)</p>

            <h3 class="kt-margin-b-30">Here are the signed statements:</h3>

            <div class="row">
              <div class="col-md-3">
                <a :href="images[4].src" @click.prevent="index = 4"><img :src="images[4].src" class="img-fluid mx-auto d-block"></a>
              </div>
              <div class="col-md-3">
                <a :href="images[5].src" @click.prevent="index = 5"><img :src="images[5].src" class="img-fluid mx-auto d-block"></a>
              </div>
              <div class="col-md-3">
                <a :href="images[6].src" @click.prevent="index = 6"><img :src="images[6].src" class="img-fluid mx-auto d-block"></a>
              </div>
              <div class="col-md-3">
                <a :href="images[7].src" @click.prevent="index = 7"><img :src="images[7].src" class="img-fluid mx-auto d-block"></a>
              </div>
            </div>

            <p class="lead">This concluded the Audit.</p>

            <p class="lead">We thank everyone for taking the time, and for being such pleasant people. You never know what can happen with a group of random strangers, so we slightly apprehensive before their arrival, but everyone was kind, understanding, curious, and took the task seriously… Great job!</p>
            <h3 class="kt-margin-t-30 kt-margin-b-20">Questions</h3>

            <p class="lead"><b>Q:</b> Looks awesome. Will you do audits regularly from now on?</p>
            <p class="lead"><b>A:</b> We will try to do them twice a year, or yearly at least, but we cannot make promises as it was quite an undertaking and we prefer to focus on the noding and staking. This audit is also only accessible in the members’ area and not intended for marketing purposes. It’s to reward you - our fellow members - who put your trust in us and helped us realize the vision that is YieldNodes and Decenomy.</p>

            <p class="lead kt-margin-t-30"><b>Q:</b> Does this mean Yieldnodes is a 100% safe opportunity and I should invest what I can?</p>
            <p class="lead"><b>A:</b> Absolutely NOT! This Audit has been done to show that.</p>

            <h2 class="kt-margin-t-30 kt-margin-b-20">WE <u>PRACTICE</u> WHAT WE PREACH!</h2>

            <p class="lead">Since the very start of YieldNodes in October 2019 we have generated an average of 10%+ monthly in value, and we wanted to give our members who stood with us some peace of mind. Talk is cheap, so we wanted to put the money where our mouth is.</p>

            <p class="lead">YieldNodes is going strong but it is still</p>

            <ul class="lead">
              <li><b>NOT</b> regulated or supervised by any financial entity yet</li>
              <li><b>STILL a VERY RISKY</b> participation model, and things can always go sour</li>
              <li><b>NOT</b> trading Cryptos and <b>NOT</b> a Bitcoin pegged investment</li>
              <li>By <b>NO MEANS</b> a bank account or retirement fund!</li>
            </ul>

            <p class="lead">So, use the information we have provided to do your own risk assessment, and understand that funds are always at risk of being lost so there are absolutely <b>NO</b> guarantees.</p>
            <p class="lead">We do what we do with honesty and integrity, but it is as much a wild ride for us as it is for you, so read and absorb what you can and always ensure you can live with the decisions you make.</p>
            <p class="lead">That being said, we are grateful for your participation and humbled by your trust - we are ready and hungry to prove ourselves to you and the world …and to build out the decentralized economy that is the long term vision: <b>DECENOMY</b></p>

            <p class="lead">Sincerely,</p>

            <p class="lead">Urs & Steve<br>
              Yieldnodes Partners.</p>

            <div class="kt-margin-t-30">
              <p>For inquiries and scheduling visits please refer to the office:</p>
              <p>DECENOMY PLC<br>
                House Gioia<br>
                Triq Sant' Anna 26<br>
                Mosta<br>
                MST 3211<br>
                +356 99887044<br>
                <a href="mailto:info@decenomy.net">info@decenomy.net</a></p>
            </div>


            <p class="kt-margin-t-50">*The term “auditor” in our case refers to people who were allowed a peak behind the curtain and does not imply that they are working in this official capacity. They are regular people and active YieldNodes members who were chosen randomly and accepted the task.</p>


            <CoolLightBox
              :items = "images"
              :index = "index"
              @close = "index = null"
            />
          </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
  name: 'AuditFall2021Page',
  components: {
    CoolLightBox
  },
  data () {
    return {
      images: [
        {
          src: require('@/../images/auditors/2/0.jpg'),
          title: 'Dima doing final checks'
        },
        {
          src: require('@/../images/auditors/2/1.jpg'),
          title: 'The auditors around the table with Dima, Nick, John and Urs'
        },
        {
          src: require('@/../images/auditors/2/2.jpg'),
          title: 'Walking the Streets of Valletta'
        },
        {
          src: require('@/../images/auditors/2/3.jpg'),
          title: 'Getting some food and refreshments'
        },
        {
          src: require('@/../images/auditors/2/4.jpg'),
        },
        {
          src: require('@/../images/auditors/2/5.jpg'),
        },
        {
          src: require('@/../images/auditors/2/6.jpg'),
        },
        {
          src: require('@/../images/auditors/2/7.jpg'),
        },
      ],
      index: null
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created () {

  },
  methods: {

  }
}
</script>
