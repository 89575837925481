<template>
  <Portal to="modals">
    <b-modal
      v-model="modalOpen"
      :title="$t('mfa.phone_title')"
      @hidden="hideModal"
      no-close-on-backdrop
      no-close-on-esc
      header-close-content=""
    >
      <template v-if="profile.phone_allowed">
        <template v-if="!profile.phone_verified">
          <VerificationPhoneForm
            :phone="phone"
            phone-activation
            @verified="verify"
          />
          <p
            v-if="canSkip"
            class="text-center"
          >
            <a
              href="#"
              @click.prevent="skip"
            >
              {{ $t('mfa.phone_later') }}
            </a>
          </p>
        </template>
        <template v-else>
          <h4>{{ $t('mfa.phone_already') }}</h4>
        </template>
      </template>
      <template v-else>
        <h4>{{ $t('mfa.phone_not_allowed') }}</h4>
      </template>
      <div slot="modal-footer" />
    </b-modal>
  </Portal>
</template>

<script>
import { mapState } from 'vuex'
import VerificationPhoneForm from '../forms/VerificationPhoneForm'

export default {
  name: 'PhoneVerifyModal',
  components: {
    VerificationPhoneForm
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    phone: {
      type: String,
      required: true
    },
    canSkip: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      modalOpen: this.show
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  watch: {
    show (val) {
      this.modalOpen = val
    }
  },
  methods: {
    verify () {
      this.$emit('verified')
    },
    hideModal () {
      this.$emit('hidden')
    }
  }
}
</script>
