<template>
  <div>
    <ReferralBasePage>
      <template v-slot:header>
        <h1 class="kt-margin-b-0">{{ $t('affiliate.links_title') }}</h1>
      </template>

      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12">
          <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
              <div class="form-group">
                <label>{{ $t('affiliate.link') }}</label>

                <div class="input-group input-group-lg">
                  <input
                    class="form-control"
                    id="basic_link"
                    :value="links.home + '?a=' + profile.aff_id"
                    readonly
                  />
                  <div class="input-group-append">
                    <button
                      id="basic_link_copy"
                      data-clipboard-target="#basic_link"
                      class="btn btn-primary"
                    >
                      {{ $t('general.copy') }}
                    </button>
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label>{{ $t('affiliate.link_tracking') }}</label>

                <div class="input-group input-group-lg">
                  <input
                    class="form-control"
                    id="track_link"
                    :value="
                      links.home + '?a=' + profile.aff_id + '&trk=mytracking911'
                    "
                    readonly
                  />
                  <div class="input-group-append">
                    <button
                      id="track_link_copy"
                      data-clipboard-target="#track_link"
                      class="btn btn-primary"
                    >
                      {{ $t('general.copy') }}
                    </button>
                  </div>
                </div>

                <div class="form-text text-muted">
                  mytracking123 - {{ $t('affiliate.link_tracking') }}
                </div>
              </div>

              <div class="form-group">
                <label>{{ $t('affiliate.link_register') }}</label>

                <div class="input-group input-group-lg">
                  <input
                    class="form-control"
                    id="signup_link"
                    :value="
                      links.default +
                        '/c/?a=' +
                        profile.aff_id +
                        '&redirect=signup'
                    "
                    readonly
                  />
                  <div class="input-group-append">
                    <button
                      id="signup_link_copy"
                      data-clipboard-target="#signup_link"
                      class="btn btn-primary"
                    >
                      {{ $t('general.copy') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-6 col-lg-6 col-md-6">
          <div class="kt-portlet kt-portlet--mobile">
            <div class="kt-portlet__body">
              <div class="alert alert-danger">
                <div class="alert-text">
                  <i class="fas fa-exclamation-triangle"></i>
                  &nbsp;<span v-html="$t('affiliate.self_affiliation_short')"></span>
                </div>

              </div>
              <div v-html="$t('affiliate.tracking_details')"></div>
            </div>
          </div>
        </div>
      </div>
    </ReferralBasePage>
  </div>
</template>

<script>
import Clipboard from "clipboard";
import { mapState } from "vuex";
import ReferralBasePage from "./ReferralBasePage";

export default {
  name: "ReferralLinkPage",
  components: { ReferralBasePage },
  data() {
    return {
      links: {
        default: 'https://members.yieldnodes.com',
        home: 'https://yieldnodes.com',
        signup: 'https://members.yieldnodes.com/register',
        aff: 'https://yieldnodes.com/affiliates',
      },
      loading: true,
      tier2Cb: null,
      basicCb: null,
      signupCb: null,
      trackCb: null
    };
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  created() {
    axios
      .get(this.$api.affiliate + "/links")
      .then(response => {
        this.links = response.data.data;
        this.isLoading = false;

        this.basicCb = new Clipboard("#basic_link_copy");
        this.basicCb.on("success", e => {
          this.$store.dispatch("showSuccessNotification", this.$t('affiliate.link_copied'));
          e.clearSelection();
        });

        // this.tier2Cb = new Clipboard('#tier2_link_copy')
        // this.tier2Cb.on('success', (e) => {
        //   this.$store.dispatch('showSuccessNotification', this.$t('affiliate.link_copied'))
        //   e.clearSelection()
        // })

        this.signupCb = new Clipboard("#signup_link_copy");
        this.signupCb.on("success", e => {
          this.$store.dispatch("showSuccessNotification", this.$t('affiliate.link_copied'));
          e.clearSelection();
        });

        this.trackCb = new Clipboard("#track_link_copy");
        this.trackCb.on("success", e => {
          this.$store.dispatch("showSuccessNotification", this.$t('affiliate.link_copied'));
          e.clearSelection();
        });
      })
      .catch(error => {
        this.$store.dispatch(
          "showErrorNotification",
          this.$t('general.unknown_error')
        );
      });
  },
  beforeDestroy() {
    if (this.basicCb) {
      this.basicCb.destroy();
    }
    /*if (this.tier2Cb) {
      this.tier2Cb.destroy()
    }*/
    if (this.signupCb) {
      this.signupCb.destroy();
    }
    if (this.trackCb) {
      this.trackCb.destroy();
    }
  }
};
</script>
