<template>
  <div>
    <VerificationInfo v-if="!profile.account_verified" :optional="false" />

    <div class="kt-portlet">
      <div class="kt-portlet__body  kt-portlet__body--fit">
        <div class="row row-no-padding row-col-separator-lg">
          <div class="col-md-12 col-lg-6 col-xl-6">
            <StatisticsBox
              :value="profile.deposit_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.deposit_balance_btc || null"
              :value-in-eur="profile.deposit_balance_eur || null"
              :value-in-eth="profile.deposit_balance_eth || null"
              dont-format
              icon="invest"
              :title="$t('dashboard.invested_balance')"
              :dots-tooltip="$t('dashboard.invested_balance_tooltip')"
              :has-warning="!!profile.deposit_balance_below_min"
              :info-text="$t('dashboard.invested_balance_low')"
              :info-tooltip="$t('dashboard.invested_balance_low_tooltip',{ minimum: profile.invested_balance_minimum_display})"
              :info="profile.deposit_has_idle?$t('deposit.idle_detected'):null"
            />
          </div>
          <div class="col-md-12 col-lg-6 col-xl-6">
            <StatisticsBox
              :value="profile.withdraw_balance_display_nc"
              :currency="profile.currency_sign"
              :decimals="profile.account_selected === 'BTC' ? 8 : 2"
              :value-in-btc="profile.withdraw_balance_btc || null"
              :value-in-eur="profile.withdraw_balance_eur || null"
              :value-in-eth="profile.withdraw_balance_eth || null"
              dont-format
              color="warning"
              icon="balance"
              :title="$t('dashboard.withdrawable_balance')"
              :dots-tooltip="$t('dashboard.withdrawable_balance_tooltip')"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-12">

        <!--<div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">What is account balances marketplace?</h3>
            </div>
          </div>
          <div class="kt-portlet__body">
            <p>For sellers: You can sell your balance here for a discounted price in BTC, USDT (trc20) or USDC (trc20). Payments are direct to your wallet address that you select from the list of "Withdrawal wallets".</p>
            <p>For buyers: You can buy someone's balance here for a discounted price in BTC, USDT (trc20) or USDC (trc20). The payment must be made very precisely in the amount which is requested to the wallet address which is mentioned. After the payment seller has to supply transaction id (hash).</p>
          </div>
        </div>-->

        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <h3 class="kt-portlet__head-title">Account Balances for Sale</h3>
            </div>
            <!--<div class="kt-portlet__head-toolbar">
              <button @click.prevent="openP2PSellModal" :disabled="hideSellButton" class="btn btn-sm btn-success"><i class="fas fa-comment-dollar"></i> Sell your balance</button>
            </div>-->
          </div>
          <div class="kt-portlet__body">
            <!--<div
              v-if="!profile.account_verified"
              class="alert alert-danger"
              role="alert"
            >
              <div class="alert-text">
                To use this function - your account must be fully verified!
              </div>
            </div>-->

            <template>
              <P2PSellsActivity
                ref="P2PSellsActivity"
                @refreshButtonStatus="refreshButtonStatus"
              />
            </template>
            <div>
              Account balances are no longer for sale. <a href="https://claim-sales.yieldnodes.com/" target="_blank">Read more info here</a>.
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--<P2PSellModal
      v-if="P2PModal"
      :show="P2PModal"
      @hidden="P2PModal = false"
      @done="update"
    />-->

  </div>


</template>

<script>
import { mapState } from 'vuex'
import StatisticsBox from '../components/functional/StatisticsBox'
import P2PSellsActivity from '../components/P2PSells/P2PSellsActivity'
import P2PSellModal from '../components/P2PSells/P2PSellModal'
import VerificationInfo from '../components/Withdrawal/VerificationInfo'
import Loader from "@/components/global/Loader";

export default {
  name: 'P2PSellsPage',
  components: {
    Loader,
    VerificationInfo,
    StatisticsBox,
    P2PSellsActivity,
    P2PSellModal
  },
  mixins: [
  ],
  data () {
    return {
      isLoading: false,
      P2PModal: false,
      hideSellButton: true
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  methods: {
    update () {
      this.$store.dispatch('setAuthUser')
      this.$refs.P2PSellsActivity.getStatus()
    },
    refreshButtonStatus (p2p_mode) {
      if(!this.profile.account_verified){
        this.hideSellButton = true;
      }else {
        if (p2p_mode === 'buy') {
          this.hideSellButton = false;
        } else {
          this.hideSellButton = true;
        }
      }
    },
    openP2PSellModal() {
      if(!this.profile.account_verified){
        this.$store.dispatch('showErrorNotification', 'Your account must be fully verified to use this function!')
      }else{
        this.P2PModal = true;
      }
    }


  }
}
</script>
