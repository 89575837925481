<template>
  <Loader :loading="isLoading">
    <form
        novalidate="novalidate"
        class="kt-form kt-form--label-right"
        @submit.prevent="updateProfile"
    >
      <div class="kt-portlet__body">
        <div class="kt-section kt-section--first kt-margin-b-0">
          <div class="kt-section__body">
            <div class="form-group row">
              <label class="col-lg-3 col-form-label">{{ $t('profile.account_type') }}</label>
              <div class="col-lg-6">
                <div class="kt-radio-inline">

                  <label class="kt-radio">
                    {{ $t('profile.account_individual') }}
                    <input
                        v-model="formData.account_type"
                        type="radio"
                        name="radio"
                        value="individual"
                    >
                    <span />
                  </label>

                  <label class="kt-radio">
                    {{ $t('profile.account_company') }}
                    <input
                        v-model="formData.account_type"
                        type="radio"
                        name="radio"
                        value="company"
                    >
                    <span />
                  </label>

                </div>
              </div>
            </div>
            <AccountInput
                v-if="formData.account_type === 'company'"
                id="company"
                v-model="formData.company"
                name="company"
                :errors="errors"
                :label="$t('profile.company')"
                :cols="3"
            />

            <AccountInput
                id="first_name"
                v-model="formData.first_name"
                name="first_name"
                :errors="errors"
                :label="$t('profile.name_first')"
                :cols="3"
            />

            <AccountInput
                id="middle_name"
                v-model="formData.middle_name"
                name="middle_name"
                :errors="errors"
                :label="$t('profile.name_middle')"
                :cols="3"
            />

            <AccountInput
                id="last_name"
                v-model="formData.last_name"
                name="last_name"
                :errors="errors"
                :label="$t('profile.name_last')"
                :cols="3"
            />

            <BirthdayInput
                :label="$t('profile.birthday')"
                :value="birthday"
                :errors="errors"
                @input="changeBirthday"
            />

            <AccountInput
                id="email"
                v-model="formData.email"
                type="email"
                name="email"
                :readonly="!!profile.email_verified"
                :errors="errors"
                :label="$t('profile.email')"
                :cols="3"
            >
              <div>
                <span v-html="emailLabel"/>
                <a
                    v-if="!profile.email_verified"
                    href="#"
                    @click.prevent="resendEmail"
                >
                  {{ $t('profile.email_resend') }}
                </a>
              </div>
            </AccountInput>

            <AccountInput
                ref="phoneInput"
                id="phone"
                v-model="formData.phone"
                type="phone"
                name="phone"
                :readonly="!!profile.phone_verified"
                :errors="errors"
                :label="$t('profile.phone')"
                :cols="3"
            >
              <div v-html="phoneLabel"></div>
            </AccountInput>

            <AccountInput
                id="address"
                v-model="formData.address"
                name="address"
                :errors="errors"
                :label="$t('profile.address')"
                :cols="3"
            />

            <AccountInput
                id="address2"
                v-model="formData.address2"
                name="address2"
                :errors="errors"
                :label="$t('profile.address2')"
                :cols="3"
            />

            <AccountInput
                id="city"
                v-model="formData.city"
                name="city"
                :errors="errors"
                :label="$t('profile.city')"
                :cols="3"
            />

            <AccountInput
                id="state"
                v-model="formData.state"
                name="state"
                :errors="errors"
                :label="$t('profile.state')"
                :cols="3"
            />

            <AccountInput
                id="zipcode"
                v-model="formData.zipcode"
                name="zipcode"
                :errors="errors"
                :label="$t('profile.zip')"
                :cols="3"
            />

            <CountrySelect
                id="country"
                v-model="formData.country"
                name="country"
                :errors="errors"
                :label="$t('profile.country')"
                :cols="3"
            />
          </div>
        </div>
      </div>
      <div class="kt-portlet__foot">
        <div class="kt-form__actions">
          <div class="row">
            <div class="col-lg-3 col-xl-3">
            </div>
            <div class="col-lg-9 col-xl-9">
              <button type="submit" class="btn btn-success">{{ $t('profile.save') }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import pick from 'lodash/pick'
import AccountInput from '../forms/AccountInput'
import BirthdayInput from '../forms/BirthdayInput'
import CountrySelect from '../forms/CountrySelect'
import Loader from '../global/Loader'
import formHasErrors from '../../mixins/formHasErrors'

export default {
  name: 'ProfileForm',
  components: {
    AccountInput,
    BirthdayInput,
    CountrySelect,
    Loader
  },
  mixins: [
    formHasErrors
  ],
  data () {
    return {
      isLoading: false,
      formField: 'formData',
      formData: {
        account_type: null,
        company: null,
        first_name: null,
        middle_name: null,
        last_name: null,
        birthday_day: null,
        birthday_month: null,
        birthday_year: null,
        email: null,
        phone: null,
        phone_country: null,
        address: null,
        address2: null,
        city: null,
        state: null,
        zipcode: null,
        country: null
      }
    }
  },
  watch: {
    'formData.phone' (val) {
      if (val && this.$refs.phoneInput && this.$refs.phoneInput.countryCode()) {
        this.formData.phone_country = this.$refs.phoneInput.countryCode().toUpperCase()
      }
    }
  },
  computed: {
    birthday () {
      // return {birthday_day, birthday_month, birthday_year} = this.formData
      // return (({ birthday_day, birthday_month, birthday_year }) => ({ birthday_day, birthday_month, birthday_year }))(this.formData)
      return {
        birthday_day: this.formData.birthday_day,
        birthday_month: this.formData.birthday_month,
        birthday_year: this.formData.birthday_year
      }
    },
    emailLabel () {
      return (this.profile.email_verified
        ? '<span class="verified">' + this.$t('profile.phone_verified') + '</span>'
        : '<span class="not_verified">' + this.$t('profile.phone_not_verified') + '</span>')
    },
    phoneLabel () {
      if(this.profile.phone_verified){
        return '<span class="verified">' + this.$t('profile.phone_verified') + '</span>';
      }else{
        if(this.profile.phone_allowed){
          return '<span class="not_verified">' + this.$t('profile.phone_not_verified') + '</span>';
        }else{
          return '<span class="not_verified">' + this.$t('profile.phone_not_allowed') + '</span>';

        }
      }
    },
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  mounted () {
    let keys = Object.keys(this.formData)
    let bday = {
      birthday_day: null,
      birthday_month: null,
      birthday_year: null
    }
    this.formData = Object.assign({}, bday, pick(this.profile, keys))
  },
  methods: {
    changeBirthday (bday) {
      this.formData.birthday_day = bday.birthday_day ? bday.birthday_day : null
      this.formData.birthday_month = bday.birthday_month ? bday.birthday_month : null
      this.formData.birthday_year = bday.birthday_year ? bday.birthday_year : null
    },
    updateProfile () {
      this.clearErrors()
      this.isLoading = true

      axios.post(this.$api.updateUserProfile, this.formData)
        .then((response) => {
          this.isLoading = false
          if (response.status > 200 && response.status < 300) {
            this.$store.dispatch('setAuthUser')
            this.$store.dispatch('showSuccessNotification', this.$t('profile.success'))
            this.$emit('updated')
          }
        }).catch((error) => {
          this.isLoading = false
          if (error.response) {
            if (error.response.status === 422) {
              this.$store.dispatch('showErrorNotification', this.$t('general.errors_below'))
              this.setErrors(error.response.data.errors)
            }
          }
        })
    },
    resendEmail () {
      this.isLoading = true
      axios.post(this.$api.resendEmail, {}).then((response) => {
        this.$store.dispatch('setAuthUser').then(() => {
          this.$store.dispatch('showSuccessNotification', this.$t('verify.email_sent'))
        })
        this.isLoading = false
      }).catch((error) => {
        if (error.response.data) {
          this.$store.dispatch('showInfoNotification', error.response.data.data.message)
        }
        this.isLoading = false
      })
    }
  }
}
</script>
