<template>
  <Loader
    :loading="isLoading"
  >
    <div class="row">
      <div class="col-xl-12">
        <div class="kt-portlet">
          <div class="kt-portlet__head">
            <div class="kt-portlet__head-label">
              <template v-if="profile.account_verified">
                <template v-if="optional && !profile.kyc_verified2">
                  <h3 class="kt-portlet__head-title kt-font-warning">Profile Verification Complete, but Marketplace Verification is not.</h3>
                </template>
                <template v-else>
                  <h3 class="kt-portlet__head-title kt-font-success" v-html="$t('verify.profile_complete')"></h3>
                </template>
              </template>
              <template v-else>
                <h3 class="kt-portlet__head-title kt-font-danger" v-html="$t('verify.profile_incomplete')"></h3>
              </template>
            </div>
          </div>
          <div class="kt-portlet__body">
            <div class="kt-list-timeline">
              <div class="kt-list-timeline__items">
                <div class="kt-list-timeline__item">
                  <span
                    class="kt-list-timeline__badge "
                    :class="{
                      'kt-list-timeline__badge--success': profile.email_verified,
                      'kt-list-timeline__badge--danger': !profile.email_verified
                    }"
                  />
                  <span
                    class="kt-list-timeline__text"
                    :class="{
                      'kt-font-success': profile.email_verified,
                      'kt-font-danger': !profile.email_verified
                    }"
                  >
                    <template v-if="profile.email_verified">
                      {{ $t('verify.email_confirmed') }}
                    </template>
                    <template v-else>
                      {{ $t('verify.email_not_confirmed') }}
                      <a
                        href="#"
                        @click.prevent="resendEmail"
                      >
                        {{ $t('verify.email_resend') }}
                      </a>
                    </template>
                  </span>
                </div>

                <div class="kt-list-timeline__item">
                  <span
                    class="kt-list-timeline__badge "
                    :class="{
                      'kt-list-timeline__badge--success': profile.profile_complete,
                      'kt-list-timeline__badge--danger': !profile.profile_complete
                    }"
                  />
                  <span
                    class="kt-list-timeline__text"
                    :class="{
                      'kt-font-success': profile.profile_complete,
                      'kt-font-danger': !profile.profile_complete
                    }"
                  >
                    <template v-if="profile.profile_complete">
                      {{ $t('verify.profile_info_complete') }}
                    </template>
                    <template v-else>
                      {{ $t('verify.profile_info_incomplete') }}
                      <a
                        href="#"
                        @click.prevent="scrollTo('profile', '#mainContentArea')"
                      >
                        {{ $t('verify.profile_info_fill') }}
                      </a>
                    </template>
                  </span>
                </div>

                <div class="kt-list-timeline__item">
                  <span
                    class="kt-list-timeline__badge "
                    :class="{
                      'kt-list-timeline__badge--success': profile.mfa_enabled,
                      'kt-list-timeline__badge--danger': !profile.mfa_enabled
                    }"
                  />
                  <span
                    class="kt-list-timeline__text"
                    :class="{
                      'kt-font-success': profile.mfa_enabled,
                      'kt-font-danger': !profile.mfa_enabled
                    }"
                  >
                    <template v-if="profile.mfa_enabled">
                      {{ $t('verify.mfa_set') }}
                    </template>
                    <template v-else>
                      {{ $t('verify.mfa_not_set') }}
                      <a
                        href="#"
                        @click.prevent="scrollTo('2fa', '#mainContentArea')"
                      >
                        {{ $t('verify.mfa_set_link') }}
                      </a>
                    </template>
                  </span>
                </div>

<!--                <div class="kt-list-timeline__item">-->
<!--                  <span-->
<!--                    class="kt-list-timeline__badge "-->
<!--                    :class="{-->
<!--                      'kt-list-timeline__badge&#45;&#45;warning': profile.deposit_first_time-->
<!--                    }"-->
<!--                  />-->
<!--                  <span-->
<!--                    class="kt-list-timeline__text"-->
<!--                    :class="{-->
<!--                      'kt-font-warning': profile.deposit_first_time-->
<!--                    }"-->
<!--                  >-->
<!--                    <router-link :to="{name: 'deposit'}">-->
<!--                      {{ $t('verify.first_deposit') }}-->
<!--                    </router-link>-->
<!--                  </span>-->
<!--                </div>-->

<!--                <div class="kt-list-timeline__item">-->
<!--                  <span-->
<!--                    class="kt-list-timeline__badge "-->
<!--                    :class="{-->
<!--                      'kt-list-timeline__badge&#45;&#45;warning': profile.has_profits-->
<!--                    }"-->
<!--                  />-->
<!--                  <span-->
<!--                    class="kt-list-timeline__text"-->
<!--                    :class="{-->
<!--                      'kt-font-warning': profile.has_profits-->
<!--                    }"-->
<!--                  >-->
<!--                    {{ $t('verify.first_profit') }}-->
<!--                  </span>-->
<!--                </div>-->


                <div class="kt-list-timeline__item">
                <span
                  class="kt-list-timeline__badge "
                  :class="{
                    'kt-list-timeline__badge--success': profile.kyc_verified,
                    'kt-list-timeline__badge--warning': profile.kyc_awaiting && !profile.kyc_verified,
                    'kt-list-timeline__badge--danger': !profile.kyc_awaiting && !profile.kyc_verified
                  }"
                />
                  <span
                    class="kt-list-timeline__text"
                    :class="{
                    'kt-font-success': profile.kyc_verified,
                    'kt-font-warning': profile.kyc_awaiting && !profile.kyc_verified,
                    'kt-font-danger': !profile.kyc_awaiting && !profile.kyc_verified
                  }"
                  >
                    <template v-if="profile.kyc_verified">
                      {{ $t('verify.kyc_ok') }}
                    </template>
                    <template v-else-if="profile.kyc_awaiting">
                      {{ $t('verify.kyc_review') }}
                    </template>
                    <template v-else>
                      {{ $t('verify.kyc_not_ok') }}
                      <a
                        href="#"
                        @click.prevent="scrollTo('kyc', '#mainContentArea')"
                      >
                        {{ $t('verify.kyc_upload') }}
                      </a>
                    </template>
                  </span>
                </div>
              </div>
            </div>

            <div v-if="optional" class="kt-list-timeline kt-margin-t-20">
              <div class="kt-list-timeline__items">

                <div class="kt-list-timeline__item">
                    <span
                      class="kt-list-timeline__badge "
                      :class="{
                        'kt-list-timeline__badge--success': profile.kyc_verified2,
                        'kt-list-timeline__badge--warning': profile.kyc_awaiting2 && !profile.kyc_verified2,
                        'kt-list-timeline__badge--danger': !profile.kyc_awaiting2 && !profile.kyc_verified2
                      }"
                    />
                  <span
                    class="kt-list-timeline__text"
                    :class="{
                        'kt-font-success': profile.kyc_verified2,
                        'kt-font-warning': profile.kyc_awaiting2 && !profile.kyc_verified2,
                        'kt-font-danger': !profile.kyc_awaiting2 && !profile.kyc_verified2
                      }"
                  >
                        <template v-if="profile.kyc_verified2">
                          Marketplace Agreement has been verified!
                        </template>
                        <template v-else-if="profile.kyc_awaiting2">
                          Marketplace Agreement is in review.
                        </template>
                        <template v-else>
                          Marketplace Agreement is not uploaded.
                          <a
                            href="#"
                            @click.prevent="scrollTo('kyc', '#mainContentArea')"
                          >
                            Upload
                          </a>
                        </template>
                      </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Loader>
</template>

<script>
import { mapState } from 'vuex'
import Loader from 'js/components/global/Loader'
import vscroll from 'vue-scrollto'

export default {
  name: 'VerificationInfo',
  components: {
    Loader
  },
  props: {
    optional: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  methods: {
    resendEmail () {
      this.isLoading = true
      axios.post(this.$api.resendEmail, {}).then((response) => {
        this.$store.dispatch('setAuthUser').then(() => {
          this.$store.dispatch('showSuccessNotification', this.$t('verify.email_sent'))
        })
        this.isLoading = false
      }).catch((error) => {
        if (error.response.data) {
          this.$store.dispatch('showInfoNotification', error.response.data.data.message)
        }
        this.isLoading = false
      })
    },
    scrollTo (route, selector) {
      if (this.$route.name === route) {
        vscroll.scrollTo(selector, 500)
      } else {
        this.$router.push({ name: route }, () => {
          this.$nextTick(() => {
            setTimeout(() => {
              vscroll.scrollTo(selector, 500)
            }, 600)
          })
        })
      }
    }
  }
}
</script>
