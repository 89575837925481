<template>
  <b-form-group
    :description="description"
    :label="label"
    :label-for="id"
    :invalid-feedback="errorMessage"
    :state="state"
    :label-cols-xl="cols === 0 ? null : cols"
    :label-cols-ld="cols === 0 ? null : cols"
  >
    <!--<b-select-->
      <!--:id="id"-->
      <!--v-model="content"-->
      <!--:state="state"-->
      <!--:name="name"-->
      <!--:required="required"-->
      <!--:autocomplete="autocomplete"-->
      <!--:options="options"-->
      <!--@change="handleChange"-->
    <!--/>-->

    <select
      :id="id"
      v-model="content"
      class="form-control"
      :name="name"
      :autocomplete="autocomplete"
      :required="required"
      @change="handleChange"
    >
      <template v-if="suggestedCountry">
        <option :value="suggestedCountry.value">
          {{ suggestedCountry.text }}
        </option>
        <option disabled>
          ──────────
        </option>
      </template>
      <option
        v-for="country in countries"
        :value="country.value"
      >
        {{ country.text }}
      </option>
    </select>

  </b-form-group>
</template>

<script>
export default {
  name: 'CountrySelect',
  props: {
    name: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    autocomplete: {
      type: String,
      default: null
    },
    errors: {
      type: Object,
      default: () => {
        return {}
      }
    },
    id: {
      type: String,
      default: null
    },
    description: {
      type: String,
      default: null
    },
    cols: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      content: this.value,
      countries: []
    }
  },
  computed: {
    hasErrors: {
      cache: false,
      get () {
        return this.errors.hasOwnProperty(this.name) && Array.isArray(this.errors[this.name]) && this.errors[this.name].length > 0
      }
    },
    errorMessage () {
      if (this.hasErrors) {
        return this.errors[this.name][0]
      }
      return ''
    },
    state: {
      cache: false,
      get () {
        return this.hasErrors ? false : null
      }
    },
    suggestedCountry () {
      if (this.countries) {
        let suggested = this.countries.filter(country => country.suggested)
        return suggested ? suggested[0] : null
      }
      return null
    }
  },
  watch: {
    value (newValue) {
      this.content = newValue
    }
  },
  created () {
    this.getCountries()
  },
  methods: {
    handleInput () {
      this.$emit('input', this.content)
    },
    handleChange () {
      this.$nextTick(() => {
        this.$emit('input', this.content)
      })
    },
    getCountries () {
      axios.get(this.$api.countries)
        .then((response) => {
          this.countries = response.data.data
        }).catch(() => {})
    }
  }

}
</script>
