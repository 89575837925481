<template>
  <Loader :loading="isLoading">
    <div class="kt-portlet">
      <form @submit.prevent="checkPhone" class="kt-form kt-form--label-right">
        <div class="kt-portlet__body">
          <h5 class="kt-margin-b-15">{{ $t('mfa.phone_title2') }}</h5>
          <p v-if="!profile.phone_verified">{{ $t('mfa.phone_details') }}</p>
          <div class="kt-section kt-section--first kt-margin-b-0">
            <div class="kt-section__body">

              <div class="form-group">
                <div class="input-group">
                  <PhoneInput
                    ref="phoneInput"
                    v-model="formData.phoneNumber"
                    name="phone_number"
                    custom-container-class="phoneInputCustom"
                    required="required"
                  />
                  <div
                    v-if="!phoneEdited && !profile.phone_verified && profile.phone_allowed"
                    class="input-group-append"
                  >
                    <button :disabled="profile.limited_mode" type="button" class="btn btn-info" @click="verifyPhone">{{ $t('mfa.verify') }}</button>
                  </div>
                </div>
                <div v-if="hasErrors" class="invalid-feedback" style="display: block;">
                  {{ errors['phoneNumber'][0] }}
                </div>
                <template v-if="!phoneEdited && profile.phone_verified">
                  <div class="form-text kt-font-success kt-font-bold">{{ $t('mfa.verified') }}</div>
                </template>
                <template v-else>
                  <template v-if="!profile.phone_allowed">
                    <div class="form-text kt-font-danger kt-font-bold">{{ $t('profile.phone_not_allowed') }}</div>
                  </template>
                  <template v-else>
                    <div class="form-text kt-font-danger kt-font-bold">{{ $t('mfa.not_verified') }}</div>
                  </template>
                </template>
              </div>
              <div class="kt-form__actions">
                <button :disabled="!phoneEdited" type="submit" class="btn btn-brand">{{ $t('mfa.phone_change') }}</button>
              </div>

            </div>
          </div>

        </div>

        <PhoneVerifyModal
          v-if="showPhoneModal"
          :show="showPhoneModal"
          :phone="formData.phoneNumber"
          @verified="phoneVerified"
          @hidden="showPhoneModal = false"
        />

        <MfaModal
          v-if="showMfa"
          :show="showMfa"
          :action="action"
          :method="profile.mfa_method"
          @verified="changePhone"
          @hidden="hideMfa"
        />

      </form>
    </div>
  </Loader>
</template>

<script>
import Loader from '../global/Loader'
import PhoneInput from '../global/PhoneInput'
import PhoneVerifyModal from './PhoneVerifyModal'
import requiresMfa from '../../mixins/requiresMfa'
import formHasErrors from '../../mixins/formHasErrors'
import { mapState } from 'vuex'
import {smsSupportedCountries} from "@/config/api";
export default {
  components: {
    PhoneInput,
    PhoneVerifyModal,
    Loader
  },
  mixins: [
    requiresMfa,
    formHasErrors
  ],
  data () {
    return {
      showPhoneModal: false,
      showMfa: false,
      isLoading: false,
      formData: {
        phoneNumber: null
      },
      formField: 'formData',
      supported_countries: []
    }
  },
  computed: {
    phoneEdited () {
      return this.formData.phoneNumber !== this.profile.phone
    },
    ...mapState({
      profile: state => state.authUser.profile
    })
  },
  mounted () {
    this.formData.phoneNumber = this.profile.phone
  },
  created () {
    axios.get(this.$api.smsSupportedCountries)
      .then((response) => {
        this.supported_countries = response.data.data;
      }).catch((error) => {}
    );

  },
  methods: {
    async checkPhone () {
      this.clearErrors()

      if (!this.formData.phoneNumber) {
        this.addError('phoneNumber', this.$t('verify.phone_required'))
        return false
      }
      if (!this.$refs.phoneInput.isValid()) {
        this.addError('phoneNumber', this.$t('verify.phone_invalid'))
        return false
      }

      if(!this.supported_countries.includes(this.$refs.phoneInput.countryCode.toUpperCase())){
        this.addError('phoneNumber', this.$t('verify.phone_blocked',{'code': this.$refs.phoneInput.countryCode.toUpperCase()}))
        return false
      }

      // if (window.AppConfig.blockedCountries.includes(this.$refs.phoneInput.countryCode.toUpperCase())) {
      //   this.addError('phone', 'You can\'t use ' + this.$refs.phoneInput.countryCode.toUpperCase() + ' based phone numbers')
      //   return false
      // }

      if (this.profile.mfa_method) {
        this.verifyMfa('phone_change')
      } else {
        this.changePhone()
      }
    },
    verifyPhone () {
      this.showPhoneModal = true
    },
    changePhone (token = null) {
      this.showMfa = false
      this.isLoading = true
      this.clearErrors()

      axios.post(this.$api.savePhone, {
        phone: this.formData.phoneNumber,
        phone_country: this.$refs.phoneInput.countryCode ? this.$refs.phoneInput.countryCode.toUpperCase() : '',
        token
      })
        .then((response) => {
          this.isLoading = false
          if (response.status >= 200 && response.status < 300) {
            this.$store.dispatch('setAuthUser').then(response => {
              this.formData.phoneNumber = this.profile.phone
            })
            this.$store.dispatch('showSuccessNotification', this.$t('mfa.phone_saved'))
          }
        }).catch((error) => {
          this.isLoading = false
          if (error.response.data.errors) {
            this.setErrors(error.response.data.errors)
          }
        })
    },
    phoneVerified () {
      this.phoneModal = false
      this.showPhoneModal = false
      this.isLoading = true
      this.$store.dispatch('setAuthUser').then(response => {
        this.isLoading = false
      })
      this.$store.dispatch('showSuccessNotification', this.$t('mfa.phone_success'))
    }
  }
}
</script>

<style>
  .phoneInputCustom {
    width: 75% !important;
  }
</style>
